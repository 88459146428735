import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: 55,
  border: "unset",
  //   backgroundColor: "var(--White, #FFF)",
  height: "44px",
  fontSize: "16px",
  fontFamily: "Poppins",
  textTransform: "none",
  color: "#929292",
  "&:hover": {
    // backgroundColor: "#FEBA3F",
  },
  "& .MuiButtonBase-root": {
    "&:hover": {
      "& .MuiTouchRipple-root": {
        backgroundColor: "#FEBA3F",
      },
    },
  },
  "& .MuiTouchRipple-root": {
    backgroundColor: "transparent",
  },
}));

function ButtonBack({
  text,
  style,
  disableRipple,
  disable,
  onClick,
  ...props
}) {
  return (
    <CustomButton
      disableRipple={disableRipple}
      disabled={disable}
      style={style}
      onClick={onClick}
      {...props}
    >
      <ArrowBackIosNewIcon sx={{ fontSize: "16px" }} />
      {text}
    </CustomButton>
  );
}

export default ButtonBack;
