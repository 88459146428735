import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import GlobalContext from "../../../../Context/Context";
import { CONNECT_DATA_VALIDATION_SCHEMA } from "../../../../consts/validators.consts";
import HeyDayTextField from "../../../../Shared/Components/TextField/HeyDayTextField";
import InfoTooltip from "../../../../Shared/Components/Tooltip/InfoTooltip";
import languages from "../../../../Shared/supported-languages.json";
import getPlaceholdersObject from "../../../Admin/Dashboard/EditDomain/Tabs/PlaceHolders/getPlaceholdersObject";
import { updateIntegrationState } from "../../helpers/helpers";
import { formFetch } from "../../../../Shared/functions/fetch";
import HeyDayFormLabel from "../../../../Shared/Components/FormLabel/HeyDayFormLabel";

const ConnectYourDataForm = ({
  setLoading,
  errorMessage,
  setErrorMessage,
  setIsContactUsPage,
  pDomain,
  pFeedUrl,
  navigate,
}) => {
  const context = useContext(GlobalContext);
  const [step, setStep] = useState(1);
  const [language, setLanguage] = useState(languages[0].code || "");
  const [langDir, setLangDir] = useState("ltr");
  const [placeholdersObj, setPlaceholdersObj] = useState({});
  const [isPluginPage, setIsPluginPage] = useState(false);

  let configs = [];

  const handleChangeLang = (e) => {
    const langObj = languages.find((obj) => obj.code === e.target.value) || {};
    const languagesDir = languages.find(
      (obj) => obj.code === e.target.value
    )?.dir;
    setPlaceholdersObj(getPlaceholdersObject(langObj.code));
    languagesDir && setLangDir(languagesDir);
    setLanguage(langObj.code);
  };

  const getUpdatedConfigs = (domain) => {
    let domainObj = context.getDomains().find((o) => o.domain === domain);
    try {
      configs = JSON.parse(domainObj.configs);
    } catch (e) {
      configs = [
        {},
        {
          searchBox: {
            type: "search",
            tmpl: {
              htmlBox: "",
              autocomplete_item: "",
              instant_search_item: "",
              results_data: "",
              search_item: "",
            },
            customTmpl: { SM_holder: { htmlBox: "", search_item: "" } },
            elements: [],
            stylesheet: "",
          },
        },
      ];
    }
    const updatedConfig = [...configs];
    const searchBoxIndex = updatedConfig.findIndex((item) => item.searchBox);
    if (searchBoxIndex !== -1 && updatedConfig[searchBoxIndex].searchBox) {
      updatedConfig[searchBoxIndex].searchBox.dir = langDir;
      if (!updatedConfig[searchBoxIndex].searchBox.customTmpl) {
        updatedConfig[searchBoxIndex].searchBox.customTmpl = {
          SM_holder: { htmlBox: "", search_item: "" },
        };
      } else if (
        !updatedConfig[searchBoxIndex].searchBox.customTmpl.SM_holder
      ) {
        updatedConfig[searchBoxIndex].searchBox.customTmpl.SM_holder = {
          htmlBox: "",
          search_item: "",
        };
      }
      updatedConfig[searchBoxIndex].searchBox.featureLanguage = language;
      if (!updatedConfig[searchBoxIndex].searchBox.tmplPlaceHolders) {
        updatedConfig[searchBoxIndex].searchBox.tmplPlaceHolders = {};
      }
      updatedConfig[searchBoxIndex].searchBox.tmplPlaceHolders =
        placeholdersObj;
    }
    return updatedConfig;
  };

  const handleSaveConfig = async (domain) => {
    const host = sessionStorage.getItem("currentDomain");
    if (host) {
      domain = host;
    }
    const updatedConfig = getUpdatedConfigs(domain);
    try {
      const requestJson = {
        action: 1014,
        host: domain,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };
      const responseJson = await context.myFetch(requestJson);
      if (responseJson.domains) {
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );
      } else if (responseJson.error) {
        context.message({ type: "error", body: responseJson.error });
      }
    } catch (error) {
      context.message({ type: "error", body: error });
    }
  };

  const createDomain = async (domainName) => {
    if (domainName && domainName.trim() !== "") {
      let clearedUrl = domainName.trim();
      const hasProtocol = new RegExp("^https?://").test(clearedUrl);
      let fullUrl;
      try {
        fullUrl = new URL(domainName);
      } catch (e) {}
      let name = hasProtocol ? clearedUrl : `https://${clearedUrl}`;

      let requestDomainCheckJson = { action: 1400, domainName: clearedUrl };
      let responseDomainCheck = await context.myFetch(
        requestDomainCheckJson,
        "op"
      );
      let isInvalid = responseDomainCheck.invalidDomainName;
      let isExists = responseDomainCheck.isDomainTaken;

      if (isExists || isInvalid) {
        setIsPluginPage(false);
        return Promise.reject(
          isInvalid ? "The site could not be found" : "Domain is taken"
        );
      }

      const requestJson = {
        action: 1017,
        domainName: name,
        searchScoreType: 2,
        credentials: context.loadCredentials(),
      };

      const fetchResponse = await context.myFetch(requestJson, "addDomain");
      const responseJson = fetchResponse;
      if (responseJson.error) {
        setIsPluginPage(false);
        return Promise.reject(responseJson.error);
      }
      sessionStorage.removeItem("AC_seen");
      sessionStorage.setItem("new_domain", true);

      let dom = hasProtocol && fullUrl ? fullUrl.host : domainName;

      if (domainName.endsWith("/")) {
        dom =
          hasProtocol && fullUrl
            ? fullUrl.host.slice(0, -1)
            : domainName.slice(0, -1);
      }

      if (responseJson.domainName) {
        window.ga("create", "UA-162443792-1", "auto");
        window.ga("set", "page", "/signup/signupSuccess");
        window.ga("send", "pageview");
        window.gtag("event", "conversion", {
          send_to: "AW-654142397/HttVCNyu9MsBEL3X9bcC",
        });
        sessionStorage.setItem("currentDomain", responseJson.domainName);
      }

      sessionStorage.setItem(
        "allDomains",
        JSON.stringify(responseJson.domains)
      );
      if (responseJson.domains.length > 0) {
        let domArr = responseJson.domains.map((item) => item.domain);
        sessionStorage.setItem("domains", domArr);
      }
      return Promise.resolve(responseJson);
    } else {
      setIsPluginPage(false);
      return Promise.reject("Set domain name");
    }
  };

  const sendFeedToServer = async (feedUrl, domain) => {
    if (sessionStorage.getItem("currentDomain")) {
      domain = sessionStorage.getItem("currentDomain");
    }
    const requestJson = {
      action: 18,
      feedUrl: feedUrl,
      host: domain,
      credentials: context.loadCredentials(),
    };
    try {
      const response = await context.myFetch(requestJson);
      if (response.error) {
        setLoading(false);
        return context.message({
          type: "error",
          body: "The feed is not valid",
        });
      } else if (response.actionStatus) {
        const pollingData = async (startTime) => {
          const maxTime = 300000;
          const currentTime = new Date().getTime();

          if (currentTime - startTime >= maxTime) {
            setLoading(false);
            const emailReq = {
              name: sessionStorage.getItem("contactName") || "",
              email: sessionStorage.getItem("name") || "",
              domain: domain,
              message: "Error: polling feed - at connect your data step 1",
              feedUrl: feedUrl,
            };
            formFetch(emailReq)
              .then((r) => {
                console.log("email sent successfully", r);
              })
              .catch((e) => {
                console.error("error sending email:", e);
              });
            setErrorMessage("Feed is not valid, please contact us");
            return context.message({
              type: "error",
              body: "Feed is not valid, please contact us",
            });
          }

          const req = {
            action: 20,
            host: domain,
            credentials: context.loadCredentials(),
          };

          const res = await context.myFetch(req);
          if (!res.actionStatus) {
            setLoading(false);
            setIsPluginPage(false);
            return;
          }
          if (res.noDataYet) {
            setTimeout(() => pollingData(startTime), 1000);
          } else {
            if (res.pollingData) {
              const data = JSON.parse(res.pollingData);
              if (data) {
                if (data.errorCode) {
                  setLoading(false);
                  setErrorMessage(data.errorCode + " please contact us");
                  setIsPluginPage(false);
                  return context.message({
                    type: "error",
                    body: data.errorCode + " please contact us",
                  });
                } else if (data.length > 2) {
                  window.gtag("event", "conversion", {
                    send_to: "AW-654142397/ZL7pCJDq6bkZEL3X9bcC",
                  });
                  if (pDomain !== "" && pFeedUrl !== "" && +sessionStorage.getItem("affStatus") !== 1) {
                    await updateIntegrationState(4, context);
                    navigate("/selfserve/4", { state: { responseData: data } });
                  } else {
                    await updateIntegrationState(3, context);
                    navigate("/selfserve/3", { state: { responseData: data } });
                  }
                  setLoading(false);
                }
              }
            } else if (res.error) {
              setIsPluginPage(false);
              return context.message({ type: "error", body: res.error });
            }
            setLoading(false);
          }
        };
        setTimeout(() => pollingData(new Date().getTime()), 3000);
        // pollingData(new Date().getTime());
      } else {
        setLoading(false);
        setIsPluginPage(false);
        setErrorMessage("The feed is not valid");
        return context.message({
          type: "error",
          body: "The feed is not valid",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const normalizeDomain = (domain) => {
    domain = domain.toLowerCase();
    domain = domain.replace(/^(https?:\/\/)?(www\.)?/, "");
    domain = domain.split("/")[0];
    return domain;
  };

  const formik = useFormik({
    initialValues: { domain: pDomain, feedUrl: pFeedUrl, trackingId: "" },
    validationSchema: CONNECT_DATA_VALIDATION_SCHEMA,
    onSubmit: async (values) => {
      setErrorMessage("");
      await formik.validateForm();
      if (formik.isValid) {
        setLoading(true);
        const normalizedInputDomain = normalizeDomain(values.domain);

        if (sessionStorage.getItem("domains")) {
          let domainFound = false;
          let sessionDomains = sessionStorage.getItem("domains");
          if (typeof sessionDomains === "string") {
            sessionDomains = sessionDomains.split(",");
          }

          for (let domain of sessionDomains) {
            if (normalizeDomain(domain) === normalizedInputDomain) {
              domainFound = true;
              sessionStorage.setItem("currentDomain", domain);
              await handleSaveConfig(values.domain);
              await sendFeedToServer(values.feedUrl, values.domain);
              break;
            }
          }

          if (!domainFound) {
            createDomain(values.domain)
              .then((data) => {
                console.log(data);
                handleSaveConfig(values.domain);
                sendFeedToServer(values.feedUrl, values.domain);
              })
              .catch((error) => {
                console.log(error);
                context.message({ type: "error", body: error });
              })
              .finally(() => setLoading(false));
          }
        } else {
          createDomain(values.domain)
            .then((data) => {
              console.log(data);
              handleSaveConfig(values.domain);
              sendFeedToServer(values.feedUrl, values.domain);
            })
            .catch((error) => {
              context.message({ type: "error", body: error });
            })
            .finally(() => setLoading(false));
        }
      }
    },
  });

  const handleNextStep = async () => {
    setErrorMessage("");
    if (step === 1) {
      try {
        const normalizedInputDomain = normalizeDomain(formik.values.domain);
        if (sessionStorage.getItem("domains")) {
          let domainFound = false;
          let sessionDomains = sessionStorage.getItem("domains");
          if (typeof sessionDomains === "string") {
            sessionDomains = sessionDomains.split(",");
          }

          for (let domain of sessionDomains) {
            if (normalizeDomain(domain) === normalizedInputDomain) {
              domainFound = true;
              sessionStorage.setItem("currentDomain", domain);
              await handleSaveConfig(formik.values.domain);
              setStep(2);
              break;
            }
          }

          if (!domainFound) {
            setLoading(true);
            createDomain(formik.values.domain)
              .then((data) => {
                console.log(data);
                handleSaveConfig(formik.values.domain);
                setLoading(false);
                setStep(2);
              })
              .catch((error) => {
                console.log(error);
                context.message({ type: "error", body: error });
              })
              .finally(() => {
                setLoading(false);
              });
          }
        } else {
          setLoading(true);
          createDomain(formik.values.domain)
            .then((data) => {
              console.log(data);
              handleSaveConfig(formik.values.domain);
              setStep(2);
            })
            .catch((error) => {
              context.message({ type: "error", body: error });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } catch (error) {
        context.message({ type: "error", body: error.message });
      }
    }
  };

  useEffect(() => {
    if (pDomain !== "" && pFeedUrl !== "") {
      formik.setValues({ domain: pDomain, feedUrl: pFeedUrl });
      setLoading(true);
      setIsPluginPage(true);
      formik.handleSubmit();
    }
  }, [pDomain, pFeedUrl]);

  if (isPluginPage) {
    return (
      <div
        style={{ marginTop: "20px" }}
        className="searchPreview-loading-message"
      >
        Please wait - We are parsing your products feed
        <div className="searchPreview-loading-dots">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <img
        alt="group87"
        style={{ marginTop: "30px", height: "130px" }}
        src="/img/Group87.png"
      />
      {step === 1 && (
        <div className="hdy-connect-data-card-desc">
          <div>Add your domain</div>{" "}
        </div>
      )}
      {step === 2 && (
        <div className="hdy-connect-data-card-desc-active">
          <div>Please provide a standard product feed,</div>
          <div>
            such as Google or Facebook{" "}
            <InfoTooltip
              iconStyle={{ color: "#616060" }}
              text={
                <React.Fragment>
                  <p style={{ margin: 0 }}>
                    Example of a valid Google Merchant feed link:
                  </p>
                  <a
                    style={{ color: "#ffffff" }}
                    href="https://support.google.com/merchants/answer/7439058?hl=en&ref_topic=3163841&sjid=8002343952450228099-EU"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Merchant Feed Documentation
                  </a>
                </React.Fragment>
              }
            />
          </div>
        </div>
      )}
      <>
        <div>
          <HeyDayTextField
            {...formik.getFieldProps("domain")}
            error={formik.touched.domain && Boolean(formik.errors.domain)}
            disabled={step === 2}
            helperText={formik.touched.domain && formik.errors.domain}
            sx={{ marginTop: "20px", width: "90%", marginRight: "20px" }}
            required
            labelValue="Enter your domain: (www.yourwebsite.com)"
          />
        </div>
        {step === 1 && (
          <div className="hdy-footer-card">
            <Button
              className="problem-btn"
              onClick={(e) => setIsContactUsPage(true)}
            >
              Have some problem? <span>contact us</span>
            </Button>
            {errorMessage !== "" && (
              <div className="error-message">{errorMessage}</div>
            )}
            <Button
              className={`hdy-next-btn ${
                Boolean(formik.errors.domain) ? "" : "disabled"
              }`}
              type="button"
              onClick={handleNextStep}
              disabled={Boolean(formik.errors.domain) || !formik.values.domain}
            >
              Next
            </Button>
          </div>
        )}
      </>
      {step === 2 && (
        <>
          <div>
            <HeyDayTextField
              required
              {...formik.getFieldProps("feedUrl")}
              error={formik.touched.feedUrl && Boolean(formik.errors.feedUrl)}
              helperText={formik.touched.feedUrl && formik.errors.feedUrl}
              style={{ marginTop: "20px", width: "90%" }}
              labelValue="Your products merchant feed: (https://www.your-feed...)"
            />
            <InfoTooltip
              iconStyle={{ color: "#616060" }}
              text={
                <React.Fragment>
                  <p style={{ margin: 0 }}>
                    Example of a valid Google Merchant feed link:
                  </p>
                  <a
                    style={{ color: "#ffffff" }}
                    href="https://support.google.com/merchants/answer/7439058?hl=en&ref_topic=3163841&sjid=8002343952450228099-EU"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Merchant Feed Documentation
                  </a>
                </React.Fragment>
              }
            />
          </div>
          <div className="connect-data-flex-div">
            <HeyDayFormLabel sx={{ display: "block", marginRight: "20px" }}>
              Feature Language:
            </HeyDayFormLabel>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                value={language}
                onChange={(e) => handleChangeLang(e)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {languages.map((lang) => (
                  <MenuItem
                    key={lang.code}
                    sx={{ fontFamily: "Poppins" }}
                    value={lang.code}
                  >
                    {lang.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="hdy-footer-card">
            <Button
              className="problem-btn"
              onClick={(e) => setIsContactUsPage(true)}
            >
              Have some problem? <span>contact us</span>
            </Button>
            {errorMessage !== "" && (
              <div className="error-message">{errorMessage}</div>
            )}
            <Button
              className={`hdy-next-btn ${
                Boolean(formik.errors.feedUrl) ? "" : "disabled"
              }`}
              type="submit"
              disabled={
                Boolean(formik.errors.feedUrl) || !formik.values.feedUrl
              }
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default ConnectYourDataForm;
