import * as Yup from "yup";

export const SIGNUP_VALIDATION_SCHEMA = Yup.object({
  contactName: Yup.string()
    .required("Required field")
    .min(3, "Field must be between 3-32 characters")
    .max(32, "Field must be between 3-32 characters"),
  email: Yup.string()
    .required("Required field")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Valid email is required"),
  password: Yup.string()
    .required("Required field")
    .min(8, "Password must be between 8-16 characters")
    .max(16, "Password must be between 8-16 characters"),
  confirmPassword: Yup.string()
    .required("Required field")
    .oneOf([Yup.ref("password")], "Passwords do not match."),
});

export const CONTACT_US_VALIDATION_SCHEMA = Yup.object({
  fullName: Yup.string()
    .required("Required field")
    .min(4, "Field must be at least 4 characters"),
  email: Yup.string()
    .required("Required field")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Valid email is required"),
  phoneNumber: Yup.string()
    .required("Required field")
    .min(6, "Valid phone number required"),
  message: Yup.string()
    .required("Required field")
    .min(50, "message must be between 50-300 characters")
    .max(300, "message must be between 50-300 characters"),
});

export const CONNECT_DATA_VALIDATION_SCHEMA = Yup.object({
  domain: Yup.string().required("Required field"),
  // .matches(
  //   /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/,
  //   "Valid domain is required"
  // ),
  feedUrl: Yup.string().required("Required field").min(6, "Required field"),
  trackingId: Yup.string().matches(
    /^G-[A-Z0-9]{10}$/,
    "Valid trackingId is required"
  ),
});
