import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export const StartOnboardingModal = ({ openConfirm, setOpenConfirm }) => {
  const goBack = async () => {
    setOpenConfirm(false);
  };

  return (
    <>
      {openConfirm && (
        <Dialog open={openConfirm} classes={{ paper: "mui-paper" }}>
          <div className="confirm-modal-dialog-img">
            <img alt="alert" src="/img/integration.png" />
          </div>

          <DialogTitle sx={{ fontFamily: "Poppins", textAlign: "center" }}>
            Onboarding
          </DialogTitle>
          <DialogContent sx={{ fontFamily: "Poppins", textAlign: "center" }}>
            <p>
              Welcome! We're excited to have you here. 🛍️ Ready to streamline
              your experience? Dive into our self-serve integration for
              effortless product feed parsing.
            </p>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              sx={{ marginRight: "20px !important" }}
              className="skip-btn"
              onClick={goBack}
              color="primary"
            >
              Later
            </Button>
            <Button
              className="complete-payment-btn"
              sx={{ marginRight: "20px !important" }}
              onClick={() => (window.location.href = "/selfserve")}
              color="primary"
            >
              Start onboarding
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
