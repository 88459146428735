import React from "react";
import { Button } from "@mui/material";

const DomainFieldAction = ({
  compact,
  current,
  setCurrent,
  domainName,
  setDomainName,
  setIndex,
  context,
}) => {
  const next = async () => {
    if (domainName && domainName.trim() !== "") {
      let clearedUrl = domainName.trim();
      const hasProtocol = new RegExp("^https?://").test(clearedUrl);
      let fullUrl;
      try {
        fullUrl = new URL(domainName);
      } catch (e) {
        console.log(e);
      }
      let name = hasProtocol ? clearedUrl : `https://${clearedUrl}`;

      let requestDomainCheckJson = {
        action: 1400,
        domainName: clearedUrl,
      };
      let responseDomainCheck = await context.myFetch(requestDomainCheckJson);

      let isInvalid = responseDomainCheck.invalidDomainName;
      let isExists = responseDomainCheck.isDomainTaken;

      if (isExists || isInvalid) {
        return context.message({
          type: "error",
          body: isInvalid ? "The site could not be found" : "Domain is taken",
        });
      }

      if (compact) {
        setDomainName(name);
        setCurrent(current + 1);
      } else {
        const requestJson = {
          action: 1017,
          domainName: name,
          searchScoreType: 1,
          credentials: context.loadCredentials(),
        };

        const fetchResponse = await context.myFetch(requestJson, "addDomain");
        const responseJson = fetchResponse;
        if (responseJson.error) {
          return context.message({
            type: "error",
            body: responseJson.error,
          });
        }
        sessionStorage.removeItem("AC_seen");
        sessionStorage.setItem("new_domain", true);

        let dom = hasProtocol && fullUrl ? fullUrl.host : domainName;

        if (
          domainName.substring(domainName.length, domainName.length - 1) === "/"
        ) {
          dom =
            hasProtocol && fullUrl
              ? fullUrl.host.substring(0, domainName.length - 1)
              : domainName.substring(0, domainName.length - 1);
        }
        setDomainName(responseJson.domainName);
        sessionStorage.setItem("currentDomain", responseJson.domainName);
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );

        const domains = JSON.parse(sessionStorage.getItem("allDomains"));
        const currentDomain = domains.filter(
          (doms) => doms.domain === responseJson.domainName
        );
        const newIndex = domains.indexOf(currentDomain[0]);

        setCurrent(current + 1);
        setIndex(newIndex);
      }
    } else {
      return context.message({
        type: "error",
        body: "Set domain name",
      });
    }
  };

  return (
    <Button className="btn-save" onClick={next}>
      Next
    </Button>
  );
};

export default DomainFieldAction;
