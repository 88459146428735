import React, { useState, useEffect, useContext } from "react";
import ContentPageHeader from "../../ContentPageHeader/ContentPageHeader";
import AdminCard from "../../../../Shared/Components/Card/AdminCard";
import GlobalContext from "../../../../Context/Context";
import { useParams } from "react-router-dom";
import { checkIntegration } from "../../Common/helpers";
import { initializeSearch } from "./helpers";
import "./SearchPreview.css";

const SearchPreview = () => {
  const context = useContext(GlobalContext);
  const params = useParams();
  const domain = context.getDomains()[params?.index];
  const [indexSize, setIndexSize] = useState(
    sessionStorage.getItem("indexSize") || 0
  );
  const [prevSize, setPrevSize] = useState(
    sessionStorage.getItem("prevSize") || 0
  );
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [activateSearchBox, setActivateSearchBox] = useState(true);
  const [counterExists, setCounterExists] = useState(true);
  const [acPopupOpen, setACPopupOpen] = useState(false);
  const [showIndPopup, setShowIndPopup] = useState(false);
  const isNewDomain = sessionStorage.getItem("new_domain");
  const previewCounter = sessionStorage.getItem("previewCounter") ?? 0;

  const getIndexSize = async () => {
    const requestJson = {
      action: 44,
      host: domain.domain,
      credentials: context.loadCredentials(),
    };
    const response = await context.myFetch(requestJson);
    if (response && !response.error) {
      if (response.totalDocs > 0 && !isScriptLoaded && activateSearchBox) {
        setActivateSearchBox(false);
        initializeSearch({
          domain: domain.domain,
          setACPopupOpen,
          domainIndex: params?.index,
          _Context: context,
          setIsScriptLoaded,
        });
      }
      setIndexSize(response.totalDocs);
      sessionStorage.setItem("indexSize", response.totalDocs);
    }
  };

  useEffect(() => {
    if (!isNewDomain) {
      getIndexSize();
      !checkIntegration(params?.index, context) && setShowIndPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScriptLoaded]);

  useEffect(() => {
    if (isNewDomain) {
      let counter = previewCounter;
      const interval = setInterval(() => {
        if (indexSize === prevSize) {
          counter++;
          sessionStorage.setItem("previewCounter", counter);
        } else {
          counter = 0;
          sessionStorage.setItem("previewCounter", counter);
          sessionStorage.setItem("prevSize", indexSize);
          setPrevSize(indexSize);
        }
        if (counter === 15) {
          setCounterExists(false);
          indexSize > 0 && setShowIndPopup(true);
          sessionStorage.removeItem("new_domain");
          clearInterval(interval);
        }
        getIndexSize();
      }, 10000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexSize, prevSize]);

  return (
    <div>
      <section className="content-header">
        <ContentPageHeader pageName="Search preview" subMenu="Search" />
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
          minHeight: "200px",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "750px",
          boxShadow: "unset",
        }}
      >
        <section className="content">
          {/* <IndexingPopup
            showIndPopup={showIndPopup}
            domainIndex={params?.index}
            context={context}
          />
          <AutoCompPopup
            acPopupOpen={acPopupOpen}
            setACPopupOpen={setACPopupOpen}
            params={domain}
            context={context}
          /> */}
          {indexSize === 0 && (
            <div
              style={{
                marginTop: "180px",
              }}
            >
              <div className="searchPreview-loading-message">
                Please wait - We are indexing your domain
                <div className="searchPreview-loading-dots">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          )}
          <div id="searchPreview"></div>
          {/* {!isNewDomain && (
            <Typography variant="body1" className="outsidedIndexSize">
              index size: {context.commaSeparator(indexSize)} pages
              {!showIndPopup && (
                <CircularProgress
                  size={24}
                  thickness={6}
                  className={"ousidedSpinner"}
                />
              )}
            </Typography>
          )}
          {isNewDomain && (
            <DemoLoader
              indexSize={indexSize}
              indexError={indexError}
              counterExists={counterExists}
            />
          )} */}
        </section>
      </AdminCard>
    </div>
  );
};

export default SearchPreview;
