import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import LegendToggleRoundedIcon from "@mui/icons-material/LegendToggleRounded";
import TroubleshootOutlinedIcon from "@mui/icons-material/TroubleshootOutlined";
// import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

const nestedList = [
  {
    title: "Dashboard",
    icon: <BarChartRoundedIcon />,
    links: [
      {
        title: "Edit Domain",
        url: "/edit-domain",
        icon: <ModeEditOutlinedIcon />,
      },
    ],
  },
  {
    title: "Search",
    icon: <ManageSearchRoundedIcon />,
    links: [
      {
        title: "Manage Stop Words",
        url: "/stop-words",
        icon: <RemoveCircleOutlineOutlinedIcon />,
      },
      {
        title: "Manage Synonyms",
        url: "/synonyms",
        icon: <SwapHorizontalCircleOutlinedIcon />,
      },
      {
        title: "Domain Grouping",
        url: "/domain-grouping",
        icon: <GroupWorkOutlinedIcon />,
      },
      { title: "Filters", url: "/filters", icon: <FilterAltOutlinedIcon /> },
      {
        title: "Manage Search Results",
        url: "/search-results",
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: "Search Preview",
        url: "/search-preview",
        icon: <VisibilityOutlinedIcon />,
      },
    ],
  },
  {
    title: "Statistics",
    icon: <QueryStatsOutlinedIcon />,
    links: [
      {
        title: "Site Analytics",
        url: "/site-analytics",
        icon: <LegendToggleRoundedIcon />,
      },
      {
        title: "Search Queries",
        url: "/search-queries",
        icon: <TroubleshootOutlinedIcon />,
      },
      // {
      //   title: 'Trending Searches',
      //   url: '/trending-searches',
      //   icon: <TrendingUpOutlinedIcon />,
      // },
    ],
  },
  {
    title: "Search More",
    icon: <ManageSearchRoundedIcon />,
    links: [
      {
        title: "Domain Grouping",
        url: "/domain-grouping",
        icon: <GroupWorkOutlinedIcon />,
      },
      { title: "Filters", url: "/filters", icon: <FilterAltOutlinedIcon /> },
      {
        title: "Manage Search Results",
        url: "/search-results",
        icon: <SettingsOutlinedIcon />,
      },
      {
        title: "Search Preview",
        url: "/search-preview",
        icon: <VisibilityOutlinedIcon />,
      },
    ],
  },
];

export default nestedList;
