import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import InfoTooltip from "../../../../Shared/Components/Tooltip/InfoTooltip";
import ButtonSave from "../../../../Shared/Components/Button/ButtonSave";
import AddIcon from "@mui/icons-material/Add";

const Variants = ({
  parentField,
  setDisplayAttributesPage,
  variantName,
  setVariantName,
  variantValue,
  setVariantValue,
  variants,
  setVariants,
  setDisplayVarPage,
  variantsStep,
  setVariantsStep,
  handleSaveCustomParse,
}) => {
  const [hasVariants, setHasVariants] = useState(null);

  const handleNext = () => {
    setVariantsStep((prevStep) => prevStep + 1);
  };

  const handleVariantNameChange = (e) => {
    setVariantName(e.target.value);
  };

  const handleAddVariant = () => {
    setVariants([...variants, { variantName, variantValue }]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = {
      parentProduct: parentField,
      variants,
    };
    handleSaveCustomParse(false, result);
    console.log(result);
    setDisplayVarPage(false);
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {variantsStep === 0 && (
        <div>
          <p className="select-feed-paragraph-bold">
            Do you have fields variants in your product feed?
            <InfoTooltip
              iconStyle={{ color: "#616060" }}
              text="Select 'Yes' if your product feed contains variant fields that identify parent products. Select 'No' if it does not."
            />
          </p>
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              className="btn-no"
              onClick={() => {
                setHasVariants(false);
                setDisplayAttributesPage(true);
                setDisplayVarPage(false);
                handleNext();
              }}
            >
              No
            </Button>
            <ButtonSave
              onClick={() => {
                setHasVariants(true);
                handleNext();
              }}
              text="Yes"
            />
          </Box>
        </div>
      )}
      {hasVariants && variantsStep === 1 && (
        <div>
          <p className="select-feed-paragraph-bold">
            Select the fields for the parent product
            <InfoTooltip
              iconStyle={{ color: "#616060" }}
              text="Select the field name that identifies the parent product in your feed."
            />
          </p>
        </div>
      )}
      {hasVariants && variantsStep === 2 && (
        <div>
          <p className="select-feed-paragraph-bold">
            Select the variant value
            <InfoTooltip
              iconStyle={{ color: "#616060" }}
              text="Select the field value of the variant (e.g., 'Large', 'Red') for your product."
            />
          </p>
        </div>
      )}
      {hasVariants && variantsStep === 3 && (
        <div>
          <p className="select-feed-paragraph-bold">
            Enter the variant name
            <InfoTooltip
              iconStyle={{ color: "#616060" }}
              text="Enter the name of the variant (e.g., size, color) that differentiates products."
            />
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: 10,
            }}
          >
            <input
              className="variant-name"
              value={variantName}
              onChange={handleVariantNameChange}
              placeholder="Variant Name"
              variant="outlined"
            />

            <ButtonSave
              onClick={() => {
                handleAddVariant();
                setVariantsStep(4);
              }}
              text="Done"
            />
          </div>
        </div>
      )}
      {hasVariants && variantsStep === 4 && (
        <div>
          <p className="select-feed-paragraph-bold">
            Do you have more variants?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Button
              className="btn-addMore"
              onClick={() => {
                setVariantName("");
                setVariantValue("");
                setVariantsStep(2);
              }}
              endIcon={<AddIcon sx={{ marginRight: "0" }} />}
            >
              Add More
            </Button>
            OR
            <ButtonSave onClick={handleSubmit} text="submit" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Variants;
