import React, { useState, useContext, useEffect } from "react";
import {
  Modal,
  Button,
  IconButton,
  Box,
  TextField,
  FormControl,
  Input,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import HeyDayFormLabel from "../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import GlobalContext from "../../../../Context/Context";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./InviteMembersModal.css";

const InviteMembersModal = ({ open, setOpen, step, setStep }) => {
  const [email, setEmail] = useState("");
  const multiUser = sessionStorage.getItem("multiUser") || false;
  const [invitationLink, setInvitationLink] = useState("");
  const context = useContext(GlobalContext);
  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "700px",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  };

  const handleClickSendInvite = async () => {
    const requestJson = {
      action: 1005,
      invite_email: email,
      credentials: context.loadCredentials(),
    };

    try {
      const response = await context.myFetch(requestJson);
      console.log(response);
      if (response.error) {
        return context.message({
          type: "error",
          body: response.error,
        });
      } else {
        context.message({
          type: "success",
          body: "The invitation has been sent successfully!",
        });
        setInvitationLink(
          "https://admin.heyday.io/invitation/" + response.linkToken
        );
        setStep(1);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const copyToClipboard = (event) => {
    const textarea = document.getElementById("invite-link-hdy");
    textarea.select();
    document.execCommand("copy");
    event.target.focus();
    context.message({
      type: "success",
      body: "Copied to clipboard successfully",
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {step === 0 && (
            <>
              {" "}
              <div className="modal-header">
                <div></div>
                <h3>Invite members</h3>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="divider"></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <HeyDayFormLabel sx={{ display: "flex" }}>
                    Invite member
                  </HeyDayFormLabel>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "15px",
                      marginBottom: "20px",
                    }}
                  >
                    <TextField
                      placeholder="example@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      sx={{
                        maxWidth: "446px",
                        width: "100%",
                        marginRight: "20px",
                        fontFamily: "Poppins",
                        "& .MuiOutlinedInput-root": {
                          height: "44px",
                          border: "2px solid var(--Grey, #5daaa1)",
                          borderRadius: "100px",
                          "& fieldset": {
                            borderColor: "white",
                            border: "none",
                          },
                        },
                      }}
                    />
                    <Button
                      onClick={handleClickSendInvite}
                      className="btn-invite-email"
                    >
                      Invite
                    </Button>
                  </div>
                  <div className="divider"></div>
                </Box>
              </div>
            </>
          )}
          {step === 1 && (
            <>
              <div className="btn-container-invite">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div className="success-invite-container">
                <img src="/img/Invite.png" />
                <h3>
                  The invitation has been <br />
                  sent successfully!
                </h3>
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <HeyDayFormLabel sx={{ display: "flex" }}>
                    Share Invitation link
                  </HeyDayFormLabel>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "15px",
                      marginBottom: "20px",
                    }}
                  >
                    <FormControl
                      sx={{
                        width: "100%",
                        fontFamily: "Poppins",
                        "& .MuiInputBase-root": {
                          height: "44px",
                          border: "2px solid var(--Grey, #5daaa1)",
                          borderRadius: "100px",
                          fontFamily: "Poppins",
                          paddingLeft: "20px",
                          "& fieldset": { border: "none" },
                          "&:hover fieldset": { borderBottom: "none" },
                          "&::before, &::after": {
                            borderBottom: "none !important",
                          },
                          "&:hover .MuiInputBase-root": {
                            borderBottom: "none",
                          },
                        },
                      }}
                    >
                      <Input
                        placeholder="example@example.com"
                        value={invitationLink}
                        id="invite-link-hdy"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={copyToClipboard}>
                              <ContentCopyIcon sx={{ color: "#5daaa1" }} />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </Box>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default InviteMembersModal;
