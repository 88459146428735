import React from "react";
import { TextField, Select, FormControl, MenuItem } from "@mui/material";
import HeyDayFormLabel from "../../../../../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";

const TextStyleEditFields = ({
  handleStyleChange,
  textStyles,
  selectedTextElement,
}) => {
  return (
    <div className="style-form">
      <h3>Edit Text Style</h3>
      {textStyles[selectedTextElement]?.display !== undefined && (
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>Display:</HeyDayFormLabel>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={textStyles[selectedTextElement].display}
              onChange={(e) => handleStyleChange(e, "display")}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "180px",
                height: "44px",
                margin: 0,
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                  width: "180px",
                  fontFamily: "Poppins",
                  color: "#616060",
                  margin: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputLabel-shrink": {
                  fontFamily: "Poppins",
                  color: "#616060",
                },
              }}
            >
              <MenuItem value="block">block</MenuItem>
              <MenuItem value="inline-block">inline-block</MenuItem>
              <MenuItem value="flex">flex</MenuItem>
              <MenuItem value="-webkit-box">-webkit-box</MenuItem>
              <MenuItem value="none">none</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
      {textStyles[selectedTextElement].color !== undefined && (
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>Text color:</HeyDayFormLabel>
          <div className="input-color-container">
            <input
              className="input-color"
              type="color"
              id="color"
              value={textStyles[selectedTextElement].color}
              onChange={(e) => handleStyleChange(e, "color")}
            />
            <span>{textStyles[selectedTextElement].color}</span>
          </div>
        </div>
      )}
      {textStyles[selectedTextElement].fontFamily !== undefined && (
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>
            Font Family:
          </HeyDayFormLabel>
          <TextField
            sx={{
              width: "180px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            type="text"
            id="fontFamily"
            value={textStyles[selectedTextElement].fontFamily}
            onChange={(e) => handleStyleChange(e, "fontFamily")}
          />
        </div>
      )}
      {textStyles[selectedTextElement]?.width !== undefined && (
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>Width:</HeyDayFormLabel>
          <TextField
            sx={{
              width: "180px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            type="text"
            id="width"
            value={textStyles[selectedTextElement].width}
            onChange={(e) => handleStyleChange(e, "width")}
          />
        </div>
      )}
      {textStyles[selectedTextElement]?.height !== undefined && (
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>Height:</HeyDayFormLabel>
          <TextField
            sx={{
              width: "180px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            type="text"
            id="height"
            value={textStyles[selectedTextElement].height}
            onChange={(e) => handleStyleChange(e, "height")}
          />
        </div>
      )}
      {textStyles[selectedTextElement]?.fontSize !== undefined && (
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>Font Size:</HeyDayFormLabel>
          <TextField
            sx={{
              width: "180px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            type="text"
            id="fontSize"
            value={textStyles[selectedTextElement].fontSize}
            onChange={(e) => handleStyleChange(e, "fontSize")}
          />
        </div>
      )}
      {textStyles[selectedTextElement]?.margin !== undefined && (
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>Margin:</HeyDayFormLabel>
          <TextField
            sx={{
              width: "180px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            type="text"
            id="margin"
            value={textStyles[selectedTextElement].margin}
            onChange={(e) => handleStyleChange(e, "margin")}
          />
        </div>
      )}
      {textStyles[selectedTextElement]?.textAlign !== undefined && (
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>Text Align:</HeyDayFormLabel>
          <FormControl sx={{ minWidth: 120 }}>
            <Select
              value={textStyles[selectedTextElement].textAlign}
              onChange={(e) => handleStyleChange(e, "textAlign")}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                width: "180px",
                height: "44px",
                margin: 0,
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                  width: "180px",
                  fontFamily: "Poppins",
                  color: "#616060",
                  margin: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputLabel-shrink": {
                  fontFamily: "Poppins",
                  color: "#616060",
                },
              }}
            >
              <MenuItem value="left">left</MenuItem>
              <MenuItem value="center">center</MenuItem>
              <MenuItem value="right">right</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default TextStyleEditFields;
