import React, { useState, useContext, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import SearchBoxUiConfiguration from "./Tabs/SearchBoxUi/SearchBoxUiConfiguration";
import SearchBoxLocation from "./Tabs/SearchBoxLocation/SearchBoxLocation";
import PlaceHoldersUiConfiguration from "../PlaceHolders/PlaceHoldersUiConfiguration";
import PropTypes from "prop-types";
import GlobalContext from "../../../../../../Context/Context";
import { useParams } from "react-router-dom";

const SiteSearch = () => {
  const [tabValue, setTabValue] = useState(0);
  const [configs, setConfigs] = useState([]);
  const [searchBox, setSearchBox] = useState({});
  const params = useParams();
  const context = useContext(GlobalContext);
  const domain = context.getDomains()[params?.index];

  useEffect(() => {
    try {
      setConfigs(JSON.parse(context.getDomains()[params?.index]?.configs));
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }, []);

  useEffect(() => {
    try {
      const search_Box =
        configs.find((item) => item.searchBox)?.searchBox || {};
      setSearchBox(search_Box);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  }, [configs]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <div className="sub-tab">
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          variant="fullWidth"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#5DAAA1",
            },
            "& .Mui-selected": {
              color: "#5DAAA1 !important",
            },
          }}
        >
          <Tab
            disableRipple
            sx={{
              textTransform: "unset",
              color: "black",
              fontSize: { xs: "12px", md: "14px" },
              fontFamily: "Poppins",
            }}
            label="Search box Location"
            {...a11yProps(0)}
          />
          <Tab
            disableRipple
            sx={{
              textTransform: "unset",
              color: "black",
              fontSize: { xs: "12px", md: "14px" },
              fontFamily: "Poppins",
            }}
            label="Search box UI configuration"
            {...a11yProps(1)}
          />
          <Tab
            disableRipple
            sx={{
              textTransform: "unset",
              color: "black",
              fontSize: { xs: "12px", md: "14px" },
              fontFamily: "Poppins",
            }}
            label="Placeholders UI Configuration"
            {...a11yProps(2)}
          />
        </Tabs>
      </div>
      {configs.length > 0 && Object.keys(searchBox).length > 0 && (
        <div>
          <CustomTabPanel value={tabValue} index={0}>
            <SearchBoxLocation
              domain={domain}
              configs={configs}
              searchBox={searchBox}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            <SearchBoxUiConfiguration
              domain={domain}
              configs={configs}
              searchBox={searchBox}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
            <PlaceHoldersUiConfiguration
              domain={domain}
              configs={configs}
              setConfigs={setConfigs}
            />
          </CustomTabPanel>
        </div>
      )}
    </div>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: { xs: 0, md: 3 } }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default SiteSearch;
