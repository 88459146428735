import React from "react";
import "./NoStatistics.css";

const NoStatistics = () => {
  return (
    <div className="no-statistics-container">
      <img alt="no-statistics" src="/img/Group87.png" />
      <h2>Hello There!</h2>
      <p>Seems like you don’t have statistic data yet</p>
    </div>
  );
};

export default NoStatistics;
