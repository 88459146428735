import React from 'react';
import './ContentPageHeader.css';

const ContentPageHeader = ({ pageName, subMenu }) => {
  return (
    <div className='header-holder'>
      <div>
        <ol className='breadcrumb'>
          <li>
            <span>
              <i className='fa fa-dashboard' /> Home
            </span>
          </li>
          {subMenu && (
            <li>
              <span>{subMenu}</span>
            </li>
          )}
          <li className='active'>{pageName}</li>
        </ol>
      </div>
    </div>
  );
};

export default ContentPageHeader;
