import React, { useState, useContext } from "react";
import { MenuItem, FormControl, Select } from "@mui/material";
import GlobalContext from "../../../../Context/Context";

const CurrencySelector = ({ onCurrencyChange }) => {
  const context = useContext(GlobalContext);
  const [currency, setCurrency] = useState("");

  const handleCurrencyChange = async (event) => {
    setCurrency(event.target.value);

    const host = sessionStorage.getItem("currentDomain") || "";
    const updatedConfig = getUpdatedConfigs(host, event.target.value);
    try {
      const requestJson = {
        action: 1014,
        host: host,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };
      const responseJson = await context.myFetch(requestJson);
      if (responseJson.domains) {
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );
        onCurrencyChange(event.target.value);
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
    }
  };

  const getUpdatedConfigs = (domain, value) => {
    let domainObj = context.getDomains().find((o) => o.domain === domain);
    let configs = [];
    try {
      configs = JSON.parse(domainObj.configs);
    } catch (e) {
      configs = [
        {},
        {
          searchBox: {
            type: "search",
            tmpl: {
              htmlBox: "",
              autocomplete_item: "",
              instant_search_item: "",
              results_data: "",
              search_item: "",
            },
            customTmpl: {
              SM_holder: {
                htmlBox: "",
                search_item: "",
              },
            },
            elements: [],
            stylesheet: "",
          },
        },
      ];
    }
    const updatedConfig = [...configs];
    const searchBoxIndex = updatedConfig.findIndex((item) => item.searchBox);
    if (searchBoxIndex !== -1 && updatedConfig[searchBoxIndex].searchBox) {
      updatedConfig[searchBoxIndex].searchBox.defaultCurrency = value;
    }
    return updatedConfig;
  };

  return (
    <FormControl
      sx={{
        width: "100%",
        alignItems: "flex-start",
        color: "#5daaa1 !important",
      }}
      className="connect-data-flex-div"
    >
      <Select
        sx={{ background: "white !important" }}
        value={currency}
        onChange={handleCurrencyChange}
        displayEmpty
      >
        <MenuItem value="" disabled>
          Select Currency
        </MenuItem>
        <MenuItem value="$">USD - $</MenuItem>
        <MenuItem value="€">EUR - €</MenuItem>
        <MenuItem value="£">GBP - £</MenuItem>
        <MenuItem value="₪">ILS - ₪</MenuItem>
      </Select>
    </FormControl>
  );
};

export default CurrencySelector;
