import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  MenuItem,
  Menu,
  IconButton,
} from "@mui/material";
import "./PageDataCard.css";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PageDataCard = ({ pageData }) => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState({});

  const nextProduct = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % pageData.length);
  };

  const prevProduct = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? pageData.length - 1 : prevIndex - 1
    );
  };

  const handleImageChange = (index) => {
    setSelectedImage(index);
  };

  const handleMenuOpen = (attrKey, event) => {
    setAnchorEl((prev) => ({
      ...prev,
      [attrKey]: event.currentTarget,
    }));
  };

  const handleMenuClose = (attrKey) => {
    setAnchorEl((prev) => ({
      ...prev,
      [attrKey]: null,
    }));
  };

  const handleMenuItemClick = (link) => {
    console.log(link);

    // window.location.href = size;
  };
  const product = pageData[activeIndex];
  if(!product.pageData)
    return(<div>Error parsing</div>)

  return (
    <div className="pageData-container">
      <IconButton onClick={prevProduct}>
        <KeyboardArrowLeft />
      </IconButton>
      <Card>
        <CardMedia
          component="img"
          sx={{ width: "50%", maxHeight: "344px", objectFit: "contain" }}
          image={
            (product.pageData?.additional_image_link &&
              product.pageData?.additional_image_link[selectedImage]) ||
            product.pageData.img
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "/img/default-product-img.webp";
          }}
          alt={product.pageData?.title}
        />
        <CardContent sx={{ width: "40%" }}>
          <Typography className="card-content-title">
            <span>Title:</span> {product.pageData?.title}
          </Typography>
          <Typography className="card-content">
            <span>Description:</span> {product.pageData?.description}
          </Typography>
          <Typography className="card-content">
            <span>Brand:</span> {product.pageData?.brand}
          </Typography>
          <Typography className="card-content">
            <span>MPN:</span> {product.pageData?.mpn}
          </Typography>
          {product.pageData?.product && (
            <Typography className="card-content-price">
              <span>Price: </span>
              {product.pageData?.product?.price}{" "}
              {product.pageData?.product?.currency}
            </Typography>
          )}
          {product.pageData.hdyAttr &&
            Object.entries(product.pageData?.hdyAttr).map(
              ([attrKey, attrValue]) => (
                <div key={attrKey}>
                  <Button
                    className="menu-attr-btn"
                    endIcon={<ExpandMoreIcon />}
                    onClick={(e) => handleMenuOpen(attrKey, e)}
                  >
                    Attribute: {decodeURIComponent(attrKey)}
                  </Button>
                  <Menu
                    anchorEl={anchorEl[attrKey]}
                    open={Boolean(anchorEl[attrKey])}
                    onClose={() => handleMenuClose(attrKey)}
                  >
                    {Object.entries(attrValue).map(
                      ([attributeKey, attributeValue]) => (
                        <MenuItem
                          key={attributeKey}
                          onClick={() => handleMenuItemClick(attributeValue)}
                        >
                          {decodeURIComponent(attributeKey)}
                        </MenuItem>
                      )
                    )}
                  </Menu>
                </div>
              )
            )}
          <div style={{ marginTop: "10px" }}>
            {Array.isArray(product.pageData?.additional_image_link) &&
              product.pageData.additional_image_link.map((image, index) => (
                <Button
                  key={index}
                  onClick={() => handleImageChange(index)}
                  style={{ marginRight: "5px" }}
                >
                  Image {index + 1}
                </Button>
              ))}
          </div>
        </CardContent>
      </Card>
      <IconButton onClick={nextProduct}>
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

export default PageDataCard;
