import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import Rule from "./Rule";
import { filterRules } from "./helpers";
import "./ManageSearchResults.css";

export const Rules = ({ domain, Context }) => {
  const [searchRules, setSearchRules] = useState([]);
  const [query, setQuery] = useState("");

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const getSearchRules = async () => {
    const requestJson = {
      action: 46,
      host: domain.domain,
      credentials: Context.loadCredentials(),
    };
    try {
      const response = await Context.myFetch(requestJson);
      if (response && !response.error) {
        const rulesArr = response.rulesConf.map((rule) => JSON.parse(rule));
        setSearchRules(rulesArr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addRulePattern = () => {
    setSearchRules((state) => [
      {
        url: "",
        name: "",
        consequences: undefined,
        validity: undefined,
      },
      ...state,
    ]);
  };

  const isAddDisabled = [...searchRules].find((r) => r.url === "");
  const filteredRules = filterRules(searchRules, query, ["url"]);

  const deleteRule = async (rule, isNewRule) => {
    const otherThanCurrent = [...searchRules].filter(
      (r) => r?.url !== rule.url
    );

    if (!isNewRule) {
      const requestJson = {
        action: 1045,
        rulesConf: otherThanCurrent,
        host: domain.domain,
        credentials: Context.loadCredentials(),
      };
      try {
        const response = await Context.myFetch(requestJson);
        if (response && !response.error) {
          Context.message({
            type: "success",
            body: `Rule is successfully removed`,
          });
          return getSearchRules();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setSearchRules(otherThanCurrent);
    }
  };

  useEffect(() => {
    getSearchRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="rulesSection">
      <div className="addSearchRule">
        <p style={{ fontSize: "16px", margin: 0 }}>Rules</p>
        <Button
          className="add-rule"
          style={{ margin: 0 }}
          onClick={addRulePattern}
          disabled={Boolean(isAddDisabled)}
        >
          Add rule
        </Button>
      </div>
      <div>
        <TextField
          sx={{
            width: "100%",
            marginTop: "20px",
            "& .MuiOutlinedInput-root": {
              height: "44px",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "100px",
              "& fieldset": {
                borderColor: "white",
                border: "none",
              },
            },
          }}
          inputProps={{
            sx: {
              "&::placeholder": {
                color: "#9a9b9d",
              },
            },
          }}
          onChange={handleQueryChange}
          placeholder="Search rules..."
          defaultValue={""}
          autoComplete="off"
          style={{ marginBottom: "10px" }}
        />
      </div>
      {filteredRules?.length > 0 &&
        filteredRules.map((rule, index) => (
          <div key={rule?.url || `new-rule-${index}`}>
            {console.log(rule)}
            <Rule
              isNewRule={rule.url === ""}
              rule={rule}
              domain={domain}
              Context={Context}
              searchRules={searchRules}
              getSearchRules={getSearchRules}
              deleteRule={deleteRule}
            />
          </div>
        ))}
    </section>
  );
};
