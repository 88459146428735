import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ConfirmationModal = ({ openConfirm, setOpenConfirm }) => {
  const navigate = useNavigate();

  const goBack = async () => {
    setOpenConfirm(false);
    setTimeout(() => {
      navigate("/selfserve/2");
    }, 1500);
  };

  return (
    <Dialog
      open={openConfirm}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      classes={{ paper: "mui-paper" }}
    >
      <DialogTitle sx={{ fontFamily: "Poppins" }}>Error fetching</DialogTitle>
      <DialogContent sx={{ fontFamily: "Poppins" }}>
        <p>
          We apologize for the inconvenience, but we are currently unable to
          retrieve the product feed. Please press "Okay" to return to the
          previous step and try again. If the issue persists, feel free to
          contact our support team for further assistance. Thank you for your
          understanding.
        </p>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ marginRight: "20px !important" }}
          onClick={goBack}
          color="primary"
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
