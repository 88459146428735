import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export const ConfirmationModal = ({
  openConfirm,
  setOpenConfirm,
  domain,
  Context,
  clearType,
}) => {
  const handleCancel = () => {
    setOpenConfirm(false);
  };

  const clearDomainIndex = async () => {
    const requestJson = {
      action: 1043,
      host: domain,
      credentials: Context.loadCredentials(),
      clear: clearType === "clear" ? true : false,
      reindex: clearType === "clear" ? false : true,
    };
    const response = await Context.myFetch(requestJson);
    var message = clearType === "clear" ? "cleared" : "reindexed";
    if (!response.error) {
      setOpenConfirm(false);
      Context.message({
        type: "success",
        body:
          "Indexes were successfully " +
          message +
          ". Change will take effect in a few minutes.",
      });
    } else {
      Context.message({
        type: "error",
        body: response.error,
      });
    }
  };

  return (
    <Dialog
      open={openConfirm}
      onClose={handleCancel}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      classes={{ paper: "mui-paper" }}
    >
      <DialogTitle id="confirmation-dialog-title">
        {clearType === "clear" ? "Clear domain index" : "Reindex domain"}{" "}
        confirmation
      </DialogTitle>
      <DialogContent>
        <p>
          Are you sure you want to{" "}
          {clearType === "clear" ? "clear all indexes" : "reindex domain"}?
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          No
        </Button>
        <Button onClick={clearDomainIndex} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
