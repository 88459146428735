export const formFetch = async (
  requestJson,
  data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }
) => {
  data.body = data.body || JSON.stringify(requestJson);
  let result;
  try {
    const f = await fetch("https://cdn.heyday.io/form/", data);
    result = await f.json();
  } catch (e) {
    result = { error: e };
  }
  return result;
};
