import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const HeatmapChart = ({ chartData, setIsLoading, startTime, isLoading }) => {
  const [heatmapData, setHeatmapData] = useState([]);
  const week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    const parseData = async () => {
      try {
        const impressionsData = week.map((day, index) => {
          const dayData = chartData
            .filter((entry) => new Date(entry._date).getDay() === index)
            .map((entry) => entry.impressions);
          const paddedData = Array(24).fill(null);
          dayData.forEach((value, idx) => {
            paddedData[idx] = value;
          });

          return {
            name: day,
            data: paddedData,
          };
        });

        setHeatmapData(impressionsData);
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(0, 1000 - elapsedTime);
        if (remainingTime > 0) {
          setTimeout(() => setIsLoading(false), remainingTime);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    parseData();
  }, [chartData]);

  const options = {
    chart: {
      height: 350,
      type: "heatmap",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#5DAAA1"],
    title: {
      text: "Users By Time Of The Day",
      align: "left",
      style: {
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#616060",
        fontWeight: "500",
      },
    },
    xaxis: {
      categories: Array.from({ length: 24 }, (_, i) => i.toString()),
    },
  };

  return (
    <div>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#5daaa1" }} />
        </Box>
      ) : (
        <Chart
          options={options}
          series={heatmapData}
          type="heatmap"
          height={350}
        />
      )}
    </div>
  );
};

export default HeatmapChart;
