export const extractAndFormatStyles = (cssText, selector) => {
  const lines = cssText.split("\n");
  let capture = false;
  let extractedStyles = "";

  for (let line of lines) {
    line = line.trim();
    if (line.startsWith(selector)) {
      capture = true;
      extractedStyles += `${line}\n`;
    } else if (capture && line === "}") {
      extractedStyles += `${line}\n\n`;
      capture = false;
    } else if (capture) {
      extractedStyles += `${line}\n`;
    }
  }

  return extractedStyles;
};

export const extractStyles = (cssText, selector) => {
  const regex = new RegExp(`(${selector}[^{}]*{[^}]*})`, "g");
  const matches = cssText.match(regex);
  return matches ? matches.join(" ") : "";
};

export const extractStyleValue = (stylesheet, selector, property) => {
  const selectorRegex = new RegExp(`${selector}\\s*{([^}]*)}`, "i");
  const selectorMatch = stylesheet.match(selectorRegex);
  if (selectorMatch) {
    const properties = selectorMatch[1];
    const propertyRegex = new RegExp(`${property}:\\s*([^;]+);`, "i");
    const propertyMatch = properties.match(propertyRegex);
    return propertyMatch ? propertyMatch[1].trim() : "";
  }
  return "";
};

export const cssToObject = (cssString) => {
  const styleObject = {};
  const blocks = cssString.match(/[^{}]+(?=\})/g);

  if (blocks) {
    for (const block of blocks) {
      const declarations = block
        .split(";")
        .map((decl) => decl.trim())
        .filter(Boolean);
      for (const declaration of declarations) {
        const [property, value] = declaration
          .split(":")
          .map((part) => part.trim());
        if (property && value) {
          const camelCaseProperty = property.replace(
            /-([a-z])/g,
            (match, letter) => letter.toUpperCase()
          );
          styleObject[camelCaseProperty] = value;
        }
      }
    }
  }

  return styleObject;
};

export const formatCssString = (cssString) => {
  const formattedString = cssString
    .replace(/;/g, ";\n\t")
    .replace(/{/g, " {\n\t")
    .replace(/}/g, "\n}\n");

  return formattedString;
};

export const removeFormatting = (cssString) => {
  const singleLineString = cssString
    .replace(/\s+/g, " ")
    .replace(/;\s+/g, ";")
    .replace(/\s+{\s+/g, "{")
    .replace(/\s+}\s+/g, "}")
    .replace(/\s+:\s+/g, ":");

  return singleLineString.trim();
};

export const extractStylesFromConfig = (configStyle, defaultStyles) => {
  const styles = { ...defaultStyles };

  const parseCSS = (css, section, defaultStyle) => {
    const regex = new RegExp(
      `#heyday_SB_SM_holder .hd-s-main .searchResults .${section}\\s*{([^}]*)}`,
      "gi"
    );
    const match = regex.exec(css);
    if (match && match[1]) {
      const styleStr = match[1].trim();
      const styleObj = styleStr.split(";").reduce((acc, style) => {
        if (style.trim()) {
          const [key, value] = style.split(":").map((item) => item.trim());
          const camelCaseKey = key.replace(/-([a-z])/g, (g) =>
            g[1].toUpperCase()
          );
          acc[camelCaseKey] = value;
        }
        return acc;
      }, {});
      return { ...defaultStyle, ...styleObj };
    }
    return defaultStyle;
  };

  for (let section in styles) {
    styles[section] = parseCSS(configStyle, section, styles[section]);
  }

  return styles;
};
