import React, { useState } from "react";
import TextStyleEditFields from "../editFields/TextStyleEditFields";
import { Button, Typography } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const feedItem = {
  id: "123",
  Rtitle: "New Study Reveals Surprising Effects of Meditation on Brain Health",
  description:
    "A groundbreaking study conducted by neuroscientists at Harvard University has uncovered some unexpected findings about the impact of meditation on brain health. Contrary to previous beliefs, the study suggests that meditation not only reduces stress but also enhances cognitive function and promotes overall well-being. The research, published in the Journal of Neuroscience, involved MRI scans of participants before and after an eight-week mindfulness meditation program. The results show significant changes in brain structure and activity, particularly in regions associated with attention, memory, and emotional regulation. These findings have profound implications for the treatment of various mental health disorders and underscore the importance of incorporating mindfulness practices into daily life.",
  url: "https://example.com",
  RimageC: "/img/news.png",
  Rdate: "26/03/2024",
  Rauthor: "Author",
};

const PostWizard = ({ handleCloseModal, updateConfig }) => {
  const [selectedTextElement, setSelectedTextElement] = useState("title");
  const [styles, setStyles] = useState({
    container: {
      display: "flex",
      flexDirection: "row",
      borderBottom: "1.5px solid rgba(196, 196, 196, 1)",
    },
    image: {
      width: "25%",
      marginRight: "20px",
      objectFit: "cover",
      height: "auto",
    },
    content: {
      width: "70%",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      margin: "0px",
      display: "block",
      color: "#000000",
      fontFamily: "Poppins",
    },
    description: {
      fontSize: "16px",
      marginBottom: "10px",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      color: "#000000",
      fontFamily: "Poppins",
      margin: "0px",
    },
    date: {
      fontSize: "14px",
      fontStyle: "italic",
      marginBottom: "5px",
      marginRight: "5px",
      color: "#d41616",
      fontFamily: "Poppins",
      display: "block",
    },
    author: {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#d41616",
      fontFamily: "Poppins",
      display: "block",
    },
  });

  function handleSavePostStyle() {
    let cssString = "";
    const classMap = {
      container: ".Rlink",
      image: ".RimageC",
      content: ".Rbody",
      title: ".Rtitle",
      description: ".description",
      date: ".Rdate",
      author: ".Rauthor",
    };

    for (const [key, value] of Object.entries(styles)) {
      const className = "#heyday_SB_holder .hd-s-main " + classMap[key];
      cssString += `${className} {`;
      for (const [property, val] of Object.entries(value)) {
        cssString += `  ${property}: ${val} !important;`;
      }
      cssString += `}`;
    }
    const successMessage = "Post style updated successfully";
    updateConfig(cssString, successMessage)
      .then(() => {
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      })
      .catch((error) => {
        console.log("error");
      });
  }

  const handleTextStyleChange = (e, styleProperty) => {
    setStyles({
      ...styles,
      [selectedTextElement]: {
        ...styles[selectedTextElement],
        [styleProperty]: e.target.value,
      },
    });
  };

  const handleTextElementChange = (name) => {
    setSelectedTextElement(name);
  };

  const toggleDisplay = (key) => {
    setStyles((prevStyles) => ({
      ...prevStyles,
      [key]: {
        ...prevStyles[key],
        display: prevStyles[key].display === "none" ? "block" : "none",
      },
    }));
  };

  return (
    <div>
      <div style={styles.container}>
        <img
          onClick={() => handleTextElementChange("image")}
          className={
            selectedTextElement === "image" ? "post-image active" : "post-image"
          }
          src={feedItem.RimageC}
          alt="News"
          style={styles.image}
        />
        <div style={styles.content}>
          <h2
            onClick={() => handleTextElementChange("title")}
            className={
              selectedTextElement === "title"
                ? "post-title active"
                : "post-title"
            }
            style={styles.title}
          >
            {feedItem.Rtitle}
          </h2>
          <p
            onClick={() => handleTextElementChange("description")}
            className={
              selectedTextElement === "description"
                ? "post-description active"
                : "post-description"
            }
            style={styles.description}
          >
            {feedItem.description}
          </p>
          <div style={{ display: "flex" }}>
            <p
              onClick={() => handleTextElementChange("date")}
              className={
                selectedTextElement === "date"
                  ? "post-date active"
                  : "post-date"
              }
              style={styles.date}
            >
              {feedItem.Rdate}
            </p>
            <p
              onClick={() => handleTextElementChange("author")}
              className={
                selectedTextElement === "author"
                  ? "post-author active"
                  : "post-author"
              }
              style={styles.author}
            >
              {feedItem.Rauthor}
            </p>
          </div>
        </div>
      </div>
      <div>
        {Object.entries(styles).map(
          ([key, style]) =>
            style.display === "none" && (
              <div className="wizard-hidden-elements" key={key}>
                <Typography>{key}</Typography>
                <Button onClick={() => toggleDisplay(key)}>
                  <KeyboardReturnIcon sx={{ color: "#ffba41" }} />
                </Button>
              </div>
            )
        )}
      </div>
      <TextStyleEditFields
        handleStyleChange={handleTextStyleChange}
        textStyles={styles}
        selectedTextElement={selectedTextElement}
      />
      <div className="btn-next-step-container">
        <Button className="btn-save" onClick={handleSavePostStyle}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default PostWizard;
