import React from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { requestDataWithParams } from "./helpers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import "./ManageSearchResults.css";

const Priority = ({ url, domain, Context, setUrl, inputEl }) => {
  const changePriority = async (num) => {
    // const requestJson = {
    //   action: 31,
    //   url,
    //   prio: num,
    //   host: domain.domain,
    //   credentials: Context.loadCredentials(),
    // };

    // try {
    //   const responseJson = await Context.myFetch(requestJson);
    //   if (!responseJson.error) {
    //     Context.message({
    //       type: "success",
    //       body: "Priority changed",
    //     });
    //   } else {
    //     Context.message({
    //       type: "error",
    //       body: responseJson?.error,
    //     });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const removePage = async (type) => {
    const response = await requestDataWithParams(url, Context, type);
    if (response && !response.error) {
      inputEl.current.value = "";
      Context.message({
        type: "success",
        body: `Page was successfully ${
          type === "remove" ? "removed" : "reindexed"
        }. Change will take effect in a few minutes.`,
      });
      return setUrl("");
    } else {
      Context.message({
        type: "error",
        body: response.error,
      });
    }
  };

  return (
    <Accordion
      defaultExpanded={true}
      sx={{
        marginBottom: "10px",
        width: "100%",
        border: "none",
        boxShadow: "unset",
        borderRadius: "20px",
        background: "var(--Light-Grey, #F4F6F8)",
        color: "var(--Mid-Grey, #616060)",
        fontFamily: "Poppins",
        fontSize: "14px",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Manage specific URL
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            className="searchResults-inc-priority"
            style={{ marginBottom: "10px", width: "185px" }}
            startIcon={<KeyboardArrowUpIcon />}
            onClick={() => changePriority(1)}
          >
            Increase priority
          </Button>
          <Button
            className="searchResults-dec-priority"
            startIcon={<KeyboardArrowDownIcon />}
            style={{ marginBottom: "10px", width: "185px" }}
            onClick={() => changePriority(-1)}
          >
            Decrease priority
          </Button>
          <Button
            className="clear-index-btn"
            onClick={() => removePage("remove")}
          >
            Remove
          </Button>
          <Button
            sx={{ marginTop: "10px !important" }}
            className="re-index-btn"
            onClick={() => removePage("reIndex")}
          >
            Reindex
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Priority;
