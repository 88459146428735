import React, { useState, useContext, useEffect } from "react";
import HeyDayFormLabel from "../../../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import "./SearchMoreUiConfiguration.css";
import GlobalContext from "../../../../../../../../Context/Context";
import IOSSwitch from "../../../../../../../../Shared/Components/Switch/IOSSwitch";
import {
  cssToObject,
  extractStyles,
  formatCssString,
  removeFormatting,
} from "./utils/helpers";
import { defaultStyles } from "./utils/defaultStyles";
import nsmBoxTmp from "../../../SiteSearch/Tabs/SearchBoxUi/templatesDefaultHtml/nsmBox";
import DisplayTmplElements from "./DisplayTmplElements";
import ImageCustomizer from "./Wizards/ImageCustomizer";
import ProductWizard from "./Wizards/ProductWizard";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const getInitialTopBarStyle = (stylesheet) => {
  let initialStyles = { ...defaultStyles };

  const barStyleText = extractStyles(
    stylesheet,
    "#heyday_SB_SM_holder #hdy_searchMessage"
  );

  if (barStyleText) {
    initialStyles.bar = {
      ...initialStyles.bar,
      ...cssToObject(barStyleText),
    };
  }

  const arrowStyleText = extractStyles(
    stylesheet,
    "#heyday_SB_SM_holder #hdy_arrowDown"
  );
  if (arrowStyleText) {
    initialStyles.arrow = {
      ...initialStyles.arrow,
      ...cssToObject(arrowStyleText),
    };
  }

  return initialStyles;
};

const SearchMoreUiConfiguration = ({ domain, configs, setConfigs }) => {
  const context = useContext(GlobalContext);
  const [expanded, setExpanded] = useState({});
  const searchBox = configs.find((item) => item.searchBox)?.searchBox || {};
  // const searchMore = configs.find((item) => item.searchMore)?.searchMore || 2;
  const searchMore = 2;
  const [currency, setCurrency] = useState(
    searchBox && searchBox.defaultCurrency ? searchBox.defaultCurrency : ""
  );
  const [smLogo, setSmLogo] = useState(
    searchBox && searchBox.customLogo?.SM_holder
      ? searchBox.customLogo.SM_holder
      : ""
  );
  const [forMoreRes, setForMoreRes] = useState(
    searchBox && searchBox.tmplPlaceHolders?.forMoreRes
      ? searchBox.tmplPlaceHolders.forMoreRes
      : ""
  );
  const [style, setStyle] = useState("");
  const [displayLocation, setDisplayLocation] = useState(searchMore);
  const [topBarStyle, setTopBarStyle] = useState(() =>
    getInitialTopBarStyle(searchBox.stylesheet)
  );

  const [displayTopBar, setDisplayTopBar] = useState(
    topBarStyle.bar.display !== "none" ? true : false
  );
  const [htmlBox, setHtmlBox] = useState(
    (searchBox &&
      searchBox.customTmpl &&
      searchBox.customTmpl.SM_holder &&
      searchBox.customTmpl.SM_holder.htmlBox) ||
      ""
  );
  const [searchItem, setSearchItem] = useState(
    (searchBox &&
      searchBox.customTmpl &&
      searchBox.customTmpl.SM_holder &&
      searchBox.customTmpl.SM_holder.search_item) ||
      ""
  );
  const [toggleEdit, setToggleEdit] = useState({
    htmlBox:
      searchBox &&
      searchBox &&
      searchBox.customTmpl &&
      searchBox.customTmpl.SM_holder &&
      searchBox.customTmpl.SM_holder.htmlBox
        ? true
        : false,
    search_item:
      searchBox &&
      searchBox.customTmpl &&
      searchBox.customTmpl.SM_holder &&
      searchBox.customTmpl.SM_holder.search_item
        ? true
        : false,
  });

  const handleToggleEdit = (key) => {
    setToggleEdit((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const closeAccordion = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: false }));
  };

  const handleChangeExpanded = (id) => (event, isExpanded) => {
    setExpanded((prev) => ({ ...prev, [id]: isExpanded }));
  };

  const searchMoreList = [
    {
      textAreaId: "htmlBox",
      textAreaLabel: "Search box HTML",
      textAreaPlaceholder: nsmBoxTmp?.htmlBox,
      isTmpl: true,
      textAreaIdEdit: toggleEdit.htmlBox,
      value: htmlBox,
      formItemType: "textArea",
      nestedKey: true,
      setter: setHtmlBox,
    },
    {
      textAreaId: "search_item",
      textAreaLabel: "Search results HTML",
      textAreaPlaceholder: nsmBoxTmp?.search_item,
      isTmpl: true,
      textAreaIdEdit: toggleEdit.search_item,
      value: searchItem,
      formItemType: "textArea",
      nestedKey: true,
      setter: setSearchItem,
    },
  ];

  useEffect(() => {
    setStyle(formatCssString(searchBox.stylesheet));
    // eslint-disable-next-line
  }, [configs]);

  const generateTopBarCSSString = () => {
    const displayValue = displayTopBar ? "block" : "none";
    console.log(topBarStyle);
    const barStyles = topBarStyle.bar;
    const arrowStyles = topBarStyle.arrow;

    const updates = [
      {
        selector: "#heyday_SB_SM_holder #hdy_searchMessage",
        properties: {
          display: displayValue,
          color: barStyles.color || "#ffffff",
          "background-color": barStyles.backgroundColor || "#007aff",
        },
      },
      {
        selector: "#heyday_SB_SM_holder #hdy_arrowDown",
        properties: {
          "border-top-color": arrowStyles.borderTopColor || "#007aff",
        },
      },
    ];

    console.log(updates);

    return updates;
  };

  const handleTextColorChange = (event) => {
    const newColor = event.target.value;
    setTopBarStyle((prevStyle) => ({
      ...prevStyle,
      bar: {
        ...prevStyle.bar,
        color: newColor,
      },
    }));
  };

  const handleBackgroundColorChange = (event) => {
    const newBackgroundColor = event.target.value;
    setTopBarStyle((prevStyle) => ({
      ...prevStyle,
      bar: {
        ...prevStyle.bar,
        backgroundColor: newBackgroundColor,
      },
      arrow: {
        ...prevStyle.arrow,
        borderTopColor: newBackgroundColor,
      },
    }));
  };
  const handleChangeTopBarDisplay = () => {
    setTopBarStyle((prevStyle) => ({
      ...prevStyle,
      bar: {
        ...prevStyle.bar,
        display: displayTopBar ? "block" : false,
      },
    }));
  };

  const getUpdatedConfigs = () => {
    const updatedConfig = [...configs];
    const searchBoxIndex = updatedConfig.findIndex((item) => item.searchBox);
    if (searchBoxIndex !== -1 && updatedConfig[searchBoxIndex].searchBox) {
      updatedConfig[searchBoxIndex].searchBox.stylesheet =
        updateStylesInSearchBox();
      if (currency !== "")
        updatedConfig[searchBoxIndex].searchBox.defaultCurrency = currency;
      if (!updatedConfig[searchBoxIndex].searchBox.customLogo) {
        updatedConfig[searchBoxIndex].searchBox.customLogo = {};
      }
      updatedConfig[searchBoxIndex].searchBox.customLogo.SM_holder = smLogo;
      if (!updatedConfig[searchBoxIndex].searchBox.customTmpl) {
        updatedConfig[searchBoxIndex].searchBox.customTmpl = {};
      }
      updatedConfig[searchBoxIndex].searchBox.customTmpl.SM_holder = {
        htmlBox: htmlBox,
        search_item: searchItem,
      };
    }
    return updatedConfig;
  };

  const updateCss = (stylesheet, updates) => {
    updates.forEach(({ selector, properties }) => {
      Object.entries(properties).forEach(([property, value]) => {
        const regex = new RegExp(
          `(${selector}\\s*{[^}]*)(\\s*${property}:\\s*[^;]+;)`,
          "i"
        );
        const newProperty = `${property}: ${value};`;
        if (regex.test(stylesheet)) {
          stylesheet = stylesheet.replace(regex, `$1${newProperty}`);
        } else {
          const selectorRegex = new RegExp(`(${selector}\\s*{)`, "i");
          if (selectorRegex.test(stylesheet)) {
            stylesheet = stylesheet.replace(
              selectorRegex,
              `$1\n  ${newProperty}`
            );
          } else {
            stylesheet = `${stylesheet}\n${selector} {\n  ${newProperty}\n}`;
          }
        }
      });
    });
    return stylesheet;
  };

  const updateStylesInSearchBox = () => {
    const updates = generateTopBarCSSString(displayTopBar, topBarStyle);
    const updatedCss = updateCss(style, updates);
    return removeFormatting(updatedCss.trim());
  };

  const handleSaveConfig = async () => {
    const updatedConfig = getUpdatedConfigs();

    try {
      const requestJson = {
        action: 1014,
        host: domain,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };

      const responseJson = await context.myFetch(requestJson);
      if (responseJson.domains) {
        setConfigs(updatedConfig);
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );
        context.message({
          type: "success",
          body: "Indexing settings were successfully updated",
        });
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
    }
  };

  return (
    <div className="search-more-conf-container">
      <div
        style={{
          position: "relative",
          width: "100%",
          marginBottom: "20px",
          backgroundColor: "rgb(251 251 251)",
          borderRadius: "25px",
          padding: "10px",
        }}
      >
        <HeyDayFormLabel sx={{ display: "flex" }}>
          Display Top Bar
        </HeyDayFormLabel>
        <IOSSwitch
          onChange={() => {
            setDisplayTopBar(!displayTopBar);
            handleChangeTopBarDisplay();
          }}
          checked={displayTopBar}
          sx={{ margin: "10px 0", display: "flex" }}
        />
        {displayTopBar && (
          <>
            <div id="hdy_searchMessage" style={topBarStyle.bar}>
              {forMoreRes || "For more results"}
              <div id="hdy_arrowDown" style={topBarStyle.arrow}></div>
            </div>
            <div className="style-form">
              <h4>Edit top bar style:</h4>
              <div className="inputWizard-container">
                <HeyDayFormLabel sx={{ width: "125px" }}>
                  Text Color:
                </HeyDayFormLabel>
                <div className="input-color-container">
                  <input
                    className="input-color"
                    type="color"
                    id="color"
                    value={topBarStyle.bar.color}
                    onChange={handleTextColorChange}
                  />
                  <span>{topBarStyle.bar.color}</span>
                </div>
              </div>
              <div className="inputWizard-container">
                <HeyDayFormLabel sx={{ width: "125px" }}>
                  Background Color:
                </HeyDayFormLabel>
                <div className="input-color-container">
                  <input
                    className="input-color"
                    type="color"
                    id="color"
                    value={topBarStyle.bar.backgroundColor}
                    onChange={handleBackgroundColorChange}
                  />
                  <span>{topBarStyle.bar.backgroundColor}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Accordion
        key="panel1"
        expanded={!!expanded["panel1"]}
        onChange={handleChangeExpanded("panel1")}
        sx={{
          width: "100%",
          boxShadow: "unset",
          borderRadius: "20px !important",
          border: "2px solid #f4f6f8",
          color: "var(--Mid-Grey, #616060)",
          fontFamily: "Poppins-Medium",
          fontSize: "14px",
          "&::before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          Advanced Configuration
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              position: "relative",
              width: "100%",
              marginBottom: "20px",
              backgroundColor: "rgb(251 251 251)",
              borderRadius: "25px",
              padding: "10px",
            }}
          >
            <ProductWizard
              domain={domain}
              configs={configs}
              setConfigs={setConfigs}
              context={context}
            />
          </div>

          <div className="input-container">
            <HeyDayFormLabel sx={{ display: "flex", alignItems: "center" }}>
              Website logo:{" "}
              {smLogo && (
                <img
                  className="website-sm-logo"
                  alt="website-logo"
                  src={smLogo}
                />
              )}
            </HeyDayFormLabel>
            <TextField
              placeholder="https://your-website-logo.com"
              value={smLogo}
              onChange={(e) => setSmLogo(e.target.value)}
              sx={{
                maxWidth: "381px",
                width: "100%",
                marginTop: "15px",
                marginRight: "20px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
            />
          </div>
          {smLogo && (
            <div
              style={{
                position: "relative",
                width: "100%",
                marginBottom: "20px",
                backgroundColor: "rgb(251 251 251)",
                borderRadius: "25px",
                padding: "10px",
              }}
            >
              <ImageCustomizer
                smLogo={smLogo}
                domain={domain}
                configs={configs}
                setConfigs={setConfigs}
                closeAccordion={() => closeAccordion("panel3")}
                context={context}
              />{" "}
            </div>
          )}
          <Accordion
            key="panel4"
            expanded={!!expanded["panel4"]}
            onChange={handleChangeExpanded("panel4")}
            sx={{
              width: "100%",
              boxShadow: "unset",
              borderRadius: "20px !important",
              border: "2px solid #f4f6f8",
              color: "var(--Mid-Grey, #616060)",
              fontFamily: "Poppins-Medium",
              marginTop: "20px",
              marginBottom: "20px",
              fontSize: "14px",
              "&::before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              Advanced Code Edit
            </AccordionSummary>
            <AccordionDetails sx={{ padding: { xs: 0, lg: "10px" } }}>
              <p className="warning-message">
                *Please ensure you edit this code carefully as it currently does
                not have any validation.
              </p>
              <p className="warning-message">
                Incorrect modifications may result in unexpected behavior or
                errors.
              </p>
              <p className="warning-message">
                Double-check your changes before saving.
              </p>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  marginBottom: "20px",
                  backgroundColor: "rgb(251 251 251)",
                  borderRadius: "25px",
                  padding: "10px",
                }}
              >
                <HeyDayFormLabel sx={{ display: "flex" }}>
                  Select default currency:
                </HeyDayFormLabel>
                <FormControl
                  sx={{
                    width: "100%",
                    alignItems: "flex-start",
                    color: "#5daaa1 !important",
                    margin: "10px 0",
                  }}
                  className="connect-data-flex-div"
                >
                  <Select
                    sx={{ background: "white !important" }}
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Currency
                    </MenuItem>
                    <MenuItem value="$">USD - $</MenuItem>
                    <MenuItem value="€">EUR - €</MenuItem>
                    <MenuItem value="£">GBP - £</MenuItem>
                    <MenuItem value="₪">ILS - ₪</MenuItem>
                  </Select>
                </FormControl>
                <div className="input-container">
                  <HeyDayFormLabel sx={{ display: "flex" }}>
                    Style:
                  </HeyDayFormLabel>
                  <textarea
                    onChange={(e) => setStyle(e.target.value)}
                    value={style}
                  />
                </div>
                {searchMoreList.map((item, index) => (
                  <DisplayTmplElements
                    item={item}
                    index={index}
                    toggleEdit={handleToggleEdit}
                    key={index}
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
      <Button
        sx={{ marginTop: 5 }}
        onClick={handleSaveConfig}
        className="btn-save"
      >
        Save
      </Button>
    </div>
  );
};

export default SearchMoreUiConfiguration;
