import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HeyDayCard from "../../../../Shared/Components/Card/SelfServeCard";
import ProgressBar from "../../ProgressBar/ProgressBar";
import {
  IconButton,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import GlobalContext from "../../../../Context/Context";
import InviteMembersModal from "../../../Admin/ManageAccount/InviteMembers/InviteMembersModal";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import HeyDayTextField from "../../../../Shared/Components/TextField/HeyDayTextField";
import ContactUs from "../../ContactUs/ContactUs";
import { updateIntegrationState } from "../../helpers/helpers";
import "./Payment.css";

const Payment = () => {
  const context = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stepModal, setStepModal] = useState(0);
  const [disableNext, setDisableNext] = useState(true);
  const [clientName, setClientName] = useState("");
  const [coin, setCoin] = useState("1");
  const [iframeUrl, setIframeUrl] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [isContactUsPage, setIsContactUsPage] = useState(false);
  const domain = sessionStorage.getItem("currentDomain") || "";
  const [errorName, setErrorName] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

  window.handleFetchApproval = (data) => {
    console.log("Received data from iframe:", data);
    if (data.paramString) {
      confirmPayment(data.paramString);
    }
  };

  const getIframeUrl = async () => {
    if (clientName.length < 2) {
      setErrorName(true);
      return;
    }
    setButtonEnabled(false);
    const requestJson = {
      action: 19,
      Coin: coin,
      ClientName: encodeURIComponent(clientName),
      credentials: context.loadCredentials(),
    };
    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        if (response.redirUrl) {
          setIframeUrl(response.redirUrl);
        }
      } else {
        context.message({
          type: "error",
          body: response.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });

      console.error("Error:", error);
    }
    setButtonEnabled(false);
  };

  const handleInputChange = (e) => {
    setIframeUrl("");
    setErrorName(false);
    setClientName(e.target.value);
    setButtonEnabled(true);
  };

  const handleCoinChange = (e) => {
    setIframeUrl("");
    setCoin(e.target.value);
    setButtonEnabled(true);
  };

  const confirmPayment = async (paramString) => {
    const requestJson = {
      action: 1020,
      subAction: 1021,
      credentials: context.loadCredentials(),
      redirParam: paramString,
      host: domain,
      ClientName: encodeURIComponent(clientName),
    };
    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        if (response.actionStatus) {
          setPaymentComplete(true);
          await updateIntegrationState(11, context);
          await handleSaveAffStatus();
          setDisableNext(false);
        }
      } else {
        context.message({
          type: "error",
          body: response.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });

      console.error("Error:", error);
    }
  };

  const handleClickNext = () => {
    // const hdyQ = sessionStorage.getItem("previewSearchQ");
    // if (hdyQ) {
    //   navigate(`/selfserve/11?hdyQ=${hdyQ}`);
    // } else {
    navigate("/selfserve/11");
    // }
  };

  const handleClickInvite = () => {
    setStepModal(0);
    setIsModalOpen(true);
  };

  const handleSaveAffStatus = async () => {
    const requestJson = {
      action: 1002,
      setStatus: "1",
      credentials: context.loadCredentials(),
    };

    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        sessionStorage.setItem("affStatus", 1);
      }
      console.log(response);
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
      console.error("Error:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div className="logout-container">
        <IconButton onClick={() => context.logout()}>
          <LogoutIcon />
        </IconButton>
        <span>Log Out</span>
      </div>
      <HeyDayCard
        contentStyle={{
          position: "relative",
          minHeight: "560px",
        }}
        sx={{
          width: "100%",
          maxWidth: "880px",
          position: "relative",
          marginTop: "100px",
          // marginBottom: 5,
          boxShadow: {
            xs: "unset",
            md: "0px 5px 7px rgba(113, 113, 113, 0.25)",
          },
        }}
      >
        <ProgressBar stepName={"payment"} progressPercentage={"0"} />
        {isContactUsPage ? (
          <ContactUs
            setIsContactUsPage={setIsContactUsPage}
            message={"onboarding - Payment Problem"}
            location={"Payment"}
            backTo={"/selfserve/4"}
          />
        ) : (
          <div className="payment-fields">
            <p
              style={{
                fontSize: "18px",
                fontFamily: "Poppins-Medium",
                color: "#5daaa1",
              }}
            >
              You have a 14-day free trial with HeyDay - Search More.
            </p>
            <p>
              During this trial period, you can unsubscribe at any time without
              any charge.
            </p>
            {!paymentComplete && (
              <div className="inputs-wrapper">
                <HeyDayTextField
                  className="hdy-text-field"
                  style={{ marginTop: "20px" }}
                  required
                  labelValue="Name on card"
                  value={clientName}
                  onChange={handleInputChange}
                  error={errorName}
                />
                <FormControl sx={{ marginTop: "20px" }}>
                  <InputLabel
                    sx={{
                      color: "#929292",
                      fontFamily: "'Heebo', sans-serif",
                      left: "15px",
                      top: "-5px",

                      "&.Mui-focused": {
                        color: "#929292 !important",
                        top: "-5px",
                        left: "15px",
                      },
                    }}
                  >
                    Select your currency
                  </InputLabel>
                  <Select
                    value={coin}
                    onChange={handleCoinChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem
                      sx={{ fontFamily: "'Heebo', sans-serif" }}
                      value={"1"}
                    >
                      <Typography variant="inherit">ILS</Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "'Heebo', sans-serif" }}
                      value={"2"}
                    >
                      <Typography variant="inherit">USD</Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "'Heebo', sans-serif" }}
                      value={"3"}
                    >
                      <Typography variant="inherit">EURO</Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ fontFamily: "'Heebo', sans-serif" }}
                      value={"4"}
                    >
                      <Typography variant="inherit">POUND</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    backgroundColor: "#5c64ff",
                    boxShadow: "unset",
                    fontFamily: "Poppins",
                    textTransform: "unset",
                    "&:hover": {
                      backgroundColor: "#7278f5",
                      boxShadow: "inherit",
                    },
                  }}
                  variant="contained"
                  disabled={!buttonEnabled}
                  onClick={() => {
                    getIframeUrl();
                    setButtonEnabled(false);
                  }}
                >
                  Load Payment Info
                </Button>
              </div>
            )}

            {!buttonEnabled ? (
              <div key={iframeUrl}>
                <iframe
                  id="hdy-payment-iframe"
                  title="hdy-payment-iframe"
                  src={iframeUrl}
                  style={{
                    width: "100%",
                    height: "540px",
                    marginBottom: 20,
                    border: "none",
                  }}
                />
              </div>
            ) : (
              <div>
                <img
                  className="payment-img"
                  src="/img/payment.jpg"
                  alt="payment"
                />
              </div>
            )}
            <p>
              *Billing occurs monthly and will be processed at the end of each
              month.
            </p>

            <Button
              className="problem-btn"
              sx={{ marginBottom: 10 }}
              onClick={(e) => setIsContactUsPage(true)}
            >
              Have some problem? <span>contact us</span>
            </Button>
            <div className="hdy-footer-card">
              {disableNext ? (
                <div className="hdy-back-link-container">
                  <span>
                    <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
                  </span>
                  <Link to="/selfserve/3" className="hdy-back-link">
                    Back
                  </Link>
                </div>
              ) : (
                <div></div>
              )}

              <Button
                disabled={disableNext}
                onClick={handleClickNext}
                className="hdy-next-btn"
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </HeyDayCard>
      <InviteMembersModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        step={stepModal}
        setStep={setStepModal}
      />
      <div className="invite-btn-container">
        <Button
          onClick={handleClickInvite}
          className="invite-btn"
          startIcon={
            <PersonAddAltOutlinedIcon
              sx={{
                color: "#5daaa1",
                backgroundColor: "#ffffff",
                borderRadius: "25px",
                padding: "5px",
              }}
            />
          }
        >
          Invite members
        </Button>
      </div>
    </div>
  );
};

export default Payment;
