import React, { useState, useEffect, useContext, useMemo } from "react";
import { Button } from "@mui/material";
import { SelectTemplateModal } from "./SelectTemplateModal";
import { useForm } from "react-hook-form";
import DisplayElements from "./DisplayElements";
import GlobalContext from "../../../../../../../../Context/Context";
import eCenterMenu from "./templatesDefaultHtml/eCenterMenuBox";
import eSideMenu from "./templatesDefaultHtml/eSideMenuBox";
import eSlideInMenu from "./templatesDefaultHtml/eSlideInMenuBox";
import eSlideMenu from "./templatesDefaultHtml/eSlideMenuBox";
import search from "./templatesDefaultHtml/searchBox";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  formatCssString,
  removeFormatting,
} from "../../../SearchMore/Tabs/SearchMoreUi/utils/helpers";

const defaultConfig = [
  {},
  {
    searchBox: {
      type: "search",
      tmpl: {
        htmlBox: "",
        autocomplete_item: "",
        instant_search_item: "",
        results_data: "",
        search_item: "",
      },
      elements: [],
      stylesheet: "",
      imageOnly: false,
      productOnly: false,
      affType: 0,
    },
  },
];

const SearchBoxUiConfiguration = ({ domain, configs, searchBox }) => {
  const initialEditState = {
    dateLocal: searchBox && searchBox.dateLocal ? true : false,
    defaultCurrency: searchBox && searchBox.defaultCurrency ? true : false,
    type: searchBox && searchBox.type ? true : false,
    logo: searchBox && searchBox.logo ? true : false,
    stylesheet: searchBox && searchBox?.stylesheet ? true : false,
    searchPage: configs && configs?.searchPage ? true : false,
    searchUrlFormat: searchBox && searchBox?.searchUrlFormat ? true : false,
    modal_stylesheet: searchBox && searchBox?.modal_stylesheet ? true : false,
    init_eval: searchBox && searchBox?.init_eval ? true : false,
    search_eval: searchBox && searchBox.search_eval ? true : false,
    htmlBox: searchBox && searchBox.tmpl?.htmlBox ? true : false,
    autocomplete_item:
      searchBox && searchBox.tmpl?.autocomplete_item ? true : false,
    instant_search_item:
      searchBox && searchBox.tmpl?.instant_search_item ? true : false,
    results_data: searchBox && searchBox.tmpl?.results_data ? true : false,
    search_item: searchBox && searchBox.tmpl?.search_item ? true : false,
    dir: searchBox && searchBox.dir ? true : false,
  };

  const defaultValues = useMemo(
    () => ({
      logo: searchBox?.logo || "",
      dateLocal: searchBox?.dateLocal || "he-HE",
      defaultCurrency: searchBox?.defaultCurrency || "",
      dir: searchBox?.dir || "ltr",
      stylesheet: formatCssString(searchBox?.stylesheet || ""),
      searchPage: configs.find((item) => item?.searchPage)?.searchPage || "",
      searchUrlFormat: searchBox?.searchUrlFormat || "",
      modal_stylesheet: searchBox?.modal_stylesheet || "",
      init_eval: searchBox?.init_eval || "",
      search_eval: searchBox?.search_eval || "",
      htmlBox: searchBox?.tmpl?.htmlBox || "",
      autocomplete_item: searchBox?.tmpl?.autocomplete_item || "",
      instant_search_item: searchBox?.tmpl?.instant_search_item || "",
      results_data: searchBox?.tmpl?.results_data || "",
      search_item: searchBox?.tmpl?.search_item || "",
    }),
    [searchBox, configs]
  );

  const context = useContext(GlobalContext);
  const [jsonText, setJsonText] = useState(JSON.stringify(configs, null, 2));
  const [type, setType] = useState(searchBox?.type || "search");
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(initialEditState);
  const [expanded, setExpanded] = useState(false);
  const { register, setValue, getValues, handleSubmit } = useForm();
  const placeholdersObject = {
    eCenterMenu,
    eSideMenu,
    eSlideMenu,
    search,
  };

  useEffect(() => {
    setLoading(true);
    Object.entries(defaultValues).forEach(([key, value]) => {
      setValue(key, value);
    });
    setLoading(false);
  }, [setValue, defaultValues]);

  useEffect(() => {
    const excludedKeys = new Set([
      "dir",
      "dateLocal",
      "type",
      "logo",
      "currency",
    ]);
    const checkAnyTrue = Object.entries(state).some(
      ([key, value]) => !excludedKeys.has(key) && value === true
    );
    if (checkAnyTrue) {
      setExpanded("panel1");
    } else {
      setExpanded(false);
    }
  }, []);

  const handleChangeExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const searchBoxList = [
    {
      textAreaId: "logo",
      textAreaLabel: "Website logo",
      textAreaPlaceholder: "Enter your logo url",
      textAreaIdEdit: state.logo,
      formItemType: "input",
      nestedKey: false,
    },
    {
      textAreaId: "dateLocal",
      textAreaLabel: "Date Local",
      textAreaIdEdit: state.dateLocal,
      formItemType: "select",
      nestedKey: false,
    },
    {
      textAreaId: "defaultCurrency",
      textAreaLabel: "Default Currency",
      textAreaIdEdit: state.defaultCurrency,
      formItemType: "select",
      nestedKey: false,
    },
    {
      textAreaId: "htmlBox",
      textAreaLabel: "Search box HTML",
      textAreaPlaceholder: placeholdersObject[type]?.htmlBox,
      isTmpl: true,
      textAreaIdEdit: state.htmlBox,
      formItemType: "textArea",
      nestedKey: true,
    },
    {
      textAreaId: "autocomplete_item",
      textAreaLabel: "Autocomplete HTML",
      textAreaPlaceholder: placeholdersObject[type]?.autocomplete_item,
      isTmpl: true,
      textAreaIdEdit: state.autocomplete_item,
      formItemType: "textArea",
      nestedKey: true,
    },
    {
      textAreaId: "search_item",
      textAreaLabel: "Search results HTML",
      textAreaPlaceholder: placeholdersObject[type]?.search_item,
      isTmpl: true,
      textAreaIdEdit: state.search_item,
      formItemType: "textArea",
      nestedKey: true,
    },
    {
      textAreaId: "instant_search_item",
      textAreaLabel: "Instant search",
      textAreaPlaceholder: placeholdersObject[type]?.instant_search_item,
      isTmpl: true,
      textAreaIdEdit: state.instant_search_item,
      formItemType: "textArea",
      nestedKey: true,
    },
    {
      textAreaId: "results_data",
      textAreaLabel: "Filter Bar HTML",
      textAreaPlaceholder: placeholdersObject[type]?.results_data,
      isTmpl: true,
      textAreaIdEdit: state.results_data,
      formItemType: "textArea",
      nestedKey: true,
    },
    {
      textAreaId: "stylesheet",
      textAreaLabel: "Style",
      textAreaPlaceholder: "Style field",
      textAreaIdEdit: state.stylesheet,
      formItemType: "textArea",
      nestedKey: false,
    },
    {
      textAreaId: "modal_stylesheet",
      textAreaLabel: "Modal Style",
      textAreaPlaceholder: "Modal Style field",
      textAreaIdEdit: state.modal_stylesheet,
      formItemType: "textArea",
      nestedKey: false,
    },
    {
      textAreaId: "init_eval",
      textAreaLabel: "On Init Eval",
      textAreaPlaceholder: "Init Eval",
      textAreaIdEdit: state.init_eval,
      formItemType: "textArea",
      nestedKey: false,
    },
    {
      textAreaId: "search_eval",
      textAreaLabel: "After Search Eval",
      textAreaPlaceholder: "After Search Eval",
      textAreaIdEdit: state.search_eval,
      formItemType: "textArea",
      nestedKey: false,
    },
    {
      textAreaId: "searchUrlFormat",
      textAreaLabel: "Search Url Format",
      textAreaPlaceholder:
        "Format example: /products/product/?s=<query>/someWord",
      textAreaIdEdit: state.searchUrlFormat,
      formItemType: "input",
      nestedKey: false,
    },
    {
      textAreaId: "dir",
      textAreaLabel: "Language direction",
      textAreaIdEdit: state.dir,
      formItemType: "radio",
      nestedKey: false,
      isPlaceHolder: false,
    },
    {
      textAreaId: "searchPage",
      textAreaLabel: "Search page navigation",
      textAreaIdEdit: state.searchPage,
      formItemType: "input",
      nestedKey: false,
      isPlaceHolder: false,
    },
  ];

  const toggleEdit = (key) => {
    setState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const updatedConfigs = (values) => {
    return configs.map((item) => {
      if (item.searchBox) {
        let searchBoxUpdated = false;
        item.searchPage = values.searchPage || "";
        item.searchBox.type = type;
        const updatedSearchBox = { ...item.searchBox };

        Object.keys(updatedSearchBox).forEach((key) => {
          if (values.hasOwnProperty(key)) {
            if (key === "stylesheet") {
              updatedSearchBox[key] = removeFormatting(values[key]);
            } else {
              updatedSearchBox[key] = values[key];
            }
            searchBoxUpdated = true;
          }
        });

        const tmplObj = {
          htmlBox: "",
          autocomplete_item: "",
          instant_search_item: "",
          results_data: "",
          search_item: "",
        };

        if (!updatedSearchBox.tmpl) {
          updatedSearchBox.tmpl = {};
        }

        for (const key in tmplObj) {
          if (!(key in updatedSearchBox.tmpl)) {
            updatedSearchBox.tmpl[key] = tmplObj[key];
          }
        }

        const tmpl = { ...updatedSearchBox.tmpl };
        let tmplUpdated = false;

        Object.keys(values).forEach((key) => {
          if (tmpl.hasOwnProperty(key)) {
            tmpl[key] = values[key];
            tmplUpdated = true;
          }
        });

        if (tmplUpdated) {
          updatedSearchBox.tmpl = tmpl;
          searchBoxUpdated = true;
        }

        if (searchBoxUpdated) {
          const missingKeys = Object.keys(values).filter(
            (key) =>
              !updatedSearchBox.hasOwnProperty(key) &&
              (!updatedSearchBox.tmpl ||
                !updatedSearchBox.tmpl.hasOwnProperty(key)) &&
              key !== "searchPage"
          );

          missingKeys.forEach((key) => {
            updatedSearchBox[key] = values[key];
          });

          return { ...item, searchBox: updatedSearchBox };
        }
      }
      return item;
    });
  };

  const onSubmit = async (values) => {
    const _configs = updatedConfigs(values);
    const requestJson = {
      action: 1014,
      host: domain?.domain,
      configs: _configs ? _configs : defaultConfig,
      credentials: context.loadCredentials(),
    };
    fetchRequest(requestJson);
  };

  const fetchRequest = async (requestJson) => {
    try {
      const responseJson = await context.myFetch(requestJson);
      if (responseJson.domains) {
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );
        context.message({
          type: "success",
          body: "Indexing settings were successfully updated",
        });
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
    }
  };

  const handleChangeJsonConfig = (event) => {
    setJsonText(event.target.value);
  };

  const handleSaveJsonConfig = () => {
    const requestJson = {
      action: 1014,
      host: domain?.domain,
      configs: JSON.parse(jsonText),
      credentials: context.loadCredentials(),
    };
    fetchRequest(requestJson);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <div>Searchbox UI configuration</div>
      <SelectTemplateModal
        domain={domain?.domain}
        configs={configs}
        selectedTemplate={type}
        setSelectedTemplate={setType}
        setStylesheetValue={setValue}
        getStyleValue={getValues}
      />
      <form
        style={{
          width: "100%",
          margin: "20px 0 10px 0",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChangeExpanded("panel1")}
            sx={{
              width: "100%",
              boxShadow: "unset",
              borderRadius: "20px !important",
              border: "2px solid #f4f6f8",
              color: "var(--Mid-Grey, #616060)",
              fontFamily: "Poppins",
              fontSize: "14px",
              "&::before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              Advanced Configuration
            </AccordionSummary>
            <AccordionDetails>
              {!loading &&
                searchBoxList.map((item, index) =>
                  item.isTmpl && !item.textAreaPlaceholder ? null : (
                    <DisplayElements
                      item={item}
                      index={index}
                      toggleEdit={toggleEdit}
                      register={register}
                      getValues={getValues}
                      setValue={setValue}
                      key={index}
                    />
                  )
                )}
              <Accordion
                sx={{
                  width: "100%",
                  boxShadow: "unset",
                  borderRadius: "20px !important",
                  border: "2px solid #f4f6f8",
                  color: "var(--Mid-Grey, #616060)",
                  fontFamily: "Poppins",
                  marginTop: "20px",
                  fontSize: "14px",
                  "&::before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                >
                  Edit JSON Config
                </AccordionSummary>
                <AccordionDetails>
                  <div className="config-elements">
                    <textarea
                      style={{
                        marginTop: "15px",
                        marginRight: "10px",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        border: "2px solid var(--Grey, #EBEFF3)",
                        borderRadius: "10px",
                        minHeight: "200px",
                        maxWidth: "100%",
                        outlineColor: "#5daaa1",
                        padding: "10px",
                      }}
                      value={jsonText}
                      onChange={handleChangeJsonConfig}
                    />
                    <Button
                      sx={{ marginTop: "10px", padding: "7px 20px" }}
                      className="btn-save"
                      onClick={handleSaveJsonConfig}
                    >
                      Save
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
          <Button
            sx={{
              textAlign: "left",
              display: "block",
              backgroundColor: "#ffffff",
              color: "var(--Green, #5daaa1)",
              borderRadius: "25px",
              marginTop: "40px",
              border: "2px solid",
              textTransform: "unset",

              borderColor: "var(--Green, #5daaa1)",
              fontFamily: "Poppins",
              padding: "5px 15px",
              height: "40px",
              "&:hover": {
                backgroundColor: "#5daaa1",
                border: "2px solid",
                borderColor: "var(--Green, #5daaa1)",
                color: "var(--Green, #ffffff)",
              },
            }}
            type="submit"
          >
            Save configuration
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SearchBoxUiConfiguration;
