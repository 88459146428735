import React, { useEffect, useRef } from "react";
import "./TermsText.css";

const TermsText = ({ setIsScrolledToBottom, error }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const card = cardRef.current;
      if (card) {
        const scrolledToBottom =
          card.scrollHeight - card.clientHeight <= card.scrollTop + 1;
        if (scrolledToBottom) {
          setIsScrolledToBottom(true);
        }
      }
    };

    const card = cardRef.current;
    if (card) {
      card.addEventListener("scroll", handleScroll);

      return () => {
        card.removeEventListener("scroll", handleScroll);
      };
    }
  }, [setIsScrolledToBottom]);

  return (
    <div ref={cardRef} className={error ? "terms error" : "terms"}>
      <p className="paragraph">
        PLEASE READ THESE TERMS OF USE CAREFULLY, PRIOR TO MARKING THE "I ACCEPT
        THESE TERMS AND CONDITIONS" BOX (OR ANY OTHER SIMILAR WORDING).
      </p>
      <p className="paragraph">
        These Terms of Use (the “Terms”) are entered into by Heyday Search
        Limited (the “Company”) and you (the “User”). The Terms contain the
        complete terms and conditions that at all times govern the User’s use of
        the website www.heyday.io (the “Site”), the Search Solution (as defined
        in Section 1.1), Documentation (as defined in Section 1.5) and Data (as
        defined in Section 6.2). Each of the Company and the User may be
        referred to as a “Party”, and together as the “Parties”. When the User
        marks the "I accept these Terms and Conditions" box (or any other
        similar wording) (the “Effective Date”), the User agrees to be bound by
        the Terms in its entirety and without reservation. If you are entering
        into the Terms on behalf of a legal entity, you represent to the Company
        that you have the authority to bind such entity to the Terms and, in
        such event, “User” as used in the Terms shall refer to such entity. IF
        YOU ARE ENTERING THE TERMS ON YOUR OWN BEHALF (AS AN INDIVIDUAL) OR IF
        YOU ARE ENTERING THE TERMS ON BEHALF OF A LEGAL ENTITY, AND YOU OR SUCH
        ENTITY (AS APPLICABLE), DO NOT AGREE WITH ANY OF THE PROVISIONS IN THE
        TERMS, YOU MUST NOT MARK THE "I ACCEPT THESE TERMS AND CONDITIONS" BOX
        (OR ANY OTHER SIMILAR WORDING), AND YOU MUST NOT USE THE SEARCH
        SOLUTION, DOCUMENTATION AND DATA. YOU MUST NOT ENTER THE TERMS IF YOU
        ARE UNDER THE AGE OF 18. To the extent permitted under applicable laws,
        the User hereby waives any applicable rights to require or receive an
        original (non-electronic) signature or delivery of non-electronic
        records of these Terms. The User should also read the{" "}
        <a href="https://heyday.io/policy.html">Privacy Policy</a> carefully. It
        sets out how the Company collects and uses the User’s personal
        information.
      </p>
      <ol className="outer">
        <li>
          DESCRIPTION OF THE SEARCH SOLUTION AND THE SITE, ACCESS AND
          INSTALLATION OF THE SEARCH SOLUTION
          <ol className="inner">
            <li>
              The Company is the owner of a search tool, which can be integrated
              into a website in order to provide a search function to end users
              of such website (the “Search Solution”).
            </li>
            <li>
              Following the User’s registration of an account with the Company
              through the Site (the “Account”), the Company shall either, make
              available to the User through the Site or provide to the User, the
              Search Solution.
            </li>
            <li>
              The User shall only integrate the Search Solution with the
              websites which the User operates or owns and on the websites or
              which the User has the right to integrate the Search Solution
              (collectively and individually the “User Website(s)”).
            </li>
            <li>
              The User shall be responsible for integrating and maintaining the
              Search Solution with the User Website(s).
            </li>
            <li>
              The User shall integrate and maintain the Search Solution and the
              Data strictly in accordance with the Company’s instructions and
              any guides and manuals delivered to the User or made available to
              the User on the Site (collectively and individually the
              “Documentation”).
            </li>
            <li>
              The User agrees, that it shall immediately remove the Search
              Solution from any User Website(s), if requested by the Company. In
              the event that the User does not comply with the foregoing,
              without derogating from the Company&#39;s other rights or
              remedies, the Company may disable the Search Solution or terminate
              the Terms on written notice to the User. In the event the Company
              disables the Search Solution, it will disable the Account as well
              and the User must cease to use the Account, Site, Search Solution,
              Documentation and Data.
            </li>
            <li>
              The Site is intended to provide the User with information about
              the Company and its products, to enable the User to purchase, use
              and maintain the Search Solution in accordance with the Terms.
            </li>
          </ol>
        </li>
        <li>
          ACCOUNT INFORMATION
          <ol className="inner">
            <li>
              The User must safeguard and not disclose its Account username and
              password and the User must supervise the use of the Account.
            </li>
            <li>
              All details provided by the User to the Company either during the
              registration process or at any time thereafter, must be true and
              accurate and the User agrees to keep its Account information up to
              date.
            </li>
            <li>
              In addition, the User agrees that it is solely and fully
              responsible for maintaining the confidentiality of the Account
              usernames and passwords and for all activities that occur under
              the Account. The User must notify the Company immediately of any
              unauthorized use of the Account or any other breach of security by
              sending an email to support@heydayservice.io.
            </li>
          </ol>
        </li>
        <li>
          GRANT OF LICENSE
          <p className="paragraph">
            Subject to the User&#39;s compliance with the Terms, from the
            Effective Date and for the remainder of the duration of the Terms,
            the Company grants the User a non-exclusive, limited,
            non-transferable, non-assignable, non- sublicensable, revocable
            license to use the Search Solution, Documentation and Data solely in
            order to provide a search function for end users of the User
            Website(s).
          </p>
        </li>
        <li>
          RESTRICTIONS
          <ol className="inner">
            <li>
              Except for the license explicitly granted in Section 3 , the User
              shall have no other right, express or implied, in the Search
              Solution, Documentation and Data, and the User shall not (nor
              attempt to) nor shall the User allow any third party to (or
              attempt to):
              <ol className="inner">
                <li>copy the Site, Search Solution, Documentation or Data;</li>
                <li>
                  sell, resell, lease, sublicense or distribute the Site, Search
                  Solution, Documentation or Data to any third party;
                </li>
                <li>
                  reverse engineer or attempt to find the underlying code or
                  script of the Search Solution or the Site;
                </li>
                <li>
                  circumvent, disable or otherwise interfere with
                  security-related features of the Search Solution or the Site
                  or any features that prevent or restrict use or copying of any
                  content or that enforce limitations on the use of the Site or
                  Search Solution;
                </li>
                <li>
                  present or share the data or information received through the
                  Site, Search Solution, Documentation or Data;
                </li>
                <li>
                  modify, revise, create derivative works of, or decompile the
                  Site, Search Solution, Documentation or Data;
                </li>
                <li>
                  represent that it possesses any proprietary interest in the
                  Site, Search Solution, Documentation or Data;
                </li>
                <li>
                  take any action that imposes or which the Company determines,
                  in its sole discretion, may impose an unreasonable or
                  disproportionately large load on the Company’s infrastructure
                  or the infrastructure which supports the Site or Search
                  Solution;
                </li>
                <li>
                  interfere or attempt to interfere with the integrity or proper
                  working of the Site or Search Solution, or either of their
                  related activities;{" "}
                </li>
                <li>
                  use any robot, spider, scraper, or other automated means to
                  access or monitor the Site or the Search Solution for any
                  purpose, or to copy, access, collect, download, or otherwise
                  obtain data, content or other information from the Site or
                  Search Solution;
                </li>
                <li>
                  use the Search Solution, Documentation or Data to provide
                  services to a third party, including without limitation on a
                  service bureau basis, to provide managed services, or through
                  a timeshare arrangement;
                </li>
                <li>
                  use the Site, Search Solution, Documentation or Data to
                  develop, offer, operate, support or enable any competing
                  service or product;
                </li>
                <li>
                  directly, or indirectly take any action to contest the
                  Company’s Intellectual Property Rights or infringe them in any
                  way;
                </li>
                <li>
                  remove, deface, obscure, or alter the Company’s or any third
                  party’s identification, attribution or copyright notices,
                  trademarks, or other proprietary rights affixed to or provided
                  as part of the Site, Search Solution, Documentation or Data;
                  or
                </li>
                <li>
                  use the Site, Search Solution, Documentation or Data in breach
                  of these Terms, any terms and conditions of any third-party
                  product or service the User uses, or any agreement which the
                  User has entered into with any third party. For the purposes
                  of the Terms "Intellectual Property Rights" shall mean,
                  without limitation, all intellectual property rights, whether
                  or not patentable, including without limitation, rights in
                  algorithms, binary code, brands, business methods, business
                  plans, computer programs, computer software, concepts,
                  confidential information, databases, developments, firmware,
                  composition of matter or materials, certification marks,
                  collective marks, copyright, customer lists, data, designs
                  (whether registered or unregistered), derivative works,
                  discoveries, distributor lists, documents, domain names, file
                  layouts, formulae, goodwill, ideas, improvements, industrial
                  designs, information, innovations, inventions, integrated
                  circuits, know-how, logos, manufacturing information, mask
                  works, materials, methods, moral rights, object code, original
                  works of authorship, patents, patent applications, patent
                  rights, including but not limited to any and all
                  continuations, divisions, reissues, re-examinations or
                  extensions, plans, processes, proprietary technology,
                  reputation, research data, research results, research records,
                  semiconductor chips, service marks, software, source code,
                  specifications, statistical models, supplier lists, systems,
                  techniques, technology, trade secrets, trademarks, trade
                  dress, trade names, trade styles, technical information,
                  utility models, and any rights analogous to the foregoing.
                </li>
              </ol>
            </li>
            <li>
              The Search Solution must not be used on any website that contains:
              <ol className="inner">
                <li>
                  sexually explicit, pornographic or obscene content (whether in
                  text or graphics);
                </li>
                <li>
                  speech or images that are offensive, profane, hateful,
                  threatening, harmful, defamatory, libelous, harassing or
                  discriminatory (whether based on race, ethnicity, creed,
                  religion, gender, sexual orientation, physical disability or
                  otherwise);
                </li>
                <li>graphic violence; or </li>
                <li>any unlawful behavior or conduct.</li>
              </ol>
            </li>
            <li>
              The User agrees that the phrase “powered by Heyday io” or such
              other designation as determined by the Company, shall be visible
              to end users of the User Website(s) below or near the search
              function that the Search Solution displays. The User shall not and
              shall not allow any third party to obscure, delete or modify such
              phrase or designation.
            </li>
            <li>
              The Company may disable the User's use of the Search Solution and
              the Account at any time and for any reason. In such event, the
              User must cease to use the Account, Site, Search Solution
              Documentation and Data.
            </li>
          </ol>
        </li>
        <li>
          AMENDMENTS
          <ol className="inner">
            <li>
              The Company reserves the right to amend the Terms from time to
              time and the Company will notify the User of any such amendment by
              publishing the new version of the Terms on https://heyday.io/terms
              or sending the User a notice of the amendment to the email address
              which has been provided with respect to the Account. Any modified
              version of the Terms will take effect seven (7) days after
              publication or seven (7) days after the email notice has been sent
              (as applicable). The User’s continued use of the Search Solution
              after such seven (7) day period, will be deemed to constitute the
              User’s acceptance of the changes to the Terms. If the User does
              not agree to any such amendment, the User’s sole and exclusive
              remedy will be to terminate the Terms in accordance with the
              provisions in the Terms. It is the User’s responsibility to ensure
              that it is aware of the correct and current Terms and the Company
              advises the User to check for updates on a regular basis.
            </li>
            <li>
              The Company may modify or discontinue any feature, functionality
              or any other tool, with respect to the Search Solution or the
              Site, at the Company’s discretion.
            </li>
            <li>
              The User acknowledges that its use of the Search Solution is not
              contingent on the delivery by the Company of any future release of
              any functionality or feature of the Search Solution or the Data.
            </li>{" "}
          </ol>
        </li>
        <li>
          INTELLECTUAL PROPERTY RIGHTS AND FEEDBACK
          <ol className="inner">
            <li>
              The Company does not and shall not be deemed to have sold or
              transferred title in the Site, Search Solution Documentation and
              Data to the User.
            </li>
            <li>
              The Site, Search Solution, Documentation and any data and
              statistics provided by the Company to the User or generated by the
              Search Solution (collectively and individually, the "Data") shall
              remain the Company’s property.{" "}
            </li>
            <li>
              All Intellectual Property Rights evidenced by or embodied in or
              related to the Search Solution and to any customizations, bespoke
              development, enhancements or derivatives thereof, or connected to
              or arising out of the services or products provided by the Company
              to the User which includes without limitation the Site, Search
              Solution, Documentation and Data, are and shall be owned solely by
              the Company. The Company reserves all rights not expressly granted
              hereunder and nothing in the Terms constitutes a waiver of the
              Company’s Intellectual Property Rights under any law.
            </li>
            <li>
              All Intellectual Property Rights in and to all content published
              on the Site (including, without limitation, all copyright,
              trademarks, software, designs, graphics, layout, look and feel,
              information, structure, database rights, design rights, patents,
              domain names, and all other materials and content of whatever
              nature) (collectively and individually, the “Content”) is the
              Company’s property or is licensed to the Company. The User may use
              the Content solely with respect to the User’s use of the Search
              Solution.
            </li>
            <li>
              To the extent the User provides to the Company any ideas,
              feedback, data, or suggestions regarding the products or services
              offered by the Company which includes without limitation to the
              Search Solution, Documentation, Site or Data (collectively and
              individually, the “Feedback”), all such Feedback shall be owned
              exclusively by the Company and the User hereby assigns and agrees
              to assign to the Company all right, title and interest in and to
              such Feedback and all Intellectual Property Rights therein.
            </li>
            <li>
              The Company may issue a press release disclosing that User is
              using the Search Solution. Furthermore, the User agrees that the
              Company may use the User’s name and logo as well as the User
              Website(s) domain names in its customer lists, financial reports
              advertising and marketing materials which includes without
              limitation displaying such on the Site. The User shall not without
              the prior written consent of the Company issue any press releases
              regarding the Search Solution nor use the Company’s name and logo
              in its advertising and marketing materials.
            </li>
          </ol>
        </li>
        <li>
          FEES
          <ol className="inner">
            <li>
              From the Effective Date, the User shall pay the Company the fees
              detailed on the page https://heyday.io/pricing.html (the “Fees”).
              The page https://heyday.io/pricing.html shall form an integral
              part of the Terms.{" "}
            </li>
            <li>
              The Fees may be invoiced by the Company to the User monthly in
              arrears and the User shall pay the Fees within 14 days of the
              invoice date.
            </li>
            <li>
              All payments shall be made in accordance with the instructions of
              the Company.
            </li>
            <li>
              All amounts payable to the Company by the User under the Terms are
              exclusive of all taxes (including without limitation VAT), levies
              or similar governmental charges, however designated, and will be
              paid by the User. Without limiting the foregoing, if any such
              taxes are imposed upon and paid by the Company, the User shall
              reimburse the Company within seven days of the date of an invoice
              from the Company for such amount. In the event that the User is
              required to withhold or deduct taxes or other government charges
              under any applicable laws or regulations, the User shall increase
              the amount payable so that the Company receives an amount that
              would have been otherwise payable under the Terms without such
              deduction or withholding of any taxes or other government charges.
            </li>
            <li>
              Any amount that has not been paid by the User to the Company in
              accordance with the terms of the Terms will be subject to a late
              charge of 1.5% per month, until paid in full.
            </li>
            <li>
              The User acknowledges and accepts that the Company's calculation
              of the amount of Fees owed to the Company shall be the sole and
              authoritative measurement and shall not be open to review or
              appeal.
            </li>
            <li>
              To ensure accurate tracking of the Fees, the User is responsible
              for ensuring that the Search Solution is properly integrated with
              the User Website(s).
            </li>
            <li>
              All payments made by the User to the Company are non-refundable.
            </li>
          </ol>
        </li>
        <li>
          CONFIDENTIALITY
          <p className="paragraph">
            The User may have access to certain non-public, proprietary,
            confidential information or trade secret of the Company, whether
            furnished before or after the Effective Date regardless of the
            manner it has been furnished (“Confidential Information”). The User
            agrees to: (i) keep the Confidential Information confidential (using
            at least the same degree of care it uses to protect its own
            confidential information, which shall in any event not be less than
            a reasonable degree of care); and (ii) not use any Confidential
            Information except solely with respect to its use of the Search
            Solution.{" "}
          </p>
        </li>
        <li>
          USER’S WARRANTIES AND REPRESENTATIONS
          <ol className="inner">
            <li>
              The User warrants and represents to the Company that:
              <ol className="inner">
                <li>
                  if the User is entering the terms as an individual on its own
                  behalf, the User is at least 18 years of age.
                </li>
                <li>
                  the User shall comply with all applicable laws and
                  regulations.
                </li>
                <li>
                  the User’s use of the Site, Search Solution, Documentation and
                  Data shall comply with all applicable laws and regulations.
                </li>
                <li>
                  the User’s use of the Site, Search Solution, Documentation and
                  Data does not and shall not infringe, violate or
                  misappropriate any Intellectual Property Rights, rights of
                  privacy, rights of publicity or any other rights of any third
                  party.
                </li>
                <li>
                  the User has not had a previous agreement with the Company
                  (which includes without limitation any agreement to use or
                  install the Search Solution) which has been terminated for the
                  User's breach or for the User's fraud.
                </li>
                <li>
                  the User shall not access or attempt to access the
                  administrative interface of the Site or the Search Solution by
                  any means of other than through the interface provided by the
                  Company in connection with the Site and Search Solution (as
                  applicable).
                </li>
                <li>
                  it is the User's responsibility to protect the privacy of the
                  end users of the User Website(s) and provide the end users of
                  the User Website(s) with a Privacy Policy which complies with
                  applicable laws and regulations.
                </li>
                <li>
                  the User shall not engage in or attempt to engage in any Fraud
                  (as defined in Section 10)
                </li>
                <li>
                  the User shall immediately and strictly comply with the
                  Company’s instructions, guidance and Documentation
                </li>
                <li>
                  the User Website(s) shall comply with all applicable laws and
                  regulations
                </li>
                <li>
                  the User Website(s) shall not infringe, violate or
                  misappropriate any Intellectual Property Rights, rights of
                  privacy, rights of publicity or any other rights of any third
                  party.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          FRAUD
          <ol className="inner">
            <li>
              The User shall not (nor attempt to) nor shall the User allow any
              third party to (or attempt to), take any fraudulent act with
              respect to the Search Solution, which includes without limitation
              interfering with the Company’s systems which enable the Company to
              count the number of searches made on the Search Solution or the
              number of pages on which the Search Function is displayed or
              concealing or fraudulently reducing the number of searches made on
              the Search Function or the number of pages on which the Search
              Function is displayed (collectively and individually “Fraud”). The
              User agrees to provide the Company with all information which the
              Company requests and fully co-operate in good faith with any
              investigation by the Company in relation to Fraud.{" "}
            </li>
            <li>
              Nothing in this Section 10 shall derogate from the Company’s other
              rights or remedies.
            </li>{" "}
          </ol>
        </li>
        <li>
          SUSPENSION AND DISABLEMENT
          <ol className="inner">
            <li>
              If the Company believes, acting reasonably, that:
              <ol className="inner">
                <li>
                  the User is using or has used the Site, Search Solution,
                  Documentation or Data in a manner that may cause harm to the
                  Company or any third party,{" "}
                </li>
                <li>
                  the User is using or has used the Site, Documentation, Data or
                  the Search Solution in a manner which is in breach of these
                  Terms; or{" "}
                </li>
                <li>any Fraud has occurred or is likely to occur,</li>
                <p className="paragraph">
                  then the Company may suspend or disable the Account and the
                  User’s use of the Search Solution and in such event that User
                  must cease to use the Account, Site, Search Solution,
                  Documentation and Data until the Company has concluded its
                  investigation.
                </p>
              </ol>
            </li>
            <li>
              Nothing in this Section 11 shall derogate from the Company’s other
              rights or remedies.
            </li>
          </ol>
        </li>
        <li>
          AUDIT
          <ol className="inner">
            <li>
              During the duration of the Terms and six months thereafter, if the
              Company believes, acting reasonably, that:
              <ol className="inner">
                <li>
                  the User is using or has used the Site, Search Solution,
                  Documentation or Data in a manner which breaches the Terms;{" "}
                </li>
                <li>any Fraud has occurred or is likely to occur; or</li>
                <li>the User has underpaid the Fees</li>
                <p className="paragraph">
                  the Company at its own expense shall have the right upon 14
                  days prior written notice to the User, during normal business
                  hours, to audit the books and records of the User in order to
                  verify that: (i) the User is not using or has used the Site,
                  Search Solution, Documentation or Data in a manner which
                  breaches the Terms; (ii) that no Fraud has occurred or is
                  likely to occur; and (iii) that the User has not underpaid the
                  Fees . The User will permit the Company to conduct such audits
                  and shall promptly respond to all such inquiries accurately
                  and completely. The User shall fully assist the Company in
                  conducting such audits and inquiries, without charge, and
                  shall make such documents available for inspection and
                  copying.
                </p>
              </ol>
            </li>
            <li>
              In the event that any such audit reveals an underpayment of the
              Fees, the User shall promptly pay the Company the amount of the
              underpaid Fees to the Company.{" "}
            </li>
            <li>
              In the event that any such audit reveals an underpayment of the
              Fees by the User of 5% or more the User shall promptly reimburse
              the Company for the cost of such audit.{" "}
            </li>
            <li>
              In the event that any such audit reveals that the User is using or
              has used the Site, Search Solution, Documentation or Data in a
              manner which breaches the Terms or that Fraud has occurred or is
              likely to occur the User shall immediately take all steps
              requested by the Company to remedy such breach or Fraud and the
              User shall promptly reimburse the Company for the cost of such
              audit.
            </li>
            <li>
              Nothing in this Section 12 shall derogate from the Company’s other
              rights or remedies.{" "}
            </li>
          </ol>
        </li>
        <li>
          EXPORT CONTROLS AND SANCTIONS
          <ol className="inner">
            <li>
              The Search Solution may be subject to Israeli, U.S. or foreign
              export controls, laws and regulations (collectively and
              individually the “Export Controls”), and the User acknowledges and
              confirms that:
              <ol className="inner">
                <li>
                  The User is not located or no shall the User use, export,
                  re-export or import the Search Solution (or any portion
                  thereof) in or to, any person, entity, organization,
                  jurisdiction or otherwise, in violation of the Export
                  Controls;{" "}
                </li>
                <li>
                  The User is not:
                  <ol className="inner">
                    <li>
                      organized under the laws of, operating from, or otherwise
                      ordinarily resident in a country or territory that is the
                      target or comprehensive U.S. economic or trade sanctions
                      (currently, Cuba, Iran, Syria, North Korea, or the Crimea
                      region of Ukraine);
                    </li>
                    <li>
                      identified on a list of prohibited or restricted persons,
                      such as the U.S. Treasury Department’s List of Specially
                      Designated Nationals and Blocked Persons, or
                    </li>
                    <li>otherwise the target of U.S. sanctions.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              The User is solely responsible for complying with applicable
              Export Controls and sanctions which may impose additional
              restrictions, prohibitions or requirements on the use, export,
              re-export or import of the Search Solution.
            </li>
          </ol>
        </li>
        <li>
          DISCLAIMER
          <p className="paragraph">
            THE SITE, SEARCH SOLUTION, DOCUMENTATION, DATA AND ANY PRODUCTS,
            SERVICES AND INFORMATION PROVIDED BY THE COMPANY UNDER THE TERMS ARE
            PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS
            WITHOUT WARRANTIES AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS
            OR IMPLIED. THE COMPANY AND ITS AFFILIATES, SERVICE PROVIDERS AND
            SUB CONTRACTORS DISCLAIM THAT THE SITE, SEARCH SOLUTION,
            DOCUMENTATION, DATA, PRODUCTS OR ANY SERVICES AS WELL AS ANY
            INFORMATION, WILL BE DELIVERED, FUNCTION OR PERFORM ERROR-FREE OR
            WITHOUT INTERRUPTION. WITHOUT LIMITING THE FOREGOING, THE COMPANY
            AND ITS AFFILIATES, SERVICE PROVIDERS AND SUB CONTRACTORS DISCLAIM
            ALL IMPLIED WARRANTIES OF MERCHANTABILITY, ACCURACY, PERFORMANCE,
            AND FITNESS FOR A PARTICULAR PURPOSE AND NON INFRINGEMENT WITH
            RESPECT TO THE SITE, SEARCH SOLUTION, DOCUMENTATION, DATA AND
            PRODUCTS ANY SERVICES AND INFORMATION PROVIDED BY THE COMPANY UNDER
            THE TERMS.
          </p>
        </li>
        <li>
          LIMITATION OF LIABILITY
          <ol className="inner">
            <li>
              TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW:
              <ol className="inner">
                <li>
                  THE COMPANY SHALL NOT BE LIABLE FOR ANY FOR INDIRECT,
                  CONSEQUENTIAL, INCIDENTAL. PUNITIVE OR SPECIAL DAMAGES OR
                  DAMAGES FOR LOSS OF PROFITS, REVENUE, DATA OR DATA USE,
                  INCURRED BY THE USER OR ANY THIRD PARTY (INCLUDING WITHOUT
                  LIMITATION LOSS OF PROFITS, BUSINESS OR ANTICIPATED SAVINGS)
                  WHETHER BASED ON CONTRACT, TORT OR OTHER LEGAL THEORY, AND
                  WHETHER THE COMPANY IS ADVISED OR SHOUL HAVE BEEN AWARE OF THE
                  POSSIBILITY OF SUCH LOSSES AND DAMAGES; AND{" "}
                </li>
                <li>
                  THE COMPANY’S LIABILITY SHALL NOT EXCEED THE TOTAL AMOUNT OF
                  THE FEES THAT THE USER HAS PAID DURING THE SIX (6) MONTH
                  PERIOD PRECEDING SUCH LIABILITY ARISING.
                </li>
              </ol>
            </li>
            <li>
              NO ACTION, REGARDLESS OF FORM, MAY BE BROUGHT BY THE USER AFTER
              THE EARLIER OF:
              <ol className="inner">
                <li>
                  SIX (6) MONTHS AFTER THE DATE ON WHICH THE USER BECOMES AWARE
                  THAT A CAUSE OF ACTION HAS ARISEN; AND
                </li>
                <li>
                  THE PERIOD SET FORTH UNDER THE APPLICABLE STATUTE OF
                  LIMITATIONS.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          INDEMNIFICATION
          <ol className="inner">
            <li>
              The User shall defend, indemnify, and hold the Company, its
              affiliates and each of their respective shareholders, officers,
              directors, employees, affiliates, sub-contractors, service
              providers, agents, successors and assigns (collectively and
              individually the Indemnified Parties) from any claim or action
              brought against the Company or the Indemnified Parties based on or
              arising from:
              <ol className="inner">
                <li>any Fraud or attempted Fraud;</li>
                <li>any breach of the Terms by the User;</li>
                <li>the User Website(s);</li>
                <li>
                  any claim related to the User’s entitlement to use the Search
                  Solution on the User Website(s) or the User's entitlement to
                  use the Documentation or Data; or
                </li>
                <li>
                  any act or omission of the User or any third party acting on
                  the User’s behalf.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          TERM AND TERMINATION
          <ol className="inner">
            <li>
              The duration of the Terms shall commence on the Effective Date and
              shall continue until terminated by a Party in accordance with the
              Terms.
            </li>
            <li>
              A Party may terminate the Terms for convenience by providing to
              the other Party, thirty (30) days’ prior written notice.{" "}
            </li>
            <li>
              The Company may terminate the Terms upon written notice to the
              User, if the User breaches the Terms or for Fraud.
            </li>
            <li>
              The Company may terminate the Terms upon written notice to the
              User, if from the Effective Date the User not paid the Fees for
              any month, as the usage of the Search Solution has not reached any
              Fees payment threshold.
            </li>
            <li>
              For a notice of termination of the Terms to be effective: (i) if
              the notice of termination is sent by the Company, it shall be sent
              by email to the User’s email address which has been provided with
              respect to the Account and shall be deemed as delivered to the
              User when sent by the Company; or (ii) if the notice of
              termination is sent by the User it shall be sent by email to
              support@heydayservice.io and shall be deemed as delivered to the
              Company when such email is received to such email address.
            </li>
            <li>
              Upon termination of the Terms for any reason:
              <ol className="inner">
                <li>
                  User's right to use to the Search Solution, Site,
                  Documentation and Data shall expire and the User shall cease
                  all use of the Account, Search Solution, Site, Documentation
                  and Data;{" "}
                </li>
                <li>
                  all outstanding Fees as well as the Fees for the month in
                  which the effective date of termination of the Terms falls
                  shall become due and payable by the effective date of
                  termination;
                </li>
                <li>the Account shall terminate; </li>
                <li>
                  the User shall immediately return to the Company the
                  Confidential Information, Data and any other information in
                  tangible form obtained in connection with the Search Solution;
                </li>
                <li>
                  the User shall immediately return to Company or erase or
                  otherwise destroy all copies of the Search Solution and
                  Documentation in the User’s possession, including without
                  limitation such that are fixed or resident in the memory or
                  hard disks of User’s computers; and
                </li>
                <li>
                  at the Company’s request, the User shall certify in writing to
                  the Company that all copies and partial copies of the Search
                  Solution and Documentation have been either returned to the
                  Company or otherwise erased or destroyed and deleted from any
                  computer, libraries or storage devices.
                </li>
              </ol>
            </li>
            <li>
              Any provisions hereof which expressly or by their nature are
              required to survive termination or expiration of the Terms in
              order to achieve their purpose shall so survive until it shall no
              longer be necessary for them to survive in order to achieve that
              purpose. Without derogating from the generality of the foregoing,
              Sections 4.1, 6, 7.3, 7.4, 7.5, 7.6, 7.8, 8, 12, 14, 15, 16, 17.6,
              17.7, 18, 19, 21 and 22 hereof shall survive termination of this
              Agreement.
            </li>
          </ol>
        </li>
        <li>
          EQUITABLE RELIEF
          <p className="paragraph">
            Without affecting any other rights and remedies that the Company may
            have, the User acknowledges and agrees that in the event of a breach
            or threatened breach of the Terms by the User, that damages would
            not be an adequate remedy and that the Company shall be entitled to
            remedies of injunction, specific performance and other equitable
            relief for any threatened or actual breach of the Terms and that no
            proof of special damages shall be necessary for the enforcement of
            the Terms.
          </p>
        </li>
        <li>
          REASONABLE ALLOCATION OF RISKS
          <p className="paragraph">
            The User hereby acknowledges and agrees that the limitations of
            liability and disclaimers contained in these Terms are agreed upon
            by the Parties and such limitations and allocation of risks to be
            commercially reasonable and suitable for the engagement hereunder,
            and the Parties have relied on these limitations and risk allocation
            in determining whether to enter these Terms.
          </p>
        </li>
        <li>
          THIRD PARTY WEBSITES
          <ol className="inner">
            <li>
              The Site may contain links to third party websites that are not
              owned or controlled by the Company (the “Links”). The User
              acknowledges and agrees that the Company has no control over, and
              assumes no responsibility for the content, privacy policies, or
              practices of, any third party websites.
            </li>
            <li>
              The User is solely responsible and liable for its use of and
              linking to third party websites and any content that the User may
              send or post to a third-party website.{" "}
            </li>
            <li>
              The User expressly releases the Company and its affiliates from
              any and all liability arising from the User’s use of any third
              party website. Accordingly, the Company encourages the User to
              read the terms and conditions and privacy policy of each third
              party website that it may choose to visit.
            </li>
          </ol>
        </li>
        <li>
          GOVERNING LAW AND JURISDICTION
          <ol className="inner">
            <li>
              The Terms and the relationship between the Parties shall be
              governed by the laws of Israel, without regard to the conflict of
              laws provisions thereof.{" "}
            </li>
            <li>
              The courts of Tel Aviv-Jaffa, Israel shall have exclusive
              jurisdiction in relation to any claim, dispute or difference
              concerning the Terms, the subject matter of the Terms, the
              relationship between the Parties with respect to the subject
              matter of the terms and any matter arising therefrom.
            </li>
            <li>
              The Parties mutually agree that the United Nations Convention on
              Contracts for the International Sale of Goods does not apply to
              the Terms.{" "}
            </li>
          </ol>
        </li>
        <li>
          ADDITIONAL TERMS
          <ol className="inner">
            <li>
              The Terms sets forth the entire understanding between the Parties
              with respect to the subject matter herein and any arrangements,
              representations, letters or understandings that existed prior to
              the Effective Date, insofar as not specifically expressed in the
              Terms shall be ineffective.
            </li>
            <li>
              If any Section of the Terms is determined to be void or
              unenforceable by a court of competent jurisdiction, such Section
              shall be interpreted as necessary to give maximum force to the
              provisions thereof, and the validity and enforceability of the
              remainder of the Terms shall not be affected.
            </li>
            <li>
              The User shall not transfer, sub-contract, sublicense or assign
              any of its rights or obligations under the Terms. Any transfer,
              sub-contracting, sub-licensing or assignment in violation of the
              foregoing shall be null and void. The Company may transfer,
              sub-contract, sublicense or assign its rights or obligations under
              the Terms to an affiliate, to a purchaser of all or substantially
              all of the Company’s assets or share capital, or to any successor.
            </li>
            <li>
              The Parties are independent contractors and nothing herein shall
              be construed to create any employment relationship, partnership,
              joint venture or agency relationship. The User does not have the
              power or authority to bind the Company in any manner whatsoever.
            </li>
            <li>
              The failure by the Company to exercise any right hereunder shall
              not operate as a waiver of the Company’s right to exercise such
              right or any other right in the future. In addition, no waiver,
              forbearance, indulgence or consent by the Company to deviate from
              the provisions of the Terms shall operate as a waiver of any
              subsequent right and no analogy shall be made from any such
              waiver, forbearance, indulgence or consent to any subsequent
              event.
            </li>
            <li>
              Other than the Company's affiliates, a person or entity who is not
              a Party to the Terms shall have no right to enforce any of its
              terms.
            </li>
            <li>
              The headings in the Terms and division of its terms into
              paragraphs are for convenience of reference only. Headings and the
              placement of individual terms within Sections are not to affect
              the interpretation of the Terms. Any phrase introduced by the
              terms "including", "include", "in particular" or any similar
              expression shall be construed as illustrative and shall not limit
              the sense of the words preceding those terms. In the Terms, if the
              context so requires, references to the singular shall include the
              plural and vice versa.{" "}
            </li>
          </ol>
        </li>
      </ol>
      Last updated: August 03, 2023.
    </div>
  );
};

export default TermsText;
