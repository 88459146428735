import React, { useState } from "react";
import ProductCard from "./ProductCard";
import { Button } from "@mui/material";
import { convertCssString } from "../helpers";
const feedItem = {
  id: "123",
  brand: "sample brand",
  title: "Sample Title",
  description: "Sample Description",
  url: "https://example.com",
  imageLink: "/img/default-product-img.webp",
  price: "$19.99",
  salePrice: "$14.99",
};

const ProductsInputWizard = ({ handleCloseModal, updateConfig }) => {
  const [textStyles, setTextStyles] = useState({
    title: {
      color: "#000000",
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
      margin: "0",
      textAlign: "left",
      display: "block",
    },
    brand: {
      color: "#000000",
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
      margin: "0",
      textAlign: "left",
      display: "block",
    },
    description: {
      color: "#000000",
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
      margin: "0",
      textAlign: "left",
      display: "block",
    },
    price: {
      color: "#000000",
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
      margin: "0",
      textAlign: "left",
      display: "inline-block",
    },
    salePrice: {
      color: "#000000",
      fontFamily: "Arial, sans-serif",
      fontSize: "16px",
      margin: "0",
      textAlign: "left",
      display: "inline-block",
    },
    image: {
      width: "100%",
      height: "auto",
    },
  });

  const [cardStyle, setCardStyle] = useState({
    border: "1px solid",
    width: "200px",
    height: "auto",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    borderColor: "#1f0000",
    borderWidth: "1px",
  });

  const handleSaveProductStyle = () => {
    let cssString = "";

    const classMap = {
      title: ".Rtitle",
      brand: ".Rbrand",
      description: ".Rdescription",
      price: ".Rprice",
      salePrice: ".sale_price",
      image: ".Rimg",
    };

    for (const [key, value] of Object.entries(textStyles)) {
      const className = "#heyday_SB_holder .hd-s-main " + classMap[key];
      cssString += `${className} {`;
      for (const [property, val] of Object.entries(value)) {
        cssString += `${property}: ${val} !important;`;
      }
      cssString += `}`;
    }

    cssString += `#heyday_SB_holder .hd-s-main .Rlink {`;
    for (const styleKey in cardStyle) {
      if (Object.hasOwnProperty.call(cardStyle, styleKey)) {
        cssString += `${styleKey}: ${cardStyle[styleKey]} !important;`;
      }
    }
    cssString += `}`;
    cssString = convertCssString(cssString);
    const successMessage = "Products card style updated successfully";
    updateConfig(cssString, successMessage)
      .then(() => {
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      })
      .catch((error) => {
        console.log("error");
      });
  };

  return (
    <div>
      <ProductCard
        product={feedItem}
        textStyles={textStyles}
        setTextStyles={setTextStyles}
        cardStyle={cardStyle}
        setCardStyle={setCardStyle}
      />
      <div className="btn-next-step-container">
        <Button className="btn-save" onClick={handleSaveProductStyle}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ProductsInputWizard;
