import React, { useContext, useEffect, useState } from "react";
import ContentPageHeader from "../ContentPageHeader/ContentPageHeader";
import GlobalContext from "../../../Context/Context";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Avatar } from "@mui/material";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import HeyDayTable from "../../../Shared/Components/Table/HeyDayTable";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import InviteMembersModal from "./InviteMembers/InviteMembersModal";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DeleteAccountModal from "./DeleteAccountModal/DeleteAccountModal";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AddCardIcon from "@mui/icons-material/AddCard";
import "./ManageAccount.css";

const ManageAccount = () => {
  const params = useParams();
  const context = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stepModal, setStepModal] = useState(0);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [page, setPage] = useState(0);
  const [table, setTable] = useState([]);

  const transformEmails = (emails) => {
    return emails.map((email) => {
      return {
        username: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar alt={email?.charAt(0)}>
              {email?.charAt(0)?.toUpperCase()}
            </Avatar>
            <span style={{ marginLeft: "8px" }}>{email}</span>
          </div>
        ),
        role: "member",
        action: (
          <IconButton onClick={() => handleDeleteMember(email)}>
            <DeleteOutlineRoundedIcon sx={{ color: "#f64a4a" }} />
          </IconButton>
        ),
      };
    });
  };

  const handleDeleteMember = async (email) => {
    var requestJson = { action: 1006, invite_email: email };
    requestJson.credentials = context.loadCredentials();

    try {
      const response = await context.myFetch(requestJson);
      if (response.error) {
        return context.message({
          type: "error",
          body: response.error,
        });
      } else {
        context.message({
          type: "success",
          body: "Member: " + email + " successfully deleted!",
        });
        removeFromSession(email);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const removeFromSession = (emailToRemove) => {
    let sessionMultiUsers = sessionStorage.getItem("multiUsers");
    if (typeof sessionMultiUsers === "string") {
      sessionMultiUsers = sessionMultiUsers.split(",");
    }
    const filteredEmails = sessionMultiUsers.filter(
      (email) => email !== emailToRemove
    );
    sessionStorage.setItem("multiUsers", filteredEmails.join(","));
    setTable(transformEmails(filteredEmails));
  };

  useEffect(() => {
    let sessionMultiUsers = sessionStorage.getItem("multiUsers");
    if (typeof sessionMultiUsers === "string") {
      setTable(transformEmails(sessionMultiUsers.split(",")));
    }
  }, []);

  const handleClickInvite = () => {
    setStepModal(0);
    setIsModalOpen(true);
  };

  const handleClickDeleteAccount = () => {
    setDeleteAccountModal(true);
  };

  return (
    <div>
      <section
        className="content-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
          maxWidth: "1000px",
          margin: "20px auto",
        }}
      >
        <ContentPageHeader pageName="Manage Accounts" />
        <div className="btns-group-invite">
          <Button
            onClick={handleClickDeleteAccount}
            className="delete-account-btn"
            startIcon={<HighlightOffRoundedIcon />}
          >
            Delete Account
          </Button>
          <Button
            onClick={handleClickInvite}
            className="invite-btn"
            startIcon={<AddCircleOutlineRoundedIcon />}
          >
            Invite more members
          </Button>
        </div>
      </section>
      <InviteMembersModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        step={stepModal}
        setStep={setStepModal}
      />
      <DeleteAccountModal
        open={deleteAccountModal}
        setOpen={setDeleteAccountModal}
      />
      <AdminCard
        contentStyle={{
          position: "relative",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "1000px",
          boxShadow: "unset",
        }}
      >
        <Box sx={{ width: "100%" }}>
          {table.length > 0 ? (
            <HeyDayTable
              rows={table}
              keys={["username", "role", "action"]}
              page={page}
              setPage={setPage}
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              {/* <div> */}
              <img
                className="img-warning-invite-page"
                style={{ width: "65px" }}
                alt="warning"
                src="/img/warning.png"
              />
              {/* </div> */}
              Oops! It seems you haven't invited anyone to access your admin
              panel yet. <br />
              Don't worry, you can easily invite team members or collaborators
              by pressing <br /> the 'Invite Members' button located above.
            </div>
          )}
        </Box>
      </AdminCard>
      <div className="payment-btn-container">
        <Button
          onClick={() => navigate("/payment")}
          className="payment-btn"
          startIcon={<AddCardIcon />}
        >
          Update payment
        </Button>
      </div>
    </div>
  );
};

export default ManageAccount;
