import React from 'react';
import ContentPageHeader from '../ContentPageHeader/ContentPageHeader';

const TrendingSearches = () => {
  return (
    <div>
      <section className='content-header'>
        <ContentPageHeader pageName='Trending searches' subMenu='Statistics' />
      </section>
    </div>
  );
};

export default TrendingSearches;
