export const formFetch = async (
  requestJson,
  data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  }
) => {
  data.body = data.body || JSON.stringify(requestJson);
  let result;
  try {
    const f = await fetch("https://cdn.heyday.io/form/", data);
    result = await f.json();
  } catch (e) {
    result = { error: e };
  }
  return result;
};

export const makePostRequest = async (query, data, nextIndex, domain) => {
  return new Promise(async (resolve, reject) => {
    try {
      const searchTwoWordResponse = async (query) => {
        const postData = {
          q: query,
          domain: domain + ",",
        };

        const response = await fetch("https://heyday.io/search/a/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        });

        const result = await response.json();
        if (result.r && result.r.length > 0) {
          for (const item of result.r) {
            if (item.split(" ").length === 2) {
              return item;
            }
          }
        }
        return null;
      };

      const originalSearchRequest = async (query, data, nextIndex) => {
        const postData = {
          affId: 1,
          q: query,
          pageResSize: 24,
          h: domain,
          p: 1,
        };

        const response = await fetch("https://heyday.io/search/s/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        });

        const result = await response.json();
        if (result.r && result.r.length > 2) {
          console.log(result.r);
          let currentUrl = new URL(window.location.href);
          let searchParams = currentUrl.searchParams;
          searchParams.append("hdyQ", query);
          currentUrl.search = searchParams.toString();
          window.history.pushState({}, "", currentUrl.toString());
          resolve();
        } else if (data[0][nextIndex]) {
          const nextTitle = data[0][nextIndex].title;
          const words = nextTitle.split(" ");
          if (words.length >= 2) {
            const nextTwoWords = words.slice(0, 2).join(" ");
            await originalSearchRequest(nextTwoWords, data, nextIndex + 1);
          } else {
            if (words[0])
              await originalSearchRequest(words[0], data, nextIndex + 1);
          }
          resolve();
        } else {
          resolve();
        }
      };

      let foundTwoWords = await searchTwoWordResponse(query);
      if (!foundTwoWords && data[0]) {
        for (let i = nextIndex; i < data[0].length; i++) {
          const nextTitle = data[0][i].title;
          const words = nextTitle.split(" ");
          if (words.length >= 1) {
            const nextWord = words[0];
            foundTwoWords = await searchTwoWordResponse(nextWord);
            if (foundTwoWords) break;
          }
        }
      }

      if (foundTwoWords) {
        setTimeout(async () => {
          await originalSearchRequest(foundTwoWords, data, nextIndex);
        }, 500);

        // console.log(foundTwoWords);
        // let currentUrl = new URL(window.location.href);
        // let searchParams = currentUrl.searchParams;
        // searchParams.append("hdyQ", foundTwoWords);
        // currentUrl.search = searchParams.toString();
        // window.history.pushState({}, "", currentUrl.toString());
        // resolve();
      } else {
        const title = data[0][0].title;
        const words = title.split(" ");
        if (words.length >= 2) {
          const word = words.slice(0, 2).join(" ");
          await originalSearchRequest(word, data, nextIndex);
        } else {
          await originalSearchRequest(query, data, nextIndex);
        }
      }
    } catch (error) {
      console.error("Post request error:", error);
      reject(error);
    }
  });
};
