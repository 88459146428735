import React from "react";
import HeyDayFormLabel from "../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import { TextField, Box, Button } from "@mui/material";

const DisplayPlaceHolderElement = ({ placeholders, handleSubmitForm }) => {
  return (
    <>
      {placeholders.map((placeholder, index) => (
        <Box
          key={index}
          sx={{
            width: { xs: "100%", md: "45%" },
            marginTop: "20px",
          }}
        >
          <HeyDayFormLabel sx={{ display: "flex" }}>
            {placeholder.label}
          </HeyDayFormLabel>
          <TextField
            placeholder={placeholder.placeholder}
            value={placeholder.placeholderId}
            onChange={(e) => placeholder.setter(e.target.value)}
            sx={{
              maxWidth: "381px",
              width: "100%",
              marginTop: "15px",
              marginRight: "20px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
          />
        </Box>
      ))}
      <Button
        type="submit"
        sx={{
          backgroundColor: "#ffffff",
          color: "var(--Green, #5daaa1)",
          borderRadius: "25px",
          marginTop: "40px",
          border: "2px solid",
          textTransform: "unset",

          borderColor: "var(--Green, #5daaa1)",
          fontFamily: "Poppins",
          padding: "5px 15px",
          height: "40px",
          width: "109px",
          "&:hover": {
            backgroundColor: "#5daaa1",
            border: "2px solid",
            borderColor: "var(--Green, #5daaa1)",
            color: "var(--Green, #ffffff)",
          },
        }}
        onClick={handleSubmitForm}
      >
        Save
      </Button>
    </>
  );
};

export default DisplayPlaceHolderElement;
