import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& input:-internal-autofill-selected': {
    WebkitBoxShadow: '0 0 0 1000px #f5f6f8 inset',
  },
  '& label': {
    color: '#929292',
    fontFamily: 'Poppins',
    left: '15px',
    top: '-5px',
  },
  '& label.Mui-focused': {
    top: '-5px',
    left: '15px',
    color: '#929292',
  },
  '& .MuiOutlinedInput-input': {
    paddingTop: 0,
    background: 'var(--Light-Grey, #F4F6F8)',
    paddingBottom: 0,
    fontFamily: 'Poppins',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    background: 'var(--Light-Grey, #F4F6F8)',
    '& fieldset': {
      borderColor: 'white',
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      fontFamily: 'Poppins',
      border: 'none',
    },
    '&:hover fieldset': {
      borderColor: 'white',
      border: 'none',
    },
  },
}));

function HeyDayMultiline({
  onChange,
  style,
  inputPropsStyle,
  labelValue,
  type,
  ...props
}) {
  return (
    <CustomTextField
      InputProps={{
        style: {
          ...inputPropsStyle,
        },
      }}
      style={style}
      FormHelperTextProps={{ style: { marginLeft: '30px' } }}
      label={labelValue}
      multiline
      onChange={onChange}
      {...props}
    />
  );
}

export default HeyDayMultiline;
