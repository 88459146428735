import React, { useContext, useState } from "react";
import { Select, MenuItem, Tooltip, IconButton } from "@mui/material";
import { checkIntegration } from "../Admin/Common/helpers";
import GlobalContext from "../../Context/Context";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { WarningRounded } from "@mui/icons-material";

const DomainDropdown = ({
  domainList,
  classname,
  domainIndex,
  setDomainIndex,
}) => {
  const context = useContext(GlobalContext);
  const navigate = useNavigate();
  const currentDomainIndex = sessionStorage.getItem("currentDomainIndex") || 0;
  const [selectedDomain, setSelectedDomain] = useState(
    domainList[currentDomainIndex]?.domain
  );

  const handleDomainSelection = (event) => {
    const val = event.target.value;
    const key = domainList.findIndex((d) => d.domain === val);
    if (key >= 0) {
      sessionStorage.setItem("currentDomain", val);
      sessionStorage.setItem("currentDomainIndex", key);
      setSelectedDomain(domainList[key].domain);
      setDomainIndex(key);
      context.setGlobalState({ domainIndex: key });
      navigate(`/dashboard/${key}`);
      // window.location.reload();
      sessionStorage.removeItem("AC_seen");
      sessionStorage.removeItem("new_domain");
      sessionStorage.removeItem("previewCounter");
      sessionStorage.removeItem("indexSize");
      sessionStorage.removeItem("prevSize");
    } else {
      navigate("/addDomain");
    }
  };

  return (
    <Select
      onChange={handleDomainSelection}
      value={selectedDomain}
      variant="standard"
      disableUnderline
      style={{
        maxWidth: "300px",
        minWidth: "150px",
        width: "100%",
        fontFamily: "Poppins",
      }}
      className={classname}
    >
      {buildOptions(domainList, context, domainIndex)}
    </Select>
  );
};

const buildOptions = (domainList, Context, domainIndex) => {
  let options = domainList.map((item) => (
    <MenuItem
      key={item.domain}
      value={item.domain}
      sx={{ fontFamily: "Poppins", fontSize: "14px" }}
    >
      {item.domain}
      {/* {!checkIntegration(domainIndex, Context, item) && (
        <Tooltip
          placement="right"
          title={`Integration has not been completed yet for this domain`}
        >
          <IconButton style={{ color: "orange" }}>
            <WarningRounded />
          </IconButton>
        </Tooltip>
      )} */}
    </MenuItem>
  ));

  options.push(
    <MenuItem key="newDomain" value="-1" sx={{ fontFamily: "Poppins" }}>
      <AddIcon fontSize="small" /> Add Domain
    </MenuItem>
  );

  return options;
};

export default DomainDropdown;
