import React, { useState, useContext } from "react";
import LaunchTwoToneIcon from "@mui/icons-material/LaunchTwoTone";
import GlobalContext from "../../../../../../../../../Context/Context";
import "./Wizard.css";
import ProductsInputWizard from "./ecommerce/ProductsInputWizard";
import SearchBoxInputWizard from "./SearchBoxInputWizard";
import PostWizard from "./news/PostWizard";
import {
  formatCssString,
  removeFormatting,
} from "../../../../SearchMore/Tabs/SearchMoreUi/utils/helpers";
import { convertCssString } from "./helpers";
const Wizard = ({
  domain,
  configs,
  setStylesheetValue,
  getStyleValue,
  handleCloseModal,
  selectedType,
  selectedTemplate,
}) => {
  const [wizardStep, setWizardStep] = useState(0);
  const context = useContext(GlobalContext);
  const [placeholder, setPlaceholder] = useState("search...");
  const [styles, setStyles] = useState({
    width: 200,
    height: 30,
    color: "#000000",
    fontSize: 16,
    fontFamily: "Arial, sans-serif",
    borderWidth: 1,
    borderColor: "#000000",
    borderRadius: 0,
    direction: "ltr",
  });

  const openNewWindow = () => {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    const urlWithParams = new URL("https://" + domain);
    urlWithParams.searchParams.set("width", styles.width);
    urlWithParams.searchParams.set("height", styles.height);
    urlWithParams.searchParams.set("color", styles.color);
    urlWithParams.searchParams.set("font", styles.fontFamily);
    if (window.existingWindow) {
      window.existingWindow.location.href = urlWithParams.toString();
      window.existingWindow.focus();
    } else {
      const newWindow = window.open(
        urlWithParams.toString(),
        "_blank",
        `width=${screenWidth},height=${screenHeight}`
      );
      window.existingWindow = newWindow;
    }
  };

  const handleSaveSearchBoxStyle = () => {
    let cssString = "";
    const searchBarStyles = {};
    const hdyQStyles = {};

    Object.keys(styles).forEach((key) => {
      if (
        key === "width" ||
        key === "height" ||
        key === "borderWidth" ||
        key === "borderColor" ||
        key === "borderRadius"
      ) {
        searchBarStyles[key] = styles[key];
      } else {
        hdyQStyles[key] = styles[key];
      }
    });
    cssString += `#heyday_SB_holder .hd-s-main .search_bar-fixed {`;
    Object.entries(searchBarStyles).forEach(([key, value]) => {
      cssString += `  ${key}: ${
        typeof value === "number" ? value + "px" : value
      } !important;`;
    });
    cssString += `}`;

    cssString += `#heyday_SB_holder .hd-s-main .HeyDay_q {`;
    Object.entries(hdyQStyles).forEach(([key, value]) => {
      cssString += `  ${key}: ${
        typeof value === "number"
          ? value + "px"
          : typeof value === "string"
          ? value.replace(/"/g, "")
          : value
      } !important;`;
    });
    cssString += `}`;
    cssString += `#heyday_SB_AC_holder .hd-s-main .search_bar-fixed {`;
    Object.entries(searchBarStyles).forEach(([key, value]) => {
      cssString += `  ${key}: ${
        typeof value === "number" ? value + "px" : value
      } !important;`;
    });
    cssString += `}`;

    cssString += `#heyday_SB_AC_holder .hd-s-main .HeyDay_q {`;
    Object.entries(hdyQStyles).forEach(([key, value]) => {
      cssString += `  ${key}: ${
        typeof value === "number"
          ? value + "px"
          : typeof value === "string"
          ? value.replace(/"/g, "")
          : value
      } !important;`;
    });
    cssString += `}`;
    const successMessage = "Search box customization successfully updated";
    cssString = convertCssString(cssString);
    updateConfig(cssString, successMessage);
  };

  const updateConfig = async (cssString, successMessage) => {
    const searchBoxIndex = configs.findIndex((config) =>
      config.hasOwnProperty("searchBox")
    );

    const newStylesheet = getStyleValue("stylesheet") + cssString;

    if (searchBoxIndex !== -1) {
      const updatedConfigs = [...configs];
      updatedConfigs[searchBoxIndex].searchBox.stylesheet =
        removeFormatting(newStylesheet);
      if (
        updatedConfigs &&
        searchBoxIndex !== undefined &&
        searchBoxIndex !== null
      ) {
        if (!updatedConfigs[searchBoxIndex]?.searchBox) {
          updatedConfigs[searchBoxIndex].searchBox = {};
        }
        if (!updatedConfigs[searchBoxIndex]?.searchBox?.tmplPlaceHolders) {
          updatedConfigs[searchBoxIndex].searchBox.tmplPlaceHolders = {};
        }
        updatedConfigs[searchBoxIndex].searchBox.tmplPlaceHolders[
          "searchBoxText"
        ] = placeholder;
        updatedConfigs[searchBoxIndex].searchBox.type = selectedTemplate;
      } else {
        console.error("error");
      }
      const requestJson = {
        action: 1014,
        host: domain,
        configs: updatedConfigs,
        credentials: context.loadCredentials(),
      };

      try {
        const responseJson = await fetchRequest(requestJson);
        if (responseJson.domains) {
          setStylesheetValue(
            "stylesheet",
            formatCssString(removeFormatting(newStylesheet))
          );
          sessionStorage.setItem(
            "allDomains",
            JSON.stringify(responseJson.domains)
          );
          context.message({
            type: "success",
            body: successMessage,
          });
          setTimeout(() => {
            setWizardStep(1);
          }, 1000);
        } else if (responseJson.error) {
          context.message({
            type: "error",
            body: responseJson.error,
          });
        }
      } catch (error) {
        context.message({
          type: "error",
          body: error,
        });
      }
    } else {
      console.error("searchBox not found in configs");
    }
  };

  const fetchRequest = async (requestJson) => {
    const responseJson = await context.myFetch(requestJson);
    return responseJson;
  };

  return (
    <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
      <div className="wizard-header-underline"></div>
      <div className="wizard-body">
        <div className="preview-mode-btn-container">
          <LaunchTwoToneIcon fontSize="small" sx={{ marginRight: "5px" }} />
          <button className="preview-mode-btn" onClick={openNewWindow}>
            Open Website in New Window
          </button>
        </div>
      </div>
      {wizardStep === 0 && (
        <SearchBoxInputWizard
          styles={styles}
          setStyles={setStyles}
          placeholder={placeholder}
          setPlaceholder={setPlaceholder}
          handleSaveSearchBoxStyle={handleSaveSearchBoxStyle}
        />
      )}
      {wizardStep === 1 && selectedType === "ecommerce" && (
        <ProductsInputWizard
          handleCloseModal={handleCloseModal}
          updateConfig={updateConfig}
        />
      )}
      {wizardStep === 1 && selectedType === "news" && (
        <PostWizard
          handleCloseModal={handleCloseModal}
          updateConfig={updateConfig}
        />
      )}
    </div>
  );
};

export default Wizard;
