import React from 'react';
import Chart from 'react-apexcharts';

const formatKey = (key) => {
  return key
    .replace(/[_-]/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const BarChart = ({ data }) => {
  const keys = Object.keys(data[0]).map(formatKey);
  const values = Object.values(data[0]);

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      borderColor: '#f1f1f1',
      strokeDashArray: 5,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    title: {
      text: 'Statistics',
      align: 'left',
      style: {
        fontSize: '14px',
        fontFamily: 'Poppins',
        color: '#616060',
        fontWeight: '500',
      },
    },
    xaxis: {
      categories: keys,
    },

    tooltip: {
      enabled: true,
      shared: false,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="tooltip">' +
          keys[dataPointIndex] +
          ': ' +
          series[seriesIndex][dataPointIndex] +
          '</div>'
        );
      },
    },
  };
  const series = [
    {
      color: '#5DAAA1',
      data: values,
    },
  ];

  return (
    <div>
      <Chart options={options} series={series} type='bar' height={350} />
    </div>
  );
};

export default BarChart;
