export const week = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const week_short = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

export const utcOptions = [
  "-12",
  "-11",
  "-10",
  "-9",
  "-8",
  "-7",
  "-6",
  "-5",
  "-4",
  "-3",
  "-2",
  "-1",
  "0",
  "+1",
  "+2",
  "+3",
  "+4",
  "+5",
  "+6",
  "+7",
  "+8",
  "+9",
  "+10",
  "+11",
  "+12",
];

export const lang = {
  0: "aa",
  1: "ab",
  2: "ae",
  3: "af",
  4: "ak",
  5: "am",
  6: "an",
  7: "ar",
  8: "as",
  9: "av",
  10: "ay",
  11: "az",
  12: "ba",
  13: "be",
  14: "bg",
  15: "bh",
  16: "bi",
  17: "bm",
  18: "bn",
  19: "bo",
  20: "br",
  21: "bs",
  22: "ca",
  23: "ce",
  24: "ch",
  25: "co",
  26: "cr",
  27: "cs",
  28: "cu",
  29: "cv",
  30: "cy",
  31: "da",
  32: "de",
  33: "dv",
  34: "dz",
  35: "ee",
  36: "el",
  37: "en",
  38: "eo",
  39: "es",
  40: "et",
  41: "eu",
  42: "fa",
  43: "ff",
  44: "fi",
  45: "fj",
  46: "fo",
  47: "fr",
  48: "fy",
  49: "ga",
  50: "gd",
  51: "gl",
  52: "gn",
  53: "gu",
  54: "gv",
  55: "ha",
  56: "he",
  57: "hi",
  58: "ho",
  59: "hr",
  60: "ht",
  61: "hu",
  62: "hy",
  63: "hz",
  64: "ia",
  65: "id",
  66: "ie",
  67: "ig",
  68: "ii",
  69: "ik",
  70: "in",
  71: "io",
  72: "is",
  73: "it",
  74: "iu",
  75: "iw",
  76: "ja",
  77: "ji",
  78: "jv",
  79: "jw",
  80: "ka",
  81: "kg",
  82: "ki",
  83: "kj",
  84: "kk",
  85: "kl",
  86: "km",
  87: "kn",
  88: "ko",
  89: "kr",
  90: "ks",
  91: "ku",
  92: "kv",
  93: "kw",
  94: "ky",
  95: "la",
  96: "lb",
  97: "lg",
  98: "li",
  99: "ln",
  100: "lo",
  101: "lt",
  102: "lu",
  103: "lv",
  104: "mg",
  105: "mh",
  106: "mi",
  107: "mk",
  108: "ml",
  109: "mn",
  110: "mo",
  111: "mr",
  112: "ms",
  113: "mt",
  114: "my",
  115: "na",
  116: "nb",
  117: "nd",
  118: "ne",
  119: "ng",
  120: "nl",
  121: "nn",
  122: "no",
  123: "nr",
  124: "nv",
  125: "ny",
  126: "oc",
  127: "oj",
  128: "om",
  129: "or",
  130: "os",
  131: "pa",
  132: "pi",
  133: "pl",
  134: "ps",
  135: "pt",
  136: "qu",
  137: "rm",
  138: "rn",
  139: "ro",
  140: "ru",
  141: "rw",
  142: "sa",
  143: "sc",
  144: "sd",
  145: "se",
  146: "sg",
  147: "sh",
  148: "si",
  149: "sk",
  150: "sl",
  151: "sm",
  152: "sn",
  153: "so",
  154: "sq",
  155: "sr",
  156: "ss",
  157: "st",
  158: "su",
  159: "sv",
  160: "sw",
  161: "ta",
  162: "te",
  163: "tg",
  164: "th",
  165: "ti",
  166: "tk",
  167: "tl",
  168: "tn",
  169: "to",
  170: "tr",
  171: "ts",
  172: "tt",
  173: "tw",
  174: "ty",
  175: "ug",
  176: "uk",
  177: "ur",
  178: "uz",
  179: "ve",
  180: "vi",
  181: "vo",
  182: "wa",
  183: "wo",
  184: "xh",
  185: "yi",
  186: "yo",
  187: "za",
  188: "zh",
  189: "zu",
  253: "all",
};

export const geoArray = [
  ["A0", "All the world"],
  ["AD", "Andorra"],
  ["AE", "United Arab Emirates"],
  ["AF", "Afghanistan"],
  ["AG", "Antigua and Barbuda"],
  ["AI", "Anguilla"],
  ["AL", "Albania"],
  ["AM", "Armenia"],
  ["AO", "Angola"],
  ["AP", "Asia/Pacific Region"],
  ["AQ", "Antarctica"],
  ["AR", "Argentina"],
  ["AS", "American Samoa"],
  ["AT", "Austria"],
  ["AU", "Australia"],
  ["AW", "Aruba"],
  ["AX", "Aland Islands"],
  ["AZ", "Azerbaijan"],
  ["BA", "Bosnia and Herzegovina"],
  ["BB", "Barbados"],
  ["BD", "Bangladesh"],
  ["BE", "Belgium"],
  ["BF", "Burkina Faso"],
  ["BG", "Bulgaria"],
  ["BH", "Bahrain"],
  ["BI", "Burundi"],
  ["BJ", "Benin"],
  ["BL", "Saint Barthelemy"],
  ["BM", "Bermuda"],
  ["BN", "Brunei Darussalam"],
  ["BO", "Bolivia"],
  ["BQ", "Bonaire, Saint Eustatius and Saba"],
  ["BR", "Brazil"],
  ["BS", "Bahamas"],
  ["BT", "Bhutan"],
  ["BV", "Bouvet Island"],
  ["BW", "Botswana"],
  ["BY", "Belarus"],
  ["BZ", "Belize"],
  ["CA", "Canada"],
  ["CC", "Cocos (Keeling) Islands"],
  ["CD", "Congo, The Democratic Republic of the"],
  ["CF", "Central African Republic"],
  ["CG", "Congo"],
  ["CH", "Switzerland"],
  ["CI", "Cote d'Ivoire"],
  ["CK", "Cook Islands"],
  ["CL", "Chile"],
  ["CM", "Cameroon"],
  ["CN", "China"],
  ["CO", "Colombia"],
  ["CR", "Costa Rica"],
  ["CU", "Cuba"],
  ["CV", "Cape Verde"],
  ["CW", "Curacao"],
  ["CX", "Christmas Island"],
  ["CY", "Cyprus"],
  ["CZ", "Czech Republic"],
  ["DE", "Germany"],
  ["DJ", "Djibouti"],
  ["DK", "Denmark"],
  ["DM", "Dominica"],
  ["DO", "Dominican Republic"],
  ["DZ", "Algeria"],
  ["EC", "Ecuador"],
  ["EE", "Estonia"],
  ["EG", "Egypt"],
  ["EH", "Western Sahara"],
  ["ER", "Eritrea"],
  ["ES", "Spain"],
  ["ET", "Ethiopia"],
  ["EU", "Europe"],
  ["FI", "Finland"],
  ["FJ", "Fiji"],
  ["FK", "Falkland Islands (Malvinas)"],
  ["FM", "Micronesia, Federated States of"],
  ["FO", "Faroe Islands"],
  ["FR", "France"],
  ["GA", "Gabon"],
  ["GB", "United Kingdom"],
  ["GD", "Grenada"],
  ["GE", "Georgia"],
  ["GF", "French Guiana"],
  ["GG", "Guernsey"],
  ["GH", "Ghana"],
  ["GI", "Gibraltar"],
  ["GL", "Greenland"],
  ["GM", "Gambia"],
  ["GN", "Guinea"],
  ["GP", "Guadeloupe"],
  ["GQ", "Equatorial Guinea"],
  ["GR", "Greece"],
  ["GS", "South Georgia and the South Sandwich Islands"],
  ["GT", "Guatemala"],
  ["GU", "Guam"],
  ["GW", "Guinea-Bissau"],
  ["GY", "Guyana"],
  ["HK", "Hong Kong"],
  ["HM", "Heard Island and McDonald Islands"],
  ["HN", "Honduras"],
  ["HR", "Croatia"],
  ["HT", "Haiti"],
  ["HU", "Hungary"],
  ["ID", "Indonesia"],
  ["IE", "Ireland"],
  ["IL", "Israel"],
  ["IM", "Isle of Man"],
  ["IN", "India"],
  ["IO", "British Indian Ocean Territory"],
  ["IQ", "Iraq"],
  ["IR", "Iran, Islamic Republic of"],
  ["IS", "Iceland"],
  ["IT", "Italy"],
  ["JE", "Jersey"],
  ["JM", "Jamaica"],
  ["JO", "Jordan"],
  ["JP", "Japan"],
  ["KE", "Kenya"],
  ["KG", "Kyrgyzstan"],
  ["KH", "Cambodia"],
  ["KI", "Kiribati"],
  ["KM", "Comoros"],
  ["KN", "Saint Kitts and Nevis"],
  ["KP", "Korea, Democratic People's Republic of"],
  ["KR", "Korea, Republic of"],
  ["KW", "Kuwait"],
  ["KY", "Cayman Islands"],
  ["KZ", "Kazakhstan"],
  ["LA", "Lao People's Democratic Republic"],
  ["LB", "Lebanon"],
  ["LC", "Saint Lucia"],
  ["LI", "Liechtenstein"],
  ["LK", "Sri Lanka"],
  ["LR", "Liberia"],
  ["LS", "Lesotho"],
  ["LT", "Lithuania"],
  ["LU", "Luxembourg"],
  ["LV", "Latvia"],
  ["LY", "Libyan Arab Jamahiriya"],
  ["MA", "Morocco"],
  ["MC", "Monaco"],
  ["MD", "Moldova, Republic of"],
  ["ME", "Montenegro"],
  ["MF", "Saint Martin"],
  ["MG", "Madagascar"],
  ["MH", "Marshall Islands"],
  ["MK", "Macedonia"],
  ["ML", "Mali"],
  ["MM", "Myanmar"],
  ["MN", "Mongolia"],
  ["MO", "Macao"],
  ["MP", "Northern Mariana Islands"],
  ["MQ", "Martinique"],
  ["MR", "Mauritania"],
  ["MS", "Montserrat"],
  ["MT", "Malta"],
  ["MU", "Mauritius"],
  ["MV", "Maldives"],
  ["MW", "Malawi"],
  ["MX", "Mexico"],
  ["MY", "Malaysia"],
  ["MZ", "Mozambique"],
  ["NA", "Namibia"],
  ["NC", "New Caledonia"],
  ["NE", "Niger"],
  ["NF", "Norfolk Island"],
  ["NG", "Nigeria"],
  ["NI", "Nicaragua"],
  ["NL", "Netherlands"],
  ["NO", "Norway"],
  ["NP", "Nepal"],
  ["NR", "Nauru"],
  ["NU", "Niue"],
  ["NZ", "New Zealand"],
  ["OM", "Oman"],
  ["PA", "Panama"],
  ["PE", "Peru"],
  ["PF", "French Polynesia"],
  ["PG", "Papua New Guinea"],
  ["PH", "Philippines"],
  ["PK", "Pakistan"],
  ["PL", "Poland"],
  ["PM", "Saint Pierre and Miquelon"],
  ["PN", "Pitcairn"],
  ["PR", "Puerto Rico"],
  ["PS", "Palestinian Territory"],
  ["PT", "Portugal"],
  ["PW", "Palau"],
  ["PY", "Paraguay"],
  ["QA", "Qatar"],
  ["RE", "Reunion"],
  ["RO", "Romania"],
  ["RS", "Serbia"],
  ["RU", "Russian Federation"],
  ["RW", "Rwanda"],
  ["SA", "Saudi Arabia"],
  ["SB", "Solomon Islands"],
  ["SC", "Seychelles"],
  ["SD", "Sudan"],
  ["SE", "Sweden"],
  ["SG", "Singapore"],
  ["SH", "Saint Helena"],
  ["SI", "Slovenia"],
  ["SJ", "Svalbard and Jan Mayen"],
  ["SK", "Slovakia"],
  ["SL", "Sierra Leone"],
  ["SM", "San Marino"],
  ["SN", "Senegal"],
  ["SO", "Somalia"],
  ["SR", "Suriname"],
  ["SS", "South Sudan"],
  ["ST", "Sao Tome and Principe"],
  ["SV", "El Salvador"],
  ["SX", "Sint Maarten"],
  ["SY", "Syrian Arab Republic"],
  ["SZ", "Swaziland"],
  ["TC", "Turks and Caicos Islands"],
  ["TD", "Chad"],
  ["TF", "French Southern Territories"],
  ["TG", "Togo"],
  ["TH", "Thailand"],
  ["TJ", "Tajikistan"],
  ["TK", "Tokelau"],
  ["TL", "Timor-Leste"],
  ["TM", "Turkmenistan"],
  ["TN", "Tunisia"],
  ["TO", "Tonga"],
  ["TR", "Turkey"],
  ["TT", "Trinidad and Tobago"],
  ["TV", "Tuvalu"],
  ["TW", "Taiwan"],
  ["TZ", "Tanzania, United Republic of"],
  ["UA", "Ukraine"],
  ["UG", "Uganda"],
  ["UM", "United States Minor Outlying Islands"],
  ["US", "United States"],
  ["UY", "Uruguay"],
  ["UZ", "Uzbekistan"],
  ["VA", "Holy See (Vatican City State)"],
  ["VC", "Saint Vincent and the Grenadines"],
  ["VE", "Venezuela"],
  ["VG", "Virgin Islands, British"],
  ["VI", "Virgin Islands, U.S."],
  ["VN", "Vietnam"],
  ["VU", "Vanuatu"],
  ["WF", "Wallis and Futuna"],
  ["WS", "Samoa"],
  ["YE", "Yemen"],
  ["YT", "Mayotte"],
  ["ZA", "South Africa"],
  ["ZM", "Zambia"],
  ["ZW", "Zimbabwe"],
];

export const getDomains = () => {
  const storedDomains = sessionStorage.getItem("allDomains");
  return storedDomains ? JSON.parse(storedDomains) : [];
};

export const getConf = () => {
  const data =
    sessionStorage.getItem("config") || localStorage.getItem("config");
  return !!data ? JSON.parse(data)[0] || JSON.parse(data) : {};
};
// export const device = {
//     'A': 'Android',
//     'L': 'Linux',
//     'I': 'iPhone',
//     'W': 'Windows',
//     'N': 'Windows Phone',
//     'M': 'Mac',
//     'X': 'Linux UI',
//     'T': 'Smart TV',
//     'P': 'iPad',
//     'S': 'PlayStation',
//     'B': 'Blackberry',
// };
export const b_hostName = (host = false) =>
  host ||
  sessionStorage.getItem("host") ||
  localStorage.getItem("host") ||
  "admin.heyday.io";
// "admin.heyday.io";
// let backend_hostName = 'webpushnotes.com';

export const dateFormat = "Do MMM YYYY HH:mm";

function hex(c) {
  const s = "0123456789abcdef";
  let i = parseInt(c);
  if (i === 0 || isNaN(c)) return "00";
  i = Math.round(Math.min(Math.max(0, i), 255));
  return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
}

/* Convert an RGB triplet to a hex string */
export function convertToHex(rgb) {
  return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
}

/* Remove '#' in color hex string */
function trim(s) {
  return s.charAt(0) === "#" ? s.substring(1, 7) : s;
}

/* Convert a hex string to an hex string */
export function convertToRGB(hex) {
  const color = [];
  color[0] = parseInt(trim(hex).substring(0, 2), 16);
  color[1] = parseInt(trim(hex).substring(2, 4), 16);
  color[2] = parseInt(trim(hex).substring(4, 6), 16);
  return color;
}

/* Convert a hsla string to an RGB triplet */
export function HSLAToHexA(hsla) {
  let ex =
    /^hsla\(((((([12]?[1-9]?\d)|[12]0\d|(3[0-5]\d))(\.\d+)?)|(\.\d+))(deg)?|(0|0?\.\d+)turn|(([0-6](\.\d+)?)|(\.\d+))rad)(((,\s?(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2},\s?)|((\s(([1-9]?\d(\.\d+)?)|100|(\.\d+))%){2}\s\/\s))((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i;
  if (ex.test(hsla)) {
    let sep = hsla.indexOf(",") > -1 ? "," : " ";
    hsla = hsla.substr(5).split(")")[0].split(sep);

    // strip the slash
    if (hsla.indexOf("/") > -1) hsla.splice(3, 1);

    let h = hsla[0],
      s = hsla[1].substr(0, hsla[1].length - 1) / 100,
      l = hsla[2].substr(0, hsla[2].length - 1) / 100,
      a = hsla[3];

    // strip label and convert to degrees (if necessary)
    if (h.indexOf("deg") > -1) h = h.substr(0, h.length - 3);
    else if (h.indexOf("rad") > -1)
      h = Math.round(h.substr(0, h.length - 3) * (180 / Math.PI));
    else if (h.indexOf("turn") > -1)
      h = Math.round(h.substr(0, h.length - 4) * 360);
    if (h >= 360) h %= 360;

    // strip % from alpha, make fraction of 1 (if necessary)
    if (a.indexOf("%") > -1) a = a.substr(0, a.length - 1) / 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      g = 0;
      b = x;
    }
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);
    a = Math.round(a * 255).toString(16);

    if (r.length === 1) r = "0" + r;
    if (g.length === 1) g = "0" + g;
    if (b.length === 1) b = "0" + b;
    if (a.length === 1) a = "0" + a;

    return "#" + r + g + b + a;
  } else {
    return "Invalid input color";
  }
}

export function generateColor(colorStart, colorEnd, colorCount) {
  // The beginning of your gradient
  const start = convertToRGB(colorStart);

  // The end of your gradient
  const end = convertToRGB(colorEnd);

  // The number of colors to compute
  const len = colorCount;

  //Alpha blending amount
  let alpha = 0.0;

  const saida = [];

  for (let i = 0; i < len; i++) {
    var c = [];
    alpha += 1.0 / len;

    c[0] = start[0] * alpha + (1 - alpha) * end[0];
    c[1] = start[1] * alpha + (1 - alpha) * end[1];
    c[2] = start[2] * alpha + (1 - alpha) * end[2];

    saida.push(convertToHex(c));
  }

  return saida;
}

//https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
export const pSBC = (p, c0, c1, l) => {
  let r,
    g,
    b,
    P,
    f,
    t,
    h,
    m = Math.round,
    a = typeof c1 == "string";
  if (
    typeof p !== "number" ||
    p < -1 ||
    p > 1 ||
    typeof c0 !== "string" ||
    (c0[0] !== "r" && c0[0] !== "#") ||
    (c1 && !a)
  ) {
    return null;
  }
  h = c0.length > 9;
  h = a ? (c1.length > 9 ? true : c1 === "c" ? !h : false) : h;
  f = pSBC.pSBCr(c0);
  P = p < 0;
  t =
    c1 && c1 !== "c"
      ? pSBC.pSBCr(c1)
      : P
      ? {
          r: 0,
          g: 0,
          b: 0,
          a: -1,
        }
      : {
          r: 255,
          g: 255,
          b: 255,
          a: -1,
        };
  p = P ? p * -1 : p;
  P = 1 - p;
  if (!f || !t) {
    return null;
  }
  if (l) {
    r = m(P * f.r + p * t.r);
    g = m(P * f.g + p * t.g);
    b = m(P * f.b + p * t.b);
  } else {
    r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5);
    g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5);
    b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
  }
  a = f.a;
  t = t.a;
  f = a >= 0 || t >= 0;
  a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0;
  if (h) {
    return (
      "rgb" +
      (f ? "a(" : "(") +
      r +
      "," +
      g +
      "," +
      b +
      (f ? "," + m(a * 1000) / 1000 : "") +
      ")"
    );
  } else {
    return (
      "#" +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
  }
};

pSBC.pSBCr = (d) => {
  const i = parseInt,
    m = Math.round;
  let n = d.length,
    x = {};
  if (n > 9) {
    const [r, g, b, a] = (d = d.split(","));
    n = d.length;
    if (n < 3 || n > 4) return null;
    x.r = i(r[3] === "a" ? r.slice(5) : r.slice(4));
    x.g = i(g);
    x.b = i(b);
    x.a = a ? parseFloat(a) : -1;
  } else {
    if (n === 8 || n === 6 || n < 4) return null;
    if (n < 6)
      d =
        "#" +
        d[1] +
        d[1] +
        d[2] +
        d[2] +
        d[3] +
        d[3] +
        (n > 4 ? d[4] + d[4] : "");
    d = i(d.slice(1), 16);
    if (n === 9 || n === 5) {
      x.r = (d >> 24) & 255;
      x.g = (d >> 16) & 255;
      x.b = (d >> 8) & 255;
      x.a = m((d & 255) / 0.255) / 1000;
    } else {
      x.r = d >> 16;
      x.g = (d >> 8) & 255;
      x.b = d & 255;
      x.a = -1;
    }
  }
  return x;
};

export function isMobile() {
  return window.innerWidth <= 768 &&
    (navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/Galaxy/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i))
    ? true
    : false;
}

export function isTablet() {
  return window.innerWidth >= 768 &&
    window.innerWidth <= 1024 &&
    (navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/Galaxy/i) ||
      navigator.userAgent.match(/BlackBerry/i))
    ? true
    : false;
}
