import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";

const DisplayProducts = ({ products, setAdvanced, advanced }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextProduct = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  const prevProduct = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? products.length - 1 : prevIndex - 1
    );
  };

  const product = products[activeIndex];

  return (
    <div style={{ marginBottom: "80px" }}>
      <div
        style={{
          maxWidth: "340px",
          width: "340px",
          margin: "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton onClick={prevProduct}>
          <KeyboardArrowLeft />
        </IconButton>
        <div className="connect-data-product-card">
          <div className="product">
            <img
              src={
                product["img"]
                  ? product["img"]
                  : "/img/default-product-img.webp"
              }
              alt={product["title"]}
              className={"product-image"}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/img/default-product-img.webp";
              }}
            />
            <div className="product-info">
              {product["brand"] && (
                <div className="product-brand">
                  Brand: {JSON.stringify(product["brand"], null, 2)}
                </div>
              )}
              <div className="product-title">
                Title: {JSON.stringify(product["title"], null, 2)}
              </div>
              <div className="product-description">
                Description: {JSON.stringify(product["description"], null, 2)}
              </div>
              {product["sale_price"] != null ? (
                <div className="product-prices-container">
                  <div
                    className="product-price"
                    style={{ textDecoration: "line-through" }}
                  >
                    {product["price"]}
                  </div>
                  <div className="product-sale-price">
                    {product["sale_price"]}
                  </div>
                </div>
              ) : (
                product["price"] != null && (
                  <div className="product-price">{product["price"]}</div>
                )
              )}

              {product["sale_price_AndCurrency"] &&
              product["priceAndCurrency"] ? (
                <div className="product-prices-container">
                  <div
                    className="product-price"
                    style={{ textDecoration: "line-through" }}
                  >
                    {product["priceAndCurrency"]}
                  </div>
                  <div className="product-sale-price">
                    {product["sale_price_AndCurrency"]}
                  </div>
                </div>
              ) : (
                product["priceAndCurrency"] != null && (
                  <div className="product-price">
                    {product["priceAndCurrency"]}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <IconButton onClick={nextProduct}>
          <KeyboardArrowRight />
        </IconButton>
      </div>
      <div className="btn-advanced-container">
        <Button
          className="btn-advanced"
          onClick={() => setAdvanced(!advanced)}
          startIcon={<SettingsSuggestRoundedIcon />}
        >
          Advanced options
        </Button>
      </div>
    </div>
  );
};

export default DisplayProducts;
