import React, { useState, useContext } from "react";
import DisplayPlaceHolderElement from "./DisplayPlaceHolderElement";
import HeyDayFormLabel from "../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import { TextField, MenuItem, Box } from "@mui/material";
import tmplPlaceHoldersEnglish from "./tmplPlaceHolder";
import tmplPlaceHoldersHebrew from "./tmplPlaceHolderHebrew";
import GlobalContext from "../../../../../../Context/Context";
import tmplPlaceHoldersArabic from "./tmplPlaceHoldersArabic";
import tmplPlaceHoldersFrench from "./tmplPlaceHoldersFrench";
import tmplPlaceHoldersGerman from "./tmplPlaceHoldersGerman";
import tmplPlaceHoldersRussian from "./tmplPlaceHoldersRussian";
import tmplPlaceHoldersSpanish from "./tmplPlaceHoldersSpanish";
import tmplPlaceHoldersItalian from "./tmplPlaceHoldersItalian";
import languages from "../../../../../../Shared/supported-languages.json";

const PlaceHoldersUiConfiguration = ({ domain, configs, setConfigs }) => {
  const searchBox = configs.find((item) => item.searchBox)?.searchBox || {};
  const context = useContext(GlobalContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    (searchBox && searchBox.featureLanguage) || ""
  );
  const [langDir, setLangDir] = useState(
    searchBox && searchBox.dir ? searchBox.dir : "ltr"
  );
  const [resWithout, setResWithout] = useState(
    searchBox && searchBox.tmplPlaceHolders?.resWithout
      ? searchBox.tmplPlaceHolders.resWithout
      : ""
  );
  const [approx, setApprox] = useState(
    searchBox && searchBox.tmplPlaceHolders?.approx
      ? searchBox.tmplPlaceHolders.approx
      : ""
  );
  const [resFound, setResFound] = useState(
    searchBox && searchBox.tmplPlaceHolders?.resFound
      ? searchBox.tmplPlaceHolders.resFound
      : ""
  );
  const [noRes, setNoRes] = useState(
    searchBox && searchBox.tmplPlaceHolders?.noRes
      ? searchBox.tmplPlaceHolders.noRes
      : ""
  );
  const [didYouMean, setDidYouMean] = useState(
    searchBox && searchBox.tmplPlaceHolders?.didYouMean
      ? searchBox.tmplPlaceHolders.didYouMean
      : ""
  );
  const [prevPage, setPrevPage] = useState(
    searchBox && searchBox.tmplPlaceHolders?.prevPage
      ? searchBox.tmplPlaceHolders.prevPage
      : ""
  );
  const [nextPage, setNextPage] = useState(
    searchBox && searchBox.tmplPlaceHolders?.nextPage
      ? searchBox.tmplPlaceHolders.nextPage
      : ""
  );
  const [searchBoxText, setSearchBoxText] = useState(
    searchBox && searchBox.tmplPlaceHolders?.searchBoxText
      ? searchBox.tmplPlaceHolders.searchBoxText
      : ""
  );
  const [missing, setMissing] = useState(
    searchBox && searchBox.tmplPlaceHolders?.missing
      ? searchBox.tmplPlaceHolders.missing
      : ""
  );
  const [mustInclude, setMustInclude] = useState(
    searchBox && searchBox.tmplPlaceHolders?.must_include
      ? searchBox.tmplPlaceHolders.must_include
      : ""
  );
  const [forP, setForP] = useState(
    searchBox && searchBox.tmplPlaceHolders?.for
      ? searchBox.tmplPlaceHolders.for
      : ""
  );
  const [brands, setBrands] = useState(
    searchBox && searchBox.tmplPlaceHolders?.brands
      ? searchBox.tmplPlaceHolders.brands
      : ""
  );
  const [priceRange, setPriceRange] = useState(
    searchBox && searchBox.tmplPlaceHolders?.priceRange
      ? searchBox.tmplPlaceHolders.priceRange
      : ""
  );
  const [categories, setCategories] = useState(
    searchBox && searchBox.tmplPlaceHolders?.categories
      ? searchBox.tmplPlaceHolders.categories
      : ""
  );
  const [sortBy, setSortBy] = useState(
    searchBox && searchBox.tmplPlaceHolders?.sortBy
      ? searchBox.tmplPlaceHolders.sortBy
      : ""
  );
  const [relevance, setRelevance] = useState(
    searchBox && searchBox.tmplPlaceHolders?.relevance
      ? searchBox.tmplPlaceHolders.relevance
      : ""
  );
  const [priceLTH, setPriceLTH] = useState(
    searchBox && searchBox.tmplPlaceHolders?.priceLTH
      ? searchBox.tmplPlaceHolders.priceLTH
      : ""
  );
  const [priceHTL, setPriceHTL] = useState(
    searchBox && searchBox.tmplPlaceHolders?.priceHTL
      ? searchBox.tmplPlaceHolders.priceHTL
      : ""
  );
  const [filter, setFilter] = useState(
    searchBox && searchBox.tmplPlaceHolders?.filter
      ? searchBox.tmplPlaceHolders.filter
      : ""
  );
  const [to, setTo] = useState(
    searchBox && searchBox.tmplPlaceHolders?.to
      ? searchBox.tmplPlaceHolders.to
      : ""
  );
  const [from, setFrom] = useState(
    searchBox && searchBox.tmplPlaceHolders?.from
      ? searchBox.tmplPlaceHolders.from
      : ""
  );
  const [ellipsisF, setEllipsisF] = useState(
    searchBox && searchBox.tmplPlaceHolders?.ellipsisF
      ? searchBox.tmplPlaceHolders.ellipsisF
      : ""
  );
  const [ellipsisL, setEllipsisL] = useState(
    searchBox && searchBox.tmplPlaceHolders?.ellipsisL
      ? searchBox.tmplPlaceHolders.ellipsisL
      : ""
  );
  const [dateN, setDateN] = useState(
    searchBox && searchBox.tmplPlaceHolders?.dateN
      ? searchBox.tmplPlaceHolders.dateN
      : ""
  );
  const [dateL, setDateL] = useState(
    searchBox && searchBox.tmplPlaceHolders?.dateL
      ? searchBox.tmplPlaceHolders.dateL
      : ""
  );
  const [forMoreRes, setForMoreRes] = useState(
    searchBox && searchBox.tmplPlaceHolders?.forMoreRes
      ? searchBox.tmplPlaceHolders.forMoreRes
      : ""
  );

  const [maxPrice, setMaxPrice] = useState(
    searchBox && searchBox.tmplPlaceHolders?.maxPrice
      ? searchBox.tmplPlaceHolders.maxPrice
      : ""
  );

  const [minPrice, setMinPrice] = useState(
    searchBox && searchBox.tmplPlaceHolders?.minPrice
      ? searchBox.tmplPlaceHolders.minPrice
      : ""
  );

  const [showFilters, setShowFilters] = useState(
    searchBox && searchBox.tmplPlaceHolders?.showFilters
      ? searchBox.tmplPlaceHolders.showFilters
      : ""
  );

  const [hideFilters, setHideFilters] = useState(
    searchBox && searchBox.tmplPlaceHolders?.hideFilters
      ? searchBox.tmplPlaceHolders.hideFilters
      : ""
  );

  const placeholders = [
    {
      label: "Results Without",
      placeholder: tmplPlaceHoldersEnglish.resWithout,
      placeholderKey: "resWithout",
      placeholderId: resWithout,
      setter: setResWithout,
    },
    {
      label: "Approximately",
      placeholder: tmplPlaceHoldersEnglish.approx,
      placeholderKey: "approx",
      placeholderId: approx,
      setter: setApprox,
    },
    {
      label: "Results Found",
      placeholder: tmplPlaceHoldersEnglish.resFound,
      placeholderKey: "resFound",
      placeholderId: resFound,
      setter: setResFound,
    },
    {
      label: "No Results Found For",
      placeholder: tmplPlaceHoldersEnglish.noRes,
      placeholderKey: "noRes",
      placeholderId: noRes,
      setter: setNoRes,
    },
    {
      label: "Did You Mean",
      placeholder: tmplPlaceHoldersEnglish.didYouMean,
      placeholderKey: "didYouMean",
      placeholderId: didYouMean,
      setter: setDidYouMean,
    },
    {
      label: "Previous",
      placeholder: tmplPlaceHoldersEnglish.prevPage,
      placeholderKey: "prevPage",
      placeholderId: prevPage,
      setter: setPrevPage,
    },
    {
      label: "Next",
      placeholder: tmplPlaceHoldersEnglish.nextPage,
      placeholderKey: "nextPage",
      placeholderId: nextPage,
      setter: setNextPage,
    },
    {
      label: "Search",
      placeholder: tmplPlaceHoldersEnglish.searchBoxText,
      placeholderKey: "searchBoxText",
      placeholderId: searchBoxText,
      setter: setSearchBoxText,
    },
    {
      label: "Missing",
      placeholder: tmplPlaceHoldersEnglish.missing,
      placeholderKey: "missing",
      placeholderId: missing,
      setter: setMissing,
    },
    {
      label: "Must Include",
      placeholder: tmplPlaceHoldersEnglish.must_include,
      placeholderKey: "must_include",
      placeholderId: mustInclude,
      setter: setMustInclude,
    },
    {
      label: "For",
      placeholder: tmplPlaceHoldersEnglish.for,
      placeholderKey: "for",
      placeholderId: forP,
      setter: setForP,
    },
    {
      label: "Brands",
      placeholder: tmplPlaceHoldersEnglish.brands,
      placeholderKey: "brands",
      placeholderId: brands,
      setter: setBrands,
    },
    {
      label: "Price Range",
      placeholder: tmplPlaceHoldersEnglish.priceRange,
      placeholderKey: "priceRange",
      placeholderId: priceRange,
      setter: setPriceRange,
    },
    {
      label: "Categories",
      placeholder: tmplPlaceHoldersEnglish.categories,
      placeholderKey: "categories",
      placeholderId: categories,
      setter: setCategories,
    },
    {
      label: "Sort By",
      placeholder: tmplPlaceHoldersEnglish.sortBy,
      placeholderKey: "sortBy",
      placeholderId: sortBy,
      setter: setSortBy,
    },
    {
      label: "Relevance",
      placeholder: tmplPlaceHoldersEnglish.relevance,
      placeholderKey: "relevance",
      placeholderId: relevance,
      setter: setRelevance,
    },
    {
      label: "Price: Low-to-Heigh",
      placeholder: tmplPlaceHoldersEnglish.priceLTH,
      placeholderKey: "priceLTH",
      placeholderId: priceLTH,
      setter: setPriceLTH,
    },
    {
      label: "Price: Heigh-to-Low",
      placeholder: tmplPlaceHoldersEnglish.priceHTL,
      placeholderKey: "priceHTL",
      placeholderId: priceHTL,
      setter: setPriceHTL,
    },
    {
      label: "Date Newest First",
      placeholder: tmplPlaceHoldersEnglish.dateN,
      placeholderKey: "dateN",
      placeholderId: dateN,
      setter: setDateN,
    },
    {
      label: "Date Latest First",
      placeholder: tmplPlaceHoldersEnglish.dateL,
      placeholderKey: "dateL",
      placeholderId: dateL,
      setter: setDateL,
    },
    {
      label: "First Ellipsis",
      placeholder: tmplPlaceHoldersEnglish.ellipsisF,
      placeholderKey: "ellipsisF",
      placeholderId: ellipsisF,
      setter: setEllipsisF,
    },
    {
      label: "Last Ellipsis",
      placeholder: tmplPlaceHoldersEnglish.ellipsisL,
      placeholderKey: "ellipsisL",
      placeholderId: ellipsisL,
      setter: setEllipsisL,
    },
    {
      label: "Filter",
      placeholder: tmplPlaceHoldersEnglish.filter,
      placeholderKey: "filter",
      placeholderId: filter,
      setter: setFilter,
    },
    {
      label: "To",
      placeholder: tmplPlaceHoldersEnglish.to,
      placeholderKey: "to",
      placeholderId: to,
      setter: setTo,
    },
    {
      label: "From",
      placeholder: tmplPlaceHoldersEnglish.from,
      placeholderKey: "from",
      placeholderId: from,
      setter: setFrom,
    },
    {
      label: "For more results",
      placeholder: tmplPlaceHoldersEnglish.forMoreRes,
      placeholderKey: "forMoreRes",
      placeholderId: forMoreRes,
      setter: setForMoreRes,
    },
    {
      label: "Maximum Price",
      placeholder: tmplPlaceHoldersEnglish.maxPrice,
      placeholderKey: "maxPrice",
      placeholderId: maxPrice,
      setter: setMaxPrice,
    },
    {
      label: "Minimum Price",
      placeholder: tmplPlaceHoldersEnglish.minPrice,
      placeholderKey: "minPrice",
      placeholderId: minPrice,
      setter: setMinPrice,
    },
    {
      label: "Hide Filters",
      placeholder: tmplPlaceHoldersEnglish.hideFilters,
      placeholderKey: "hideFilters",
      placeholderId: hideFilters,
      setter: setHideFilters,
    },
    {
      label: "Show Filters",
      placeholder: tmplPlaceHoldersEnglish.showFilters,
      placeholderKey: "showFilters",
      placeholderId: showFilters,
      setter: setShowFilters,
    },
  ];

  const updatedConfigs = (values) => {
    return configs.map((item) => {
      if (item.searchBox) {
        const updatedSearchBox = { ...item.searchBox };
        let searchBoxUpdated = false;
        if (updatedSearchBox.dir) {
          updatedSearchBox.dir = langDir;
        }
        updatedSearchBox.featureLanguage = selectedLanguage;
        if (!updatedSearchBox.tmplPlaceHolders) {
          updatedSearchBox.tmplPlaceHolders = {};
        }
        const tmplPlaceHolders = { ...updatedSearchBox.tmplPlaceHolders };
        let tmplUpdated = false;

        Object.keys(values).forEach((key) => {
          tmplPlaceHolders[key] = values[key];
          tmplUpdated = true;
        });

        if (tmplUpdated) {
          updatedSearchBox.tmplPlaceHolders = tmplPlaceHolders;
          searchBoxUpdated = true;
        }

        if (searchBoxUpdated) {
          return { ...item, searchBox: updatedSearchBox };
        }
      }
      return item;
    });
  };

  const handleChangeLanguage = (value) => {
    const languagesDir = languages.find((obj) => obj.code === value)?.dir;
    languagesDir && setLangDir(languagesDir);
    setSelectedLanguage(value);
    let placeholdersSetters = {
      eng: tmplPlaceHoldersEnglish,
      heb: tmplPlaceHoldersHebrew,
      spa: tmplPlaceHoldersSpanish,
      deu: tmplPlaceHoldersGerman,
      ara: tmplPlaceHoldersArabic,
      rus: tmplPlaceHoldersRussian,
      fra: tmplPlaceHoldersFrench,
      ita: tmplPlaceHoldersItalian,
    };

    const selectedPlaceholders = placeholdersSetters[value];
    placeholders.forEach((placeholder) => {
      placeholder.setter(selectedPlaceholders[placeholder.placeholderKey]);
    });
  };

  const handleSavePlaceholders = async () => {
    const values = {};
    placeholders.forEach((item) => {
      values[item.placeholderKey] = item.placeholderId;
    });
    const _configs = updatedConfigs(values);
    try {
      const requestJson = {
        action: 1014,
        host: domain?.domain,
        configs: _configs,
        credentials: context.loadCredentials(),
      };

      const responseJson = await fetchRequest(requestJson);
      if (responseJson.domains) {
        setConfigs(_configs);
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );

        context.message({
          type: "success",
          body: "Indexing settings were successfully updated",
        });
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
    }
  };

  const fetchRequest = async (requestJson) => {
    const responseJson = await context.myFetch(requestJson);
    return responseJson;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div>Placeholders UI configuration</div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "45%" }, marginTop: "20px" }}>
          <HeyDayFormLabel sx={{ display: "flex" }}>
            Select Language
          </HeyDayFormLabel>
          <TextField
            select
            sx={{
              width: "100%",
              marginTop: "15px",
              marginRight: "20px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            value={selectedLanguage}
            onChange={(e) => handleChangeLanguage(e.target.value)}
          >
            {languages.map((lang) => (
              <MenuItem
                key={lang.code}
                sx={{ fontFamily: "Poppins" }}
                value={lang.code}
              >
                {lang.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <DisplayPlaceHolderElement
          placeholders={placeholders}
          handleSubmitForm={handleSavePlaceholders}
        />
      </div>
    </div>
  );
};

export default PlaceHoldersUiConfiguration;
