import React from 'react';
import { Checkbox, FormLabel, TextField, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteOutline from '@mui/icons-material/DeleteOutline';

const SynonymRows = (props) => {
  const { synonymsArray, setSynonymsArray, handleSave, handleDelete } = props;
  const isMobile = useMediaQuery('(max-width:780px)');

  const handleChange = (event, index, field) => {
    const newState = [...synonymsArray];
    newState[index][field] = event.target.value;
    setSynonymsArray(newState);
  };

  const handleCheckboxChange = (event, index) => {
    const newState = [...synonymsArray];
    newState[index].isExclusive = event.target.checked;
    setSynonymsArray(newState);
  };

  const removeRow = (index) => {
    setSynonymsArray(synonymsArray.filter((item, idx) => idx !== index));
  };

  return (
    <form className='synonymsForm'>
      {synonymsArray.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            marginBottom: '15px',
            position: isMobile ? 'static' : 'relative',
          }}
        >
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',
              marginRight: '15px',
              width: '355px',
            }}
          >
            <div
              style={{ width: '120px', display: 'flex', alignItems: 'center' }}
            >
              <Checkbox
                checked={!!item.isExclusive}
                onChange={(event) => handleCheckboxChange(event, index)}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={
                  <CheckCircleOutlineIcon style={{ color: '#FEBA3F' }} />
                }
              />
              <FormLabel>Synonyms</FormLabel>
            </div>
            <div style={{ position: isMobile ? 'relative' : 'static' }}>
              <TextField
                value={item.synonyms}
                onChange={(event) => handleChange(event, index, 'synonyms')}
                placeholder='synonym_1, synonym_2, ...'
                fullWidth
                sx={{
                  maxWidth: '200px',
                  marginLeft: isMobile ? '' : '20px',
                  '& .MuiOutlinedInput-root': {
                    height: '44px',
                    border: '2px solid var(--Grey, #EBEFF3)',
                    borderRadius: '100px',
                    '& fieldset': {
                      borderColor: 'white',
                      border: 'none',
                    },
                  },
                }}
              />
              <div
                style={{
                  alignSelf: 'flex-end',
                  position: 'absolute',
                  right: isMobile ? '-40px' : 0,
                  top: isMobile ? '0px' : '10px',
                }}
              >
                <IconButton
                  onClick={() => removeRow(index)}
                  style={{ cursor: 'pointer' }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',
              width: '355px',
              marginRight: '15px',
            }}
          >
            <div style={{ width: isMobile ? '120px' : '' }}>
              <div style={{ width: '42px' }}></div>
              <FormLabel>of</FormLabel>
            </div>
            <TextField
              value={item.mappedWord}
              onChange={(event) => handleChange(event, index, 'mappedWord')}
              placeholder='word'
              fullWidth
              sx={{
                maxWidth: '200px',
                '& .MuiOutlinedInput-root': {
                  height: '44px',
                  border: '2px solid var(--Grey, #EBEFF3)',
                  borderRadius: '100px',
                  '& fieldset': {
                    borderColor: 'white',
                    border: 'none',
                  },
                },
              }}
            />
          </div>
        </div>
      ))}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          sx={{
            backgroundColor: '#ffffff',
            color: 'var(--Red, #F64A4A)',
            borderRadius: '25px',
            border: '2px solid',
            textTransform: 'unset',
            borderColor: 'var(--Grey, #EBEFF3)',
            fontFamily: 'Poppins',
            padding: '5px 15px',
            height: '40px',
            '&:hover': {
              backgroundColor: '#F64A4A',
              border: '2px solid',
              borderColor: 'var(--Green, #F64A4A)',
              color: 'var(--Green, #ffffff)',
            },
          }}
          startIcon={<DeleteOutline />}
          onClick={handleDelete}
        >
          Delete all synonyms
        </Button>
        <Button
          sx={{
            backgroundColor: '#ffffff',
            color: 'var(--Green, #5daaa1)',
            borderRadius: '25px',
            border: '2px solid',
            textTransform: 'unset',
            borderColor: 'var(--Green, #5daaa1)',
            fontFamily: 'Poppins',
            padding: '5px 15px',
            height: '40px',
            '&:hover': {
              backgroundColor: '#5daaa1',
              border: '2px solid',
              borderColor: 'var(--Green, #5daaa1)',
              color: 'var(--Green, #ffffff)',
            },
          }}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default SynonymRows;
