import React, { useContext, useState } from "react";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import "./ContactUs.css";
import HeyDayTextField from "../../../Shared/Components/TextField/HeyDayTextField";
import HeyDayMultiline from "../../../Shared/Components/TextField/HeyDayMultiline";
import HeyDayButton from "../../../Shared/Components/Button/HeyDayButton";
import HeyDayAutocomplete from "../../../Shared/Components/TextField/HeyDayAutocomplete";
import countriesList from "../../../Shared/countries.json";
import { useFormik } from "formik";
import { CONTACT_US_VALIDATION_SCHEMA } from "../../../consts/validators.consts";
import ContentPageHeader from "../ContentPageHeader/ContentPageHeader";
import WhatsAppIcon from "../../../Shared/Components/WhatsApp/WhatsAppIcon";
import GlobalContext from "../../../Context/Context";

const ContactUs = () => {
  const [dialCode, setDialCode] = useState("");
  const context = useContext(GlobalContext);
  const submitForm = async (values) => {
    const requestJson = {
      name: values.fullName,
      affId: sessionStorage.getItem("id") || 0,
      domain: sessionStorage.getItem("currentDomain") || "",
      email: values.email,
      phoneNumber: dialCode + values.phoneNumber,
      message: values.message,
    };

    try {
      const response = await formFetch(requestJson);
      if (!response.error) {
        console.log(response);
        context.message({
          type: "success",
          body: "Your message sent successfully",
        });

        // setIframeUrl(response.url);
      } else {
        context.message({
          type: "error",
          body: response.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
      console.error("Error:", error);
    }
  };
  const formFetch = async (
    requestJson,
    data = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ) => {
    data.body = data.body || JSON.stringify(requestJson);
    let result;
    try {
      const f = await fetch("https://cdn.heyday.io/form/", data);
      result = await f.json();
    } catch (e) {
      result = { error: e };
    }
    return result;
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: CONTACT_US_VALIDATION_SCHEMA,
    onSubmit: (values) => submitForm(values),
  });
  return (
    <div>
      <section className="content-header">
        <ContentPageHeader pageName="Contact Us" />
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
          minHeight: "530px",
        }}
        sx={{ width: "100%", position: "relative", maxWidth: "750px" }}
      >
        <div className="found-problem">Found a problem or have a question?</div>
        <img
          alt="group87"
          style={{ marginTop: "30px", height: "79px", width: "140px" }}
          src="/img/contact.png"
        />
        <div className="title-admin-card">Get in Touch</div>
        <form onSubmit={formik.handleSubmit}>
          <HeyDayTextField
            {...formik.getFieldProps("fullName")}
            error={formik.touched.fullName && Boolean(formik.errors.fullName)}
            helperText={formik.touched.fullName && formik.errors.fullName}
            style={{
              marginTop: "20px",
              width: "100%",
            }}
            required
            labelValue="Full Name"
          />
          <HeyDayTextField
            {...formik.getFieldProps("email")}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            style={{
              marginTop: "20px",
              width: "100%",
            }}
            required
            labelValue="Email"
          />
          <div style={{ display: "flex" }}>
            <HeyDayAutocomplete
              id="hdy-phone-dial"
              searchKeys={["name", "flag", "dial_code"]}
              requiredField={true}
              onChange={(event, value) => setDialCode(value.dial_code)}
              style={{ width: "25%", marginTop: "20px" }}
              options={countriesList}
              getOptionLabelKeys={["flag", "dial_code"]}
              label="Code"
              disablePortal
            />
            <HeyDayTextField
              {...formik.getFieldProps("phoneNumber")}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              style={{
                marginTop: "20px",
                width: "75%",
              }}
              required
              labelValue="Phone Number"
            />
          </div>

          <HeyDayMultiline
            {...formik.getFieldProps("message")}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            sx={{ width: "100%", mt: "20px" }}
            label="Message"
            required
            rows={4}
          />
          <HeyDayButton
            style={{ marginTop: 30, width: "160px" }}
            text="Submit"
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
          />
        </form>

        <WhatsAppIcon
          phoneNumber="+972548712160"
          message="Hello! I have a question."
        />
      </AdminCard>
    </div>
  );
};

export default ContactUs;
