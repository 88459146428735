export const convertCssString = (cssString) => {
  const camelCaseRegex = /([a-z])([A-Z])/g;

  return cssString
    .replace(/([a-zA-Z-]+)\s*:\s*([^;]+);?/g, (match, property, value) => {
      const kebabCaseProperty = property
        .replace(camelCaseRegex, "$1-$2")
        .toLowerCase();
      return `  ${kebabCaseProperty}: ${value.trim()};`;
    })
    .replace(/}\s*/g, "}")
    .replace(/{\s*/g, " {");
};
