const generateLink = (affId, domain) => {
  return `<div class="Rlink">
    <div class="Rmain <%=obj.phz%>">
    <a href="https://heyday.io/sm/selfNsm.html?d=${domain}&affId=${affId}&hdyQ=<%= encodeURIComponent(obj.pd.title) %>&u=<%=encodeURIComponent(obj.clickUrl)%>" target="_blank" class="Rsearch-more">See the suggestions for this item</a>
        <div class="slideshow-container">
            <% if (Array.isArray(obj.pd.additional_images) && obj.pd.additional_images.length > 0) { %>
                <% obj.pd.additional_images = [obj.pd.img, ...obj.pd.additional_images]; %>
                <% obj.pd.additional_images.forEach(function(image, index) { %>
                    <div class="RimgC fade slide">
                        <a href="<%=obj.clickUrl%>" target="_blank">
                            <img src="<%= image %>" <%if(obj.defaultImg){%> onerror="this.onerror=null;this.src='<%=obj.defaultImg%>';"<%}%> class="Rimg" />
                        </a>
                    </div>
                <% }); %>
                <a class="prev"></a>
                <a class="next"></a>
            <% } else { %>
                <div class="RimgC">
                    <a href="<%=obj.clickUrl%>" target="_blank">
                        <img src="<%= obj.pd.img %>" <%if(obj.defaultImg){%> onerror="this.onerror=null;this.src='<%=obj.defaultImg%>';"<%}%> class="Rimg" />
                    </a>
                </div>
            <% } %>
        </div>
        <div class="dots-container">
            <% if (Array.isArray(obj.pd.additional_images) && obj.pd.additional_images.length > 0) { %>
                <% obj.pd.additional_images.forEach(function(_, index) { %>
                    <span class="dot <%= index === 0 ? 'imgActive' : '' %>"></span>
                <% }); %>
            <% } %>
        </div>
        <div class="results-box">
                <% if(obj.pd.brand){ %>
                    <div class="Rbrand"><%= obj.pd.brand %></div>
                <% } else { %>
                    <div class="Rbrand"></div>
                <% } %>
                <a href="<%=obj.clickUrl%>" target="_blank" class="Rtitle"><%= obj.pd.title %></a>
                <% if (obj.pd.hdyAttr) { %>
                <div class="attributes-container">
                    <% for (var attrKey in obj.pd.hdyAttr) { %>
                        <div class="attribute-group">
                            <div class="hdy-attr-dropdown">
                                <div class="hdy-attr-selection">
                                <% if (obj.pd.selectedAttrKey && typeof obj.pd.selectedAttrKey[attrKey] !== 'undefined' && obj.pd.selectedAttrKey[attrKey] !== null) { %>
                                    <span class="hdy-selected-value" key-name="<%= decodeURIComponent(attrKey)%>"><%= decodeURIComponent(attrKey) + ": " + obj.pd.selectedAttrKey[attrKey] %></span>
                                <% } else { %>
                                    <span class="hdy-selected-value" key-name="<%= decodeURIComponent(attrKey)%>"><%= decodeURIComponent(attrKey) + ":" %></span>
                                <% } %>                                  
                                    <div class="hdy-dropdown-arrow">
                                    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
                                    </div>
                                </div>
                                <div class="hdy-dropdown-list">
                                    <% for (var valueKey in obj.pd.hdyAttr[attrKey]) { %>
                                        <div class="hdy-dropdown-item" data-value-atter-url="<%= obj.pd.hdyAttr[attrKey][valueKey].url %>" data-value-url="<%= obj.pd.hdyAttr[attrKey][valueKey].uriParam %>" data-value-img="<%= obj.pd.hdyAttr[attrKey][valueKey].img %>" data-value-price="<%= obj.pd.hdyAttr[attrKey][valueKey].price %>" data-value-sale-price="<%= obj.pd.hdyAttr[attrKey][valueKey].sale_price %>"  data-value-currency="<%= obj.pd.product.currency %>">
                                            <%= valueKey %>
                                        </div>
                                    <% } %>
                                </div>
                            </div>
                        </div>
                    <% } %>
                </div>
            <% } %>
            <% if(obj.pd.product && obj.pd.product.sale_price){ %>
                <div class="Rprice_before"><%= obj.pd.product.currency %><%= obj.pd.product.price %></div>
                <div class="Rprice"><%= obj.pd.product.currency %><%= obj.pd.product.sale_price %></div>
            <% } else { %>
                <% if(obj.pd.product && obj.pd.product.price && obj.pd.product.currency){ %>
                    <div class="Rprice"><%= obj.pd.product.currency %><%= obj.pd.product.price %></div>
                <% } %>
            <% } %>
            <div class="add-to-cart-container">
                <div class="add-qty">
                    <div class="quantity-control" data-quantity="">
                        <button class="quantity-btn" onclick="decreaseQuantity(this)">
                            <svg viewBox="0 0 409.6 409.6">
                                <g>
                                    <g>
                                        <path d="M392.533,187.733H17.067C7.641,187.733,0,195.374,0,204.8s7.641,17.067,17.067,17.067h375.467 c9.426,0,17.067-7.641,17.067-17.067S401.959,187.733,392.533,187.733z" />
                                    </g>
                                </g>
                            </svg>
                        </button>
                        <input type="number" class="quantity-input" data-quantity-target="" value="1" step="1" min="1" max="" name="quantity">
                        <button class="quantity-btn" onclick="increaseQuantity(this)">
                            <svg viewBox="0 0 426.66667 426.66667">
                                <path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="add-to-cart-btn" data-product-id="<%= obj.pd.id %>" data-product-url="<%=obj.clickUrl%>" onclick="handleAddToCart(this)">הוספה לסל</div>
            </div>
        </div>
    </div>
    </div>`;
};

export default generateLink;
