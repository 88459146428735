import React, { useContext, useState } from "react";
import HeyDayCard from "../../../Shared/Components/Card/SelfServeCard";
import GlobalContext from "../../../Context/Context";
import ProgressBar from "../ProgressBar/ProgressBar";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton, Button } from "@mui/material";
import InviteMembersModal from "../../Admin/ManageAccount/InviteMembers/InviteMembersModal";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import "./ReachOutSoon.css";

const ReachOutSoon = () => {
  const location = useLocation();
  const context = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stepModal, setStepModal] = useState(0);
  const backTo = location.state?.backTo || "/selfserve";
  const backToLocation = location.state?.location || "your last step";

  const handleClickInvite = () => {
    setStepModal(0);
    setIsModalOpen(true);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div className="logout-container">
        <IconButton onClick={() => context.logout()}>
          <LogoutIcon />
        </IconButton>
        <span>Log Out</span>
      </div>
      <HeyDayCard
        contentStyle={{
          position: "relative",
          minHeight: "560px",
        }}
        sx={{
          width: "100%",
          maxWidth: "880px",
          position: "relative",
          marginTop: "100px",
          boxShadow: {
            xs: "unset",
            md: "0px 5px 7px rgba(113, 113, 113, 0.25)",
          },
        }}
      >
        <ProgressBar stepName={"payment"} progressPercentage={"0"} />
        <div className="reachOutSoon-container">
          <img alt="react-out" src="/img/ReachOut.png" />
          <h1>We'll reach out soon!</h1>
          <div>
            <p>In the meantime, feel free to explore more about</p>
            <p>our services on our website. we look forward</p>
            <p>to connecting with you soon!</p>
          </div>
          <Button className="back-to-payment" onClick={() => navigate(backTo)}>
            Back To {backToLocation}
          </Button>
        </div>
      </HeyDayCard>
      <InviteMembersModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        step={stepModal}
        setStep={setStepModal}
      />
      <div className="invite-btn-container">
        <Button
          onClick={handleClickInvite}
          className="invite-btn"
          startIcon={
            <PersonAddAltOutlinedIcon
              sx={{
                color: "#5daaa1",
                backgroundColor: "#ffffff",
                borderRadius: "25px",
                padding: "5px",
              }}
            />
          }
        >
          Invite members
        </Button>
      </div>
    </div>
  );
};

export default ReachOutSoon;
