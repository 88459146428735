import React, { useState } from "react";
import "./ProductCard.css";
import { Typography, Button } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import TextStyleEditFields from "../editFields/TextStyleEditFields";
import CardStyleEditFields from "../editFields/CardStyleEditFields";

const ProductCard = ({
  product,
  textStyles,
  setTextStyles,
  cardStyle,
  setCardStyle,
}) => {
  const handleCardChange = (e) => {
    const { name, value } = e.target;
    setCardStyle({
      ...cardStyle,
      [name]: value,
    });
  };

  const [selectedTextElement, setSelectedTextElement] = useState("title");

  const handleTextElementChange = (name) => {
    setSelectedTextElement(name);
  };

  const handleStyleChange = (e, styleProperty) => {
    setTextStyles({
      ...textStyles,
      [selectedTextElement]: {
        ...textStyles[selectedTextElement],
        [styleProperty]: e.target.value,
      },
    });
  };

  const toggleDisplay = (key) => {
    setTextStyles((prevStyles) => ({
      ...prevStyles,
      [key]: {
        ...prevStyles[key],
        display: prevStyles[key].display === "none" ? "block" : "none",
      },
    }));
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ position: "relative", height: "330px" }}>
        <div className="wizard-product" style={cardStyle}>
          <div className="product-image">
            <img
              onClick={() => handleTextElementChange("image")}
              className={
                selectedTextElement === "image"
                  ? "wizard-product-image active"
                  : "wizard-product-image"
              }
              src={product.imageLink}
              alt={product.title}
              style={textStyles.image}
            />
          </div>
          <div className="wizard-product-info">
            <h2
              onClick={() => handleTextElementChange("title")}
              className={
                selectedTextElement === "title"
                  ? "product-title active"
                  : "product-title"
              }
              name="title"
              style={textStyles.title}
            >
              {product.title}
            </h2>
            <p
              onClick={() => handleTextElementChange("brand")}
              className={
                selectedTextElement === "brand"
                  ? "product-brand active"
                  : "product-brand"
              }
              name="brand"
              style={textStyles.brand}
            >
              {product.brand}
            </p>
            <p
              onClick={() => handleTextElementChange("description")}
              className={
                selectedTextElement === "description"
                  ? "product-description active"
                  : "product-description"
              }
              name="description"
              style={textStyles.description}
            >
              {product.description}
            </p>
            <div className="product-price">
              <span
                onClick={() => handleTextElementChange("price")}
                className={
                  selectedTextElement === "price"
                    ? "regular-price active"
                    : "regular-price"
                }
                name="price"
                style={textStyles.price}
              >
                {product.price}
              </span>
              <span
                onClick={() => handleTextElementChange("salePrice")}
                className={
                  selectedTextElement === "salePrice"
                    ? "sale-price active"
                    : "sale-price"
                }
                name="salePrice"
                style={textStyles.salePrice}
              >
                {product.salePrice}
              </span>
            </div>
          </div>
        </div>
        <div>
          {Object.entries(textStyles).map(
            ([key, style]) =>
              style.display === "none" && (
                <div className="wizard-hidden-elements" key={key}>
                  <Typography>{key}</Typography>
                  <Button onClick={() => toggleDisplay(key)}>
                    <KeyboardReturnIcon sx={{ color: "#ffba41" }} />
                  </Button>
                </div>
              )
          )}
        </div>
      </div>
      <div className="wizard-edit-container">
        <CardStyleEditFields
          handleCardChange={handleCardChange}
          cardStyle={cardStyle}
        />

        <TextStyleEditFields
          handleStyleChange={handleStyleChange}
          textStyles={textStyles}
          selectedTextElement={selectedTextElement}
        />
      </div>
    </div>
  );
};

export default ProductCard;
