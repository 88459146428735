import React, { Component } from "react";
import GlobalContext from "../../../Context/Context";
import { getDomains, getConf, b_hostName } from "../theme/helpers";
import DBmanager from "../DBmanager/utils/DBmanager";
import moment from "moment";
import querystring from "querystring";
import toast, { Toaster } from "react-hot-toast";
import cpannelConfig from "../DBmanager/utils/cpannel-config.json";

const backend_hostName = b_hostName();

let WEBSQL = false;
try {
  WEBSQL =
    window.openDatabase &&
    window.openDatabase("SP", "1.0", "super push", 24 * 1024 * 1024);
} catch (e) {}

const withContext = (Enhanceable) => {
  return class WithContext extends Component {
    constructor(props) {
      super(props);
      this.state = {
        confs: getConf(),
        DBmanagerReady: false,
        domainIndex: sessionStorage.getItem("currentDomainIndex") || 0,
      };
    }

    setGlobalState = (newState) => {
      this.setState(newState);
    };

    componentDidMount = async () => {
      const loadDBmanager =
        window.location.pathname.includes("/selfserve") ||
        !!!sessionStorage.getItem("token");
      if (loadDBmanager) {
        this.setState({ DBmanagerReady: true });
      } else {
        await this.initDBmanager();
      }
      this.mounted = true;
    };

    componentWillUnmount = () => {
      this.mounted = false;
    };

    initDBmanager = async () => {
      var that = this;
      try {
        const { confJson } = DBmanager;
        if (!confJson && this.getConf().tables) {
          const DBmanConf = this.getConf();
          DBmanager.construct(DBmanConf, {}).then(function (r) {
            console.log("dbmanager loaded ok");
            that.setState({ DBmanagerReady: true });
            console.log("code after initialization");
          });
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    loadCredentials = () => {
      let accessToken;
      if (sessionStorage.getItem("token")) {
        accessToken = sessionStorage.getItem("token");
      } else {
        sessionStorage.getItem("accessToken");
      }
      return {
        uName: sessionStorage.getItem("name"),
        accessToken: accessToken,
      };
    };

    CollectionToObj = (_arr, _key, _value) => {
      /**
       * Converts Provided collection to object
       * @array : mandatory
       * @string : key - optional - if provided every model in the array will be searched for given value and the value will be used as corresponding object's model key
       * @string : value - optional - if provided together with key every model in the object will only have this value from array
       */
      if (!_arr) {
        return {};
      }
      _arr = [..._arr];
      if (typeof _key === "function") {
        let o = {};
        for (let i = 0; i < _arr.length; i++) {
          o = { ...o, ..._key(_arr[i], i, _arr) };
        }
        return o;
      }
      if (typeof _key === "string" && !_value) {
        return _arr.reduce((_reducer, _current, _i) => {
          if (_i === 1) {
            const _r = {};
            _r[_reducer[_key]] = { ..._reducer };
            delete _r[_reducer[_key]][_key];
            _reducer = _r;
          }

          const $key = _current[_key];
          _reducer[$key] = { ..._current };
          delete _reducer[$key][_key];
          return _reducer;
        });
      }
      if (typeof _key === "string" && typeof _value === "string") {
        return _arr.reduce((_reducer, _current, _i) => {
          if (_i === 1) {
            const _r = {};
            _r[_reducer[_key]] = _reducer[_value];
            _reducer = _r;
          }

          _reducer[_current[_key]] = _current[_value];
          return _reducer;
        });
      }
      return { ..._arr };
    };

    message = (obj) => {
      if (obj.type === "success") {
        toast.success(obj.body);
      } else {
        if (obj.type === "error") {
          toast.error(obj.body);
        }
      }
    };

    initGoogle = (callback) => {
      window.google.accounts.id.initialize({
        client_id:
          "132932844029-8hnok8p73dir4ubg0jguo92gl3bjsvj3.apps.googleusercontent.com",
        callback: callback,
      });
      window.google.accounts.id.prompt((notification) => {
        // if one-tap is not displayed show the google sign-in button (in private mode for example)
        if (notification.isNotDisplayed()) {
          console.log("not displayed");
          window.google.accounts.id.renderButton(
            document.getElementById("g_id_onload"),
            {
              type: "standard",
              shape: "rectangular",
              theme: "outline",
              text: "continue_with",
              size: "large",
              logo_alignment: "center",
              width: 300,
            }
          );
        }
      });
    };

    logout = async () => {
      const requestJson = {
        action: 37,
        credentials: this.loadCredentials(),
      };
      await this.myFetch(requestJson);
      sessionStorage.removeItem("id");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("domains");
      sessionStorage.removeItem("currentDomain");
      sessionStorage.removeItem("currentDomainIndex");
      sessionStorage.removeItem("allDomains");
      sessionStorage.removeItem("affType");
      sessionStorage.removeItem("firstLogin");
      sessionStorage.removeItem("AC_seen");
      sessionStorage.removeItem("new_domain");
      sessionStorage.removeItem("previewCounter");
      sessionStorage.removeItem("indexSize");
      sessionStorage.removeItem("prevSize");
      sessionStorage.removeItem("TOS");
      sessionStorage.removeItem("contactName");
      localStorage.removeItem("entryUrl");
      sessionStorage.removeItem("multiUsers");
      sessionStorage.removeItem("userPicture");
      sessionStorage.removeItem("integrationState");
      sessionStorage.removeItem("affStatus");
      sessionStorage.removeItem("onboardingModal");
      sessionStorage.removeItem("feedDataRes");
      sessionStorage.removeItem("platform");
      // localStorage.removeItem("defaultParse");

      this.setState({
        redirect: "/login",
        DBmanagerReady: false,
        domainIndex: 0,
      });
      // window.location.href = "/login";
    };

    getDomains = getDomains;

    //getConf = () => confJsonRef;
    getConf = getConf;

    webSQLEnabled = () => !!WEBSQL;

    backend_hostName = backend_hostName;

    // fetchConfig = this.state?.conf
    //   ? () => {}
    //   : async () => {
    //       // const response = await fetch(`https://${backend_hostName}/webPushConf.json`, {
    //       // const response = await fetch(`https://heyday.io/webPushConf.json`, {
    //       //   // !temporarily!
    //       //   method: "GET",
    //       // });
    //       // const config = await response.json();
    //       sessionStorage.setItem("config", JSON.stringify(cpannelConfig));
    //       return cpannelConfig;
    //     };

    fetchConfig = this.state?.conf
      ? () => {}
      : () => {
          const config = cpannelConfig;
          sessionStorage.setItem("config", JSON.stringify(config));
          return config;
        };

    fetchPushes = async () => {
      const domain = this.getDomains()[this.props.match.params.index];
      const requestJson = {
        action: 7,
        host: domain && domain.domain,
      };
      requestJson.credentials = this.loadCredentials();
      const { pushesList = [] } = await this.myFetch(requestJson);

      if (!pushesList.error) {
        return pushesList.map((item) => {
          return { ...JSON.parse(item.payloadJson), ...item };
        });
      } else {
        console.log(pushesList.error);
        return [];
      }
    };

    fetchSegments = async () => {
      const domain = this.getDomains()[this.props.match.params.index];
      const requestJson = {
        action: 22,
        host: domain.domain,
      };
      requestJson.credentials = this.loadCredentials();
      // requestJson.segmentsList = [this.props.item.instractionJson.eventConf];
      const { segmentsList = [] } = await this.myFetch(requestJson);
      if (!segmentsList.error) {
        return segmentsList.map((item) => {
          return JSON.parse(item);
        });
      } else {
        console.log(segmentsList.error);
        return [];
      }
    };

    // fetchEvents = async (flag) => {
    //   const domain = this.getDomains()[this.props.match.params.index] || {};
    //   const requestJson = {
    //     action: 18,
    //     eventType: flag ? 1 : 0,
    //     host: domain.domain,
    //   };
    //   requestJson.credentials = this.loadCredentials();
    //   const { eventsList = [] } = await this.myFetch(requestJson);
    //   if (!eventsList.error) {
    //     return eventsList;
    //   } else {
    //     console.log(eventsList.error);
    //     return [];
    //   }
    // };

    readFile = (blobFile) => {
      const reader = new FileReader();
      // Handle errors load
      return new Promise((resolve, reject) => {
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.onerror = (e) => {
          reject({ error: e });
        };
        reader.readAsText(blobFile);
      });
    };

    fetchData = async (
      table,
      dateStart,
      dBack,
      metaData = {
        graphType: "summery",
      }
    ) => {
      const { params } = this.props.match;
      const domain = this.getDomains()[params.index];
      const tables = table instanceof Array ? table : [table];
      const host = domain.domain;
      if (!host) return;
      return Promise.all(
        tables.map(
          (_$table) =>
            new Promise(async (resolve, reject) => {
              const requestJson = {
                action: _$table.actionId,
                dailyReport: 1,
                host: host,
                credentials: {
                  ...this.loadCredentials(),
                },
              };
              requestJson.credentials = this.loadCredentials();
              if (dateStart) {
                requestJson.startDay = dateStart.utc().format("YYYY-MM-DD");
                requestJson.daysBack = dBack;
              } else {
                requestJson.startDay = moment(Date.now())
                  .utc()
                  .format("YYYY-MM-DD");
                requestJson.daysBack = 6;
              }

              /*requestJson.startDay = document.getElementById("startDay_" + submitedAffId).value;
        requestJson.daysBack = parseInt(document.getElementById("daysBack_" + submitedAffId).value);*/

              /*const metaData = {
          "grapthType": "summery",
          "where"     : {"GEO": {"isExclude": false, "params": ["AR", "ZZ"]}},
          "groupBy"   : ["GEO"]
        };


        metaData = {
          "grapthType": "weekHourSummery"
        };

        metaData = {
          "grapthType"  : "timeLine",
          "where"       : {"GEO": {"isExclude": false, "params": ["AR", "ZZ", 'MX']}},
          "timeInterval": "hour" ///day
        };*/

              if (this.state.DBmanagerReady) {
                this.DBmanager.getGraphData(
                  requestJson.credentials,
                  requestJson.startDay,
                  requestJson.daysBack,
                  _$table.actionId,
                  host,
                  metaData
                )
                  .then((obj) => {
                    console.log("getGraphData resp OK: ", obj);
                    resolve({
                      table: _$table,
                      data: obj,
                    });
                  })
                  .catch((err) => {
                    console.log("getGraphData err: ", err);
                    reject({ error: err, errorCode: 1 });
                  });
              }
            })
        )
      );
    };

    GetQuery = async (sqlString) => {
      return new Promise((resolve, reject) => {
        if (!sqlString) {
          reject({ error: "Invalid query parameters" });
        }
        if (!WEBSQL.transaction) {
          reject({ error: "This browser is not supported" });
        }

        WEBSQL.transaction((tx) =>
          tx.executeSql(
            sqlString,
            [],
            (tx, res) => {
              resolve(Array.from(res.rows));
            },
            (transaction, error) => {
              reject({ error, transaction });
            }
          )
        );
      });
    };

    myFetch = async (
      requestJson,
      meta,
      endpoint,
      data = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ) => {
      let uri = "";
      if (
        requestJson &&
        requestJson.credentials &&
        requestJson.credentials.accessToken &&
        requestJson.credentials.uName
      ) {
        uri = `?c=1&accessToken=${requestJson.credentials.accessToken}&uName=${requestJson.credentials.uName}`;
        delete requestJson.credentials;
      }
      //let endpoint = '';
      if (meta === "op") {
        endpoint = `https://${backend_hostName}/panWbPush/OP`;
      } else if (meta !== "signup") {
        endpoint = endpoint || `https://${backend_hostName}/panWbPush/${uri}`;
      } else {
        endpoint = endpoint || `https://${backend_hostName}/panWbPush/`;
      }

      data.body = data.body || JSON.stringify(requestJson);

      let result, parser;
      try {
        const f = await fetch(endpoint, data);
        const type =
          f.headers.get("Content-Type").split(";")[0] === "application/json"
            ? "json"
            : "text";
        let endType = meta === "blob" ? "blob" : type;
        parser = f[endType]();
        result = await parser;
        switch (result.errorCode) {
          case "1":
          case 1:
            if (
              sessionStorage.getItem("token") ||
              sessionStorage.getItem("accessToken")
            ) {
              // session has been expired
              !!this.mounted &&
                this.setState(
                  {
                    error: "Session is expired, please login",
                  },
                  this.logout
                );
            } else {
              this.setState({
                error: result.error,
              });
            }
            break;
          default:
            if (result.error === "user name or password invalid.") {
              if (sessionStorage.getItem("token")) {
                sessionStorage.removeItem("token");
                this.message({
                  type: "error",
                  body: result.error,
                });
              }
              this.logout();
            }
            break;
        }
      } catch (e) {
        this.message({
          type: "error",
          body: e.message,
        });
        result = { error: e.message };
        // result = Promise.reject(() => ({ error: e }));
      }
      return result;
    };

    handlePushChange = (event, item) => {
      const stateKey = item.toLocaleLowerCase().replace(" ", "");
      const valueToState = event.target.value.trim();

      switch (stateKey) {
        case "title":
          this.setState(() => ({
            title: valueToState,
          }));
          break;
        case "image":
          this.setState(() => ({
            image: valueToState,
          }));
          break;
        case "body":
          this.setState(() => ({
            body: valueToState,
          }));
          break;
        case "icon":
          this.setState(() => ({
            icon: valueToState,
          }));
          break;
        case "badge":
          this.setState(() => ({
            badge: valueToState,
          }));
          break;
        case "clickurl":
          this.setState(() => ({
            clickUrl: valueToState,
          }));
          break;
        case "actionsurls":
          this.setState(() => ({
            actionsUrls: valueToState,
          }));
          break;
        case "actions":
          this.setState(() => ({
            actions: valueToState,
          }));
          break;
        default:
          break;
      }
    };

    fetchAudienceSize = async (id) => {
      const domain = this.getDomains()[this.props.match.params.index];
      let requestJson = {
        action: 24,
        host: domain.domain,
        segmentId: id,
      };
      requestJson.credentials = this.loadCredentials();
      const result = await this.myFetch(requestJson);
      if (!result.error) {
        return result.userCount;
      }
    };

    commaSeparator = (x) => {
      if (!x || !x.toString) {
        return x;
      }
      x = x.toString();
      const pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    };

    maxZ = () =>
      Math.max.apply(
        null,
        [...document.querySelectorAll("body *")].map((e) => {
          const styles = document.defaultView.getComputedStyle(e, null),
            zIndex = styles.getPropertyValue("z-index"),
            pos = styles.getPropertyValue("position");

          if (pos !== "static" && zIndex !== "auto") {
            return parseInt(zIndex) || 1;
          }
          return 1;
        })
      );

    saveToUrl = (qstring = {}) => {
      const { location, history } = this.props;
      qstring = {
        ...querystring.decode(location.hash.substr(1)),
        ...qstring,
      };
      history.replace(`${location.pathname}#${querystring.encode(qstring)}`);
    };

    DBmanager = DBmanager;

    render() {
      const { props, state, myFetch, loadCredentials, handlePushChange } = this;
      const _props = { ...this };
      // const navigate = useNavigate();

      [
        "state",
        "message",
        "myFetch",
        "logout",
        "loadCredentials",
        "handlePushChange",
        "getDomains",
        "setGlobalState",
      ].forEach((prop) => {
        if (!_props[prop]) {
          delete _props[prop];
        }
      });

      const { redirect } = state;
      if (!!redirect) window.location.href = redirect;

      return (
        <>
          <Toaster
            toastOptions={{
              duration: 5000,
            }}
          />
          <GlobalContext.Provider value={{ ..._props }}>
            <Enhanceable
              {...state}
              {...props}
              myFetch={myFetch}
              loadCredentials={loadCredentials}
              handlePushChange={handlePushChange}
            />
          </GlobalContext.Provider>
        </>
      );
    }
  };
};

export { withContext };
