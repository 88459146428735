import React, { useState, useContext } from "react";
import GlobalContext from "../../Context/Context";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Drawer,
  Box,
  CssBaseline,
  MenuItem,
  Menu,
  Typography,
  Tooltip,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  List,
  Toolbar,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import NestedList from "./NestedList";
import AdminRoutes from "../../Routes/AdminRoutes";
import DomainDropdown from "./DomainDropdown";
import nestedList from "./menuConfig";
import { Link, useLocation } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { useNavigate } from "react-router-dom";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    [theme.breakpoints.up("sm")]: {
      marginLeft: open ? 0 : -drawerWidth,
    },
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const theme = useTheme();
  const context = useContext(GlobalContext);
  const navigate = useNavigate();
  const domainList = context.getDomains();
  const isMobileView = !useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = useState(!isMobileView);
  const location = useLocation();
  const [domainIndex, setDomainIndex] = useState(
    sessionStorage.getItem("currentDomainIndex") || 0
  );
  const domainType = domainList[domainIndex]?.type || 0;
  const userPicture = sessionStorage.getItem("userPicture");
  const contactName = sessionStorage.getItem("contactName") || null;
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const settings = [
    {
      name: "Manage accounts",
      icon: <GroupOutlinedIcon />,
      onClick: handleManageAccountClick,
    },

    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      onClick: () => {
        navigate(`/dashboard/${domainIndex}`);
        handleCloseUserMenu();
      },
    },
    {
      name: "Logout",
      icon: <LogoutRoundedIcon />,
      onClick: () => {
        context.logout();
        handleCloseUserMenu();
      },
    },
  ];
  function handleManageAccountClick() {
    navigate(`/manage-account/${domainIndex}`);
    handleCloseUserMenu();
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "#fff",
          boxShadow: "0px 10px 60px 0px rgba(226, 236, 249, 0.50)",
          borderRight: "1px solid",
          borderColor: "#E6EFF5",
        }}
      >
        <Toolbar
          sx={{
            background: "#FFF",
            borderBottom: "1px solid",
            borderColor: "#E6EFF5",
          }}
        >
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: "flex" }}></Box>
          <Box sx={{ flexGrow: 0, alignItems: "center", display: "flex" }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ marginRight: "10px", p: 0 }}
              >
                <Avatar alt={contactName} src={userPicture}>
                  {contactName
                    ? contactName.charAt(0).toLocaleUpperCase()
                    : null}
                </Avatar>
              </IconButton>
            </Tooltip>
            <span style={{ marginRight: "20px", color: "#000000" }}>
              {contactName}
            </span>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={setting.onClick}>
                  <span style={{ marginRight: "10px", marginTop: "5px" }}>
                    {setting.icon}
                  </span>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            border: "none",
          },
        }}
        variant={isMobileView ? "temporary" : "persistent"}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader sx={{ justifyContent: "space-between" }}>
          <img
            src="/img/HeyDay_Logo.png"
            alt="heyday_logo"
            style={{
              width: "129px",
              height: "46px",
              cursor: "pointer",
              marginLeft: "10px",
            }}
            onClick={() => (window.location.href = "/")}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <List>
          <ListItem disablePadding sx={{ width: "80%", margin: "auto" }}>
            <DomainDropdown
              domainList={domainList}
              className="domainDropdownD"
              domainIndex={domainIndex}
              setDomainIndex={setDomainIndex}
            />
          </ListItem>
        </List>
        <NestedList nestedList={nestedList[0]} domainIndex={domainIndex} />
        {domainType === 1 ? (
          <NestedList nestedList={nestedList[3]} domainIndex={domainIndex} />
        ) : (
          <NestedList nestedList={nestedList[1]} domainIndex={domainIndex} />
        )}
        <NestedList nestedList={nestedList[2]} domainIndex={domainIndex} />
        <List>
          <ListItem disablePadding sx={{ width: "80%", margin: "auto" }}>
            <ListItemButton
              to={`/contact/${domainIndex}`}
              disableRipple
              component={Link}
              style={{
                borderRadius: "100px",
                textDecoration: "none",
                color:
                  location.pathname === "/contact/" + domainIndex
                    ? "#ffffff"
                    : "black",
                backgroundColor:
                  location.pathname === "/contact/" + domainIndex
                    ? "#FFBA41"
                    : "transparent",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "30px",
                  color:
                    location.pathname === "/contact/" + domainIndex
                      ? "#ffffff"
                      : "black",
                }}
              >
                <ContactSupportOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Contact Us"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main
        open={open}
        sx={{
          backgroundColor: "#F4F6F8",
          minHeight: "100vh",
          overflow: "auto",
          marginLeft: isMobileView ? 0 : drawerWidth,
        }}
      >
        <Toolbar />
        <AdminRoutes />
      </Main>
    </Box>
  );
}
