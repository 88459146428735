import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@mui/material";
import SmProductCard from "./SmProductCard";
import "./ProductWizard.css";
import ButtonNext from "../../../../../../../../../Shared/Components/Button/ButtonNext";
import ButtonBack from "../../../../../../../../../Shared/Components/Button/ButtonBack";
import ButtonSave from "../../../../../../../../../Shared/Components/Button/ButtonSave";
import { productCardDefaultStyle } from "../utils/defaultStyles";
import { removeFormatting, extractStylesFromConfig } from "../utils/helpers";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ProductWizard = ({ domain, configs, setConfigs, context }) => {
  const [step, setStep] = useState(0);
  const [styles, setStyles] = useState(productCardDefaultStyle);
  const [error, setError] = useState({});
  const searchBoxIndex = configs.findIndex((item) => item.searchBox);
  const configStyle = configs[searchBoxIndex].searchBox.stylesheet;

  useEffect(() => {
    if (configStyle) {
      const extractedStyles = extractStylesFromConfig(
        configStyle,
        productCardDefaultStyle
      );
      setStyles(extractedStyles);
    } else {
      setStyles(productCardDefaultStyle);
    }
  }, [configStyle]);

  const validCSSUnit =
    /^(auto|0|(\d+(\.\d+)?)(px|em|rem|%|vh|vw|vmin|vmax|ch|ex|cm|mm|in|pt|pc))$/;

  const handleChange = (e, property) => {
    const { value } = e.target;
    if (!validCSSUnit.test(value)) {
      if (value !== "") {
        setError((prevError) => ({
          ...prevError,
          [property]: "Use valid CSS units.",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          [property]: "",
        }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        [property]: "",
      }));
    }
    const [section, styleProperty] = property.split(".");
    setStyles((prevStyles) => ({
      ...prevStyles,
      [section]: {
        ...prevStyles[section],
        [styleProperty]: value,
      },
    }));
  };

  const updateCss = (stylesheet, selector, property, value) => {
    const regex = new RegExp(
      `(${selector}\\s*{[^}]*)(\\s*${property}:\\s*[^;]+;)`,
      "i"
    );
    const newProperty = `${property}: ${value};`;

    if (regex.test(stylesheet)) {
      return stylesheet.replace(regex, `$1${newProperty}`);
    } else {
      const selectorRegex = new RegExp(`(${selector}\\s*{)`, "i");
      if (selectorRegex.test(stylesheet)) {
        return stylesheet.replace(selectorRegex, `$1\n  ${newProperty}`);
      } else {
        return `${stylesheet}\n${selector} {\n  ${newProperty}\n}`;
      }
    }
  };

  const generateCSS = (stylesheet) => {
    let updatedStylesheet = stylesheet;
    for (let section in styles) {
      for (let property in styles[section]) {
        const cssProperty = property.replace(
          /([A-Z])/g,
          (g) => `-${g[0].toLowerCase()}`
        );
        updatedStylesheet = updateCss(
          updatedStylesheet,
          `#heyday_SB_SM_holder .hd-s-main .searchResults .${section}`,
          cssProperty,
          styles[section][property]
        );
      }
    }
    return removeFormatting(updatedStylesheet);
  };

  const handleSaveConfig = async () => {
    const updatedConfig = [...configs];
    const searchBoxIndex = updatedConfig.findIndex((item) => item.searchBox);

    if (searchBoxIndex !== -1 && updatedConfig[searchBoxIndex].searchBox) {
      const currentStylesheet =
        updatedConfig[searchBoxIndex].searchBox.stylesheet || "";
      updatedConfig[searchBoxIndex].searchBox.stylesheet =
        generateCSS(currentStylesheet);
    }

    try {
      const requestJson = {
        action: 1014,
        host: domain,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };

      const responseJson = await context.myFetch(requestJson);
      if (responseJson.domains) {
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );
        context.message({
          type: "success",
          body: "Indexing settings were successfully updated",
        });
        setConfigs(updatedConfig);
        setTimeout(() => {
          setStep(0);
        }, 800);
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
    }
  };

  const resetToDefaultStyle = () => {
    setStyles(productCardDefaultStyle);
    setStep(7);
    context.message({
      type: "success",
      body: "The style has been reset to default successfully.",
    });
  };

  return (
    <div className="wizard">
      {step > 0 && (
        <button
          className="reset-default-style-btn"
          onClick={resetToDefaultStyle}
        >
          Reset Style To Default
        </button>
      )}

      <div className="preview-container">
        <SmProductCard styles={styles} step={step} />
      </div>
      {step === 0 && (
        <div className="step">
          <button className="start-wizard-btn" onClick={() => setStep(1)}>
            Start Product Card Wizard
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M12.999 2.99984V1M12.999 14.9989V12.999M5.9996 7.99944H7.99944M17.9986 7.99944H19.9985M15.7988 10.7992L16.9987 11.9991M15.7988 5.19966L16.9987 3.99976M1 19.9985L9.99928 10.9992M10.1993 5.19966L8.99936 3.99976"
                stroke="#FFBA41"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
      {step === 1 && (
        <div className="step">
          <h2>Product Card Style</h2>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Width:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              error={!!error["Rlink.width"]}
              helperText={error["Rlink.width"]}
              value={styles.Rlink.width}
              onChange={(e) => handleChange(e, "Rlink.width")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Border Color:</InputLabel>
            <div className="input-color-container">
              <input
                className="input-color"
                type="color"
                value={styles.Rlink.borderColor}
                onChange={(e) => handleChange(e, "Rlink.borderColor")}
              />
              <span>{styles.Rlink.borderColor}</span>
            </div>
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Border Width:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              error={!!error["Rlink.borderWidth"]}
              helperText={error["Rlink.borderWidth"]}
              type="text"
              value={styles.Rlink.borderWidth}
              onChange={(e) => handleChange(e, "Rlink.borderWidth")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Border Radius:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              error={!!error["Rlink.borderRadius"]}
              helperText={error["Rlink.borderRadius"]}
              type="text"
              value={styles.Rlink.borderRadius}
              onChange={(e) => handleChange(e, "Rlink.borderRadius")}
            />
          </div>
          <div className="wizard-btns-container">
            <ButtonBack text={"Back"} onClick={() => setStep(0)} />
            <ButtonNext text={"Next"} onClick={() => setStep(2)} />
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="step">
          <h2>Image Container Style</h2>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Height:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              error={!!error["RimgC.height"]}
              helperText={error["RimgC.height"]}
              value={styles.RimgC.height}
              onChange={(e) => handleChange(e, "RimgC.height")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Width:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              error={!!error["RimgC.width"]}
              helperText={error["RimgC.width"]}
              value={styles.RimgC.width}
              onChange={(e) => handleChange(e, "RimgC.width")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Border Radius:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              error={!!error["Rimg.padding"]}
              helperText={error["Rimg.padding"]}
              type="text"
              value={styles.Rimg.padding || 0}
              onChange={(e) => handleChange(e, "Rimg.padding")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>margin:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              value={styles.RimgC.margin}
              onChange={(e) => handleChange(e, "RimgC.margin")}
            />
          </div>
          <div className="wizard-btns-container">
            <ButtonBack text={"Back"} onClick={() => setStep(1)} />
            <ButtonNext text={"Next"} onClick={() => setStep(3)} />
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="step">
          <h2>Title Style</h2>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Font Family:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              value={styles.Rtitle.fontFamily}
              onChange={(e) => handleChange(e, "Rtitle.fontFamily")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Font Size:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              error={!!error["Rtitle.fontSize"]}
              helperText={error["Rtitle.fontSize"]}
              value={styles.Rtitle.fontSize}
              onChange={(e) => handleChange(e, "Rtitle.fontSize")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Color:</InputLabel>
            <div className="input-color-container">
              <input
                className="input-color"
                type="color"
                value={styles.Rtitle.color}
                onChange={(e) => handleChange(e, "Rtitle.color")}
              />
              <span>{styles.Rtitle.color}</span>
            </div>
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Text Align:</InputLabel>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                value={styles.Rtitle.textAlign || "left"}
                onChange={(e) => handleChange(e, "Rtitle.textAlign")}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  width: "180px",
                  height: "44px",
                  margin: 0,
                  backgroundColor: "white",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    width: "180px",
                    fontFamily: "Poppins",
                    color: "#616060",
                    margin: 0,
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputLabel-shrink": {
                    fontFamily: "Poppins",
                    color: "#616060",
                  },
                }}
              >
                <MenuItem value="left">left</MenuItem>
                <MenuItem value="center">center</MenuItem>
                <MenuItem value="right">right</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="wizard-btns-container">
            <ButtonBack text={"Back"} onClick={() => setStep(2)} />
            <ButtonNext text={"Next"} onClick={() => setStep(4)} />
          </div>
        </div>
      )}
      {step === 4 && (
        <div className="step">
          <h2>Brand Style</h2>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Font Family:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              value={styles.Rbrand.fontFamily}
              onChange={(e) => handleChange(e, "Rbrand.fontFamily")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Font Size:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              error={!!error["Rbrand.fontSize"]}
              helperText={error["Rbrand.fontSize"]}
              value={styles.Rbrand.fontSize}
              onChange={(e) => handleChange(e, "Rbrand.fontSize")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Color:</InputLabel>
            <div className="input-color-container">
              <input
                className="input-color"
                type="color"
                value={styles.Rbrand.color}
                onChange={(e) => handleChange(e, "Rbrand.color")}
              />
              <span>{styles.Rbrand.color}</span>
            </div>
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Text Align:</InputLabel>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                value={styles.Rbrand.textAlign || "left"}
                onChange={(e) => handleChange(e, "Rbrand.textAlign")}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  width: "180px",
                  height: "44px",
                  margin: 0,
                  backgroundColor: "white",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    width: "180px",
                    fontFamily: "Poppins",
                    color: "#616060",
                    margin: 0,
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputLabel-shrink": {
                    fontFamily: "Poppins",
                    color: "#616060",
                  },
                }}
              >
                <MenuItem value="left">left</MenuItem>
                <MenuItem value="center">center</MenuItem>
                <MenuItem value="right">right</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="wizard-btns-container">
            <ButtonBack text={"Back"} onClick={() => setStep(3)} />
            <ButtonNext text={"Next"} onClick={() => setStep(5)} />
          </div>
        </div>
      )}
      {step === 5 && (
        <div className="step">
          <h2>Sale Price Style</h2>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Font Family:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  backgroundColor: "white",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              value={styles.Rprice.fontFamily}
              onChange={(e) => handleChange(e, "Rprice.fontFamily")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Font Size:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  backgroundColor: "white",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              error={!!error["Rprice.fontSize"]}
              helperText={error["Rprice.fontSize"]}
              type="text"
              value={styles.Rprice.fontSize}
              onChange={(e) => handleChange(e, "Rprice.fontSize")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Color:</InputLabel>
            <div className="input-color-container">
              <input
                className="input-color"
                type="color"
                value={styles.Rprice.color}
                onChange={(e) => handleChange(e, "Rprice.color")}
              />
              <span>{styles.Rprice.color}</span>
            </div>
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Text Align:</InputLabel>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                value={styles.Rprice.textAlign || "left"}
                onChange={(e) => handleChange(e, "Rprice.textAlign")}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  width: "180px",
                  height: "44px",
                  margin: 0,
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    width: "180px",
                    fontFamily: "Poppins",
                    color: "#616060",
                    margin: 0,
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputLabel-shrink": {
                    fontFamily: "Poppins",
                    color: "#616060",
                  },
                }}
              >
                <MenuItem value="left">left</MenuItem>
                <MenuItem value="center">center</MenuItem>
                <MenuItem value="right">right</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="wizard-btns-container">
            <ButtonBack text={"Back"} onClick={() => setStep(4)} />
            <ButtonNext text={"Next"} onClick={() => setStep(6)} />
          </div>
        </div>
      )}
      {step === 6 && (
        <div className="step">
          <h2>Regular Price Style</h2>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Font Family:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  backgroundColor: "white",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              value={styles.Rprice_before.fontFamily}
              onChange={(e) => handleChange(e, "Rprice_before.fontFamily")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Font Size:</InputLabel>
            <TextField
              sx={{
                width: "180px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  backgroundColor: "white",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              type="text"
              error={!!error["Rprice_before.fontSize"]}
              helperText={error["Rprice_before.fontSize"]}
              value={styles.Rprice_before.fontSize}
              onChange={(e) => handleChange(e, "Rprice_before.fontSize")}
            />
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Color:</InputLabel>
            <div className="input-color-container">
              <input
                className="input-color"
                type="color"
                value={styles.Rprice_before.color}
                onChange={(e) => handleChange(e, "Rprice_before.color")}
              />
              <span>{styles.Rprice_before.color}</span>
            </div>
          </div>
          <div className="inputWizard-container">
            <InputLabel sx={{ width: "125px" }}>Text Align:</InputLabel>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                value={styles.Rprice_before.textAlign || "left"}
                onChange={(e) => handleChange(e, "Rprice_before.textAlign")}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  width: "180px",
                  height: "44px",
                  margin: 0,
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    width: "180px",
                    fontFamily: "Poppins",
                    color: "#616060",
                    margin: 0,
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputLabel-shrink": {
                    fontFamily: "Poppins",
                    color: "#616060",
                  },
                }}
              >
                <MenuItem value="left">left</MenuItem>
                <MenuItem value="center">center</MenuItem>
                <MenuItem value="right">right</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="wizard-btns-container">
            <ButtonBack text={"Back"} onClick={() => setStep(5)} />
            <ButtonNext text={"Next"} onClick={() => setStep(7)} />
          </div>
        </div>
      )}
      {step === 7 && (
        <div className="step">
          <h2>Save your product style</h2>
          <div className="wizard-btns-container">
            <ButtonBack text={"Back"} onClick={() => setStep(6)} />
            <div className="save-style-container">
              <div className="save-style-arrow">
                <ArrowDownwardIcon className="arrow" />
              </div>
              <ButtonSave text={"Save"} onClick={handleSaveConfig} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductWizard;
