import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../../../Context/Context";
import { useParams } from "react-router-dom";
import HeatmapChart from "./HeatmapChart";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const UsersByTime = ({ dateStart, daysBack, domain, isClickedReload }) => {
  const context = useContext(GlobalContext);
  const params = useParams();
  // const domain = context.getDomains()[params?.index]?.domain;
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let startTime = Date.now();

  const actionId = 3;
  const metaData = {
    graphType: "timeLine",
    timeInterval: "hour",
    sum: [["Impressions", ["impressions"]]],
  };
  //   const metaData = {
  //     graphType: 'summery',
  //     sum: [
  //       ['devices', ['OS']],
  //       ['Impressions', ['impressions']],
  //     ],
  //     groupBy: ['OS'],
  //   };
  //   const metaData = { // for the GEO graph!!!!!!!!!!!!!!!!!!!!!!!!!
  //     graphType: 'summery',
  //     sum: [['Impressions', ['impressions']]],
  //     groupBy: ['GEO'],
  //   };
  //   const metaData = {
  //     graphType: 'summery',
  //     sum: [
  //       ['devices', ['OS']],
  //       ['Impressions', ['impressions']],
  //     ],
  //     groupBy: ['OS'],
  //   };
  //   const metaData = {
  //     graphType: 'weekHourSummery',
  //     sum: [['Impressions', ['impressions']]],
  //     groupBy: ['_date'],
  //   };
  //   const action = 28;
  //   const metaData = {
  //     graphType: 'timeLine',
  //     where: {},
  //     preVentDefault: true,
  //     sum: [['searchCount', ['count']]],
  //     timeInterval: 'day',
  //   };
  //   const metaData = {
  //     graphType: 'weekHourSummery',
  //     sum: [['Impressions', ['impressions']]],
  //     groupBy: ['_date'],
  //   };

  const getChartData = async () => {
    setIsLoading(true);
    startTime = Date.now();
    setChartData([]);
    try {
      const result = await context.DBmanager.getGraphData(
        context.loadCredentials(),
        dateStart,
        6,
        actionId,
        domain,
        metaData
      );
      setChartData(result);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (context.state.DBmanagerReady) getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateStart,
    daysBack,
    context.state.domainIndex,
    context.state.DBmanagerReady,
    isClickedReload,
  ]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 350,
      }}
    >
      {chartData.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#5daaa1" }} />
        </Box>
      ) : (
        <div style={{ width: "100%" }}>
          <HeatmapChart
            chartData={chartData}
            setIsLoading={setIsLoading}
            startTime={startTime}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default UsersByTime;
