const checkIntegration = (domainIndex = 0, context, item) => {
  const domain = item
    ? retrieveDomain(context, item)
    : context.getDomains()[domainIndex];
  return domain ? domain.isVerified : false;
};
const checkElements = (domainIndex, context) => {
  const domain = context.getDomains()[domainIndex];
  const configs = JSON.parse(domain.configs);
  const hasSearchBox = configs.find((el) => el.searchBox);
  const searchBox = hasSearchBox && hasSearchBox.searchBox;
  const containsElements =
    searchBox && searchBox.elements && searchBox.elements.length !== 0;
  return containsElements;
};
const retrieveDomain = (context, item) => {
  return context.getDomains().find((dom) => dom.domain === item.domain);
};
const checkStandardUI = (domainIndex, context) => {
  const domain = context.getDomains()[domainIndex];
  const configs = JSON.parse(domain.configs);
  const objWithSearchBox = configs.find((el) => el.searchBox);
  const searchBox = objWithSearchBox && objWithSearchBox.searchBox;
  if (searchBox && searchBox.standardUi) {
    return searchBox.standardUi;
  } else {
    return false;
  }
};
const checkStandardUIDesktop = (domainIndex, context) => {
  const domain = context.getDomains()[domainIndex];
  const configs = JSON.parse(domain.configs);
  const objWithSearchBox = configs.find((el) => el.searchBox);
  const searchBox = objWithSearchBox && objWithSearchBox.searchBox;
  if (searchBox && searchBox.standardUi_desktop) {
    return searchBox.standardUi_desktop;
  } else {
    return false;
  }
};

export {
  checkIntegration,
  checkElements,
  checkStandardUI,
  checkStandardUIDesktop,
};
