/* eslint-disable no-useless-escape */
var defaultTmpl = {
  htmlBox: `
  <div class="hd-s-main">
    <% if(obj.logo) { %>
      <div class="header">
        <a href="<%=obj.home%>"><img class="logo" src="<%=obj.logo%>" /></a>
      </div>
    <% } %>
    <div class="search_bar-fixed">
      <button class="closeSearch">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path class="closeSvgPath" d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" stroke="#3b78e6"/>
        </svg>
      </button>
      <button class="searchBtn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" class=""></path>
        </svg>
      </button>
      <input type="text" class="HeyDay_q" placeholder="[!searchBoxText]" />
      <div class="autoComplete"></div>
      <button class="clearIcon">
        <svg width="12px" height="12px">
          <line x1="0%" y1="0%" x2="100%" y2="100%" style="stroke:#828282;stroke-width:2" />
          <line x1="0%" y1="100%" x2="100%" y2="0%" style="stroke:#828282;stroke-width:2" />
        </svg>
      </button>
    </div>
    <div class="searchResults"></div>
    <div class="paging"></div>
  </div>
`,
  autocomplete_item: `
  <li class="ACel">
    <div class="clockIcon">
      <svg class="[!AC_item_left]" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.16016" cy="8" r="7" stroke="#C5C5C5" stroke-opacity="0.9" stroke-width="2"/>
        <path d="M8.16016 4V9L11.6602 11" stroke="#C5C5C5" stroke-opacity="0.9" stroke-width="2"/>
      </svg>
      <span class="removeHistory">x</span>
    </div>
    <div class="searchIcon">
      <svg class="[!AC_item_right]" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83207 0.984141C4.96202 1.0875 4.35371 1.25898 3.64191 1.60149C2.96611 1.92667 2.52673 2.23768 1.95867 2.79296C1.05462 3.67665 0.496543 4.69042 0.212303 5.96539C0.120315 6.37782 0.108825 6.5044 0.100368 7.19554C0.0922333 7.85858 0.102108 8.02453 0.171291 8.38602C0.651277 10.8948 2.47623 12.8131 4.95331 13.4124C5.34054 13.5061 5.48426 13.5214 6.16444 13.5417C6.82007 13.5612 7.00245 13.5541 7.37615 13.4945C8.08951 13.3809 8.89938 13.1003 9.49288 12.7613L9.76976 12.6031L11.8686 14.733C14.2542 17.1537 14.1426 17.0617 14.6978 17.067C15.1018 17.0709 15.3807 16.9551 15.6572 16.6688C15.935 16.381 16.0093 16.1988 16.0129 15.7963C16.0182 15.2104 16.1329 15.3563 13.7454 12.9113L11.6465 10.7619L11.7806 10.5431C12.1444 9.94978 12.4465 9.15268 12.5862 8.4177C12.6589 8.03483 12.6675 7.8887 12.6592 7.17643C12.6491 6.31096 12.6021 5.99703 12.3785 5.30231C11.8785 3.74885 10.6761 2.35189 9.22607 1.63997C8.36796 1.21869 7.57022 1.02093 6.6028 0.989628C6.24964 0.978222 5.90282 0.975761 5.83207 0.984141ZM7.23499 2.97938C8.7562 3.26349 10.0699 4.42702 10.5527 5.91786C11.2134 7.95761 10.2827 10.2015 8.37822 11.161C7.6446 11.5306 6.99951 11.6701 6.20099 11.6318C5.60565 11.6033 5.24261 11.5278 4.75058 11.3303C3.85166 10.9695 3.04026 10.257 2.55 9.39794C1.96944 8.38063 1.84396 6.96783 2.23547 5.85705C2.46813 5.19703 2.83436 4.61915 3.32014 4.14545C4.36404 3.1276 5.77639 2.70694 7.23499 2.97938Z" fill="#C5C5C5" fill-opacity="0.9"/>
      </svg>
    </div>
    <%=obj.item%>
  </li>
`,
  search_item: `
  <a href="<%=obj.clickUrl%>" target="_parent" class="Rlink">
    <div class="Rmain <%=obj.phz%>">
      <div class="Rbody">
        <div class="RimageC" <%if(obj.pd.img){%> style="background-image: url('<%=obj.pd.img%>')"<%}%> ></div>
        <div class="Rtitle"><%=obj.pd.title%></div>
        <%if(obj.description){%>
          <div class="description">
            <%=obj.description%>
          </div>
        <%}%>
      </div>
      <%if(obj.pd.author || obj.creationTime){%>
        <div class="Rda">
          <%if(obj.pd.author){%>
            <span class="Rauthor"><%=obj.pd.author%></span>
          <%}%>
          <%if(obj.creationTime){%>
            <span class="Rdate"><%=obj.creationTime%></span>
          <%}%>
          <%if(obj.nr && false){%>
            <div class="Rmissing">[!missing]: <span class="n"><%=obj.nr%></span> | [!must_include]: <span class="y" onclick="mustInclude('<%=obj.nr%>')"><%=obj.nr%></span></div>
          <%}%>
        </div>
      <%}%>
    </div>
  </a>
`,

  results_without: `<p class="res_p">[!resWithout]: <b><%=obj.nr%></b><br />[!approx] <%=obj.res%> [!resFound]</p>`,
  low_quality: `<p class="res_p">[!approx] <%=obj.res%> [!resWithout]</p><div class='lowQC'> <p class='lowQH'>It looks like there aren't many great matches for your search</p>
      <p class='lowQ'>Tip: Try using words that might appear on the page you’re looking for.</p></div><p>`,
  approximately_found: `<p class="res_p">
<span>[!approx]</span> 
<span class="res-num"><%=obj.res%></span> 
<span>[!resFound]</span> 
<span>[!for]</span> 
<span class="res-for"><q><%=obj.q%><q/></span>
</p>`,
  no_results: `<p class="noRes">[!noRes] <span class="res-for"><q><%=obj.q%></q></span>`,
  spelling_suggestions: `<p class="hd-s-syn">[!didYouMean] 
                            <span class="c-p" onclick="search({q:\'<%=obj.q%>\', dym:true})">\"<%=obj.v%>\"</span>?
                        </p><br/>`,
  spelling_correction: `<div>
                        <p class="hd-s-syn fn-12">Showing results for <b><i><%=obj.c%></i></b></p>
                        <p class="hd-s-syn">Display results for <span class="c-p" onclick="search({f:true})">\"<%=obj.q%>\"</span> instead</p>
                    </div>`,
  paging_item: `<<%if(obj.active){%>span<%}else{%>a<%}%> href="<%=obj.href%>" data-p="<%=obj.href%>" title="<%=obj.title%>" class="<%=obj.active?'active':'do-search'%>"><%=obj.title%></<%if(obj.active){%>span<%}else{%>a<%}%>>`,
  autocomplete_holder: "ul",
  ellipsis_format1: `[!ellipsisF]`,
  ellipsis_format2: `[!ellipsisL]`,
  sort_by: `<div class="select-dropdown">
            <div class="select-dropdown-header" class="selectDropdownMenuButton">
              <span class="selected">
                <% if (obj.sortBySelected === "relevance") { %>
                    [!relevance]
                <% } else if (obj.sortBySelected === "asc") { %>
                  [!dateOTN]
                <% } else if (obj.sortBySelected === "desc") { %>
                  [!dateNTO]
                <% } else { %>
                    [!sortBy]
                <% } %>
            </span>
                <i class="arrow"></i>
                <div class="select-dropdown-menu" class="selectDropdownMenu">
                    <div class="select-dropdown-item" data-value="relevance">[!relevance]</div>
                    <div class="select-dropdown-item" data-value="asc">[!dateOTN]</div>
                    <div class="select-dropdown-item" data-value="desc">[!dateNTO]</div>
                </div
            </div>
        </div>`,
};

export default defaultTmpl;
