import React, { useContext, useEffect, useState } from "react";
import ContentPageHeader from "../../ContentPageHeader/ContentPageHeader";
import GlobalContext from "../../../../Context/Context";
import { useParams } from "react-router-dom";
import AdminCard from "../../../../Shared/Components/Card/AdminCard";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Button } from "@mui/material";
import SiteSearch from "./Tabs/SiteSearch/SiteSearch";
import ViewDomainMeta from "./Tabs/ViewDomainMeta/ViewDomainMeta";
import SearchMore from "./Tabs/SearchMore/SearchMore";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const EditDomain = () => {
  const params = useParams();
  const context = useContext(GlobalContext);
  const domain = context.getDomains()[params?.index];
  // const domainType = domain?.type || 0;
  const domainType = 0;
  const [tabValue, setTabValue] = useState(0);
  const [isSmActive, setIsSmActive] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    try {
      const conf = domain?.configs ? JSON.parse(domain.configs) : [];
      setConfigs(conf);
      const smConfIndex = conf.findIndex(
        (item) => item?.searchMore !== undefined
      );
      if (smConfIndex !== -1) {
        setIsSmActive(true);
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeActiveSM = async () => {
    handleCloseModal();
    const dom = context.getDomains()[params?.index];
    const confArray = dom?.configs ? JSON.parse(dom.configs) : [];
    if (!Array.isArray(confArray)) {
      context.message({
        type: "error",
        body: "Configurations are not properly loaded.",
      });
      return;
    }

    const updatedConfig = [...confArray];
    const smConfIndex = updatedConfig.findIndex(
      (item) => item?.searchMore !== undefined
    );

    if (smConfIndex !== -1) {
      if (isSmActive) {
        updatedConfig.splice(smConfIndex, 1);
        setIsSmActive(false);
      } else {
        updatedConfig[smConfIndex].searchMore = 2;
        setIsSmActive(true);
      }
    } else {
      if (!isSmActive) {
        updatedConfig.push({ searchMore: 2 });
        setIsSmActive(true);
      }
    }

    const host = sessionStorage.getItem("currentDomain");

    if (!host) {
      context.message({
        type: "error",
        body: "No current domain found in session storage.",
      });
      return;
    }

    try {
      const requestJson = {
        action: 1014,
        host: host,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };

      const responseJson = await context.myFetch(requestJson);

      if (responseJson.domains) {
        setConfigs(updatedConfig);
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );

        setTimeout(() => {
          context.message({
            type: "success",
            body: !isSmActive
              ? "Search More is now active"
              : "Search More is now inactive",
          });
        }, 800);
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error.message,
      });
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  });

  const getTabs = () => {
    const tabs = [];
    const panels = [];

    if (domainType !== 1) {
      tabs.push(
        <Tab
          key="site-search"
          disableRipple
          sx={{
            textTransform: "unset",
            color: "black",
            fontSize: "14px",
            fontFamily: "Poppins",
          }}
          label="Site Search"
          {...a11yProps(0)}
        />
      );
      panels.push(
        <CustomTabPanel
          key="panel-site-search"
          value={tabValue}
          index={tabs.length - 1}
        >
          <SiteSearch />
        </CustomTabPanel>
      );
    }

    if (domainType !== 2) {
      tabs.push(
        <Tab
          key="search-more"
          disableRipple
          sx={{
            textTransform: "unset",
            fontFamily: "Poppins",
            color: "black",
          }}
          label="Search More"
          {...a11yProps(tabs.length)}
        />
      );
      panels.push(
        <CustomTabPanel
          key="panel-search-more"
          value={tabValue}
          index={tabs.length - 1}
        >
          <SearchMore />
        </CustomTabPanel>
      );
    }

    tabs.push(
      <Tab
        key="javascript"
        disableRipple
        sx={{
          textTransform: "unset",
          fontFamily: "Poppins",
          color: "black",
        }}
        label="Javascript"
        {...a11yProps(tabs.length)}
      />
    );
    panels.push(
      <CustomTabPanel
        key="panel-javascript"
        value={tabValue}
        index={tabs.length - 1}
      >
        {configs.length > 0 && (
          <ViewDomainMeta domain={domain?.domain} configs={configs} />
        )}
      </CustomTabPanel>
    );

    return { tabs, panels };
  };

  const { tabs, panels } = getTabs();

  return (
    <div>
      <section className="content-header">
        <ContentPageHeader pageName="Edit domain" />
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <Button
            className="btn-start-on-boarding"
            onClick={() => (window.location.href = "/selfserve/2")}
          >
            Start On Boarding
          </Button>
          {domainType !== 2 && (
            <Button
              className={
                isSmActive ? "btn-activate-sm-active" : "btn-activate-sm"
              }
              onClick={handleClickOpenModal}
            >
              {isSmActive ? "Deactivate Search More" : "Activate Search More"}
            </Button>
          )}
        </div>
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
          minHeight: "270px",
          width: {
            xs: "100%",
            md: "90%",
          },
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "1000px",
          boxShadow: "unset",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              variant="fullWidth"
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#5DAAA1",
                },
                "& .Mui-selected": {
                  color: "#5DAAA1 !important",
                },
              }}
            >
              {tabs}
            </Tabs>
          </Box>
          {panels}
        </Box>
      </AdminCard>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Search More Activation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to{" "}
            {isSmActive
              ? "Deactivate the 'Search More' feature?"
              : "Activate the 'Search More' feature?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="disagree-btn-sm" onClick={handleCloseModal}>
            No
          </Button>
          <Button className="agree-btn-sm" onClick={handleChangeActiveSM}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default EditDomain;
