import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./SmProductCard.css";

const SmProductCard = ({ styles, step }) => {
  const renderArrow = () => {
    switch (step) {
      case 1:
        return (
          <ArrowForwardIcon
            sx={{ color: "#5daaa1" }}
            className="arrow arrow-Rlink"
          />
        );
      case 2:
        return (
          <ArrowForwardIcon
            sx={{ color: "#5daaa1" }}
            className="arrow arrow-RimgC"
          />
        );
      case 3:
        return (
          <ArrowForwardIcon
            sx={{ color: "#5daaa1" }}
            className="arrow arrow-Rtitle"
          />
        );
      case 4:
        return (
          <ArrowForwardIcon
            sx={{ color: "#5daaa1" }}
            className="arrow arrow-Rbrand"
          />
        );
      case 5:
        return (
          <ArrowForwardIcon
            sx={{ color: "#5daaa1" }}
            className="arrow arrow-Rprice"
          />
        );
      case 6:
        return (
          <ArrowForwardIcon
            sx={{ color: "#5daaa1" }}
            className="arrow arrow-Rprice_before"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="Rlink" style={styles.Rlink}>
      {renderArrow()}
      <div className="Rmain odd">
        <div className="slideshow-container">
          <div className="RimgC fade slide" style={styles.RimgC}>
            <a>
              <img
                style={styles.Rimg}
                src="/img/default-product-img.webp"
                onError={(e) =>
                  (e.target.src =
                    "https://cdn.heyday.io/heyday.io/img/defaultProductImg.png")
                }
                className="Rimg"
                alt="Product"
              />
            </a>
          </div>
        </div>
        <div className="results-box" style={styles.resultsBox}>
          <div className="Rbrand" style={styles.Rbrand}>
            Product Brand
          </div>
          <a className="Rtitle" style={styles.Rtitle}>
            Product Title
          </a>
          <div className="Rprice" style={styles.Rprice}>
            130$
          </div>
          <div className="Rprice_before" style={styles.Rprice_before}>
            150$
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmProductCard;
