var tmplPlaceHolders = {
  resWithout: "results without",
  approx: "Approximately",
  resFound: "results found",
  noRes: "No results found for",
  didYouMean: "Did you mean",
  prevPage: "Previous",
  nextPage: "Next",
  AC_item_left: "AC_item_left",
  AC_item_right: "AC_item_right",
  searchBoxText: "search",
  missing: "Missing",
  must_include: "Must include",
  for: "for",
  brands: "Brands",
  clear: "clear",
  priceRange: "Price range",
  categories: "Categories",
  sortBy: "Sort By",
  relevance: "Relevance",
  priceLTH: "Price: low to high",
  priceHTL: "Price: hight to low",
  filter: "Filter",
  to: "To",
  from: "From",
  ellipsisF: "...",
  ellipsisL: "...",
  dateN: "Date: newest first",
  dateL: "Date: latest date",
  forMoreRes: "For More Results",
  minPrice: "Minimum Price",
  maxPrice: "Maximum Price",
  showFilters: 'Show Filters',
  hideFilters: 'Hide Filters',
};

export default tmplPlaceHolders;
