import React, { useContext } from "react";
import GlobalContext from "../Context/Context";
import { Route, Routes, Navigate } from "react-router-dom";
import ContactUs from "../Components/Admin/ContactUs/ContactUs";
import Dashboard from "../Components/Admin/Dashboard/Dashboard";
import DomainGrouping from "../Components/Admin/Search/DomainGrouping";
import Filters from "../Components/Admin/Search/Filters";
import ManageSearchResults from "../Components/Admin/Search/ManageSearchResults/ManageSearchResults";
import ManageSynonyms from "../Components/Admin/Search/Synonyms/ManageSynonyms";
import ManageStopWords from "../Components/Admin/Search/ManageStopWords";
import SearchPreview from "../Components/Admin/Search/SearchPreview/SearchPreview";
import SiteAnalytics from "../Components/Admin/Statistics/SiteAnalytics";
import SearchQueries from "../Components/Admin/Statistics/SearchQueries";
import TrendingSearches from "../Components/Admin/Statistics/TrendingSearches";
import EditDomain from "../Components/Admin/Dashboard/EditDomain/EditDomain";
import AddDomain from "../Components/Admin/AddDomain/AddDomain";
import Terms from "../Auth/SignUp/Terms";
import ManageAccount from "../Components/Admin/ManageAccount/ManageAccount";
import Invitation from "../Components/Admin/Invitation/Invitation";
import Payment from "../Components/Admin/Payment/Payment";

const AdminRoutes = () => {
  const context = useContext(GlobalContext);
  const allDomains = context.getDomains();
  const tos = sessionStorage.getItem("TOS");
  const token = sessionStorage.getItem("token");
  const affStatus = +sessionStorage.getItem("affStatus") || 1;
  const currentDomainIndex = +sessionStorage.getItem("currentDomainIndex") || 0;
  if (+tos === 0 && token) {
    return (
      <Routes>
        <Route path="/terms" element={<Terms isSelfServe={false} />} />
        <Route path="/manage-account/0" element={<ManageAccount />} />
        <Route path="/*" element={<Navigate to="/terms" replace />} />
      </Routes>
    );
  } else if (
    (allDomains && allDomains.length === 0 && token) ||
    (!allDomains && token)
  ) {
    return (
      <Routes>
        <Route path="/AddDomain" element={<AddDomain />} />
        <Route path="/manage-account/0" element={<ManageAccount />} />
        <Route path="/*" element={<Navigate to="/AddDomain" replace />} />
      </Routes>
    );
  } else if (affStatus === 2) {
    return (
      <Routes>
        <Route path="/payment" element={<Payment />} />
        <Route path="/*" element={<Navigate to="/payment" replace />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route exact path="/dashboard/:index" element={<Dashboard />} />
      <Route path="/manage-account/:index" element={<ManageAccount />} />
      <Route path="/invitation/:token" element={<Invitation />} />
      <Route path="/domain-grouping/:index" element={<DomainGrouping />} />
      <Route path="/AddDomain" element={<AddDomain />} />
      <Route path="/edit-domain/:index" element={<EditDomain />} />
      <Route path="/stop-words/:index" element={<ManageStopWords />} />
      <Route path="/synonyms/:index" element={<ManageSynonyms />} />
      <Route path="/filters/:index" element={<Filters />} />
      <Route path="/search-results/:index" element={<ManageSearchResults />} />
      <Route path="/search-preview/:index" element={<SearchPreview />} />
      <Route path="/site-analytics/:index" element={<SiteAnalytics />} />
      <Route path="/search-queries/:index" element={<SearchQueries />} />
      <Route path="/trending-searches/:index" element={<TrendingSearches />} />
      <Route path="/contact/:index" element={<ContactUs />} />
      <Route path="/payment" element={<Payment />} />
      <Route
        path="/*"
        element={<Navigate to={`/dashboard/${currentDomainIndex}`} replace />}
      />
    </Routes>
  );
};

export default AdminRoutes;
