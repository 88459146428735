import React, { useState, useEffect, useContext } from "react";
import "./ProductFeed.css";
import { Button, IconButton, Box } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { updateIntegrationState } from "../../helpers/helpers";
import PageDataCard from "../../Products/PageDataCard";
import GlobalContext from "../../../../Context/Context";
import AttributesPage from "./AttributesPage";
import InfoTooltip from "../../../../Shared/Components/Tooltip/InfoTooltip";
import { formFetch } from "../../../../Shared/functions/fetch";
import { useNavigate } from "react-router-dom";
import HeydayCardLoading from "../../../Admin/Loading/HeydayCardLoading";
import CurrencySelector from "./CurrencySelector";
import Variants from "./Variants";

const ProductFeed = ({
  defaultPathMap,
  products,
  convertParsedProduct,
  parsedProductsArray,
  domain,
}) => {
  const context = useContext(GlobalContext);
  const [productIndex, setProductIndex] = useState(0);
  const [selectedFields, setSelectedFields] = useState([]);
  const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
  const [minimized, setMinimized] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [displayAttributesPage, setDisplayAttributesPage] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [currency, setCurrency] = useState("");
  const [parentField, setParentField] = useState("");
  const [variantName, setVariantName] = useState("");
  const [variantValue, setVariantValue] = useState("");
  const [variants, setVariants] = useState([]);
  const [variantsStep, setVariantsStep] = useState(0);
  const [displayVarPage, setDisplayVarPage] = useState(false);
  const fields = Object.keys(defaultPathMap);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentStep === fields.length) {
      const newDefaultMap = convertObject(selectedFields);
      convertParsedProduct(products, newDefaultMap);
      setDisplayVarPage(true);
      // setDisplayAttributesPage(true);
    }
    // eslint-disable-next-line
  }, [currentStep]);

  const nextProduct = () => {
    setProductIndex((prevIndex) => (prevIndex + 1) % products.length);
  };

  const prevProduct = () => {
    setProductIndex((prevIndex) =>
      prevIndex === 0 ? products.length - 1 : prevIndex - 1
    );
  };

  const toggleMinimized = (key) => {
    setMinimized({
      ...minimized,
      [key]: !minimized[key],
    });
  };

  const handleFieldSelection = (field, path, checkboxId) => {
    if (displayVarPage) {
      if (variantsStep === 0) {
        document.getElementById(checkboxId).checked = false;
        document.getElementById(checkboxId).disabled = false;
      } else if (variantsStep === 1) {
        setParentField(path);
        setDisabledCheckboxes((prev) => [...prev, checkboxId]);
        setVariantsStep((prevStep) => prevStep + 1);
      } else if (variantsStep === 2) {
        setVariantValue(path);
        setDisabledCheckboxes((prev) => [...prev, checkboxId]);
        setVariantsStep((prevStep) => prevStep + 1);
      }
    } else if (field !== "keywords") {
      if (!path && !checkboxId) {
        setSelectedFields([
          ...selectedFields,
          { field, path: defaultPathMap[field] },
        ]);
        const checkbox = document.getElementById(
          `${defaultPathMap[field]}-checkbox`
        );
        if (checkbox) {
          checkbox.disabled = true;
          checkbox.checked = true;
          setDisabledCheckboxes((prev) => [
            ...prev,
            `${defaultPathMap[field]}-checkbox`,
          ]);
        }
        setCurrentStep(currentStep + 1);
      } else {
        setSelectedFields([...selectedFields, { field, path }]);
        setCurrentStep(currentStep + 1);
        setDisabledCheckboxes((prev) => [...prev, checkboxId]);
      }
    } else {
      if (!path && !checkboxId) {
        setSelectedFields([
          ...selectedFields,
          { field, path: defaultPathMap[field] },
        ]);
        setCurrentStep(currentStep + 1);
      } else {
        setSelectedFields([...selectedFields, { field, path }]);
        setDisabledCheckboxes((prev) => [...prev, checkboxId]);
      }
    }
  };

  const handleCheckboxChange = (event, nestedPath) => {
    const isChecked = event.target.checked;
    const parentElement = event.target?.parentNode?.parentNode;
    if (parentElement) {
      const checkboxes = parentElement.querySelectorAll(
        `input[type="checkbox"]`
      );
      checkboxes.forEach((checkbox) => {
        checkbox.disabled = isChecked;
      });
    }

    if (!nestedPath.includes(".")) {
      const childCheckboxes = document.querySelectorAll(
        `input[id^="${nestedPath}-checkbox-"]`
      );
      childCheckboxes.forEach((checkbox) => {
        checkbox.disabled = isChecked;
        checkbox.checked = false;
      });
    } else {
      const parentCheckbox = document.getElementById(
        nestedPath.split(".").slice(0, -1).join(".") + "-checkbox"
      );
      if (parentCheckbox) {
        parentCheckbox.disabled = isChecked;
        parentCheckbox.checked = false;
      }
    }

    handleFieldSelection(
      fields[currentStep],
      nestedPath,
      nestedPath + "-checkbox"
    );
  };

  const renderData = (data, parentPath = "", level = 0) => {
    return Object.entries(data).map(([key, value], index) => {
      const nestedKey = key;
      const nestedPath = parentPath ? `${parentPath}.${nestedKey}` : nestedKey;
      const isObject = typeof value === "object" && value !== null;
      const isMinimized = minimized[nestedKey];
      const toggleIcon = isMinimized ? "+" : "-";
      const toggleClickHandler = () => {
        toggleMinimized(nestedKey);
      };

      const checkboxId = `${nestedPath}-checkbox`;

      return (
        <div key={nestedPath}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: `${level * 20}px`,
              cursor: isObject ? "pointer" : "default",
            }}
          >
            <input
              type="checkbox"
              id={checkboxId}
              onChange={(event) => handleCheckboxChange(event, nestedPath)}
              disabled={disabledCheckboxes.includes(checkboxId)}
              checked={disabledCheckboxes.includes(checkboxId)}
            />
            <p
              onClick={isObject ? toggleClickHandler : null}
              style={{
                marginLeft: "10px",
                fontSize: "14px",
                cursor: isObject ? "pointer" : "default",
                fontWeight:
                  defaultPathMap[fields[currentStep]] === nestedPath ? 600 : "",
                color:
                  defaultPathMap[fields[currentStep]] === nestedPath
                    ? "#5daaa1"
                    : "",
              }}
            >
              {isObject ? toggleIcon : ""} {nestedKey}:<br />
              <span
                className="product-feed-value-span"
                style={{ marginLeft: "2em", display: "block" }}
              >
                {!isObject && `${value}`}
              </span>
            </p>
          </div>
          {!isMinimized && isObject && renderData(value, nestedPath, level + 1)}
        </div>
      );
    });
  };

  function convertObject(inputObject) {
    let outputObject = {};
    inputObject.forEach((item) => {
      let field = item["field"];
      let path = item["path"].split(".");
      if (path[0] === "") return;
      let currentObj = outputObject;
      for (let i = 0; i < path.length; i++) {
        let key = path[i];
        if (i === path.length - 1) {
          currentObj[key] = field;
        } else {
          if (!currentObj[key]) {
            const nextKeyIsNumeric = !isNaN(parseInt(path[i + 1]));
            currentObj[key] = nextKeyIsNumeric ? {} : [];
          } else if (Array.isArray(currentObj[key])) {
            const nextKeyIsNumeric = !isNaN(parseInt(path[i + 1]));
            if (nextKeyIsNumeric) {
              currentObj[key] = {};
            }
          }
          currentObj = currentObj[key];
        }
      }
    });

    return outputObject;
  }

  const handleSave = async (urlAttributeParams = false, variants = false) => {
    const defaultMap = convertObject(selectedFields);

    const requestJson = {
      action: 18,
      subAction: 1,
      fieldMapping: JSON.stringify(defaultMap),
      host: domain,
      credentials: context.loadCredentials(),
    };

    if (urlAttributeParams) {
      requestJson["attributeConfs"] = JSON.stringify(urlAttributeParams);
    } else if (variants) {
      requestJson["attributeConfs"] = JSON.stringify(variants);
    }

    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        await updateIntegrationState(11, context);

        const pullingData = async (startTime) => {
          const maxTime = 1600000;
          const currentTime = new Date().getTime();

          if (currentTime - startTime >= maxTime) {
            const emailReq = {
              name: sessionStorage.getItem("contactName") || "",
              email: sessionStorage.getItem("name") || "",
              domain: domain,
              message:
                "Error: polling data - at connect your data step 2 after update",
            };
            formFetch(emailReq)
              .then((r) => {
                console.log("email sended successfully", r);
              })
              .catch((e) => {
                console.error("error sending email:", e);
              });
            handleSelectAgain();
            return context.message({
              type: "error",
              body: "Error by getting your products",
            });
          }

          const req = {
            action: 20,
            host: domain,
            credentials: context.loadCredentials(),
          };

          const res = await context.myFetch(req);
          if (!res.actionStatus) {
            return;
          }
          if (res.noDataYet) {
            setTimeout(() => pullingData(startTime), 1000);
          } else {
            if (res.pollingData) {
              const data = JSON.parse(res.pollingData);
              if (data.length > 0) {
                setPageData(data[0]);
                const findCurrency = (data) => {
                  for (let i = 0; i < data.length; i++) {
                    if (data[i]?.pageData?.product?.currency) {
                      return data[i].pageData.product.currency;
                    }
                  }
                  return "";
                };

                const currencyR = findCurrency(data[0]);
                setCurrency(currencyR);
              }
            }
          }
        };
        pullingData(new Date().getTime());
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
      console.error("Error:", error);
    }
  };

  const handleSelectAgain = () => {
    setSelectedFields([]);
    setDisabledCheckboxes([]);
    setCurrentStep(0);
  };

  const handleClickSave = async () => {
    if (+sessionStorage.getItem("affStatus") === 1) {
      await updateIntegrationState(11, context);
      navigate("/selfserve/11");
    } else {
      await updateIntegrationState(4, context);
      navigate("/selfserve/4");
    }
  };

  return (
    <>
      {currentStep < fields.length || displayVarPage ? (
        <>
          <div className="hdy-connect-data-desc">
            {fields[currentStep] === "keywords" ? (
              <div>
                <p className="select-feed-paragraph">
                  Select the field for:
                  <span>{fields[currentStep]}</span>
                  <InfoTooltip
                    iconStyle={{ color: "#616060" }}
                    text="Select multiple fields from your product feed to improve search indexing with relevant keywords."
                  />
                </p>
                or use our default selection for this field by click:
                <IconButton
                  onClick={() => handleFieldSelection(fields[currentStep])}
                >
                  <CheckCircleOutlineRoundedIcon sx={{ color: "#ffba41" }} />
                </IconButton>
              </div>
            ) : (
              !displayVarPage && (
                <>
                  <div>
                    <p className="select-fields-description">
                      Select the appropriate checkbox corresponding to each XML
                      field. For instance, for the{" "}
                      <span className="field-active">'Id'</span> key, choose the
                      checkbox labeled{" "}
                      <span className="field-active">'id: "123"'</span>. Ensure
                      correct matches for accurate data representation.
                    </p>
                  </div>
                  <p className="select-feed-paragraph">
                    Select the field for:
                    <span>{fields[currentStep]}</span>
                  </p>
                  or use our default selection for this field by click:
                  <IconButton
                    onClick={() => handleFieldSelection(fields[currentStep])}
                  >
                    <CheckCircleOutlineRoundedIcon sx={{ color: "#ffba41" }} />
                  </IconButton>
                </>
              )
            )}
            {displayVarPage && (
              <Variants
                parentField={parentField}
                setDisplayAttributesPage={setDisplayAttributesPage}
                variantName={variantName}
                setVariantName={setVariantName}
                variantValue={variantValue}
                setVariantValue={setVariantValue}
                variants={variants}
                setVariants={setVariants}
                setDisplayVarPage={setDisplayVarPage}
                variantsStep={variantsStep}
                setVariantsStep={setVariantsStep}
                handleSaveCustomParse={handleSave}
              />
            )}
          </div>
          {(displayVarPage && variantsStep === 0) ||
          (displayVarPage && variantsStep === 3) ? null : (
            <>
              <div className="next-prev-feed-item-container">
                <IconButton
                  sx={{ borderRadius: "100px" }}
                  onClick={prevProduct}
                >
                  <KeyboardArrowLeft />
                </IconButton>
                <p style={{ color: "#616060" }}>
                  Feed item: [{productIndex + 1}/{products.length}]
                </p>
                <IconButton
                  sx={{ borderRadius: "100px" }}
                  onClick={nextProduct}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </div>
              <div className="connect-data-product-feed-container">
                <div className="product-feed">
                  <div>{renderData(products[productIndex])}</div>
                </div>
                {fields[currentStep] === "keywords" && (
                  <button
                    className="done-btn-keywords"
                    onClick={() => {
                      setCurrentStep(currentStep + 1);
                      // setDisplayAttributesPage(true);
                      // setDisplayVarPage(true);
                    }}
                  >
                    Done
                  </button>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        !displayAttributesPage && (
          <>
            {pageData.length === 0 && (
              <HeydayCardLoading loading={pageData.length === 0} />
            )}
            {pageData.length > 0 && currency === "" && (
              <Box textAlign="center" mt={4}>
                <p className="select-fields-description">
                  We can't find your currency in your feed.
                </p>
                <p className="select-fields-description">
                  Please select your currency:
                </p>
                <CurrencySelector
                  onCurrencyChange={(selectedCurrency) =>
                    setCurrency(selectedCurrency)
                  }
                />
              </Box>
            )}
            {pageData.length > 0 && currency !== "" && (
              <>
                <div style={{ margin: "20px auto" }}>
                  After your update here are some of the products parsed from
                  your products feed.
                </div>
                <PageDataCard pageData={pageData} />
                <div className="hdy-footer-card">
                  <Button
                    onClick={handleSelectAgain}
                    className="btn-select-again"
                  >
                    Select Again
                  </Button>
                  <Button onClick={handleClickSave} className="btn-save">
                    Save
                  </Button>
                </div>
              </>
            )}
          </>
        )
      )}
      {displayAttributesPage && (
        <AttributesPage
          selectedFields={selectedFields}
          products={products}
          setDisplayAttributesPage={setDisplayAttributesPage}
          handleSaveCustomParse={handleSave}
        />
      )}
    </>
  );
};

export default ProductFeed;
