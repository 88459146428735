import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../../../Context/Context";
import HeyDayCard from "../../../../Shared/Components/Card/SelfServeCard";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { IconButton, Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ViewDomainMeta from "../../../Admin/Dashboard/EditDomain/Tabs/ViewDomainMeta/ViewDomainMeta";
import { updateIntegrationState } from "../../helpers/helpers";
import InviteMembersModal from "../../../Admin/ManageAccount/InviteMembers/InviteMembersModal";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";

const MetaTag = () => {
  const integrationStep = +sessionStorage.getItem("integrationState") || 0;
  const affStatus = +sessionStorage.getItem("affStatus") || 0;
  const context = useContext(GlobalContext);
  const domain = sessionStorage.getItem("currentDomain");
  const domainObj = context.getDomains().find((o) => o.domain === domain);
  let configs = [];
  if (domainObj) {
    configs = JSON.parse(domainObj.configs);
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stepModal, setStepModal] = useState(0);
  const navigate = useNavigate();

  const handleClickNext = async () => {
    await updateIntegrationState(0, context);
    await handleChangeActiveSM();
    navigate("/selfserve/6");
  };
  const handleClickInvite = () => {
    setStepModal(0);
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (integrationStep < 5 && integrationStep > 0 && affStatus !== 1) {
      navigate("/selfserve/4");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeActiveSM = async () => {
    if (!Array.isArray(configs) || configs.length === 0) {
      context.message({
        type: "error",
        body: "Configurations are not properly loaded.",
      });
      return;
    }

    const updatedConfig = [...configs];
    const smConfIndex = updatedConfig.findIndex(
      (item) => item?.searchMore !== undefined
    );

    if (smConfIndex !== -1) {
      updatedConfig[smConfIndex].searchMore = 2;
    } else {
      updatedConfig.push({ searchMore: 2 });
    }

    const host = sessionStorage.getItem("currentDomain");

    if (!host) {
      context.message({
        type: "error",
        body: "No current domain found in session storage.",
      });
      return;
    }

    try {
      const requestJson = {
        action: 1014,
        host: host,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };

      const responseJson = await context.myFetch(requestJson);

      if (responseJson.domains) {
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );

        setTimeout(() => {
          context.message({
            type: "success",
            body: "Search More is now active",
          });
        }, 800);
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error.message,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div className="logout-container">
        <IconButton onClick={() => context.logout()}>
          <LogoutIcon />
        </IconButton>
        <span>Log Out</span>
      </div>
      <HeyDayCard
        contentStyle={{ position: "relative", minHeight: "530px" }}
        sx={{
          width: "100%",
          maxWidth: "880px",
          position: "relative",
          marginTop: "100px",
          boxShadow: {
            xs: "unset",
            md: "0px 5px 7px rgba(113, 113, 113, 0.25)",
          },
        }}
      >
        <ProgressBar stepName={"preview"} progressPercentage={"80"} />
        <ViewDomainMeta domain={domain} isNewDomain={true} />
        <div className="hdy-footer-card">
          <div className="hdy-back-link-container">
            <span>
              <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            </span>
            <Link to="/selfserve/11" className="hdy-back-link">
              Back
            </Link>
          </div>
          <Button onClick={handleClickNext} className="hdy-next-btn">
            Active Search More
          </Button>
        </div>
      </HeyDayCard>
      <InviteMembersModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        step={stepModal}
        setStep={setStepModal}
      />
      <div className="invite-btn-container">
        <Button
          onClick={handleClickInvite}
          className="invite-btn"
          startIcon={
            <PersonAddAltOutlinedIcon
              sx={{
                color: "#5daaa1",
                backgroundColor: "#ffffff",
                borderRadius: "25px",
                padding: "5px",
              }}
            />
          }
        >
          Invite members
        </Button>
      </div>
    </div>
  );
};

export default MetaTag;
