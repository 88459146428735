/* eslint-disable no-useless-escape */
var defaultTmpl = {
  htmlBox: `<div class="hd-s-main">
    <%if(obj.logo){%>
      <div class="header">
        <a hre="<%=obj.home%>"><img class="logo" src="<%=obj.logo%>" /></a>
      </div>
    <%}%>
    <div class="search_bar-fixed">
      <button class="closeSearch">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
          <path class="closeSvgPath" d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" stroke="#3b78e6"/>
        </svg>
      </button>
      <button class="searchBtn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" class=""></path>
        </svg>
      </button>
      <input type="text" class="HeyDay_q" placeholder="[!searchBoxText]" />
      <div class="autoComplete_container">
        <div class="autoComplete"></div>
        <div class="instantSearch"></div>
      </div>
      <button class="clearIcon">
        <svg width="12px" height="12px">
          <line x1="0%" y1="0%" x2="100%" y2="100%" style="stroke:#828282;stroke-width:2" />
          <line x1="0%" y1="100%" x2="100%" y2="0%" style="stroke:#828282;stroke-width:2" />
        </svg>
      </button>
    </div>
    <div class="searchResults"></div>
    <div class="paging"></div>
  </div>
`,
  results_data: `<div class="filters-container">
      <div class="filter-bar">
        <div id="attributes" class="dropdown">
          <div id="brand" class="it_attribute">
            <button class="dropbtn">
              <h4 class="section-title">[!brands] <i class="fa fa-caret-down caret-icon"></i></h4>
            </button>
            <div class="dropdown-content">
              <div class="checkbox-div">
                <div class="clear-results">
                  <h5>[!brands]</h5>
                  <button id="clearBrandsBtn" class="clear-btn">[!clear]</button>
                </div>
                <div class="checkboxes-div"><%=obj.brands%></div>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div id="priceRange-dropdown" class="dropdown">
          <button class="dropbtn">
            <h4 class="section-title">[!priceRange] <i class="fa fa-caret-down caret-icon"></i></h4>
          </button>
          <div class="dropdown-content">
            <div>
              <div class="clear-results">
                <h5>[!priceRange]</h5>
                <button id="clearPriceRangeBtn" class="clear-btn">[!clear]</button>
              </div>
              <div class="priceRange-container"><%=obj.priceRange%></div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div id="categories" class="dropdown">
          <button class="dropbtn">
            <h4 class="section-title">[!categories] <i class="fa fa-caret-down caret-icon"></i></h4>
          </button>
          <div class="dropdown-content">
            <div class="checkbox-div">
              <div class="clear-results">
                <h5>[!categories]</h5>
                <button id="clearCategoriesBtn" class="clear-btn">[!clear]</button>
              </div>
              <div class="checkboxes-div"><%=obj.categories%></div>
            </div>
          </div>
        </div>
        <div class="select-dropdown">
          <div class="select-dropdown-header" class="selectDropdownMenuButton">
            <span class="selected">
              <% if (obj.sortBySelected === "relevance") { %>
                [!relevance]
              <% } else if (obj.sortBySelected === "price_asc") { %>
                [!priceLTH]
              <% } else if (obj.sortBySelected === "price_desc") { %>
                [!priceHTL]
              <% } else { %>
                [!sortBy]
              <% } %>
            </span>
            <i class="arrow"></i>
            <div class="select-dropdown-menu" class="selectDropdownMenu">
              <div class="select-dropdown-item" data-value="relevance">[!relevance]</div>
              <div class="select-dropdown-item" data-value="price_asc">[!priceLTH]</div>
              <div class="select-dropdown-item" data-value="price_desc">[!priceHTL]</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,

  autocomplete_item: `
  <li class="ACel">
    <div class="clockIcon">
      <svg class="[!AC_item_left]" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.16016" cy="8" r="7" stroke="#C5C5C5" stroke-opacity="0.9" stroke-width="2"/>
        <path d="M8.16016 4V9L11.6602 11" stroke="#C5C5C5" stroke-opacity="0.9" stroke-width="2"/>
      </svg>
      <span class="removeHistory">x</span>
    </div>
    <div class="searchIcon">
      <svg class="[!AC_item_right]" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83207 0.984141C4.96202 1.0875 4.35371 1.25898 3.64191 1.60149C2.96611 1.92667 2.52673 2.23768 1.95867 2.79296C1.05462 3.67665 0.496543 4.69042 0.212303 5.96539C0.120315 6.37782 0.108825 6.5044 0.100368 7.19554C0.0922333 7.85858 0.102108 8.02453 0.171291 8.38602C0.651277 10.8948 2.47623 12.8131 4.95331 13.4124C5.34054 13.5061 5.48426 13.5214 6.16444 13.5417C6.82007 13.5612 7.00245 13.5541 7.37615 13.4945C8.08951 13.3809 8.89938 13.1003 9.49288 12.7613L9.76976 12.6031L11.8686 14.733C14.2542 17.1537 14.1426 17.0617 14.6978 17.067C15.1018 17.0709 15.3807 16.9551 15.6572 16.6688C15.935 16.381 16.0093 16.1988 16.0129 15.7963C16.0182 15.2104 16.1329 15.3563 13.7454 12.9113L11.6465 10.7619L11.7806 10.5431C12.1444 9.94978 12.4465 9.15268 12.5862 8.4177C12.6589 8.03483 12.6675 7.8887 12.6592 7.17643C12.6491 6.31096 12.6021 5.99703 12.3785 5.30231C11.8785 3.74885 10.6761 2.35189 9.22607 1.63997C8.36796 1.21869 7.57022 1.02093 6.6028 0.989628C6.24964 0.978222 5.90282 0.975761 5.83207 0.984141ZM7.23499 2.97938C8.7562 3.26349 10.0699 4.42702 10.5527 5.91786C11.2134 7.95761 10.2827 10.2015 8.37822 11.161C7.6446 11.5306 6.99951 11.6701 6.20099 11.6318C5.60565 11.6033 5.24261 11.5278 4.75058 11.3303C3.85166 10.9695 3.04026 10.257 2.55 9.39794C1.96944 8.38063 1.84396 6.96783 2.23547 5.85705C2.46813 5.19703 2.83436 4.61915 3.32014 4.14545C4.36404 3.1276 5.77639 2.70694 7.23499 2.97938Z" fill="#C5C5C5" fill-opacity="0.9"/>
      </svg>
    </div>
    <%=obj.item%>
  </li>
`,
  instant_search_item: `
  <a class="Rlink" onclick="navigateToUrl('<%=obj.clickUrl%>')";">
    <div class="IRmain <%=obj.phz%>">
      <div class="IRimgC"><img src="<%=obj.pd.img%>" class="IRimg" /></div>
      <div class="instant-text">
        <%if(obj.pd.title){%>
          <div class="Rtitle"><%=obj.pd.title%></div>
        <%}%>
        <%if(obj.pd.product && obj.pd.product.sale_price){%>
          <div class="Rprice_before"><%= obj.pd.product.currency %> <%=obj.pd.product.price%></div>
          <div class="Rprice"><%= obj.pd.product.currency %> <%=obj.pd.product.sale_price%></div>
        <%} else {%>
          <div class="Rprice"><%= obj.pd.product.currency %> <%=obj.pd.product.price%></div>
        <%}%> 
        <%if(obj.pd.brand){%>
          <div class="Rbrand"><%=obj.pd.brand%></div>
        <%}%>
      </div>
    </div>
  </a>
`,
  search_item: `
  <div class="Rlink">
    <div class="Rmain <%=obj.phz%>">
      <div class="slideshow-container">
        <% if (obj.pd.additional_images && obj.pd.additional_images.length > 0) { %>
          <% obj.pd.additional_images.unshift(obj.pd.img); %>
          <% obj.pd.additional_images.forEach(function(image, index) { %>
            <div class="RimgC fade slide">
              <a href="<%=obj.clickUrl%>" target="_parent"><img src="<%= image %>" <%if(obj.defaultImg){%> onerror="onErrorImg(this,\'<%=obj.defaultImg%>\')"<%}%> class="Rimg loading" /></a>
            </div>
          <% }); %>
          <a class="prev"></a>
          <a class="next"></a>
        <% } else { %>
          <div class="RimgC">
            <a href="<%=obj.clickUrl%>" target="_parent"><img src="<%= obj.pd.img %>" <%if(obj.defaultImg){%> onerror="onErrorImg(this,\'<%=obj.defaultImg%>\')"<%}%> class="Rimg loading" /></a>
          </div>
        <% } %>
      </div>
      <div class="dots-container">
        <% if (obj.pd.additional_images && obj.pd.additional_images.length > 0) { %>
          <% obj.pd.additional_images.forEach(function(_, index) { %>
            <span class="dot <%= index === 0 ? 'imgActive' : '' %>"></span>
          <% }); %>
        <% } %>
      </div>
      <div class="results-box">
        <% if(obj.pd.brand){ %>
          <div class="Rbrand loading"><%= obj.pd.brand %></div>
        <% } else { %>
          <div class="Rbrand loading"></div>
        <% } %>
        <div class="Rtitle loading"><%= obj.pd.title %></div>
        <% if(obj.pd.product && obj.pd.product.sale_price){ %>
          <div class="Rprice_before loading"><%= obj.pd.product.currency %> <%= obj.pd.product.price %></div>
          <div class="Rprice loading"><%= obj.pd.product.currency %> <%= obj.pd.product.sale_price %></div>
        <% } else { %>
          <% if(obj.pd.product && obj.pd.product.price && obj.pd.product.currency){ %>
            <div class="Rprice loading"><%= obj.pd.product.currency %> <%= obj.pd.product.price %></div>
          <% } %>
        <% } %>
        <div class="add-to-cart-container"> 
          <div class="add-qty">
            <div class="quantity-control" data-quantity="">
              <button class="quantity-btn" onclick="decreaseQuantity(this)">
                <svg viewBox="0 0 409.6 409.6">
                  <g>
                    <g>
                      <path d="M392.533,187.733H17.067C7.641,187.733,0,195.374,0,204.8s7.641,17.067,17.067,17.067h375.467 c9.426,0,17.067-7.641,17.067-17.067S401.959,187.733,392.533,187.733z" />
                    </g>
                  </g>
                </svg>
              </button>
              <input type="number" class="quantity-input" data-quantity-target="" value="1" step="1" min="1" max="" name="quantity">
              <button class="quantity-btn" onclick="increaseQuantity(this)">
                <svg viewBox="0 0 426.66667 426.66667">
                  <path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0" />
                </svg>
              </button>
            </div>
          </div>
          <div class="add-to-cart-btn" data-product-id=\"<%= obj.pd.id %>\" data-product-url=\"<%=obj.clickUrl%>\" onclick="handleAddToCart(this)">הוספה לסל</div>
        </div>
      </div>
    </div>
  </div>
`,

  ellipsis_format1: `[!ellipsisF]`,
  ellipsis_format2: `[!ellipsisL]`,
  results_without: `<p class="res_p">[!resWithout]: <b><%=obj.nr%></b><br />[!approx] <%=obj.res%> [!resFound]</p>`,
  approximately_found: `<p class="res_p">[!approx] <%=obj.res%> [!resFound] [!for] "<%=obj.q%>"</p>`,
  no_results: `<p class="noRes">[!noRes] <b><%=obj.q%>`,
  spelling_suggestions: `<p class="hd-s-syn">[!didYouMean] 
  <span class="c-p" onclick="search({q:\'<%=obj.q%>\', dym:true})">\"<%=obj.v%>\"</span>?
  </p><br/>`,
  spelling_correction: `<div>
    <p class="hd-s-syn fn-12">Showing results for <b><i><%=obj.c%></i></b></p>
    <p class="hd-s-syn">Display results for <span class="c-p" onclick="search({f:true})">\"<%=obj.q%>\"</span> instead</p>
    </div>`,
  paging_item: `<<%if(obj.active){%>span<%}else{%>a<%}%> href="<%=obj.href%>" data-p="<%=obj.href%>" title="<%=obj.title%>" class="<%=obj.active?'active':'do-search'%>"><%=obj.title%></<%if(obj.active){%>span<%}else{%>a<%}%>>`,
  autocomplete_holder: "ul",
  price_range: `
  <form class="priceRange-form" onsubmit="search(); return false;">
    <div class="filter-price-range-container">
      <% if (obj.rangeFilter) { %>
        <input placeholder="[!from]" min="1" type="number" class="rangeFrom pRange" value="<%= obj.rangeFilter[1]/10 %>">
        <input type="number" min="1" placeholder="[!to]" class="rangeTo pRange" value="<%= obj.rangeFilter[2]/10 %>">
      <% } else { %>
        <input placeholder="[!from]" min="1" type="number" class="rangeFrom pRange">
        <input type="number" min="1" placeholder="[!to]" class="rangeTo pRange">
      <% } %>
      <button type="submit" class="filterRangeBtn">[!filter]</button>
    </div>
  </form>
`,
  price_range_slider: `
  <div class="d-flex">
    <div class="wrapper">
      <div class="price-input">
        <div class="field">
          <span>Min</span>
          <% if (obj.rangeFilter || obj.catData.ranges.price) { %>
            <input type="number" id="rangeFrom" class="input-min" value="<%= obj.catData.ranges.price[0]/10 %>">
          <% } %>
        </div>
        <div class="separator">-</div>
        <div class="field">
          <span>Max</span>
          <% if (obj.rangeFilter || obj.catData.ranges.price) { %>
            <input type="number" id="rangeTo" class="input-max" value="<%= obj.catData.ranges.price[1]/10 %>">
          <% } %>
        </div>
      </div>
      <div class="slider">
        <div class="progress"></div>
      </div>
      <div class="range-input">
        <% if (obj.rangeFilter || obj.catData.ranges.price) { %>
          <input type="range" class="range-min" min="<%= obj.catData.ranges.price[0]/10 %>" max="<%= obj.catData.ranges.price[1]/10 %>" value="<%= obj.catData.ranges.price[0]/10 %>" step="1">
          <input type="range" class="range-max" min="<%= obj.catData.ranges.price[0]/10 %>" max="<%= obj.catData.ranges.price[1]/10 %>" value="<%= obj.catData.ranges.price[1]/10 %>" step="1">
        <% } %>
      </div>
    </div>
    <button class="filterRangeBtn">[!filter]</button>
  </div>
`,
};

export default defaultTmpl;
