import React from "react";
import WhatsAppIconMaterial from "@mui/icons-material/WhatsApp";

const WhatsAppIcon = ({ phoneNumber, message }) => {
  const handleWhatsAppClick = () => {
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        marginTop: "20px",
        alignItems: "center",
      }}
    >
      <p>Or click the WhatsApp icon to contact us: </p>
      <WhatsAppIconMaterial
        style={{ cursor: "pointer", marginBottom: "15px", marginLeft: "10px" }}
        onClick={handleWhatsAppClick}
      />
    </div>
  );
};

export default WhatsAppIcon;
