import React from "react";
import { Button, TextField, Typography } from "@mui/material";

const DomainField = ({ setDomainName }) => {
  return (
    <div className="urlField">
      <div>
        <Typography>
          URL*: (
          <span
            style={{
              fontFamily: "Poppins",
            }}
          >
            for example: https://www.yourwebsite.com
          </span>
          )
        </Typography>
        <TextField
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              height: "44px",
              marginTop: "20px",
              backgroundColor: "#fff",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "100px",
              fontFamily: "Poppins",
              "& fieldset": {
                borderColor: "white",
                border: "none",
              },
            },
          }}
          inputProps={{
            sx: {
              "&::placeholder": {
                color: "#9a9b9d",
              },
            },
          }}
          onChange={(event) => setDomainName(event.target.value.trim())}
          placeholder="Domain name"
          fullWidth
        />
      </div>
    </div>
  );
};

export default DomainField;
