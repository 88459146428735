var tmplPlaceHoldersArabic = {
  resWithout: "النتائج بدون",
  approx: "تقريبًا",
  resFound: "تم العثور على نتائج",
  noRes: "لم يتم العثور على نتائج لـ",
  didYouMean: "هل تقصد",
  prevPage: "السابق",
  nextPage: "التالي",
  AC_item_left: "AC_item_left",
  AC_item_right: "AC_item_right",
  searchBoxText: "بحث",
  missing: "مفقود",
  must_include: "يجب أن تتضمن",
  for: "لـ",
  brands: "العلامات التجارية",
  clear: "مسح",
  priceRange: "النطاق السعري",
  categories: "الفئات",
  sortBy: "ترتيب حسب",
  relevance: "الصلة",
  priceLTH: "السعر: من الأدنى إلى الأعلى",
  priceHTL: "السعر: من الأعلى إلى الأدنى",
  filter: "تصفية",
  to: "إلى",
  from: "من",
  ellipsisF: "...",
  ellipsisL: "...",
  dateN: "التاريخ: الأحدث أولاً",
  dateL: "التاريخ: الأقدم أولاً",
  forMoreRes: "لمزيد من النتائج",
  minPrice: "السعر الأدنى",
  maxPrice: "السعر الأقصى",
  showFilters: 'إظهار الفلاتر',
  hideFilters: 'إخفاء الفلاتر'
};

export default tmplPlaceHoldersArabic;
