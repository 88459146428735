import React, { useState } from "react";
import {
  IconButton,
  Box,
  Tooltip,
  Popover,
  Button,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HeyDayFormLabel from "../../../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";

const DisplayTmplElements = ({ item, index, toggleEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ width: "100%" }} className="config-elements" key={index}>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <HeyDayFormLabel sx={{ display: "flex", alignItems: "center" }}>
          {item.textAreaLabel}
          {item.isTmpl && (
            <Tooltip title="Copy Placeholder">
              <IconButton
                onClick={handlePopoverClick}
                size="small"
                sx={{ ml: 1 }}
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </HeyDayFormLabel>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClick}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          PaperProps={{
            style: { maxWidth: "450px", maxHeight: "400px" },
          }}
        >
          <Typography sx={{ p: 1 }}>{item.textAreaPlaceholder}</Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
            <Button
              sx={{
                color: "var(--Green, #5daaa1)",
                fontFamily: "Poppins",
                textTransform: "none",
              }}
              startIcon={<ContentCopyIcon />}
              onClick={() => copyToClipboard(item.textAreaPlaceholder)}
              size="small"
            >
              Copy
            </Button>
          </Box>
        </Popover>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <textarea
            style={{
              flex: "1",
              marginTop: "15px",
              marginRight: "10px",
              fontFamily: "Poppins",
              fontSize: "14px",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "10px",
              minHeight: "200px",
              maxWidth: "100%",
              outlineColor: "#5daaa1",
            }}
            id={item.textAreaId}
            placeholder={item.textAreaPlaceholder}
            defaultValue={item.value}
            onChange={(e) => item.setter(e.target.value)}
            disabled={item.textAreaIdEdit}
          />
          <IconButton
            style={{ marginTop: "15px" }}
            onClick={() => toggleEdit(item.textAreaId)}
            disabled={!item.textAreaIdEdit}
          >
            <EditIcon />
          </IconButton>
        </div>
      </Box>
    </div>
  );
};

export default DisplayTmplElements;
