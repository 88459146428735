import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HeyDayCard from "../../../../Shared/Components/Card/SelfServeCard";
import ProgressBar from "../../ProgressBar/ProgressBar";
import LogoutIcon from "@mui/icons-material/Logout";
import GlobalContext from "../../../../Context/Context";
import InviteMembersModal from "../../../Admin/ManageAccount/InviteMembers/InviteMembersModal";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { updateIntegrationState } from "../../helpers/helpers";
import { initializeSearch } from "../../../Admin/Search/SearchPreview/helpers";
import { IconButton, Button } from "@mui/material";
import "./SearchPreview.css";
import { formFetch, makePostRequest } from "./helpers";
import HeydayCardLoading from "../../../Admin/Loading/HeydayCardLoading";

const SearchPreview = () => {
  const context = useContext(GlobalContext);
  const navigate = useNavigate();
  const integrationStep = +sessionStorage.getItem("integrationState") || 0;
  const affStatus = +sessionStorage.getItem("affStatus") || 0;
  const domain = context.getDomains()[context.state.domainIndex];
  const configs = JSON.parse(domain.configs) || [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stepModal, setStepModal] = useState(0);
  const [indexSize, setIndexSize] = useState(
    +sessionStorage.getItem("indexSize") || 0
  );
  const [prevSize, setPrevSize] = useState(
    +sessionStorage.getItem("prevSize") || 0
  );
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [activateSearchBox, setActivateSearchBox] = useState(true);
  const [counterExists, setCounterExists] = useState(true);
  const [acPopupOpen, setACPopupOpen] = useState(false);
  const [showIndPopup, setShowIndPopup] = useState(false);
  const [timeLeft, setTimeLeft] = useState(210);
  const [maxTimeToIndex, setMaxTimeToIndex] = useState(360);
  const [loading, setLoading] = useState(true);
  const pDomain = sessionStorage.getItem("pDomain");
  const pFeedUrl = sessionStorage.getItem("pFeedUrl");
  // const isNewDomain = sessionStorage.getItem("new_domain");
  const isNewDomain = true;
  const previewCounter = +sessionStorage.getItem("previewCounter") || 0;

  const getIndexSize = async () => {
    const requestJson = {
      action: 44,
      host: domain?.domain,
      credentials: context.loadCredentials(),
    };
    const response = await context.myFetch(requestJson);
    if (response && !response.error) {
      if (response.totalDocs > 0 && activateSearchBox) {
        setLoading(true);
        setTimeout(async () => {
          setActivateSearchBox(false);
          let currentUrl = new URL(window.location.href);
          let searchParams = currentUrl.searchParams;
          if (!searchParams.has("hdyQ")) {
            let defaultParse = {};
            try {
              defaultParse =
                JSON.parse(localStorage.getItem("defaultParse")) || [];
            } catch (e) {
              defaultParse = [];
            }
            if (defaultParse.length === 0) {
              navigate("/selfserve/5");
            }
            await loadQuery(defaultParse, 0);
          }
          setLoading(false);
          initializeSearch({
            domain: domain?.domain,
            setACPopupOpen,
            domainIndex: context.state.domainIndex,
            _Context: context,
            setIsScriptLoaded,
          });
          setTimeout(() => {
            if (window._heyDayInterface)
              window._heyDayInterface.methods.activateSearchBox();
          }, 2000);
        }, 12000);
      }
      setIndexSize(response.totalDocs);
      sessionStorage.setItem("indexSize", response.totalDocs);
    }
  };

  useEffect(() => {
    if (integrationStep < 5 && integrationStep > 0 && affStatus !== 1) {
      navigate("/selfserve/4");
    }
    if (affStatus === 1) {
      const startIndexReq = {
        action: 1021,
        host: domain?.domain,
        credentials: context.loadCredentials(),
      };
      context.myFetch(startIndexReq);
    }
    getIndexSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadQuery = async (defaultParse, index) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = defaultParse;
        if (data.length > 0 && data[0][index]) {
          if (data[0][index].title) {
            const title = data[0][index].title;
            const words = title.split(" ");
            if (words.length >= 1) {
              const word = words[0];
              await makePostRequest(word, data, index + 1, domain?.domain);
            }
          }
        }
        console.log("loadQuery completed");
        resolve();
      } catch (error) {
        console.error("Error in loadQuery:", error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (isNewDomain) {
      let counter = previewCounter;
      const interval = setInterval(() => {
        if (indexSize > 0) {
          clearInterval(interval);
          return;
        }

        if (indexSize === prevSize) {
          counter++;
          sessionStorage.setItem("previewCounter", counter);
        } else {
          counter = 0;
          sessionStorage.setItem("previewCounter", counter);
          sessionStorage.setItem("prevSize", indexSize);
          setPrevSize(indexSize);
        }

        if (counter === 25) {
          setCounterExists(false);
          setShowIndPopup(true);
          sessionStorage.removeItem("new_domain");
          clearInterval(interval);
        }

        getIndexSize();
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [indexSize, prevSize]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    const maxTimeCountdown = setInterval(() => {
      setMaxTimeToIndex((prev) => {
        if (prev === 1 && indexSize === 0) {
          const requestJson = {
            name: sessionStorage.getItem("contactName") || "",
            email: sessionStorage.getItem("name") || "",
            domain: domain?.domain,
            message: "Error: indexing domain - at search preview",
          };
          formFetch(requestJson)
            .then((response) => {
              console.log("email sended successfully", response);
            })
            .catch((error) => {
              console.error("error sending email:", error);
            });
        }
        return prev > 0 ? prev - 1 : 0;
      });
    }, 1000);

    return () => {
      clearInterval(countdown);
      clearInterval(maxTimeCountdown);
    };
  }, [indexSize]);

  const handleClickNext = async () => {
    if(pDomain && pFeedUrl){
      await updateIntegrationState(0, context);
      await handleChangeActiveSM();
      navigate("/selfserve/6");
    }else{
      await updateIntegrationState(5, context);
      navigate("/selfserve/5");
    }
  };

  const handleClickInvite = () => {
    setStepModal(0);
    setIsModalOpen(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleChangeActiveSM = async () => {
    if (!Array.isArray(configs) || configs.length === 0) {
      context.message({
        type: "error",
        body: "Configurations are not properly loaded.",
      });
      return;
    }

    const updatedConfig = [...configs];
    const smConfIndex = updatedConfig.findIndex(
      (item) => item?.searchMore !== undefined
    );

    if (smConfIndex !== -1) {
      updatedConfig[smConfIndex].searchMore = 2;
    } else {
      updatedConfig.push({ searchMore: 2 });
    }

    const host = sessionStorage.getItem("currentDomain");

    if (!host) {
      context.message({
        type: "error",
        body: "No current domain found in session storage.",
      });
      return;
    }

    try {
      const requestJson = {
        action: 1014,
        host: host,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };

      const responseJson = await context.myFetch(requestJson);

      if (responseJson.domains) {
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );

        setTimeout(() => {
          context.message({
            type: "success",
            body: "Search More is now active",
          });
        }, 800);
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error.message,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div className="logout-container">
        <IconButton onClick={() => context.logout()}>
          <LogoutIcon />
        </IconButton>
        <span>Log Out</span>
      </div>

      <HeyDayCard
        contentStyle={{ position: "relative", minHeight: "530px" }}
        sx={{
          width: "100%",
          maxWidth: "880px",
          position: "relative",
          marginTop: "100px",
          boxShadow: {
            xs: "unset",
            md: "0px 5px 7px rgba(113, 113, 113, 0.25)",
          },
        }}
      >
        <ProgressBar stepName={"preview"} progressPercentage={"40"} />

        {indexSize === 0 || loading ? (
          <div
            style={{
              marginTop: "180px",
            }}
          >
            {timeLeft > 0 && (
              <div className="searchPreview-timer">
                Time left: {formatTime(timeLeft)}
              </div>
            )}
            {maxTimeToIndex === 0 ? (
              <div className="searchPreview-loading-message">
                Something went wrong. Out team is looking into it and will
                contact you shortly.
              </div>
            ) : (
              <div className="searchPreview-loading-message">
                Please wait - We are indexing your domain
                <div className="searchPreview-loading-dots">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <section className="content">
            <p>Let's see how it looks with your products!</p>
            <div id="searchPreview"></div>
          </section>
        )}
        <div className="hdy-footer-card">
          <div className="hdy-back-link-container">
            <span>
              <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            </span>
            <Link
              to={
                +sessionStorage.getItem("affStatus") === 1
                  ? "/selfserve/2"
                  : "/selfserve/4"
              }
              className="hdy-back-link"
            >
              Back
            </Link>
          </div>
          <Button onClick={handleClickNext} className="hdy-next-btn">
            {pDomain && pFeedUrl ? 'Active Search More' : 'Next'}
          </Button>
        </div>
      </HeyDayCard>

      <InviteMembersModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        step={stepModal}
        setStep={setStepModal}
      />
      <div className="invite-btn-container">
        <Button
          onClick={handleClickInvite}
          className="invite-btn"
          startIcon={
            <PersonAddAltOutlinedIcon
              sx={{
                color: "#5daaa1",
                backgroundColor: "#ffffff",
                borderRadius: "25px",
                padding: "5px",
              }}
            />
          }
        >
          Invite members
        </Button>
      </div>
    </div>
  );
};

export default SearchPreview;
