import React, { useState, useContext } from "react";
import {
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useParams } from "react-router-dom";
import GlobalContext from "../../../../../../Context/Context";
import { checkIntegration } from "../../../../Common/helpers";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./ViewDomainMeta.css";

const ViewDomainMeta = ({ domain, isNewDomain }) => {
  const [state, setState] = useState({ coreMethod: 0 });
  const context = useContext(GlobalContext);
  const params = useParams();
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const openMeta = (num) => {
    if (state.coreMethod === null || state.coreMethod !== num) {
      setState(() => ({
        coreMethod: num,
      }));
    } else if (state.coreMethod === num) {
      setState(() => ({
        coreMethod: null,
      }));
    }
  };

  const copyToClipboard = (event) => {
    const textarea = document.getElementById("text0");
    textarea.select();
    document.execCommand("copy");
    event.target.focus();
    context.message({
      type: "success",
      body: "Copied to clipboard successfully",
    });
  };

  const removeDomain = async () => {
    const requestJson = {
      action: 1026,
      host: domain || "",
      credentials: context.loadCredentials(),
    };
    const responseJson = await context.myFetch(requestJson);
    const response = responseJson;

    if (!response.error) {
      context.message({
        type: "success",
        body: "Domain was successfully deleted",
      });
      let allDoms = JSON.parse(sessionStorage.getItem("allDomains"));
      allDoms = allDoms.filter((doms) => doms.domain !== domain);

      sessionStorage.setItem("allDomains", JSON.stringify(allDoms));
      sessionStorage.setItem("currentDomainIndex", 0);
      sessionStorage.setItem("currentDomain", allDoms[0]?.domain || "");
      if (allDoms.length > 0) {
        let domArr = allDoms.map((item) => item.domain);
        sessionStorage.setItem("domains", domArr);
      } else {
        sessionStorage.removeItem("domains");
      }
      setTimeout(() => {
        window.location.href = `/dashboard/0`;
      }, 1500);
    } else {
      context.message({
        type: "error",
        body: response.error,
      });
    }
    handleCloseModal();
  };

  return (
    <div className="implement-js-code-container">
      <h2>Adding the HeyDay main Javascript tag:</h2>
      <p className="implement-js-description" style={{ userSelect: "none" }}>
        On each page where heyday is required, add to the head section of the
        html page the below javascript tag:
      </p>

      <div
        className={"domain-meta pos-relative"}
        style={{ margin: "0 0 4rem" }}
      >
        <div className="holder">
          <div
            style={{
              marginRight: "30px",
              opacity:
                state.coreMethod === null || state.coreMethod === 0 ? 1 : 0,
            }}
            className={
              state.coreMethod === 0
                ? "services services_first activeMethod"
                : "services services_first"
            }
            onClick={() => openMeta(0)}
          >
            <div>
              <img src="/img/js-shield.png" alt="javascript" />
              <div className="js-text">JavaScript</div>
            </div>
            <p>
              Install via <span>JavaScript</span>
            </p>
            <div>
              <Tooltip arrow title={`Javascript code`}>
                <span className="signupQuestion">?</span>
              </Tooltip>
              <span
                style={{
                  fontFamily:
                    "Helvetica Neue, Liberation Regular, Arial, Helvetica, sans-serif",
                }}
              >
                Help Docs
              </span>
            </div>
          </div>
        </div>
        <div
          className="holder_implementation"
          style={{
            zIndex: state.coreMethod === null ? -1 : 30,
            opacity: state.coreMethod === null ? 0 : 1,
          }}
        >
          <div>
            <div style={{ position: "relative" }}>
              <textarea
                id="text0"
                style={{ borderRadius: "5px", position: "relative" }}
                readOnly
                value={`<script src="https://cdn.heyday.io/cstmst/heyDayMain.js?affId=${sessionStorage.getItem(
                  "id"
                )}&d=${domain}" async type="text/javascript"></script>`}
              ></textarea>
              <Button
                className="copy-clipboard-btn"
                style={{ position: "absolute", right: "10px", bottom: "10px" }}
                onClick={copyToClipboard}
                endIcon={<ContentCopyIcon />}
              >
                Copy to clipboard
              </Button>
            </div>

            {!checkIntegration(params?.index, context) && (
              <p
                className="alert-verification"
                style={{ margin: "auto 0px 10px", color: "#ff4d4f" }}
              >
                Domain verification requires that heyDayMain.js will be in the
                root page. Meaning that in this url: {domain} the heyDayMain.js
                will be available exactly as described above.
              </p>
            )}
          </div>
        </div>
      </div>
      {!isNewDomain && (
        <>
          {" "}
          <Dialog
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="delete-domain-dialog"
          >
            <DialogTitle id="delete-domain-dialog">
              {"Domain delete confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete{" "}
                <b>{sessionStorage.getItem("currentDomain")}</b>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className="disagree-btn" onClick={handleCloseModal}>
                Disagree
              </Button>
              <Button className="agree-btn" onClick={removeDomain}>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button className="danger-btn" onClick={handleClickOpenModal}>
              Delete Domain
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewDomainMeta;
