import React, { useState, useContext } from "react";
import GlobalContext from "../../Context/Context";
import HeyDayCard from "../../Shared/Components/Card/SelfServeCard";
import { Box, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import "./Terms.css";
import TermsText from "./components/TermsText";
import HeyDayAutocomplete from "../../Shared/Components/TextField/HeyDayAutocomplete";
import HeyDayButton from "../../Shared/Components/Button/HeyDayButton";
import countriesList from "../../Shared/countries.json";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  updateIntegrationState,
  getIntegrationState,
} from "../../Components/SelfServe/helpers/helpers";
import { useNavigate } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Terms = ({ isSelfServe }) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const context = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isAgreed, setIsAgreed] = useState(false);
  const [countryCode, setCountryCode] = useState(undefined);
  const [errorScroll, setErrorScroll] = useState(false);
  const [errorCountry, setErrorCountry] = useState(false);

  const handleTerms = (e) => {
    const value = e?.target?.checked;
    setErrorScroll(!value);
    setIsAgreed(value);
  };

  const sendTerms = async () => {
    if (countryCode === undefined) {
      setErrorCountry(true);
      return;
    }
    if (!isAgreed) {
      setErrorScroll(true);
      return;
    }
    const requestJson = {
      action: 1047,
      countryCode: countryCode,
      credentials: context.loadCredentials(),
    };
    if (isAgreed && countryCode !== undefined) {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        response.TOS === 1 && sessionStorage.setItem("TOS", 1);
        context.message({
          type: "success",
          body: "Welcome To HeyDay Dashboard",
        });

        if (isSelfServe) {
          const integrationResponse = await getIntegrationState(context);
          if (integrationResponse.integrationState === 3 || integrationResponse.integrationState === 4) {
            setTimeout(() => {
              window.location.href = `/selfserve/3`;
            }, 1500);
          } else {
            await updateIntegrationState(2, context);
            setTimeout(() => {
              window.location.href = `/selfserve/2`;
            }, 1500);
          }
        } else {
          setTimeout(() => {
            navigate(`/`);
          }, 2000);
        }
      }
    }
  };

  const handleClickCheckboxWrapper = () => {
    if (!isAgreed && !isScrolledToBottom) {
      setErrorScroll(true);
      return;
    }
  };
  const errorStyle = errorScroll
    ? { border: "1px solid #d32f2f", borderRadius: "5px", marginTop: "5px" }
    : {};

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: isSelfServe ? "100vh" : "",
      }}
    >
      <Box className="login-main-container">
        <HeyDayCard
          sx={{ maxHeight: "800px", width: "100%", backgroundColor: "#ffffff" }}
        >
          <div className="hdy-card-title">Terms of Use Heyday.io</div>
          <div className="card-line"></div>
          <HeyDayAutocomplete
            id="hdy-country"
            onChange={(event, value) => {
              setCountryCode(value?.code);
              setErrorCountry(false);
            }}
            style={{ width: 250, marginTop: 30, marginBottom: "20px" }}
            options={countriesList}
            getOptionLabelKeys={["flag", "name"]}
            searchKeys={["name", "code", "dial_code"]}
            requiredField={true}
            label="Country"
            disablePortal
            error={errorCountry}
          />

          <TermsText
            setIsScrolledToBottom={setIsScrolledToBottom}
            error={errorScroll}
          />
          {errorScroll && (
            <div className="terms-arrow-container">
              <ArrowDownwardIcon className="arrow-down" />
              <div>
                <p>Please read all the terms</p>
                <p>and scroll to the bottom to accept them</p>
              </div>
            </div>
          )}
          <div className="card-line" style={{ marginTop: "20px" }}></div>
          <div style={{ ...errorStyle }} onClick={handleClickCheckboxWrapper}>
            <FormGroup>
              <FormControlLabel
                disabled={!isScrolledToBottom}
                control={
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    onChange={handleTerms}
                    checkedIcon={
                      <CheckCircleOutlineIcon style={{ color: "#FEBA3F" }} />
                    }
                  />
                }
                label={
                  <>
                    <span>I accept </span>
                    <a
                      className="hdy-green-href"
                      href="https://heyday.io/terms.html"
                    >
                      <span> HeyDay’s Terms of Service, Build Plan Terms </span>
                    </a>
                    <span>and </span>
                    <a
                      className="hdy-green-href"
                      href="https://heyday.io/pricing.html"
                    >
                      <span> Service Limits, </span>
                    </a>
                    <span>and have read </span>
                    <a
                      className="hdy-green-href"
                      href="https://heyday.io/policy.html"
                    >
                      <span> HeyDay’s Privacy policy</span>
                    </a>
                  </>
                }
              />
            </FormGroup>
          </div>
          <HeyDayButton
            onClick={sendTerms}
            // disable={!isAgreed || countryCode === undefined}
            style={{ marginTop: 30, width: "100%" }}
            text="Submit"
          />
        </HeyDayCard>
      </Box>
    </div>
  );
};

export default Terms;
