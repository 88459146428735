var tmplPlaceHoldersFrench = {
  resWithout: "résultats sans",
  approx: "Environ",
  resFound: "résultats trouvés",
  noRes: "Aucun résultat trouvé pour",
  didYouMean: "Vouliez-vous dire",
  prevPage: "Précédent",
  nextPage: "Suivant",
  AC_item_left: "AC_item_left",
  AC_item_right: "AC_item_right",
  searchBoxText: "recherche",
  missing: "Manquant",
  must_include: "Doit inclure",
  for: "pour",
  brands: "Marques",
  clear: "effacer",
  priceRange: "Plage de prix",
  categories: "Catégories",
  sortBy: "Trier par",
  relevance: "Pertinence",
  priceLTH: "Prix: du plus bas au plus élevé",
  priceHTL: "Prix: du plus élevé au plus bas",
  filter: "Filtrer",
  to: "À",
  from: "De",
  ellipsisF: "...",
  ellipsisL: "...",
  dateN: "Date: les plus récentes en premier",
  dateL: "Date: les plus anciennes en premier",
  forMoreRes: "Pour plus de résultats",
  minPrice: "Prix minimum",
  maxPrice: "Prix maximum",
  showFilters: 'Afficher les filtres',
  hideFilters: 'Masquer les filtres'
};

export default tmplPlaceHoldersFrench;
