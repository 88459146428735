import React, { useState, useEffect } from "react";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";

const steps = [
  { name: "connectYourData", label: "Connect Your Data" },
  { name: "payment", label: "Payment Details" },
  { name: "preview", label: "Preview" },
  { name: "done", label: "You Are Ready To Go" },
];

const ProgressBar = ({ stepName, progressPercentage }) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const stepIndex = steps.findIndex((step) => step.name === stepName);
    setActiveStep(stepIndex !== -1 ? stepIndex : 0);
  }, [stepName]);

  const getProgress = (index) => {
    if (index < activeStep) {
      return 100;
    }
    if (index === activeStep) {
      return progressPercentage;
    }
    return 0;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        top: "-80px",
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "700px",
        width: "80%",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {steps.map((step, index) => (
          <React.Fragment key={step.name}>
            <div style={{ textAlign: "center", flex: 1, position: "relative" }}>
              {index < activeStep ? (
                <CheckCircleSharpIcon
                  style={{ color: "#FFBA41" }}
                  fontSize="large"
                />
              ) : index === activeStep ? (
                <KeyboardArrowDownRoundedIcon
                  style={{
                    border: "1px solid",
                    borderRadius: "50%",
                    borderColor: "#FFBA41",
                    width: "0.8em",
                    height: "0.8em",
                    color: "#FFBA41",
                  }}
                  fontSize="large"
                />
              ) : (
                <KeyboardArrowRightRoundedIcon
                  style={{
                    border: "1px solid",
                    borderRadius: "50%",
                    borderColor: "#FFBA41",
                    width: "0.8em",
                    height: "0.8em",
                    color: "#FFBA41",
                  }}
                  fontSize="large"
                />
              )}
            </div>
            {index < steps.length - 1 && (
              <div
                style={{
                  flex: 1,
                  position: "relative",
                  height: "2px",
                  background: `linear-gradient(to right, #FFBA41 ${getProgress(
                    index
                  )}%, #e0e0e0 ${getProgress(index)}%)`,
                  margin: "0 -16px", // Adjust this value to align the line properly
                }}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          width: "110%",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        {steps.map((step, index) => (
          <div
            key={step.name}
            style={{
              textAlign: "center",
              flex: 1,
              fontFamily: "Poppins-Medium",
              fontSize: "14px",
              color: step.name === stepName ? "#373737" : "#929292",
            }}
          >
            {index === activeStep || index === 3 ? <>{step.label}</> : <></>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
