import React, { useState, useEffect, useContext } from "react";
import ContentPageHeader from "../ContentPageHeader/ContentPageHeader";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import GlobalContext from "../../../Context/Context";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import "./Search.css";

const DomainGrouping = () => {
  const context = useContext(GlobalContext);
  const [grouped, setGrouped] = useState();
  const params = useParams();
  const domain = context.getDomains()[params?.index];
  const availableDomains = context
    .getDomains()
    .map((el) => el.domain)
    .filter((el) => el !== domain.domain);

  const getGrouped = async () => {
    const requestJson = {
      action: 40,
      host: domain && domain.domain,
    };
    requestJson.credentials = context.loadCredentials();
    try {
      const responseJson = await context.myFetch(requestJson);
      if (responseJson.groupedDomains) {
        const parsed = JSON.parse(responseJson.groupedDomains);
        return setGrouped(Object.keys(parsed));
      }
      context.message({
        type: "error",
        body: responseJson.error,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const saveGrouped = async () => {
    const groupedDomains = {};
    grouped.forEach((el) => (groupedDomains[el] = 1));
    const requestJson = {
      action: 1039,
      host: domain && domain.domain,
      groupedDomains: groupedDomains,
    };

    requestJson.credentials = context.loadCredentials();
    try {
      const responseJson = await context.myFetch(requestJson);
      if (!responseJson.error) {
        return context.message({
          type: "success",
          body: "Group has been saved",
        });
      }
      return context.message({
        type: "error",
        body: responseJson.error,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setDomain = (dom) => {
    grouped.includes(dom)
      ? setGrouped(grouped.filter((doms) => doms !== dom))
      : setGrouped([...grouped, dom]);
  };

  useEffect(() => {
    getGrouped();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <section className="content-header">
        <ContentPageHeader pageName="Domain grouping" subMenu="Search" />
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "750px",
          boxShadow: "unset",
        }}
      >
        {availableDomains && availableDomains.length > 0 ? (
          <section
            className="groupedDomains"
            style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "flex-start",
            }}
          >
            <p>Available domains for grouping:</p>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
              }}
            >
              {availableDomains &&
                availableDomains.length > 0 &&
                availableDomains.map((dom) => (
                  <Button
                    key={dom}
                    className={
                      grouped && grouped.includes(dom)
                        ? "domain-grouped-btn"
                        : "domain-grouped-btn-default"
                    }
                    onClick={() => setDomain(dom)}
                  >
                    {dom}
                  </Button>
                ))}
            </div>
            <Button className="saveBtn" onClick={saveGrouped}>
              Save
            </Button>
          </section>
        ) : (
          <p>No available domains for grouping</p>
        )}
      </AdminCard>
    </div>
  );
};

export default DomainGrouping;
