import React, { useEffect, useState } from "react";
import "./Welcome.css";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Welcome = () => {
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const contactName = sessionStorage.getItem("contactName");
  const platform = sessionStorage.getItem('platform');

  useEffect(() => {
    try {
      if (params?.token) {
        setToken(params.token);
      }
      if (params?.uName) {
        setUserName(params.uName);
      }
      if (params?.password) {
        setPassword(params.password);
      }
    } catch (error) {
      console.error("Error decoding parameters:", error);
    }
  }, [params]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleContinue = () => {
    if (token) {
      sessionStorage.setItem("token", token);
    }
    handleClose();
    window.location.href = "/selfserve/2";
  };

  return (
    <div className="heyday-root-welcome">
      <div className="heyday-animatedBox">
        <Box className="heyday-welcomeBox">
          <img
            src="/img/HeyDay_Logo.png"
            alt="Welcome"
            className="heyday-image"
          />
        <Box sx={{ margin: "50px auto" }}>
          <Typography
            variant="h4"
            gutterBottom
            className="heyday-welcomeText"
          >
            Hey <span>{contactName ? contactName : ""},</span>
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            className="heyday-welcomeText"
          >
            Welcome to <span className="heyday-strong">HeyDay! 🚀</span>
          </Typography>
          <Typography
          sx={{marginTop: 3}}
            variant="h6"
            gutterBottom
            className="heyday-congratulationsText"
          >
            Congratulations on successfully installing the HeyDay <span style={{color: '#5daaa1', fontWeight:'600'}}>{platform.charAt(0).toUpperCase() + platform.slice(1)}</span> plugin! 🎉
          </Typography>
        </Box>
          <Typography variant="body1" className="heyday-noteText">
            Below are your account login details, it's important to keep them
            secure:
          </Typography>
          <div className="heyday-card-under-line"></div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              marginTop: "40px",
            }}
          >
            <div className="user-login-details">
              <label>Username</label>{" "}
              <div className="login-input-container">
                <span>{userName}</span>
              </div>
            </div>
            <div className="user-login-details">
              <label>Password</label>{" "}
              <div className="login-input-container">
                <span>{password}</span>
              </div>
            </div>
          </Box>
          <Button
            className="btn-save"
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleOpen}
          >
            Continue to Finalize the Integration Step
          </Button>
        </Box>
      </div>
      <img
        src="/img/rocket.png"
        alt="heyday-rocket"
        className="heyday-rocket"
      />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Important!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make sure you have saved your login details as you won't be
            able to see them again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleContinue} color="primary">
            I have saved them, continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Welcome;
