import React from "react";

const ConnectYourDataHeader = () => {
  return (
    <>
      <div className="hdy-card-title">Connect your data</div>
      <div className="card-line" style={{ width: "100%", marginTop: "10px" }}></div>
    </>
  );
};

export default ConnectYourDataHeader;
