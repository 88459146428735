import {
  getIntegrationState,
  updateIntegrationState,
} from "../../Components/SelfServe/helpers/helpers";
import { b_hostName } from "../../Components/Admin/theme/helpers";
const backend_hostName = b_hostName();

export const login = async (
  context,
  email,
  password = false,
  userType = false,
  token = false,
  platform = false
) => {
  const endpoint = token ? false : `https://${backend_hostName}/panWbPush/`;
  const requestJson = { action: 1 };

  if (!window.jwt) {
    requestJson.credentials = {
      uName: email,
      [token ? "accessToken" : "password"]: token ? token : password,
    };
  } else {
    requestJson.jwt = window.jwt;
  }

  if (!navigator.onLine) {
    context.message({
      type: "error",
      body: "Check internet connection",
    });
    return;
  }

  const resp = await context.myFetch(requestJson, null, endpoint, {
    credentials: "include",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestJson),
  });
  const config = await context.fetchConfig();
  if (resp.error || !resp.affDefault || config.error) {
    console.log(resp);
    context.message({
      type: "error",
      body: resp.error,
    });
  } else {
    let objKey = !!resp.affDefault && Object.keys(resp.affDefault)[0];
    if (!objKey) {
      context.message({
        type: "error",
        body: "Something went wrong",
      });
      setTimeout(() => {
        window.location.href = "/login";
      }, 800);
    }
    var domainsLength = 0;
    sessionStorage.setItem("affStatus", resp.affDefault[objKey].status);
    sessionStorage.setItem("id", resp.affDefault[objKey].id);
    sessionStorage.setItem("name", resp.affDefault[objKey].name);
    sessionStorage.setItem("affType", resp.affDefault[objKey].affType);
    sessionStorage.setItem("TOS", resp?.TOS);
    if (resp.multiUsers && resp.multiUsers.length > 0) {
      sessionStorage.setItem("multiUsers", resp.multiUsers);
    }
    if (resp.affDefault[objKey].domains) {
      sessionStorage.setItem(
        "allDomains",
        JSON.stringify(resp.affDefault[objKey].domains)
      );
      if (resp.affDefault[objKey].domains.length > 0) {
        let domArr = resp.affDefault[objKey].domains.map((item) => item.domain);
        domainsLength = domArr.length;
        sessionStorage.setItem("domains", domArr);
        sessionStorage.setItem("currentDomain", domArr[0]);
      }
    }

    if (resp.contactName)
      sessionStorage.setItem("contactName", resp.contactName);

    if (resp.uName) {
      sessionStorage.setItem("name", resp.uName);
    }

    if (resp.TOS === 0 && platform) {
      sessionStorage.setItem("platform", platform);
      const wAccessToken = resp.accessToken ? encodeURIComponent(resp.accessToken) : token ? encodeURIComponent(token) : null;
      const wEmail = encodeURIComponent(email);
      const wPassword = encodeURIComponent(password);
      window.location.href = `/welcome/${wAccessToken}/${wEmail}/${wPassword}`;
      return;
    }

      sessionStorage.setItem("token", token ? token : resp.accessToken);
      const integrationResponse = await getIntegrationState(context);
      if (
        integrationResponse.integrationState &&
        integrationResponse.integrationState !== 0 &&
        domainsLength === 1
      ) {
        sessionStorage.setItem(
          "integrationState",
          integrationResponse.integrationState
        );
        console.log(resp.affDefault[objKey].status);
        console.log(integrationResponse.integrationState);
        if (
          resp.affDefault[objKey].status === 1 &&
          integrationResponse.integrationState === 4
        ) {
          window.location.href = "/selfserve/11";
        } else {
          window.location.href =
            "/selfserve/" + integrationResponse.integrationState;
        }
      } else {
        if (userType) {
          if (userType === "1" || userType === "0") {
            await updateIntegrationState(1, context);
            sessionStorage.setItem("integrationState", 2);
            window.location.href = "/selfserve/2";
          }
        } else {
          sessionStorage.setItem("integrationState", 0);
          window.location.href = "/dashboard";
        }
      }

  }
};
