var tmplPlaceHoldersRussian = {
  resWithout: "результаты без",
  approx: "Примерно",
  resFound: "найденные результаты",
  noRes: "Нет результатов для",
  didYouMean: "Вы имели в виду",
  prevPage: "Предыдущий",
  nextPage: "Следующий",
  AC_item_left: "AC_item_left",
  AC_item_right: "AC_item_right",
  searchBoxText: "поиск",
  missing: "Отсутствует",
  must_include: "Должно содержать",
  for: "для",
  brands: "Бренды",
  clear: "очистить",
  priceRange: "Ценовой диапазон",
  categories: "Категории",
  sortBy: "Сортировать по",
  relevance: "Релевантность",
  priceLTH: "Цена: от низкой к высокой",
  priceHTL: "Цена: от высокой к низкой",
  filter: "Фильтр",
  to: "До",
  from: "От",
  ellipsisF: "...",
  ellipsisL: "...",
  dateN: "Дата: сначала новые",
  dateL: "Дата: сначала старые",
  forMoreRes: "Для дополнительных результатов",
  minPrice: "Минимальная цена",
  maxPrice: "Максимальная цена",
  showFilters: 'Показать фильтры',
  hideFilters: 'Скрыть фильтры'
};

export default tmplPlaceHoldersRussian;
