import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../Context/Context";
import { login } from "../utils/auth";
import HeydayCardLoading from "../../Components/Admin/Loading/HeydayCardLoading";
import { Box, Avatar } from "@mui/material";
import HeyDayTextField from "../../Shared/Components/TextField/HeyDayTextField";
import HeyDayCard from "../../Shared/Components/Card/SelfServeCard";
import HeyDayButton from "../../Shared/Components/Button/HeyDayButton";

const AutoLogin = () => {
  const context = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  useEffect(() => {
    if (window.location.search) {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const pass = urlParams.get("p");
        const token = urlParams.get("t");
        const userName = urlParams.get("u");
        const platform = urlParams.get("platform");
        const pFeedUrl = urlParams.get("pFeedUrl");
        const pDomain = urlParams.get("pDomain");

        if (pFeedUrl && pDomain) {
          sessionStorage.setItem("pDomain", pDomain);
          sessionStorage.setItem("pFeedUrl", pFeedUrl);
        }

        if (userName && pass) {
          handleLoginClick(userName, pass, platform,false);
        }else if(userName && token){
          handleLoginClick(userName,false,platform,token)
        }
      } catch (error) {
        console.error("Error processing URL parameters:", error);
      }
    }
  }, []);

  const handleLoginClick = (
    userName = false,
    pass = false,
    platform = false,
    token = false
  ) => {
    if (userName && pass) {
      setEmail(userName);
      setPassword(pass);
      login(context, userName, pass, false, false, platform)
        .then(() => {
          setIsBtnDisabled(false);
        })
        .catch((error) => {
          console.error("Failed to log in:", error);
          setIsBtnDisabled(false);
        });
    } else if(userName && token){
      login(context, userName, false, false, token, platform)
        .then(() => {
          setIsBtnDisabled(false);
        })
        .catch((error) => {
          console.error("Failed to log in:", error);
          setIsBtnDisabled(false);
        });
    }else {
      login(context, email, password)
        .then(() => {
          setIsBtnDisabled(false);
        })
        .catch((error) => {
          console.error("Failed to log in:", error);
          setIsBtnDisabled(false);
        });
    }
  };
  return(<></>);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
      }}
    >
      <Box className="login-main-container">
        <img
          style={{
            margin: "20px 0",
            height: "62px",
            width: "175px",
            cursor: "pointer",
          }}
          alt="Heyday-logo"
          src="/img/HeyDay_Logo.png"
          onClick={() => (window.location.href = "/login")}
        />
        <HeyDayCard
          sx={{
            width: "100%",
            "@media (max-width: 780px)": {
              boxShadow: "unset",
            },
          }}
        >
          {isBtnDisabled && <HeydayCardLoading loading={isBtnDisabled} />}
          <div className="hdy-card-title">Login to your HeyDay account</div>
          <div className="card-line"></div>
          <div className="hdy-paragraph-text">Please enter your details</div>
          <HeyDayTextField
            onChange={(e) => setEmail(e.target.value)}
            style={{
              marginTop: "20px",
              width: "100%",
            }}
            labelValue="Email address"
            type="email"
          />
          <HeyDayTextField
            onChange={(e) => setPassword(e.target.value)}
            style={{
              marginTop: "20px",
              width: "100%",
            }}
            type="password"
            labelValue="Password"
          />
          <HeyDayButton
            onClick={() => {
              handleLoginClick();
              setIsBtnDisabled(true);
            }}
            disabled={isBtnDisabled}
            style={{ marginTop: 30, width: "100%" }}
            text="Login"
          />
          <div
            className="hdy-paragraph-text"
            style={{ fontSize: "16px", marginTop: "30px" }}
          >
            Or
          </div>
          <Box
            sx={{
              width: "50%",
              marginTop: "30px",
              display: "flex",
              marginLeft: "25%",
              height: "60px",
            }}
          >
            <Box
              sx={{
                boxShadow: "0px 5px 7px 3px rgba(113, 113, 113, 0.25)",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                margin: "auto",
                padding: "10px",
                position: "relative",
                cursor: "pointer",
              }}
              //   onClick={() => context.initGoogle(handleCredentialResponse)}
            >
              <Avatar
                sx={{
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                src="/img/googleIcon.png"
              />
            </Box>
          </Box>
          <div className="hdy-paragraph-text" style={{ marginTop: "30px" }}>
            Don't have an account yet?{" "}
            <span>
              <Link className="hdy-green-href" to={"/signup"}>
                Sign Up
              </Link>
            </span>
          </div>
        </HeyDayCard>
      </Box>
    </div>
  );
};

export default AutoLogin;
