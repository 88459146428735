import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";

const CustomCard = styled(Box)(({ theme }) => ({
  width: "70%",
  boxShadow: "0px 5px 7px rgba(113, 113, 113, 0.25)",
  borderRadius: "35px",
  maxWidth: "550px",
  position: "relative",
  margin: "auto",

  "& .content": {
    margin: "auto",
    width: "90%",
    // minHeight: '530px',
    // height: '530px',
    paddingBottom: "20px",
    paddingTop: "20px",
  },
}));

function HeyDayCard({ children, sx, contentStyle }) {
  return (
    <CustomCard sx={sx}>
      <Box className="content" style={contentStyle}>
        {children}
      </Box>
    </CustomCard>
  );
}

export default HeyDayCard;
