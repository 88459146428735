import React, { useState } from "react";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { DatePicker } from "antd";
import moment from "moment";
import "./ManageSearchResults.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IOSSwitch from "../../../../Shared/Components/Switch/IOSSwitch";

const Rule = ({
  isNewRule,
  rule,
  domain,
  Context,
  searchRules,
  getSearchRules,
  deleteRule,
}) => {
  const [state, setState] = useState({
    url: rule?.url || "",
    consequences: rule?.consequences || 1,
    condition: rule?.condition || "",
    validity:
      isNewRule || +rule.validity === 0
        ? undefined
        : moment(new Date(rule.validity * 1000)),
  });
  const [condition, setCondition] = useState(
    rule?.condition === "*" ? false : true
  );
  const [validity, setValidity] = useState(rule?.validity === 0 ? false : true);
  const setUrl = (e) => {
    e.persist();
    setState((state) => ({ ...state, url: e?.target?.value }));
  };
  const setConditionValue = (e) => {
    e.persist();
    setState((state) => ({ ...state, condition: e?.target?.value }));
  };
  const setDate = (momentEvent) => {
    if (momentEvent) {
      console.log(momentEvent.unix());
      setState((prevState) => ({
        ...prevState,
        validity: momentEvent.unix(),
      }));
    } else {
      console.error("Invalid momentEvent");
    }
  };

  const handleChangeConsequences = (event) => {
    setState((prevState) => ({
      ...prevState,
      consequences: event.target.value,
    }));
  };

  const saveRule = async () => {
    if (state.url.trim() === "") {
      return Context.message({
        type: "error",
        body: "URL is required field",
      });
    } else if (typeof state.consequences !== "number") {
      return Context.message({
        type: "error",
        body: "Consequences is required field",
      });
    } else if (condition && state.condition.trim() === "") {
      return Context.message({
        type: "error",
        body: "Please define keywords",
      });
    } else if (validity && !(state?.validity > moment(new Date()).unix())) {
      console.log(state?.validity);
      console.log(validity);
      console.log(moment(new Date()).unix());
      return Context.message({
        type: "error",
        body: "Please select date",
      });
    } else {
      const otherThanCurrent = [...searchRules].filter(
        (rule) => rule?.url !== ""
      );
      const requestJson = {
        action: 1045,
        rulesConf: [
          ...otherThanCurrent,
          {
            url: state.url,
            consequences: state.consequences || 1,
            condition: condition ? state.condition : "*",
            validity: validity ? state.validity : 0,
          },
        ],
        host: domain.domain,
        credentials: Context.loadCredentials(),
      };

      try {
        const response = await Context.myFetch(requestJson);
        if (response && !response.error) {
          Context.message({
            type: "success",
            body: `Rule is successfully ${isNewRule ? "created" : "updated"}`,
          });
          return getSearchRules();
        } else {
          Context.message({
            type: "error",
            body: response.error,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Accordion
      defaultExpanded={isNewRule}
      sx={{
        marginBottom: "10px",
        width: "100%",
        border: "none",
        boxShadow: "unset",
        borderRadius: "20px !important",
        background: "var(--Light-Grey, #F4F6F8)",
        color: "var(--Mid-Grey, #616060)",
        fontFamily: "Poppins",
        fontSize: "14px",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {state?.url || "Add new rule"}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div>
          <p style={{ textAlign: "left" }}>
            URL <span>(Define URL for this rule)</span>
          </p>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                backgroundColor: "#fff",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            inputProps={{
              sx: {
                "&::placeholder": {
                  color: "#9a9b9d",
                },
              },
            }}
            onChange={setUrl}
            placeholder="www.your_website.com"
            defaultValue={state?.url}
            autoComplete="off"
            style={{ maxWidth: "575px" }}
          />
        </div>
        <div>
          <p style={{ textAlign: "left" }}>
            Consequences <span>(Select search result position)</span>
          </p>
          <Select
            sx={{
              borderRadius: "25px",
              background: "white",
              width: "100%",
              fontFamily: "Poppins",
              maxWidth: "400px",
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputLabel-shrink": {
                fontFamily: "Poppins",
                color: "#616060",
              },
            }}
            required
            value={state.consequences}
            onChange={handleChangeConsequences}
            placeholder={"1 - 10"}
            mode="default"
            style={{ maxWidth: "575px" }}
          >
            {_buildConsequences()}
          </Select>
        </div>
        <div>
          <p style={{ textAlign: "left" }}>
            Condition{" "}
            <span>(Define keywords or apply on all search queries)</span>
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IOSSwitch
              onChange={() => setCondition(!condition)}
              checked={condition}
              sx={{ marginRight: "10px" }}
            />
            <TextField
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  backgroundColor: "#fff",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              inputProps={{
                sx: {
                  "&::placeholder": {
                    color: "#9a9b9d",
                  },
                },
              }}
              onChange={setConditionValue}
              placeholder={
                condition
                  ? 'Define KW: "first,second,third"'
                  : "Apply on all results"
              }
              defaultValue={state?.condition}
              autoComplete="off"
              style={{
                width: "calc(100% - 50px)",
                maxWidth: "526px",
              }}
              disabled={!condition}
            />
          </div>
        </div>
        <div>
          <p style={{ textAlign: "left" }}>
            Validity <span>(condition valid until)</span>
          </p>
          <div style={{ display: "flex" }}>
            <IOSSwitch
              onChange={() => setValidity(!validity)}
              checked={validity}
              sx={{ marginRight: "10px" }}
            />
            <DatePicker
              showTime
              defaultValue={state?.validity}
              placeholder={validity ? "Select Time" : "Indefinite"}
              disabled={!validity}
              onOk={setDate}
            />
          </div>
        </div>
        <div className="ruleActions">
          <Button className="btn-save" onClick={saveRule} style={{ margin: 0 }}>
            Save
          </Button>
          <Button
            className="btn-delete"
            onClick={() => deleteRule(rule, isNewRule)}
            style={{ margin: 0 }}
          >
            Delete
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const _buildConsequences = () => {
  let numbers = [];

  for (let i = 1; i <= 10; i++) {
    numbers.push(i);
  }

  return numbers.map((el) => {
    return (
      <MenuItem key={el} value={el}>
        {el}
      </MenuItem>
    );
  });
};

export default Rule;
