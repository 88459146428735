import React, { useState, useContext, useEffect } from "react";
import ContentPageHeader from "../ContentPageHeader/ContentPageHeader";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import HeyDayMultiline from "../../../Shared/Components/TextField/HeyDayMultiline";
import { Button } from "@mui/material";
import GlobalContext from "../../../Context/Context";
import { useParams } from "react-router-dom";

const ManageStopWords = () => {
  const context = useContext(GlobalContext);
  const params = useParams();
  const [wordList, setWordList] = useState([]);
  const domain = context.getDomains()[params?.index];

  const getStopWords = async () => {
    const requestJson = {
      action: 33,
      host: domain && domain.domain,
    };
    requestJson.credentials = context.loadCredentials();
    try {
      const responseJson = await context.myFetch(requestJson);
      if (responseJson.wordList) {
        const _wordList = JSON.parse(responseJson.wordList);
        _wordList.wordList && setWordList(_wordList.wordList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value.trim();
    const newList = newValue ? [newValue] : [];
    setWordList(newList);
  };

  const saveStopWords = async () => {
    let rawWordList = wordList[0].split("\n");
    const filteredWordList = rawWordList.filter(
      (item) => item.trim().length > 0
    );
    const leng = filteredWordList.length;
    if (leng > 100) {
      context.message({
        type: "error",
        body: "Max word count is 100",
      });

      return;
    }
    const requestJson = {
      action: 1032,
      host: domain?.domain,
      wordList: filteredWordList,
      credentials: context.loadCredentials(),
    };
    try {
      const responseJson = await context.myFetch(requestJson);
      !responseJson.error &&
        context.message({
          type: "success",
          body: "Stop words have been saved",
        });

      if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStopWords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <section className="content-header">
        <ContentPageHeader pageName="Stop words" subMenu="Search" />
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
          minHeight: "270px",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "750px",
          boxShadow: "unset",
        }}
      >
        <HeyDayMultiline
          onChange={handleChange}
          value={wordList}
          sx={{
            width: "100%",
            mt: "20px",
            "& .MuiOutlinedInput-root": {
              background: "#ffffff",
              border: "1px solid",
              borderColor: "#E3E8EB",
              borderRadius: "10px",
            },
            "& .MuiOutlinedInput-input": {
              background: "#ffffff",
            },
          }}
          label="Stop Words"
          rows={4}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "30px",
          }}
        >
          <Button
            sx={{
              backgroundColor: "#ffffff",
              color: "var(--Green, #5daaa1)",
              borderRadius: "25px",
              border: "2px solid",
              textTransform: "unset",
              borderColor: "var(--Green, #5daaa1)",
              fontFamily: "Poppins",
              padding: "5px 15px",
              height: "40px",
              "&:hover": {
                backgroundColor: "#5daaa1",
                border: "2px solid",
                borderColor: "var(--Green, #5daaa1)",
                color: "var(--Green, #ffffff)",
              },
            }}
            onClick={saveStopWords}
          >
            Save
          </Button>
        </div>
      </AdminCard>
    </div>
  );
};

export default ManageStopWords;
