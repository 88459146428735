import React from "react";
import HeyDayFormLabel from "../../../../../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import { TextField } from "@mui/material";

const CardStyleEditFields = ({ handleCardChange, cardStyle }) => {
  return (
    <div>
      <h3>Edit Card Style</h3>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>Border color:</HeyDayFormLabel>
        <div className="input-color-container">
          <input
            className="input-color"
            type="color"
            id="border-color"
            name="borderColor"
            value={cardStyle.borderColor}
            onChange={handleCardChange}
          />
          <span>{cardStyle.borderColor}</span>
        </div>
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>
          Background color:
        </HeyDayFormLabel>
        <div className="input-color-container">
          <input
            className="input-color"
            type="color"
            id="background-color"
            name="backgroundColor"
            value={cardStyle.backgroundColor}
            onChange={handleCardChange}
          />
          <span>{cardStyle.backgroundColor}</span>
        </div>
      </div>

      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>Width:</HeyDayFormLabel>
        <TextField
          sx={{
            width: "180px",
            "& .MuiOutlinedInput-root": {
              height: "44px",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "100px",
              "& fieldset": {
                borderColor: "white",
                border: "none",
              },
            },
          }}
          type="text"
          name="width"
          value={cardStyle.width}
          onChange={handleCardChange}
        />
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>Height:</HeyDayFormLabel>
        <TextField
          sx={{
            width: "180px",
            "& .MuiOutlinedInput-root": {
              height: "44px",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "100px",
              "& fieldset": {
                borderColor: "white",
                border: "none",
              },
            },
          }}
          type="text"
          name="height"
          value={cardStyle.height}
          onChange={handleCardChange}
        />
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>
          Border Radius:
        </HeyDayFormLabel>
        <TextField
          sx={{
            width: "180px",
            "& .MuiOutlinedInput-root": {
              height: "44px",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "100px",
              "& fieldset": {
                borderColor: "white",
                border: "none",
              },
            },
          }}
          type="text"
          name="borderRadius"
          value={cardStyle.borderRadius}
          onChange={handleCardChange}
        />
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>Border Width:</HeyDayFormLabel>
        <TextField
          sx={{
            width: "180px",
            "& .MuiOutlinedInput-root": {
              height: "44px",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "100px",
              "& fieldset": {
                borderColor: "white",
                border: "none",
              },
            },
          }}
          type="text"
          name="borderWidth"
          value={cardStyle.borderWidth}
          onChange={handleCardChange}
        />
      </div>
    </div>
  );
};

export default CardStyleEditFields;
