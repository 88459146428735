import generateLink from "./searchItemTemplate";

export const initializeSearch = ({
  domain,
  setACPopupOpen,
  domainIndex,
  _Context,
  setIsScriptLoaded,
}) => {
  let main_script = document.createElement("script");
  main_script.setAttribute(
    "src",
    "https://cdn.heyday.io/cstmst/heyDayMain.js?affId=1&d=admin.heyday.io"
  );

  main_script.onload = function () {
    setIsScriptLoaded(true);
  };

  const domainConf = _Context.getDomains()[_Context.state.domainIndex];
  const affId = +sessionStorage.getItem("id") || 1;
  const domainType = domainConf?.type || 0;

  window._heyDay = { cmd: [] };
  var conf = {};
  conf.sDomain = domain;
  if (domainType !== 2) {
    conf.searchBox = {
      type: "nsm",
      getFilters: false,
    };
    if (domainType === 1 || domainType === 0) {
      conf.searchBox.tmpl = {
        search_item: generateLink(affId, domain),
      };
    }
  }

  window._heyDay.cmd.push(conf);
  if (window._heyDayInterface && window._heyDayInterface.methods) {
    window._heyDayInterface.methods.activateSearchBox();
  } else {
    document.body.appendChild(main_script);
  }

  // if (!checkIntegration(domainIndex, _Context)) {
  //   window.addEventListener(
  //     "message",
  //     function (ev) {
  //       const ac_seen = sessionStorage.getItem("AC_seen");

  //       if (
  //         ev.origin !== "https://heyday.io" ||
  //         typeof ev.data.id == "undefined"
  //       ) {
  //         return;
  //       }
  //       let context = ev.data.context;
  //       if (context.autoCompleteH > 100 && !ac_seen) {
  //         setACPopupOpen(true);
  //       }
  //     },
  //     false
  //   );
  // }
};
