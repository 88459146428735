import React from "react";
import { Button, Typography } from "@mui/material";
import ViewDomainMeta from "../Dashboard/EditDomain/Tabs/ViewDomainMeta/ViewDomainMeta";

const Integration = ({ domainName }) => {
  return (
    <div className="integration">
      <Typography variant="h3">Success!</Typography>
      {+sessionStorage.getItem("affType") !== 1 && (
        <Typography variant="body1">
          Upon installation, your website will prompt visitors to subscribe. If
          you don't see an optin prompt on your site, contact us for help.
        </Typography>
      )}
      <ViewDomainMeta domain={domainName} isNewDomain={true} />
    </div>
  );
};

const IntegrationAction = ({ current, setCurrent }) => {
  const handleOpenTerms = () => {
    // if (current && setCurrent) {
    //   setCurrent(current + 1);
    // }
    window.location.href = "/";
  };
  return (
    <Button className="btn-proceed" onClick={handleOpenTerms}>
      Proceed
    </Button>
  );
};

export { Integration, IntegrationAction };
