import { b_hostName } from "../../theme/helpers";
const backend_hostName = b_hostName();

const DBmanager = {
  ////////////////////////////////////////////////////////////////////
  //// DO NOT CHANGE THIS OBJECT WITHOUT CONSOULTING WITH NISSIM  ////
  ////////////////////////////////////////////////////////////////////
  initStatus: null,
  WASM_DB: null,
  confJson: null,
  construct: async function (confJsonRef) {
    if (DBmanager.confJson !== null) {
      if (DBmanager.initStatus) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    }
    DBmanager.confJson = confJsonRef;
    DBmanager.confJson.tablesObj = {};
    DBmanager.confJson.tables.forEach((t) => {
      DBmanager.confJson.tablesObj[t.actionId] = t;
    });

    try {
      return window.globalThis.sqlite3InitModule().then(function (sqlite3) {
        const oo = sqlite3.oo1; /*high-level OO API*/
        DBmanager.WASM_DB = new oo.DB("/mydb.sqlite3", "c");
        DBmanager.initStatus = true;
        console.log("Initialization complete");
      });
    } catch (e) {
      console.log("DB manager wasm sqlite load error | " + e);
      throw e;
    }
  },
  dbstateChahe: {},
  getToDateTS: function (toDateRangeTS, timeInterval) {
    // eslint-disable-next-line
    if (timeInterval == "hour") {
      let todayStr = DBmanager.getTodayStr();
      let todayTS = DBmanager.getDateRangeArray(todayStr, 0);
      // eslint-disable-next-line
      if (todayTS[0] == toDateRangeTS) return new Date().getTime();
    }
    return toDateRangeTS + 86400000;
  },
  getTodayStr: function () {
    let todayLocal = new Date();
    let todayStr = todayLocal.toISOString();
    return todayStr.substring(0, todayStr.indexOf("T"));
  },
  getDateRangeArray: function (startDay, daysBack) {
    let dateRanage = [];
    let itDate = Date.parse(startDay + "T00:00:00.000Z");
    for (let i = 0; i <= daysBack; i++) {
      dateRanage.push(itDate - i * 86400000);
    }
    return dateRanage;
  },
  timeStampTodateStrTimeslot: function (timeStamp, deltaTime) {
    let date = new Date(timeStamp);
    if (deltaTime === 3600000) {
      return date.toISOString().replace(/^([^T]+)T(\d{2}).*$/, "$1 $2:00:00");
    } else {
      return date.toISOString().replace(/^([^T]+)T(\d{2}).*$/, "$1");
    }
  },
  initRefObj: function (values, sum, refObj) {
    values.forEach((e) => {
      refObj[e] = 0;
    });
    if (sum) {
      sum.forEach((e) => {
        refObj[e[0]] = 0;
      });
    }
  },
  formatTimeLineRows: function (
    fromDateTS,
    toDateTS,
    deltaTime,
    mapRows,
    rows,
    values,
    sum,
    mapValues
  ) {
    let dateStr;
    for (let ts = fromDateTS; ts < toDateTS; ts += deltaTime) {
      dateStr = DBmanager.timeStampTodateStrTimeslot(ts, deltaTime);
      let itObj;
      if (mapValues && typeof mapValues[dateStr] !== "undefined") {
        itObj = mapValues[dateStr];
      } else {
        itObj = {};
        DBmanager.initRefObj(values, sum, itObj);
        itObj._date = dateStr;
      }
      mapRows[dateStr] = itObj;
      rows.push(mapRows[dateStr]);
    }
  },
  formatWeekHour: function (values, sum) {
    let week = new Array(7);
    for (let i = 0; i < 7; i++) {
      week[i] = new Array(24);
      for (let j = 0; j < 24; j++) {
        week[i][j] = {};
        DBmanager.initRefObj(values, sum, week[i][j]);
      }
    }
    return week;
  },
  needsFetchFromServer: function (startDay, daysBack, table) {
    const dateRangeArray = DBmanager.getDateRangeArray(startDay, daysBack);
    if (typeof DBmanager.dbstateChahe[table.name] === "undefined") {
      DBmanager.dbstateChahe[table.name] = { todayLastCache: 0 };
      DBmanager.DB.updateDdstate(table.name);
    }
    return DBmanager.chechDBstateCache(
      startDay,
      daysBack,
      table,
      dateRangeArray
    );
  },
  chechDBstateCache: function (startDay, daysBack, table, dateRangeArray) {
    let todayStr = DBmanager.getTodayStr();
    let todayYesterdayArray = DBmanager.getDateRangeArray(todayStr, 1);
    let todayYesterdayOnly = false;
    let element;

    if (dateRangeArray[0] > todayYesterdayArray[0]) {
      // throw()
      console.log("date error - future");
      return false;
    }

    for (let k = 0; k < dateRangeArray.length; k++) {
      element = dateRangeArray[k];
      if (
        element !== todayYesterdayArray[0] &&
        typeof DBmanager.dbstateChahe[table.name][element] === "undefined"
      ) {
        if (element === todayYesterdayArray[1]) {
          todayYesterdayOnly = true;
        } else return [startDay, daysBack];
      }
    }

    if (todayYesterdayOnly) {
      return [todayStr, 1];
    } else if (dateRangeArray[0] === todayYesterdayArray[0]) {
      if (
        DBmanager.dbstateChahe[table.name].todayLastCache <
        new Date().getTime() - 60000
      ) {
        return [todayStr, 0];
      }
    }
    return false;
  },
  fetchDataFromServer: function (
    credentials,
    startDay,
    daysBack,
    actionId,
    host
  ) {
    let requestJson = {
      action: actionId,
      dailyReport: 1,
      host: host,
    };
    requestJson.credentials = credentials;
    requestJson.startDay = startDay;
    requestJson.daysBack = parseInt(daysBack);
    let uri = "";
    if (
      requestJson.credentials &&
      requestJson.credentials.accessToken &&
      requestJson.credentials.uName
    ) {
      uri =
        "?c=1&accessToken=" +
        requestJson.credentials.accessToken +
        "&uName=" +
        requestJson.credentials.uName;
      delete requestJson.credentials;
    }
    return new Promise((resolve, reject) => {
      fetch(`https://${backend_hostName}/panWbPush/${uri}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestJson),
      })
        .then(function (response) {
          // eslint-disable-next-line
          if (response.ok && response.status == 200) {
            response
              .text()
              .then(function (csv) {
                // eslint-disable-next-line
                if (csv && csv.charAt(0) == "{") csv = "";
                resolve(csv);
              })
              .catch(function (err) {
                reject(err);
              });
          } else {
            reject();
          }
        })
        .catch(function (err) {
          reject(err);
        });
    });
  },
  createCacheTable: function (tableName) {
    if (typeof DBmanager.confJson.cacheTables === "undefined") {
      DBmanager.confJson.cacheTables = {};
    }
    if (typeof DBmanager.confJson.cacheTables[tableName] === "undefined") {
      DBmanager.confJson.cacheTables[tableName] = {
        name: tableName + "_cacheState",
        create_filelds: "date integer PRIMARY KEY",
        filelds: "date",
      };
    }
    let cacheTable = DBmanager.confJson.cacheTables[tableName];
    DBmanager.WASM_DB.exec(
      "CREATE TABLE IF NOT EXISTS `" +
        cacheTable.name +
        "` (" +
        cacheTable.create_filelds +
        ")"
    );
    return cacheTable;
  },

  getTable: function (actionId, affHost) {
    const tableName =
      affHost.replace(/\./g, "_") +
      "_" +
      DBmanager.confJson.tablesObj[actionId].name;
    if (typeof DBmanager.confJson.tablesObj[tableName] === "undefined") {
      DBmanager.confJson.tablesObj[tableName] = JSON.parse(
        JSON.stringify(DBmanager.confJson.tablesObj[actionId])
      );
      DBmanager.confJson.tablesObj[tableName].name = tableName;
    }
    return DBmanager.confJson.tablesObj[tableName];
  },
  getGraphQueryParams: function (actionId) {
    return DBmanager.confJson.tablesObj[actionId].filelds_data.parameters;
  },
  getGraphData: function (
    credentials,
    startDay,
    daysBack,
    actionId,
    affHost,
    metaData
  ) {
    const table = DBmanager.getTable(actionId, affHost);
    const dateRangeArray = DBmanager.getDateRangeArray(startDay, daysBack);
    return new Promise((resolve, reject) => {
      let fetchRangeFromServer = DBmanager.needsFetchFromServer(
        startDay,
        daysBack,
        table
      );
      if (fetchRangeFromServer) {
        DBmanager.fetchDataFromServer(
          credentials,
          fetchRangeFromServer[0],
          fetchRangeFromServer[1],
          actionId,
          affHost
        ).then((csv) => {
          DBmanager.DB.insertData(
            csv,
            table,
            DBmanager.getDateRangeArray(
              fetchRangeFromServer[0],
              fetchRangeFromServer[1]
            )
          );
          let resp = DBmanager.DB.execQuery(dateRangeArray, table, metaData);
          resolve(resp);
        });
      } else {
        let resp = DBmanager.DB.execQuery(dateRangeArray, table, metaData);
        resolve(resp);
      }
    });
  },
  DB: {
    execQuery: function (dateRangeArray, table, metaData) {
      let timeInterval =
        typeof metaData.timeInterval !== "undefined"
          ? metaData.timeInterval
          : "day";
      let toDateTS = DBmanager.getToDateTS(dateRangeArray[0], timeInterval);
      let fromDateTS = dateRangeArray[dateRangeArray.length - 1];
      let wherePortion = `WHERE date >= ${fromDateTS} AND date < ${toDateTS}`;
      let sum = typeof metaData.sum === "object" ? metaData.sum : false;

      if (typeof metaData.where === "object") {
        for (let k in metaData.where) {
          if (metaData.where.hasOwnProperty(k)) {
            let inCond = metaData.where[k].isExclude ? "not in" : "in";
            let params = metaData.where[k].params;
            if (Array.isArray(params)) {
              wherePortion += ` AND ${k} ${inCond} ('${params.join("','")}')`;
            } else {
              console.error(
                `params is undefined or not an array for key: ${k}`
              );
            }
          }
        }
      }

      let sql = "SELECT ";
      if (!metaData.preVentDefault) {
        table.filelds_data.values.forEach((e) => {
          sql += ` SUM(${e}) as ${e}, `;
        });
      }
      if (sum) {
        sum.forEach((e) => {
          sql += `(SUM(${e[1].join(")+SUM(")})) as ${e[0]}, `;
        });
      }
      // eslint-disable-next-line
      if (
        typeof metaData.groupBy === "object" &&
        // eslint-disable-next-line
        !(metaData.graphType == "summery" || table.actionId == 28)
      ) {
        delete metaData.groupBy;
      }

      if (typeof metaData.groupBy === "object") {
        for (var i = 0; i < metaData.groupBy.length; i++) {
          // eslint-disable-next-line
          if (metaData.groupBy[i] == "_date") {
            metaData.groupBy.splice(i, 1);
            break;
          }
        }
        // eslint-disable-next-line
        if (metaData.groupBy.length == 0) {
          delete metaData.groupBy;
        } else {
          metaData.groupBy.forEach((e) => {
            sql += `${e}, `;
          });
        }
      }
      switch (metaData.graphType) {
        case "summery":
          sql = sql.slice(0, -2);
          break;
        case "weekHourSummery":
        case "timeLine":
          if (metaData.graphType === "timeLine") {
            if (timeInterval === "hour") {
              sql +=
                " strftime('%Y-%m-%d %H:00:00', datetime(date/1000, 'unixepoch')) AS _date ";
            } else {
              sql +=
                " strftime('%Y-%m-%d', datetime(date/1000, 'unixepoch')) AS _date ";
            }
          } ///weekHourSummery
          else
            sql +=
              " strftime('%w-%H', datetime(date/1000, 'unixepoch')) AS _date ";
          break;
        default:
          // reject('graphType err');
          return;
      }

      sql += " FROM `" + table.name + "` " + wherePortion;
      if (typeof metaData.groupBy === "object") {
        sql += " GROUP BY ";
        metaData.groupBy.forEach((e) => {
          sql += `${e}, `;
        });
        sql = sql.slice(0, -2);
      }
      // eslint-disable-next-line
      if (metaData.graphType !== "summery") {
        if (typeof metaData.groupBy != "object") {
          sql += " GROUP BY ";
        } else {
          sql += " , ";
        }

        sql += " _date order by date asc";
      }

      if (
        metaData.graphType === "summery" &&
        typeof metaData.orderBy === "object"
      ) {
        sql += " ORDER BY ";
        metaData.orderBy.forEach((e) => {
          sql += `${e} desc, `;
        });
        sql = sql.slice(0, -2);
      }
      // let stmt = DBmanager.DB.readSql(table, sql);
      let rows = [];
      let mapValues = {};
      if (metaData.graphType === "summery") {
        DBmanager.WASM_DB.exec({
          sql: sql,
          rowMode: "object",
          callback: function (r) {
            rows.push(r);
          },
        });
      } else if (metaData.graphType === "weekHourSummery") {
        DBmanager.WASM_DB.exec({
          sql: sql,
          rowMode: "object",
          callback: function (r) {
            mapValues[r._date] = r;
          },
        });
        rows = DBmanager.formatWeekHour(table.filelds_data.values, sum);
        let key;
        for (let w = 0; w < 7; w++) {
          for (let H = 0; H < 24; H++) {
            key = w + "-" + H.toString().padStart(2, "0");
            if (typeof mapValues[key] !== "undefined") {
              rows[w][H] = mapValues[key];
            }
          }
        }
      } else {
        let deltaTime =
          metaData.graphType === "timeLine" && timeInterval === "hour"
            ? 3600000
            : 86400000;
        let mapRows = {};
        DBmanager.WASM_DB.exec({
          sql: sql,
          rowMode: "object",
          callback: function (r) {
            mapValues[r._date] = r;
          },
        });
        var values = table.filelds_data.values;
        // eslint-disable-next-line
        if (typeof metaData.groupBy == "object") {
          values = values.concat(metaData.groupBy);
        }
        DBmanager.formatTimeLineRows(
          fromDateTS,
          toDateTS,
          deltaTime,
          mapRows,
          rows,
          values,
          sum,
          mapValues
        );
      }
      return rows;
    },
    getGraphQueryParams: function (actionId) {
      return DBmanager.confJson.tablesObj[actionId].filelds_data.parameters;
    },
    updateDdstate: function (tableName) {
      let cacheTable = DBmanager.createCacheTable(tableName);
      DBmanager.WASM_DB.exec({
        sql: "select date FROM `" + cacheTable.name + "`",
        rowMode: "object",
        callback: function (row) {
          DBmanager.dbstateChahe[tableName][parseInt(row["date"])] = 1;
        },
      });
    },
    insertData: function (csv, table, dateRangeArray) {
      DBmanager.WASM_DB.exec(
        "CREATE TABLE IF NOT EXISTS `" +
          table.name +
          "` (" +
          table.create_filelds +
          ")"
      );
      if (!csv) {
        return;
      }
      const valuesCount = table.create_filelds.split(",").length;
      let cacheTable = DBmanager.createCacheTable(table.name);
      let todayStr = DBmanager.getTodayStr();
      let todayTS = DBmanager.getDateRangeArray(todayStr, 0);
      let cacheSql = "";
      dateRangeArray.forEach(function (d) {
        if (d !== todayTS[0]) {
          cacheSql += "('" + d + "'),";
          DBmanager.dbstateChahe[table.name][d] = 1;
        } else DBmanager.dbstateChahe[table.name].todayLastCache = new Date().getTime();
      });
      if (cacheSql) {
        cacheSql =
          "REPLACE INTO `" +
          cacheTable.name +
          "` (date) values " +
          cacheSql.slice(0, -1);
        DBmanager.WASM_DB.exec(cacheSql);
      }

      let deleteDateRange = [];
      let sql =
        "INSERT INTO `" + table.name + "` (" + table.filelds + ") values ";
      const dateIndex = table.dateIndex;
      const hourIndex = table.hourIndex;
      let record;
      let line;
      let pos = 0;
      let nextPos = 0;
      nextPos = csv.indexOf("\n", pos);
      while (nextPos > pos) {
        line = csv.substr(pos, nextPos - pos).replace(/'/g, "''");
        record = line.split(",");
        // eslint-disable-next-line
        if (record.length == valuesCount) {
          if (hourIndex)
            record[dateIndex] = Date.parse(
              record[dateIndex] + "T" + record[hourIndex] + ":00:00.000Z"
            );
          else
            record[dateIndex] = Date.parse(
              record[dateIndex] + "T00:00:00.000Z"
            );
          deleteDateRange.push(record[dateIndex]);
          sql += "('" + record.join("','") + "'),";
        } else {
          console.log("DBmanager csv line length error | table " + table.name);
        }
        pos = nextPos + 1;
        nextPos = csv.indexOf("\n", pos);
      }
      DBmanager.WASM_DB.exec(
        "delete from `" +
          table.name +
          "` where date in('" +
          deleteDateRange.join("','") +
          "')"
      );
      DBmanager.WASM_DB.exec(sql.slice(0, -1));
    },
  },
};

// DBmanager.construct(DBmanConf, {}).then(function (r) {
//   console.log('dbmanager loaded ok');
// });

export default DBmanager;
