import React, { useEffect, useState, useContext } from "react";
import HeyDayCard from "../../../../Shared/Components/Card/SelfServeCard";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { generatePathMap, createObjects } from "../../helpers/helpers";
import DisplayProducts from "../../Products/DisplayProducts";
import ProductFeed from "./ProductFeed";
import { Button, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import GlobalContext from "../../../../Context/Context";
import HeydayCardLoading from "../../../Admin/Loading/HeydayCardLoading";
import { updateIntegrationState } from "../../helpers/helpers";
import LogoutIcon from "@mui/icons-material/Logout";
import { ConfirmationModal } from "../../ConfirmationModal";
import "../ConnectData/ConnectYourData.css";
import InviteMembersModal from "../../../Admin/ManageAccount/InviteMembers/InviteMembersModal";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { formFetch } from "../../../../Shared/functions/fetch";
import ContactUs from "../../ContactUs/ContactUs";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import AttributesPage from "./AttributesPage";

const DisplayDefaultParse = () => {
  const location = useLocation();
  const context = useContext(GlobalContext);
  const navigate = useNavigate();
  const [defaultPathMap, setDefaultPathMap] = useState({});
  const [parsedProductsArray, setParsedProductsArray] = useState([]);
  const [advanced, setAdvanced] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stepModal, setStepModal] = useState(0);
  const [isContactUsPage, setIsContactUsPage] = useState(false);
  const [displayAttributesPage, setDisplayAttributesPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataResponse, setDataResponse] = useState(
    location.state?.responseData || []
  );
  const [productsCount, setProductsCount] = useState(0);
  const domain = sessionStorage.getItem("currentDomain") || "";
  const selectedFields = [
    {
      field: "url",
      path: "link",
    },
  ];

  useEffect(() => {
    if (dataResponse.length > 0) {
      localStorage.setItem("defaultParse", JSON.stringify(dataResponse));
      convertParsedProduct(dataResponse[0], dataResponse[1]);
      setProductsCount(dataResponse[2]);
    } else {
      loadServerDefaultParse();
    }
  }, [dataResponse]);

  const handleClickInvite = () => {
    setStepModal(0);
    setIsModalOpen(true);
  };

  const isExtractAttributesFromURLs = () => {
    const allAttributes = dataResponse[0].reduce((acc, product) => {
      const url = decodeURIComponent(
        product[selectedFields.find((o) => o.field === "url").path]
      );

      const queryString = url.split("?")[1];
      const urlSearchParams = new URLSearchParams(queryString);
      urlSearchParams.forEach((value, key) => {
        if (!acc.find((attr) => attr.attribute === key)) {
          acc.push({ attribute: key, customName: key });
        }
      });
      return acc;
    }, []);

    return allAttributes.length > 0;
  };

  const handleClickNext = async (alreadyDisplayAtter = false) => {
    if (
      !displayAttributesPage &&
      isExtractAttributesFromURLs() &&
      !alreadyDisplayAtter
    ) {
      setDisplayAttributesPage(true);
    } else {
      if (+sessionStorage.getItem("affStatus") === 1) {
        await updateIntegrationState(11, context);
        navigate("/selfserve/11");
      } else {
        await updateIntegrationState(4, context);
        navigate("/selfserve/4");
      }
    }
  };

  const handleSave = async (urlAttributeParams) => {
    const requestJson = {
      action: 18,
      subAction: 1,
      fieldMapping: JSON.stringify(dataResponse[1]),
      attributeConfs: JSON.stringify(urlAttributeParams),
      host: domain,
      credentials: context.loadCredentials(),
    };

    setIsLoading(true);
    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        const pullingData = async (startTime) => {
          const maxTime = 60000;
          const currentTime = new Date().getTime();

          if (currentTime - startTime >= maxTime) {
            setIsLoading(false);
            const emailReq = {
              name: sessionStorage.getItem("contactName") || "",
              email: sessionStorage.getItem("name") || "",
              domain: domain,
              message:
                "Error: polling data - at connect your data step 2 after update",
            };
            formFetch(emailReq)
              .then((r) => {
                console.log("email sended successfully", r);
              })
              .catch((e) => {
                console.error("error sending email:", e);
              });
            navigate("/selfserve/2");
            return context.message({
              type: "error",
              body: "Error by getting your products",
            });
          }

          const req = {
            action: 20,
            host: domain,
            credentials: context.loadCredentials(),
          };

          const res = await context.myFetch(req);
          if (!res.actionStatus) {
            return;
          }
          if (res.noDataYet) {
            setTimeout(() => pullingData(startTime), 1000);
          } else {
            if (res.pollingData) {
              const data = JSON.parse(res.pollingData);
              if (data.length > 0) {
                setIsLoading(false);
                handleClickNext(true);
              }
            }
          }
        };
        pullingData(new Date().getTime());
      }
    } catch (error) {
      setIsLoading(false);
      context.message({
        type: "error",
        body: error,
      });
      console.error("Error:", error);
    }
  };

  const loadServerDefaultParse = async () => {
    const requestJson = {
      action: 18,
      subAction: 2,
      host: domain,
      credentials: context.loadCredentials(),
    };
    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        const pullingData = async (startTime) => {
          const maxTime = 60000;
          const currentTime = new Date().getTime();

          if (currentTime - startTime >= maxTime) {
            const emailReq = {
              name: sessionStorage.getItem("contactName") || "",
              email: sessionStorage.getItem("name") || "",
              domain: domain,
              message: "Error: polling data - at connect your data step 2",
            };
            formFetch(emailReq)
              .then((r) => {
                console.log("email sended successfully", r);
              })
              .catch((e) => {
                console.error("error sending email:", e);
              });
            return context.message({
              type: "error",
              body: "Error by getting your products",
            });
          }

          const req = {
            action: 20,
            host: domain,
            credentials: context.loadCredentials(),
          };

          const res = await context.myFetch(req);
          if (!res.actionStatus) {
            return;
          }
          if (res.noDataYet) {
            setTimeout(() => pullingData(startTime), 1000);
          } else {
            if (res.pollingData) {
              const data = JSON.parse(res.pollingData);
              if (data.length > 0) {
                setDataResponse(data);
              }
            }
          }
        };
        pullingData(new Date().getTime());
      } else {
        context.message({
          type: "error",
          body: response.error,
        });
        setOpenConfirm(true);
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });

      console.error("Error:", error);
    }
  };

  const convertParsedProduct = (productsArray, defaultMap) => {
    const pathMap = generatePathMap(defaultMap);
    if (!pathMap.keywords) {
      pathMap.keywords = "";
    }
    setDefaultPathMap(pathMap);
    const resultArray = createObjects(productsArray, pathMap);
    setParsedProductsArray(resultArray);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div className="logout-container">
        <IconButton onClick={() => context.logout()}>
          <LogoutIcon />
        </IconButton>
        <span>Log Out</span>
      </div>
      <ConfirmationModal
        openConfirm={openConfirm}
        setOpenConfirm={setOpenConfirm}
      />
      <HeyDayCard
        contentStyle={{
          position: "relative",
          minHeight: "560px",
        }}
        sx={{
          width: "100%",
          maxWidth: "880px",
          position: "relative",
          marginTop: "100px",
          boxShadow: {
            xs: "unset",
            md: "0px 5px 7px rgba(113, 113, 113, 0.25)",
          },
        }}
      >
        <ProgressBar stepName={"connectYourData"} progressPercentage={"60"} />
        <div className="hdy-card-title">Connect your data</div>
        {isContactUsPage ? (
          <ContactUs
            setIsContactUsPage={setIsContactUsPage}
            message={"onboarding - Connect your data step 3 - Parse Feed"}
            location={"Connect your data"}
            backTo={"/selfserve/3"}
          />
        ) : (
          <>
            {advanced && (
              <Button
                className="hdy-go-back-btn"
                onClick={() => setAdvanced(false)}
                startIcon={<ArrowBackIosIcon sx={{ fontSize: "15px" }} />}
              >
                Exit from advanced
              </Button>
            )}
            <div
              className="card-line"
              style={{ width: "100%", marginTop: "10px" }}
            ></div>
            {displayAttributesPage ? (
              <>
                {isLoading && <HeydayCardLoading loading={isLoading} />}
                <AttributesPage
                  selectedFields={selectedFields}
                  products={dataResponse[0]}
                  setDisplayAttributesPage={(e) => console.log(e)}
                  handleSaveCustomParse={(attr) => handleSave(attr)}
                  isAdvanced={true}
                  handleClickNext={(e) => handleClickNext(e)}
                />
              </>
            ) : (
              <div>
                {advanced && dataResponse.length > 0 ? (
                  <>
                    <ProductFeed
                      defaultPathMap={defaultPathMap}
                      products={dataResponse[0]}
                      convertParsedProduct={convertParsedProduct}
                      parsedProductsArray={parsedProductsArray}
                      domain={domain}
                    />
                  </>
                ) : (
                  <>
                    {productsCount > 0 && (
                      <div className="connect-data-card-description">
                        We Found {productsCount} Products in Your Feed
                      </div>
                    )}
                    <div className="connect-data-card-description">
                      <div>
                        Here are some of the products parsed from your feed.
                      </div>
                    </div>
                    {parsedProductsArray.length > 0 ? (
                      <div>
                        <DisplayProducts
                          products={parsedProductsArray}
                          setAdvanced={setAdvanced}
                          advanced={advanced}
                        />
                      </div>
                    ) : (
                      <HeydayCardLoading loading={true} />
                    )}

                    <div className="hdy-footer-card">
                      <div className="hdy-back-link-container">
                        <span>
                          <ArrowBackIosIcon />
                        </span>
                        <Link to="/selfserve/2" className="hdy-back-link">
                          Back
                        </Link>
                      </div>
                      <Button
                        onClick={() => handleClickNext()}
                        className="hdy-next-btn"
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </HeyDayCard>
      <InviteMembersModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        step={stepModal}
        setStep={setStepModal}
      />
      <div
        style={{
          width: "90%",
          flexWrap: "wrap",
          gap: "20px",
        }}
        className="invite-btn-container"
      >
        <Button
          className="problem-btn-ccd"
          onClick={(e) => setIsContactUsPage(true)}
          startIcon={
            <QuestionMarkOutlinedIcon
              sx={{
                color: "#5daaa1",
                backgroundColor: "#ffffff",
                borderRadius: "25px",
                padding: "5px",
              }}
            />
          }
        >
          contact-us
        </Button>
        <Button
          onClick={handleClickInvite}
          className="invite-btn"
          startIcon={
            <PersonAddAltOutlinedIcon
              sx={{
                color: "#5daaa1",
                backgroundColor: "#ffffff",
                borderRadius: "25px",
                padding: "5px",
              }}
            />
          }
        >
          Invite members
        </Button>
      </div>
    </div>
  );
};

export default DisplayDefaultParse;
