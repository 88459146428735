import { Route, Routes, Navigate } from "react-router-dom";
import Terms from "../Auth/SignUp/Terms";
import ConnectYourData from "../Components/SelfServe/Steps/ConnectData/ConnectYourData";
import Payment from "../Components/SelfServe/Steps/Payment/Payment";
import DisplayDefaultParse from "../Components/SelfServe/Steps/FeedParsingSelection/DisplayDefaultParse";
import IntegrationComplete from "../Components/SelfServe/Steps/IntegrationCompolete/IntegrationComplete";
import MetaTag from "../Components/SelfServe/Steps/MetaTag/MetaTag";
import Login from "../Auth/Login/Login";
import ThankYouPage from "../Components/SelfServe/Steps/Payment/ThankYouPage";
import ReachOutSoon from "../Components/SelfServe/ContactUs/ReachOutSoon";
import SearchPreview from "../Components/SelfServe/Steps/SearchPreview/SearchPreview";

const AppRoutes = () => {
  const tos = +sessionStorage.getItem("TOS");
  const token = sessionStorage.getItem("token");
  const integrationStep = +sessionStorage.getItem("integrationState");
  const affStatus = +sessionStorage.getItem("affStatus") || 0;
  let redirect = "/selfserve/2";
  if (integrationStep !== 0) {
    if (affStatus === 1 && integrationStep === 4) {
      redirect = "/selfserve/5";
    } else {
      if (integrationStep === 1 && tos === 1) {
        redirect = "/selfserve/2";
      } else {
        redirect = "/selfserve/" + integrationStep;
      }
    }
  }

  if (tos === 0 && token) {
    return (
      <Routes>
        <Route path="/selfserve/1" element={<Terms isSelfServe={true} />} />
        <Route path="/*" element={<Navigate to="/selfserve/1" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/selfserve/2" element={<ConnectYourData />} />
      <Route path="/selfserve/3" element={<DisplayDefaultParse />} />
      <Route path="/selfserve/4" element={<Payment />} />
      <Route path="/selfserve/5" element={<MetaTag />} />
      <Route path="/selfserve/6" element={<IntegrationComplete />} />
      <Route path="/selfserve/10" element={<ReachOutSoon />} />
      <Route path="/selfserve/11" element={<SearchPreview />} />
      <Route path="/selfserve/thank-you-page/*" element={<ThankYouPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<Navigate to={redirect} replace />} />
    </Routes>
  );
};

export default AppRoutes;
