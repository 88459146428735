import React, { useState, useEffect, useContext } from "react";
import ContentPageHeader from "../ContentPageHeader/ContentPageHeader";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import GlobalContext from "../../../Context/Context";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import "./Search.css";

const Filters = () => {
  const context = useContext(GlobalContext);
  const params = useParams();
  const domain = context.getDomains()[params?.index];
  const [filters, setFilters] = useState("");
  const [boosts1, setBoosts1] = useState("");
  const [boosts2, setBoosts2] = useState("");
  const [boosts3, setBoosts3] = useState("");

  const getFilters = async () => {
    const requestJson = {
      action: 42,
      host: domain && domain.domain,
    };

    requestJson.credentials = context.loadCredentials();
    try {
      const responseJson = await context.myFetch(requestJson);
      if (responseJson.urlPrefixList) {
        const urlPrefixList = responseJson.urlPrefixList;
        const filters = [];
        const boosts1 = [];
        const boosts2 = [];
        const boosts3 = [];

        Object.keys(urlPrefixList).forEach((url) => {
          switch (urlPrefixList[url]) {
            case 1:
              filters.push(url);
              break;
            case 2:
              boosts1.push(url);
              break;
            case 3:
              boosts2.push(url);
              break;
            case 4:
              boosts3.push(url);
              break;
            default:
              filters.push(url);
          }
        });

        setFilters(filters.join("\n"));
        setBoosts1(boosts1.join("\n"));
        setBoosts2(boosts2.join("\n"));
        setBoosts3(boosts3.join("\n"));
      } else {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveFilters = async (filteredUrls) => {
    const requestJson = {
      action: 1041,
      host: domain && domain.domain,
      urlPrefixList: filteredUrls,
    };
    requestJson.credentials = context.loadCredentials();
    try {
      const responseJson = await context.myFetch(requestJson);
      if (!responseJson.error) {
        getFilters();
        return context.message({
          type: "success",
          body: "Filters have been saved",
        });
      }
      return context.message({
        type: "error",
        body: responseJson.error,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const setFilter = (e) => {
    setFilters(e.target.value);
  };
  const setBoost1 = (e) => {
    setBoosts1(e.target.value);
  };
  const setBoost2 = (e) => {
    setBoosts2(e.target.value);
  };
  const setBoost3 = (e) => {
    setBoosts3(e.target.value);
  };

  const composeLinks = () => {
    const error = (el) =>
      context.message({
        type: "error",
        body: `Link '${el}' is invalid`,
      });

    const linkObj = {};
    const domains = [filters, boosts1, boosts2, boosts3];
    const linkValues = [1, 2, 3, 4];

    domains.forEach((domainLinks, index) => {
      if (domainLinks) {
        const arr = domainLinks.split("\n").filter((el) => el.trim() !== "");

        const newArr = arr.map((el) => {
          if (el.includes("://")) {
            let tmp = new URL(el);
            return tmp.hostname === domain.domain
              ? `${tmp.hostname}${tmp.pathname}`
              : error(el);
          }

          return el.substring(0, el.indexOf("/")) === domain.domain
            ? el.replaceAll(/ /g, "")
            : error(el);
        });

        newArr.forEach((link) => (linkObj[link] = linkValues[index]));
      }
    });

    if (!Object.values(linkObj).includes(undefined)) {
      saveFilters(linkObj);
    }
  };

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="search-filters-container">
      <section className="content-header">
        <ContentPageHeader pageName="Filters" subMenu="Search" />
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "750px",
          boxShadow: "unset",
        }}
      >
        <section className="content searchFilters">
          <p>Paste your links including domain name ({domain.domain}):</p>
          <div>
            <textarea
              value={filters}
              placeholder={`${domain.domain}/<your_uri>`}
              onChange={setFilter}
            />
          </div>
        </section>
        <section className="content searchFilters">
          <p>
            Indexer scoring boost 1 - Paste your links including domain name (
            {domain.domain}):
          </p>
          <div>
            <textarea
              value={boosts1}
              placeholder={`${domain.domain}/<your_uri>`}
              onChange={setBoost1}
            />
          </div>
        </section>
        <section className="content searchFilters">
          <p>
            Indexer scoring boost 2 - Paste your links including domain name (
            {domain.domain}):
          </p>
          <div>
            <textarea
              value={boosts2}
              placeholder={`${domain.domain}/<your_uri>`}
              onChange={setBoost2}
            />
          </div>
        </section>
        <section className="content searchFilters">
          <p>
            Indexer scoring boost 3 - Paste your links including domain name (
            {domain.domain}):
          </p>
          <div>
            <textarea
              value={boosts3}
              placeholder={`${domain.domain}/<your_uri>`}
              onChange={setBoost3}
            />
          </div>
        </section>
        <Button className="saveBtn" onClick={composeLinks}>
          Save
        </Button>
      </AdminCard>
    </div>
  );
};

export default Filters;
