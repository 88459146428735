import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../../Context/Context";
import { useParams } from "react-router-dom";
import ContentPageHeader from "../ContentPageHeader/ContentPageHeader";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import { DatePicker } from "antd";
import HeyDayTable from "../../../Shared/Components/Table/HeyDayTable";
import NoStatistics from "./NoStatistics/NoStatistics";
import HeydayCardLoading from "../Loading/HeydayCardLoading";
import ReplayIcon from "@mui/icons-material/Replay";

dayjs.extend(customParseFormat);
const SearchQueries = () => {
  const [table, setTable] = useState([]);
  const [page, setPage] = useState(0);
  const context = useContext(GlobalContext);
  const params = useParams();
  const domain = context.getDomains()[params?.index]?.domain;
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isClickedReload, setIsClickedReload] = useState(false);
  const [dateStart, setDateStart] = useState(
    dayjs(Date.now()).subtract(7, "d").format("YYYY-MM-DD")
  );
  const dateEnd = dayjs(Date.now()).format("YYYY-MM-DD");
  const actionId = "28";
  const [daysBack, setDaysBack] = useState(6);
  const [startDay, setStartDay] = useState(
    dayjs(Date.now()).format("YYYY-MM-DD")
  );
  const [metaData, setMetaData] = useState({
    graphType: "summery",
    where: {
      searchClick: {
        isExclude: false,
        params: ["Y", "N"],
      },
    },
    groupBy: ["query"],
    orderBy: ["count"],
  });

  const { RangePicker } = DatePicker;
  const handleChangeTab = (event, newValue) => {
    switch (newValue) {
      case 0:
        setMetaData({
          graphType: "summery",
          where: {
            searchClick: {
              isExclude: false,
              params: ["Y", "N"],
            },
          },
          groupBy: ["query"],
          orderBy: ["count"],
        });
        break;
      case 1:
        setMetaData({
          graphType: "summery",
          where: {
            searchClick: {
              isExclude: false,
              params: ["Y"],
            },
          },
          groupBy: ["query"],
          orderBy: ["count"],
        });
        break;
      case 2:
        setMetaData({
          graphType: "summery",
          where: {
            searchClick: {
              isExclude: false,
              params: ["N"],
            },
          },
          groupBy: ["query"],
          orderBy: ["count"],
        });
        break;
      // case 3:
      //   setMetaData({
      //     graphType: "summery",
      //     where: {
      //       searchClick: {
      //         isExclude: true,
      //         params: ["Y", "N"],
      //       },
      //     },
      //     groupBy: ["query", "searchClick"],
      //     orderBy: ["count"],
      //   });
      //   break;
      default:
    }
    setPage(0);
    setTabValue(newValue);
  };

  const onDateChange = (dateString) => {
    const start = Date.parse(dateString[0]);
    const end = Date.parse(dateString[1]);
    const diff = end - start;
    setDaysBack(Math.round(diff / (1000 * 60 * 60 * 24)));
    setDateStart(dateString[0].format("YYYY-MM-DD"));
    setStartDay(dateString[1].format("YYYY-MM-DD"));
  };

  const getChartData = async () => {
    setLoading(true);
    let startFetchTime = Date.now();
    try {
      context.DBmanager.getGraphData(
        context.loadCredentials(),
        startDay,
        daysBack,
        actionId,
        domain,
        metaData
      ).then((result) => {
        setTable(result);
        const elapsedTime = Date.now() - startFetchTime;
        const remainingTime = Math.max(0, 1000 - elapsedTime);
        if (remainingTime > 0) {
          setTimeout(() => setLoading(false), remainingTime);
        } else {
          setLoading(false);
        }
      });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    if (context.state.DBmanagerReady) {
      getChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    daysBack,
    startDay,
    tabValue,
    context.state.DBmanagerReady,
    isClickedReload,
  ]);

  return (
    <div>
      <section className="content-header">
        <ContentPageHeader pageName="Search queries" subMenu="Statistics" />
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
          minHeight: "270px",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "1000px",
          boxShadow: "unset",
        }}
      >
        {loading && <HeydayCardLoading loading={loading} />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="graphType-tab"
            sx={{
              backgroundColor: "var(--Light-Grey, #F4F6F8)",
              borderRadius: "37px",
              padding: "5px",
              maxWidth: "60%",
              "& .MuiTabs-indicator": {
                backgroundColor: "unset",
              },
              "& .Mui-selected": {
                color: "#616060 !important",
                backgroundColor: "#fff",
                borderRadius: "100px",
              },
            }}
          >
            <Tab
              sx={{
                textTransform: "unset",
                fontFamily: "Poppins",
                color: "#9A9B9D",

                fontSize: "14px",
              }}
              label="All queries"
              aria-label="All-queries"
            />
            <Tab
              sx={{
                textTransform: "unset",
                fontFamily: "Poppins",

                color: "#9A9B9D",
                fontSize: "14px",
              }}
              label="Queries with results"
              aria-label="Queries-with-results"
            />
            <Tab
              sx={{
                textTransform: "unset",
                fontFamily: "Poppins",

                color: "#9A9B9D",

                fontSize: "14px",
              }}
              label="Queries without results"
              aria-label="Queries-without-results"
            />
            {/* <Tab
              sx={{
                textTransform: "unset",
                fontFamily: "Poppins",

                color: "#9A9B9D",

                fontSize: "14px",
              }}
              label="Queries with Clicks"
              aria-label="Queries-with-Clicks"
            /> */}
          </Tabs>
          <div className="site-analytics-main-section">
            <IconButton onClick={() => setIsClickedReload(!isClickedReload)}>
              <ReplayIcon />
            </IconButton>
            <RangePicker
              className="heyday-custom-datepicker"
              style={{ width: "250px" }}
              format={"YYYY-MM-DD"}
              defaultValue={[
                dayjs(dateStart, "YYYY-MM-DD"),
                dayjs(dateEnd, "YYYY-MM-DD"),
              ]}
              onChange={onDateChange}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </div>
        </Box>
        <div style={{ marginTop: "30px" }}>
          {table?.length > 0 ? (
            <HeyDayTable
              rows={table}
              keys={Object.keys(table[0])}
              page={page}
              setPage={setPage}
            />
          ) : (
            <NoStatistics />
          )}
        </div>
      </AdminCard>
    </div>
  );
};

export default SearchQueries;
