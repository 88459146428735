const requestDataWithParams = (url, Context, type) => {
  let host;
  let clearedUrl = url.trim();
  const hasProtocol = new RegExp("^https?://").test(clearedUrl);
  const tmp = hasProtocol ? clearedUrl : `https://${clearedUrl}`;
  let getUrl;
  try {
    getUrl = new URL(tmp);
    host = getUrl.host;
  } catch {
    return { error: "Url is not valid" };
  }

  const requestJson = {
    action: 1038,
    subAction: type,
    host: host,
    credentials: Context.loadCredentials(),
    url: getUrl || url,
  };

  return fetchRequest(requestJson, Context);
};

const fetchRequest = async (requestJson, Context) => {
  const responseJson = await Context.myFetch(requestJson);
  return responseJson;
};

const filterRules = (fields, query, properties) => {
  return (
    fields &&
    fields.length &&
    fields.filter((field) => {
      let matches = true;

      if (query) {
        let containsQuery = false;

        properties.forEach((property) => {
          let check = field[property]?.name || field[property];
          if (
            check &&
            check.trim().toLowerCase().includes(query.toLowerCase())
          ) {
            containsQuery = true;
          }
        });

        if (!containsQuery) {
          matches = false;
        }
      }

      return matches;
    })
  );
};

export { requestDataWithParams, filterRules };
