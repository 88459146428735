import React, { useState, useContext } from "react";
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import HeyDayTextField from "../../../Shared/Components/TextField/HeyDayTextField";
import HeyDayAutocomplete from "../../../Shared/Components/TextField/HeyDayAutocomplete";
import countries from "../../../Shared/countries.json";
import { enforceFormat, formatToPhone } from "../Steps/Payment/phoneUtils";
import GlobalContext from "../../../Context/Context";
import { useNavigate } from "react-router-dom";

const ContactUs = ({ setIsContactUsPage, message, location, backTo }) => {
  const context = useContext(GlobalContext);
  const navigate = useNavigate();
  const [domainVisitorsNum, setDomainVisitorsNum] = useState("");
  const [contactName, setContactName] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const [errors, setErrors] = useState({});

  const handleChangeCountry = (countryObj) => {
    if (countryObj) {
      setCountry(countryObj.code + "-" + countryObj.name);
    } else {
      setCountry("");
    }
  };

  const isValidUrl = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(url);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!contactName) newErrors.contactName = "Contact name is required";
    if (!phoneNumber) newErrors.phoneNumber = "Phone number is required";
    if (!siteUrl) {
      newErrors.siteUrl = "Website URL is required";
    } else if (!isValidUrl(siteUrl)) {
      newErrors.siteUrl = "Invalid URL format";
    }
    if (!country) newErrors.country = "Country is required";
    if (!domainVisitorsNum)
      newErrors.domainVisitorsNum = "Visitors number is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClickNext = async () => {
    if (!validateFields()) {
      context.message({
        type: "error",
        body: "Please fill all required fields correctly.",
      });
      return;
    }

    const requestJson = {
      name: contactName,
      email: sessionStorage.getItem("name") || "",
      phoneNumber,
      country,
      siteUrl,
      domainVisitorsNum,
      message: message,
    };
    // return;
    try {
      const response = await formFetch(requestJson);
      if (!response.error) {
        console.log(response);
        navigate("/selfserve/10", {
          state: { backTo: backTo, location: location },
        });
        // setIframeUrl(response.url);
        context.message({
          type: "success",
          body: "Your message sent successfully",
        });
      } else {
        context.message({
          type: "error",
          body: response.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
      console.error("Error:", error);
    }
  };

  const handleChangePhone = (event) => {
    enforceFormat(event);
    formatToPhone(event, setPhoneNumber);
  };

  const formFetch = async (
    requestJson,
    data = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ) => {
    data.body = data.body || JSON.stringify(requestJson);
    let result;
    try {
      const f = await fetch("https://cdn.heyday.io/form/", data);
      result = await f.json();
    } catch (e) {
      result = { error: e };
    }
    return result;
  };

  return (
    <div className="payment-contact-us-fields">
      <p>
        Please provide your details and we will contact you soon as possible
      </p>
      <HeyDayTextField
        style={{ width: "100%", marginTop: "20px" }}
        required
        labelValue="Contact Name"
        value={contactName}
        onChange={(e) => setContactName(e.target.value)}
        error={!!errors.contactName}
        helperText={errors.contactName || ""}
      />
      <HeyDayTextField
        id="phoneNumber"
        style={{
          marginTop: "20px",
          width: "100%",
        }}
        required
        value={phoneNumber}
        onChange={handleChangePhone}
        placeholder="(123) 456 - 789012"
        label="Phone Number"
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber || ""}
      />
      <HeyDayTextField
        style={{ width: "100%", marginTop: "20px" }}
        required
        value={siteUrl}
        labelValue="Website URL"
        onChange={(e) => setSiteUrl(e.target.value)}
        error={!!errors.siteUrl}
        helperText={errors.siteUrl || ""}
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <HeyDayAutocomplete
          id="hdy-countries-ac"
          searchKeys={["name", "code", "dial_code"]}
          requiredField={true}
          onChange={(event, newValue) => {
            handleChangeCountry(newValue);
          }}
          style={{ width: "49%", marginTop: "20px" }}
          options={countries}
          getOptionLabelKeys={["flag", "name"]}
          label="Country"
          disablePortal
        />
        <FormControl sx={{ width: "49%", marginTop: "20px" }}>
          <InputLabel
            sx={{
              color: "#929292",
              fontFamily: "Poppins",
              left: "15px",
              top: "-5px",

              "&.Mui-focused": {
                color: "#929292 !important",
                top: "-5px",
                left: "15px",
              },
            }}
          >
            Monthly Visitors number
          </InputLabel>
          <Select
            value={domainVisitorsNum}
            onChange={(e) => setDomainVisitorsNum(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            error={!!errors.domainVisitorsNum}
          >
            <MenuItem sx={{ fontFamily: "Poppins" }} value={"100-5000"}>
              100-5,000
            </MenuItem>
            <MenuItem sx={{ fontFamily: "Poppins" }} value={"5000-10000"}>
              5,000-10,000
            </MenuItem>
            <MenuItem sx={{ fontFamily: "Poppins" }} value={"10000-20000"}>
              10,000-20,000
            </MenuItem>
            <MenuItem sx={{ fontFamily: "Poppins" }} value={"20000-50000"}>
              20,000-50,000
            </MenuItem>
            <MenuItem sx={{ fontFamily: "Poppins" }} value={"50000-200000"}>
              50,000-200,000
            </MenuItem>
            <MenuItem sx={{ fontFamily: "Poppins" }} value={"200000-1000000"}>
              200,000-1,000,000
            </MenuItem>
            <MenuItem sx={{ fontFamily: "Poppins" }} value={"1000000+"}>
              1,000,000+
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="hdy-footer-card">
        <div className="hdy-back-link-container">
          <Button
            className="back-to-payment-btn"
            onClick={(e) => setIsContactUsPage(false)}
          >
            Back to {location}
          </Button>
        </div>
        <Button onClick={handleClickNext} className="hdy-next-btn">
          Next
        </Button>
      </div>
    </div>
  );
};

export default ContactUs;
