import React, { useState, useEffect } from "react";
import { TextField, FormControl, Select, MenuItem } from "@mui/material";
import HeyDayFormLabel from "../../../../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import ButtonSave from "../../../../../../../../../Shared/Components/Button/ButtonSave";
import { removeFormatting, extractStyleValue } from "../utils/helpers";

const ImageCustomizer = ({
  smLogo,
  domain,
  configs,
  setConfigs,
  closeAccordion,
  context,
}) => {
  const [width, setWidth] = useState("100px");
  const [height, setHeight] = useState("100px");
  const [objectFit, setObjectFit] = useState("contain");
  const [alignment, setAlignment] = useState("center");
  const [widthError, setWidthError] = useState("");
  const [heightError, setHeightError] = useState("");

  useEffect(() => {
    const searchBoxConfig = configs.find((item) => item.searchBox);
    if (searchBoxConfig && searchBoxConfig.searchBox.stylesheet) {
      const stylesheet = searchBoxConfig.searchBox.stylesheet;
      setWidth(
        extractStyleValue(
          stylesheet,
          "#heyday_SB_SM_holder .hd-s-main .header img",
          "width"
        ) || "100px"
      );
      setHeight(
        extractStyleValue(
          stylesheet,
          "#heyday_SB_SM_holder .hd-s-main .header img",
          "height"
        ) || "100px"
      );
      setObjectFit(
        extractStyleValue(
          stylesheet,
          "#heyday_SB_SM_holder .hd-s-main .header img",
          "object-fit"
        ) || "contain"
      );
      setAlignment(
        extractStyleValue(
          stylesheet,
          "#heyday_SB_SM_holder .hd-s-main .header",
          "text-align"
        ) || "center"
      );
    }
  }, [configs]);

  const validCSSUnit =
    /^(auto|0|(\d+(\.\d+)?)(px|em|rem|%|vh|vw|vmin|vmax|ch|ex|cm|mm|in|pt|pc))$/;

  const handleStyleChange = (setter, setError) => (event) => {
    const value = event.target.value;
    if (!validCSSUnit.test(value)) {
      if (value !== "") {
        setError("Invalid format. Use valid CSS units.");
      } else {
        setError("");
      }
      setter(value);
    } else {
      setError("");
      setter(value);
    }
  };

  const updateCss = (stylesheet, selector, property, value) => {
    const regex = new RegExp(
      `(${selector}\\s*{[^}]*)(\\s*${property}:\\s*[^;]+;)`,
      "i"
    );
    const newProperty = `${property}: ${value};`;

    if (regex.test(stylesheet)) {
      return stylesheet.replace(regex, `$1${newProperty}`);
    } else {
      const selectorRegex = new RegExp(`(${selector}\\s*{)`, "i");
      if (selectorRegex.test(stylesheet)) {
        return stylesheet.replace(selectorRegex, `$1\n  ${newProperty}`);
      } else {
        return `${stylesheet}\n${selector} {\n  ${newProperty}\n}`;
      }
    }
  };

  const handleSaveConfig = async () => {
    let updatedConfig = [...configs];
    const searchBoxIndex = updatedConfig.findIndex((item) => item.searchBox);

    if (searchBoxIndex !== -1 && updatedConfig[searchBoxIndex].searchBox) {
      if (!updatedConfig[searchBoxIndex].searchBox.customLogo) {
        updatedConfig[searchBoxIndex].searchBox.customLogo = {};
      }
      updatedConfig[searchBoxIndex].searchBox.customLogo.SM_holder = smLogo;
      let stylesheet = updatedConfig[searchBoxIndex].searchBox.stylesheet || "";

      stylesheet = updateCss(
        stylesheet,
        "#heyday_SB_SM_holder .hd-s-main .header img",
        "width",
        width
      );
      stylesheet = updateCss(
        stylesheet,
        "#heyday_SB_SM_holder .hd-s-main .header img",
        "height",
        height
      );
      stylesheet = updateCss(
        stylesheet,
        "#heyday_SB_SM_holder .hd-s-main .header img",
        "object-fit",
        objectFit
      );

      stylesheet = updateCss(
        stylesheet,
        "#heyday_SB_SM_holder .hd-s-main .header",
        "width",
        "100%"
      );

      stylesheet = updateCss(
        stylesheet,
        "#heyday_SB_SM_holder .hd-s-main .header",
        "display",
        "flex"
      );

      stylesheet = updateCss(
        stylesheet,
        "#heyday_SB_SM_holder .hd-s-main .header",
        "text-align",
        alignment
      );

      const justify =
        alignment === "center"
          ? alignment
          : alignment === "right"
          ? "flex-start"
          : "flex-end";
      stylesheet = updateCss(
        stylesheet,
        "#heyday_SB_SM_holder .hd-s-main .header",
        "justify-content",
        justify
      );

      updatedConfig[searchBoxIndex].searchBox.stylesheet =
        removeFormatting(stylesheet);
    }

    try {
      const requestJson = {
        action: 1014,
        host: domain,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };

      const responseJson = await context.myFetch(requestJson);
      if (responseJson.domains) {
        setConfigs(updatedConfig);
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );
        context.message({
          type: "success",
          body: "Indexing settings were successfully updated",
        });
        setTimeout(() => {
          closeAccordion();
        }, 800);
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
    }
  };

  return (
    <div>
      <div style={{ textAlign: alignment }}>
        <img
          className="sm-logo"
          alt="website-logo"
          src={smLogo}
          style={{ width, height, objectFit }}
        />
      </div>
      <div className="logo-style-flex-form-card">
        <div className="style-form">
          <div className="inputWizard-container">
            <HeyDayFormLabel sx={{ width: "125px" }}>Width:</HeyDayFormLabel>
            <TextField
              sx={{
                width: { lg: "180px", xs: "100%" },
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              value={width}
              onChange={handleStyleChange(setWidth, setWidthError)}
              placeholder="e.g., 100px, 50%"
              error={!!widthError}
              helperText={widthError}
            />
          </div>
          <div className="inputWizard-container">
            <HeyDayFormLabel sx={{ width: "125px" }}>Height:</HeyDayFormLabel>
            <TextField
              sx={{
                width: { lg: "180px", xs: "100%" },
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              value={height}
              onChange={handleStyleChange(setHeight, setHeightError)}
              placeholder="e.g., 100px, 50%"
              error={!!heightError}
              helperText={heightError}
            />
          </div>
          <div className="inputWizard-container">
            <HeyDayFormLabel sx={{ width: "125px" }}>
              Object Fit:
            </HeyDayFormLabel>
            <FormControl
              sx={{ minWidth: 120, width: { md: "180px", xs: "100%" } }}
            >
              <Select
                value={objectFit}
                onChange={(e) => setObjectFit(e.target.value)}
                sx={{
                  width: "180px",
                  height: "44px",
                  margin: 0,
                  border: "2px solid var(--Grey, #EBEFF3)",
                  backgroundColor: "white",
                  borderRadius: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    width: "180px",
                    fontFamily: "Poppins",
                    color: "#616060",
                    margin: 0,
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputLabel-shrink": {
                    fontFamily: "Poppins",
                    color: "#616060",
                  },
                }}
              >
                <MenuItem value="fill">Fill</MenuItem>
                <MenuItem value="contain">Contain</MenuItem>
                <MenuItem value="cover">Cover</MenuItem>
                <MenuItem value="scale-down">Scale Down</MenuItem>
                <MenuItem value="none">None</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="inputWizard-container">
            <HeyDayFormLabel sx={{ width: "125px" }}>
              Alignment:
            </HeyDayFormLabel>
            <FormControl
              sx={{ minWidth: 120, width: { md: "180px", xs: "100%" } }}
            >
              <Select
                value={alignment}
                onChange={(e) => setAlignment(e.target.value)}
                sx={{
                  width: "180px",
                  height: "44px",
                  margin: 0,
                  border: "2px solid var(--Grey, #EBEFF3)",
                  backgroundColor: "white",
                  borderRadius: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    width: "180px",
                    fontFamily: "Poppins",
                    color: "#616060",
                    margin: 0,
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputLabel-shrink": {
                    fontFamily: "Poppins",
                    color: "#616060",
                  },
                }}
              >
                <MenuItem value="left">Left</MenuItem>
                <MenuItem value="center">Center</MenuItem>
                <MenuItem value="right">Right</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <ButtonSave
          style={{ marginTop: 10 }}
          text={"Save"}
          onClick={handleSaveConfig}
        />
      </div>
    </div>
  );
};

export default ImageCustomizer;
