import React, { useState, useContext } from "react";
import {
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import GlobalContext from "../../../../Context/Context";
import { useParams } from "react-router-dom";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import HeyDayFormLabel from "../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import "../Search.css";
const IndexingRulesForm = ({ domain }) => {
  const params = useParams();
  const context = useContext(GlobalContext);
  const configs = JSON.parse(domain.configs) || [];
  const searchBoxIndex = configs.findIndex((item) => item.searchBox);
  const searchBox = configs[searchBoxIndex]
    ? configs[searchBoxIndex].searchBox
    : {};

  const [imageOnly, setImageOnly] = useState(
    (searchBox && searchBox.imageOnly) || false
  );
  const [productOnly, setProductOnly] = useState(
    (searchBox && searchBox.productOnly) || false
  );
  const [recommendList, setRecommendList] = useState(
    (searchBox && searchBox.recommend) || []
  );
  const [mainElValue, setMainElValue] = useState(
    (searchBox && searchBox.mainEl) || ""
  );

  const searchBoxList = [
    {
      textAreaId: "mainEl",
      textAreaLabel: "Main article element:",
      textAreaPlaceholder: "#yourDomSelector",
      textAreaIdEdit: mainElValue,
      formItemType: "input",
      setter: setMainElValue,
    },
    {
      textAreaId: "imageOnly",
      textAreaLabel: "Pages with images only:",
      textAreaIdEdit: imageOnly,
      formItemType: "checkbox",
      setter: setImageOnly,
    },
    {
      textAreaId: "productOnly",
      textAreaLabel: "Product pages only:",
      textAreaIdEdit: productOnly,
      formItemType: "checkbox",
      setter: setProductOnly,
    },
  ];

  const toggleEdit = (key) => {
    const setter = searchBoxList.find((item) => item.textAreaId === key).setter;
    setter((prevState) => !prevState);
  };

  const deleteRecommend = (index) => {
    let stateList = [...recommendList];
    stateList.splice(index, 1);
    setRecommendList(stateList);
  };

  const addListItem = () => {
    setRecommendList((prevState) => ["", ...prevState]);
  };

  const updateListItem = (e, index) => {
    const value = e.target.value;
    let tmpArr = [...recommendList];
    tmpArr.splice(index, 1, value);
    setRecommendList(tmpArr);
  };

  const saveIndexingRules = async (e) => {
    e.preventDefault();
    const dom = context.getDomains()[params?.index];
    const confArray = dom?.configs ? JSON.parse(dom.configs) : [];
    if (!Array.isArray(confArray)) {
      context.message({
        type: "error",
        body: "Configurations are not properly loaded.",
      });
      return;
    }

    const updatedConfig = [...confArray];
    const searchBoxIndex = updatedConfig.findIndex((item) => item.searchBox);
    updatedConfig[searchBoxIndex].searchBox.imageOnly = imageOnly;
    updatedConfig[searchBoxIndex].searchBox.productOnly = productOnly;
    updatedConfig[searchBoxIndex].searchBox.recommend = recommendList;
    updatedConfig[searchBoxIndex].searchBox.mainEl = mainElValue;
    const host = sessionStorage.getItem("currentDomain");

    try {
      const requestJson = {
        action: 1014,
        host: host,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };
      const responseJson = await context.myFetch(requestJson);
      if (responseJson.domains) {
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );
        context.message({
          type: "success",
          body: "Indexing setting saved successfully",
        });
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
    }
  };

  return (
    <section>
      <form className="indexingRulesSection" onSubmit={saveIndexingRules}>
        <p>Indexing settings</p>
        {searchBoxList.map((formItem, index) => (
          <div key={index}>
            {formItem.formItemType === "input" ? (
              <Box
                sx={{
                  width: "100%",
                  marginTop: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <HeyDayFormLabel sx={{ display: "flex", marginRight: "15px" }}>
                  {formItem.textAreaLabel}
                </HeyDayFormLabel>

                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <TextField
                    sx={{
                      width: "100%",
                      marginRight: "20px",
                      "& .MuiOutlinedInput-root": {
                        height: "44px",
                        border: "2px solid var(--Grey, #EBEFF3)",
                        borderRadius: "100px",
                        "& fieldset": {
                          borderColor: "white",
                          border: "none",
                        },
                      },
                    }}
                    inputProps={{
                      sx: {
                        "&::placeholder": {
                          color: "#9a9b9d",
                        },
                      },
                    }}
                    value={mainElValue}
                    onChange={(e) => setMainElValue(e.target.value)}
                    id={formItem.textAreaId}
                    placeholder={formItem.textAreaPlaceholder}
                  />
                </div>
              </Box>
            ) : (
              <Grid item xs={12} key={index}>
                <FormControlLabel
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",

                    margin: 0,
                  }}
                  control={
                    <Checkbox
                      checked={formItem.textAreaIdEdit}
                      onChange={() => toggleEdit(formItem.textAreaId)}
                    />
                  }
                  label={formItem.textAreaLabel}
                  labelPlacement="start"
                />
              </Grid>
            )}
          </div>
        ))}
        <div className="searchRecommend">
          <div className="recommendDescr">
            <p className="non-margin">Define search suggestions</p>
            <IconButton
              disabled={recommendList.length >= 10}
              onClick={addListItem}
            >
              <AddCircleOutlineRoundedIcon sx={{ color: "#5DAAA1" }} />
            </IconButton>
          </div>
          <div className="recommendList">
            {recommendList.map((el, index) => (
              <div key={index} className="listItem">
                <TextField
                  sx={{
                    width: "100%",
                    marginRight: "20px",

                    "& .MuiOutlinedInput-root": {
                      height: "44px",
                      border: "2px solid var(--Grey, #EBEFF3)",
                      borderRadius: "100px",
                      background: "#ffffff",
                      "& fieldset": {
                        borderColor: "white",
                        border: "none",
                      },
                    },
                  }}
                  inputProps={{
                    sx: {
                      fontFamily: "Poppins",
                      "&::placeholder": {
                        color: "#9a9b9d",
                      },
                    },
                  }}
                  value={el}
                  onChange={(e) => updateListItem(e, index)}
                  placeholder="Add recommendation text..."
                  fullWidth
                  autoComplete="off"
                />
                <IconButton onClick={() => deleteRecommend(index)}>
                  <DeleteOutlineRoundedIcon sx={{ color: "#F64A4A" }} />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
        <Button
          className="margin-lg-top"
          sx={{
            textAlign: "left",
            display: "block",
            backgroundColor: "#ffffff",
            color: "var(--Green, #5daaa1)",
            borderRadius: "25px",
            marginTop: "40px",
            border: "2px solid",
            textTransform: "unset",

            borderColor: "var(--Green, #5daaa1)",
            fontFamily: "Poppins",
            padding: "5px 15px",
            height: "40px",
            "&:hover": {
              backgroundColor: "#5daaa1",
              border: "2px solid",
              borderColor: "var(--Green, #5daaa1)",
              color: "var(--Green, #ffffff)",
            },
          }}
          onClick={saveIndexingRules}
        >
          Save changes
        </Button>
      </form>
    </section>
  );
};

export default IndexingRulesForm;
