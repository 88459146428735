import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const HeydayCardLoading = ({ loading }) => {
  return (
    <div className="heyday-card-connect-data-loading">
      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#feba3f" }} />
        </Box>
      )}
    </div>
  );
};

HeydayCardLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default HeydayCardLoading;
