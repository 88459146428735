import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import ContentPageHeader from "../ContentPageHeader/ContentPageHeader";
import GlobalContext from "../../../Context/Context";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Tabs, Tab, Box, Typography, IconButton } from "@mui/material";
import { DatePicker } from "antd";
import HeyDayTable from "../../../Shared/Components/Table/HeyDayTable";
import BarChart from "./Charts/BarChart";
import AreaChart from "./Charts/AreaChart";
import FiltersAnalytics from "./FiltersAnalytics";
import "./SiteAnalytics.css";
import HeydayCardLoading from "../Loading/HeydayCardLoading";
import ReplayIcon from "@mui/icons-material/Replay";

dayjs.extend(customParseFormat);

const formatKey = (key) => {
  return key
    .replace(/[_-]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const SiteAnalytics = () => {
  const [page, setPage] = useState(0);
  const [table, setTable] = useState([]);
  const [series, setSeries] = useState([]);
  const context = useContext(GlobalContext);
  const params = useParams();
  const domain = context.getDomains()[params?.index]?.domain;
  const [tabValue, setTabValue] = useState(1);
  const [config, setConfig] = useState({});
  const [isClickedReload, setIsClickedReload] = useState(false);
  const [dateStart, setDateStart] = useState(
    dayjs(Date.now()).subtract(7, "d").format("YYYY-MM-DD")
  );
  const [startDay, setStartDay] = useState(
    dayjs(Date.now()).format("YYYY-MM-DD")
  );
  const dateEnd = dayjs(Date.now()).format("YYYY-MM-DD");
  const [daysBack, setDaysBack] = useState(6);
  const [metaData, setMetaData] = useState({ graphType: "timeLine" });
  const [loading, setLoading] = useState(true);
  const { RangePicker } = DatePicker;

  const onDateChange = (dateString) => {
    const start = Date.parse(dateString[0]);
    const end = Date.parse(dateString[1]);
    const diff = end - start;
    setDaysBack(Math.round(diff / (1000 * 60 * 60 * 24)));
    setDateStart(dateString[0].format("YYYY-MM-DD"));
    setStartDay(dateString[1].format("YYYY-MM-DD"));
  };

  const handleChangeTab = (event, newTabValue) => {
    switch (newTabValue) {
      case 0:
        setMetaData({
          graphType: "summery",
          groupBy: ["affId"],
        });
        break;
      case 1:
        setMetaData({ graphType: "timeLine" });
        break;
      case 2:
        setMetaData({
          graphType: "timeLine",
          timeInterval: "hour",
        });
        break;
      default:
    }
    setPage(0);
    setTabValue(newTabValue);
  };

  const getChartData = async (actionId) => {
    setLoading(true);
    let startFetchTime = Date.now();
    try {
      const result = await context.DBmanager.getGraphData(
        context.loadCredentials(),
        startDay,
        daysBack,
        actionId,
        domain,
        { ...metaData }
      );
      if (
        result.length > 0 &&
        config &&
        config.tables &&
        config.tables[0] &&
        config.tables[0].filelds_data
      ) {
        const keys = config.tables[0].filelds_data.values;

        const newSeries = keys.map((key) => ({
          name: formatKey(key),
          data: result.map((data) => ({
            x: data._date ? new Date(data._date) : 1,
            y: data[key],
          })),
        }));
        setSeries(newSeries);
        setTable(result);
        const elapsedTime = Date.now() - startFetchTime;
        const remainingTime = Math.max(0, 1000 - elapsedTime);
        if (remainingTime > 0) {
          setTimeout(() => setLoading(false), remainingTime);
        } else {
          setLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(config).length === 0) {
      const confValues = context.getConf();
      if (confValues) {
        setConfig(confValues);
      }
    }
    if (context.state.DBmanagerReady) getChartData(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    daysBack,
    startDay,
    tabValue,
    config,
    context.state.DBmanagerReady,
    isClickedReload,
  ]);

  return (
    <div className="site-analytics-container">
      <section className="content-header">
        <ContentPageHeader pageName="Site analytics" subMenu="Statistics" />
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
          minHeight: "270px",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "1000px",
          boxShadow: "unset",
        }}
      >
        {loading && <HeydayCardLoading loading={loading} />}
        <Box className="analytics-tab-container">
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="graphType-tab"
            className="analytics-tabs"
          >
            <Tab label="Summary" />
            <Tab label="Daily" />
            <Tab label="Hourly" />
          </Tabs>
          <div className="site-analytics-main-section">
            <IconButton onClick={() => setIsClickedReload(!isClickedReload)}>
              <ReplayIcon />
            </IconButton>
            <RangePicker
              className="heyday-custom-datepicker"
              format="YYYY-MM-DD"
              defaultValue={[
                dayjs(dateStart, "YYYY-MM-DD"),
                dayjs(dateEnd, "YYYY-MM-DD"),
              ]}
              onChange={onDateChange}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </div>
        </Box>

        <AdminCard
          contentStyle={{
            position: "relative",
            marginTop: "20px",
            borderRadius: "20px",
            backgroundColor: "#F4F6F8",
            width: "100%",
            padding: "15px",
          }}
          sx={{
            width: "100%",
            position: "relative",
            boxShadow: "unset",
          }}
        >
          <div className="filters-section">
            <Typography variant="body1" className="filters-title">
              Filters
            </Typography>
            {Object.keys(config).length !== 0 && (
              <FiltersAnalytics
                options={config?.paramsNames}
                metaData={metaData}
                setMetaData={setMetaData}
                getChartData={getChartData}
                context={context}
              />
            )}
          </div>
        </AdminCard>
        <AdminCard
          contentStyle={{
            position: "relative",
            marginTop: "20px",
            borderRadius: "20px",
            backgroundColor: "#F4F6F8",
            width: "100%",
            padding: "15px",
          }}
          sx={{
            width: "100%",
            position: "relative",
            boxShadow: "unset",
          }}
        >
          <Box className="chart-container">
            {series?.length > 0 && table?.length > 0 && (
              <>
                {tabValue === 0 ? (
                  <BarChart data={table} />
                ) : (
                  <AreaChart series={series} />
                )}
              </>
            )}
          </Box>
        </AdminCard>
        <div className="table-section">
          {table?.length > 0 && (
            <HeyDayTable
              rows={table}
              keys={Object.keys(table[0])}
              page={page}
              setPage={setPage}
            />
          )}
        </div>
      </AdminCard>
    </div>
  );
};

export default SiteAnalytics;
