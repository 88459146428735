var tmplPlaceHoldersItalian = {
  resWithout: "Risultati senza",
  approx: "Approssimativamente",
  resFound: "Risultati trovati",
  noRes: "Nessun risultato trovato per",
  didYouMean: "Intendevi",
  prevPage: "Precedente",
  nextPage: "Successivo",
  AC_item_left: "AC_item_left",
  AC_item_right: "AC_item_right",
  searchBoxText: "Ricerca",
  missing: "Mancante",
  must_include: "Deve includere",
  for: "per",
  brands: "Marche",
  clear: "Cancellare",
  priceRange: "Intervallo di prezzo",
  categories: "Categorie",
  sortBy: "Ordina per",
  relevance: "Rilevanza",
  priceLTH: "Prezzo: basso ad alto",
  priceHTL: "Prezzo: alto a basso",
  filter: "Filtrare",
  to: "A",
  from: "Da",
  ellipsisF: "...",
  ellipsisL: "...",
  dateN: "Data: più recenti prima",
  dateL: "Data: più vecchie prima",
  forMoreRes: "Per altri risultati",
  minPrice: "Prezzo minimo",
  maxPrice: "Prezzo massimo",
  showFilters: 'Mostra filtri',
  hideFilters: 'Nascondi filtri'
};

export default tmplPlaceHoldersItalian;
