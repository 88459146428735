import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Graticule,
} from "react-simple-maps";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
const geoUrl = require("../../../../Shared/world-countries.json");

const GEOchart = ({ data }) => {
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });

  const getColor = (impressions) => {
    if (impressions < 10) return "#f1f1f1";
    else if (impressions < 100) return "#5daaa14f";
    else if (impressions < 1000) return "#5daaa1a8";
    else if (impressions < 5000) return "#5EABA2";
    else if (impressions < 10000) return "#5EABA2";
    else return "#666666";
  };

  const handleMouseEnter = (geo) => {
    const countryData = data.find((item) => item[0] === geo.properties.name);
    const impressions = countryData ? countryData[1] : 0;
    setHoveredCountry({ name: geo.properties.name, impressions });
  };

  const handleMouseLeave = () => {
    setHoveredCountry(null);
  };

  const handleZoomIn = () => {
    setPosition((prevState) => ({ ...prevState, zoom: prevState.zoom * 2 }));
  };

  const handleZoomOut = () => {
    setPosition((prevState) => ({ ...prevState, zoom: prevState.zoom / 2 }));
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <IconButton onClick={handleZoomIn}>
          <ZoomInIcon />
        </IconButton>
        <IconButton onClick={handleZoomOut}>
          <ZoomOutIcon />
        </IconButton>
      </div>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{ scale: 120 }}
        style={{ width: "100%" }}
        height={300}
      >
        <Graticule stroke="#f1f1f1" />
        <ZoomableGroup zoom={position.zoom} center={position.coordinates}>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const countryData = data.find(
                  (item) => item[0] === geo.properties.name
                );
                const popularity = countryData ? countryData[1] : 0;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={getColor(popularity)}
                    onMouseEnter={() => handleMouseEnter(geo)}
                    onMouseLeave={handleMouseLeave}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      {hoveredCountry && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            backgroundColor: "white",
            padding: "5px",
          }}
        >
          <p>Country: {hoveredCountry.name}</p>
          <p>Impressions: {hoveredCountry.impressions}</p>
        </div>
      )}
    </div>
  );
};

export default GEOchart;
