import React, { useState, useEffect, useContext } from "react";
import SearchQueries from "./Analytics/SearchQueries";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import SiteAnalytics from "./Analytics/SiteAnalytics";
import UsersByTime from "./Analytics/UsersByTime";
import GlobalContext from "../../../Context/Context";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "./Dashboard.css";
import SessionByCountry from "./Analytics/SessionByCountry";
import { ConfirmModal } from "./ConfirmModal";
import ReplayIcon from "@mui/icons-material/Replay";
import { IconButton } from "@mui/material";

dayjs.extend(customParseFormat);
const Dashboard = () => {
  const context = useContext(GlobalContext);
  const [dateStart, setDateStart] = useState(
    dayjs(Date.now()).subtract(7, "d").format("YYYY-MM-DD")
  );
  const [startDay, setStartDay] = useState(
    dayjs(Date.now()).format("YYYY-MM-DD")
  );
  const dateEnd = dayjs(Date.now()).format("YYYY-MM-DD");
  const [config, setConfig] = useState({});
  const [daysBack, setDaysBack] = useState(6);
  const [openConfirm, setOpenConfirm] = useState(false);
  const { RangePicker } = DatePicker;
  const domain = context.getDomains()[context.state.domainIndex]?.domain;
  const [isClickedReload, setIsClickedReload] = useState(false);

  const onDateChange = (dateString) => {
    const start = Date.parse(dateString[0]);
    const end = Date.parse(dateString[1]);
    const diff = end - start;
    setDaysBack(Math.round(diff / (1000 * 60 * 60 * 24)));
    setDateStart(dateString[0].format("YYYY-MM-DD"));
    setStartDay(dateString[1].format("YYYY-MM-DD"));
  };

  useEffect(() => {
    const affStatus = +sessionStorage.getItem("affStatus");
    const isClickedLater = sessionStorage.getItem("isClickedLater");
    if (affStatus === 0 && !isClickedLater) {
      setOpenConfirm(true);
      // sessionStorage.setItem("affStatus", 1);
      sessionStorage.setItem("isClickedLater", true);
    }
    const confValues = context.getConf();
    if (confValues) {
      setConfig(confValues);
    }
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <ConfirmModal openConfirm={openConfirm} setOpenConfirm={setOpenConfirm} />
      <section>
        <div className="dashboard-main-section">
          <IconButton onClick={() => setIsClickedReload(!isClickedReload)}>
            <ReplayIcon />
          </IconButton>
          <RangePicker
            className="heyday-dashboard-custom-datepicker"
            format="YYYY-MM-DD"
            defaultValue={[
              dayjs(dateStart, "YYYY-MM-DD"),
              dayjs(dateEnd, "YYYY-MM-DD"),
            ]}
            onChange={onDateChange}
            disabledDate={(current) =>
              current && current > dayjs().endOf("day")
            }
          />
        </div>
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
          minHeight: "270px",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "950px",
          boxShadow: "unset",
        }}
      >
        <SearchQueries
          dateStart={startDay}
          daysBack={daysBack}
          domain={domain}
          isClickedReload={isClickedReload}
        />
      </AdminCard>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "950px",
          margin: "auto",
        }}
      >
        <AdminCard
          contentStyle={{
            position: "relative",
            minHeight: "270px",
          }}
          sx={{
            width: { xs: "100%", md: "48%" },
            marginTop: "30px",
            position: "relative",
            maxWidth: "850px",
            boxShadow: "unset",
          }}
        >
          <SiteAnalytics
            dateStart={startDay}
            daysBack={daysBack}
            domain={domain}
            isClickedReload={isClickedReload}
          />
        </AdminCard>
        <AdminCard
          contentStyle={{
            position: "relative",
            minHeight: "270px",
          }}
          sx={{
            width: { xs: "100%", md: "48%" },
            marginTop: "30px",
            position: "relative",
            maxWidth: "850px",
            boxShadow: "unset",
          }}
        >
          <UsersByTime
            dateStart={startDay}
            daysBack={daysBack}
            domain={domain}
            isClickedReload={isClickedReload}
          />
        </AdminCard>
      </div>
      <AdminCard
        contentStyle={{
          position: "relative",
          // minHeight: "270px",
          height: "100%",
        }}
        sx={{
          width: "100%",
          marginTop: "30px",
          position: "relative",
          maxWidth: "935px",
          boxShadow: "unset",
        }}
      >
        {config.paramsNames && (
          <SessionByCountry
            dateStart={startDay}
            daysBack={daysBack}
            config={config}
            domain={domain}
            isClickedReload={isClickedReload}
          />
        )}
      </AdminCard>
    </div>
  );
};

export default Dashboard;
