import tmplPlaceHoldersEnglish from "./tmplPlaceHolder";
import tmplPlaceHoldersHebrew from "./tmplPlaceHolderHebrew";
import tmplPlaceHoldersArabic from "./tmplPlaceHoldersArabic";
import tmplPlaceHoldersFrench from "./tmplPlaceHoldersFrench";
import tmplPlaceHoldersGerman from "./tmplPlaceHoldersGerman";
import tmplPlaceHoldersRussian from "./tmplPlaceHoldersRussian";
import tmplPlaceHoldersSpanish from "./tmplPlaceHoldersSpanish";
import tmplPlaceHoldersItalian from "./tmplPlaceHoldersItalian";

const placeholderMappings = {
  eng: tmplPlaceHoldersEnglish,
  heb: tmplPlaceHoldersHebrew,
  spa: tmplPlaceHoldersSpanish,
  deu: tmplPlaceHoldersGerman,
  ara: tmplPlaceHoldersArabic,
  rus: tmplPlaceHoldersRussian,
  fra: tmplPlaceHoldersFrench,
  ita: tmplPlaceHoldersItalian,
};

const getPlaceholdersObject = (code) => {
  const placeholders = placeholderMappings[code] || tmplPlaceHoldersEnglish;
  const result = {};

  Object.keys(placeholders).forEach((key) => {
    result[key] = placeholders[key];
  });

  return result;
};

export default getPlaceholdersObject;
