import React, { useState, useEffect } from "react";
import { IconButton, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "./AttributesPage.css";

const AttributesPage = ({
  selectedFields,
  products,
  setDisplayAttributesPage,
  handleSaveCustomParse,
  isAdvanced = false,
  handleClickNext = null,
}) => {
  const [extractedAttributes, setExtractedAttributes] = useState([]);
  const [customAttributeKeys, setCustomAttributeKeys] = useState({});

  useEffect(() => {
    extractAttributesFromURLs();
  }, []);

  const extractAttributesFromURLs = () => {
    const attributeFormats = {
      woocommerce: /^(pa_|attribute_pa_|attribute_)/,
      shopify: /^(option\d*|variant)$/,
      magento: /^(attribute\d*|attribute_|super_attribute)$/,
      bigcommerce: /^(attribute|option|option_value)$/,
      prestashop: /^(id_attribute|group_|id_customization)$/,
      opencart: /^(product_option|product_option_value)$/,
      squarespace: /^variant$/,
    };

    const allAttributes = products.reduce((acc, product) => {
      const url = decodeURIComponent(
        product[selectedFields.find((o) => o.field === "url").path]
      );

      const queryString = url.split("?")[1];
      if (queryString) {
        const urlSearchParams = new URLSearchParams(queryString);
        urlSearchParams.forEach((value, key) => {
          const isAttribute = Object.values(attributeFormats).some((format) =>
            format.test(key)
          );
          if (isAttribute && !acc.find((attr) => attr.attribute === key)) {
            acc.push({ attribute: key, customName: key });
          }
        });
      }
      return acc;
    }, []);

    setExtractedAttributes(allAttributes);
  };

  const handleKeyChange = (attribute, e) => {
    setCustomAttributeKeys({
      ...customAttributeKeys,
      [attribute]: e.target.value,
    });
  };
  const handleDeleteAttribute = (index) => {
    setExtractedAttributes((prevAttributes) =>
      prevAttributes.filter((_, i) => i !== index)
    );
  };

  const handleSave = async () => {
    const urlAttributeParams = extractedAttributes.map((attributeObj) => ({
      regex: `(.+[\\?&])(${encodeURIComponent(
        attributeObj.attribute
      )}=([^&]+)[&]?)(.*)`,
      name: encodeURIComponent(
        customAttributeKeys[attributeObj.attribute] || attributeObj.attribute
      ),
      valueFrom: { type: "regex", key: 3 },
    }));
    
    if(urlAttributeParams.length > 0){
      await handleSaveCustomParse(urlAttributeParams);
    }else{
      await handleSaveCustomParse(false);
    }
    
    setDisplayAttributesPage(false);
  };

  return (
    <div className="extracted-attributes-container">
      {extractedAttributes.length > 0 ? (
        <div>
          <h2>We found that you have some attributes in your product feed.</h2>
          <p>Please select the display name you want for each attribute:</p>
          {extractedAttributes.map((attributeObj, index) => (
            <div key={index}>
              <label htmlFor={`customKey_${attributeObj.attribute}`}>
                Custom Key for{" "}
                <span className="attribute-key">{attributeObj.attribute}</span>:
              </label>
              <div className="inputs-container">
                <input
                  id={`customKey_${attributeObj.attribute}`}
                  type="text"
                  value={
                    customAttributeKeys[attributeObj.attribute] !== undefined
                      ? customAttributeKeys[attributeObj.attribute]
                      : attributeObj.attribute
                  }
                  onChange={(e) => handleKeyChange(attributeObj.attribute, e)}
                />
                <IconButton onClick={() => handleDeleteAttribute(index)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No attributes extracted yet.</p>
      )}
      {!isAdvanced ? (
        <Button
          sx={{ display: "flex", marginTop: "30px" }}
          className="btn-save"
          onClick={handleSave}
        >
          Save
        </Button>
      ) : (
        <div className="hdy-footer-card">
          <div className="btn-skip-container">
            <Button
              onClick={() => handleClickNext(true)}
              className="hdy-skip-btn"
            >
              Skip this step
            </Button>
          </div>
          <Button
            sx={{ display: "flex" }}
            className="btn-save"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default AttributesPage;
