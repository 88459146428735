import "./App.css";
import { BrowserRouter } from "react-router-dom";
import OnboardingRoutes from "./Routes/OnboardingRoutes";
import DrawerHeader from "./Components/NavigationBar/DrawerHeader";
import AuthRoutes from "./Routes/AuthRoutes";
import { withContext } from "./Components/Admin/HOC/InitHoc";

const AdminPanelWithContext = withContext(DrawerHeader);
const SelfServeWithContext = withContext(OnboardingRoutes);
const LoginWithContext = withContext(AuthRoutes);

function App() {
  if (window.location.pathname.includes("/autoLogin")) {
    sessionStorage.clear();
  }

  if (window.location.search) {
    const urlParams = new URLSearchParams(window.location.search);
    // const token = urlParams.get("t");
    // if (token) {
    //   if (sessionStorage.getItem("token")) {
    //     if (sessionStorage.getItem("token") !== token) {
    //       sessionStorage.setItem("token", token);
    //     }
    //   }
    // }
  }
  const isLoggedIn = () => !!sessionStorage.getItem("token");
  const isSelfServe = window.location.pathname.includes("/selfserve");

  return (
    <div className="App">
      <BrowserRouter>
        {isLoggedIn() && !isSelfServe && <AdminPanelWithContext />}
        {isLoggedIn() && isSelfServe && <SelfServeWithContext />}
        {!isLoggedIn() && <LoginWithContext />}
      </BrowserRouter>
    </div>
  );
}

export default App;
