import React from 'react';
import ReactApexChart from 'react-apexcharts';

const AreaChart = ({ series }) => {
  const options = {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    title: {
      text: 'Statistics',
      align: 'left',
      style: {
        fontSize: '14px',
        fontFamily: 'Poppins',
        color: '#616060',
        fontWeight: '500',
      },
    },
    subtitle: {
      text: '',
      align: 'left',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          color: '#616060',
          fontWeight: '500',
        },
      },
    },
    yaxis: {
      opposite: false,
      labels: {
        style: {
          fontSize: '14px',
          fontFamily: 'Poppins',
          color: '#616060',
          fontWeight: '500',
        },
      },
    },
    legend: {
      horizontalAlign: 'left',
    },
    grid: {
      show: true,
      borderColor: '#f1f1f1',
      strokeDashArray: 5,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type='area'
        height={350}
      />
    </div>
  );
};

export default AreaChart;
