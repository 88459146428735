import React, { useState, useContext } from "react";
import { Modal, IconButton, Box, DialogActions, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import GlobalContext from "../../../../Context/Context";

import "../InviteMembers/InviteMembersModal.css";

const DeleteAccountModal = ({ open, setOpen }) => {
  const [email, setEmail] = useState("");
  const multiUser = sessionStorage.getItem("multiUser") || false;
  const [invitationLink, setInvitationLink] = useState("");
  const context = useContext(GlobalContext);
  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "500px",
    minHeight: "300px",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  };

  const handleClickDeleteAccount = async () => {
    const requestJson = {
      action: 1030,
      credentials: {
        uName: sessionStorage.getItem("name"),
        accessToken: sessionStorage.getItem("token")
          ? sessionStorage.getItem("token")
          : sessionStorage.getItem("accessToken"),
      },
    };

    try {
      const response = await context.myFetch(requestJson);
      if (response.error) {
        return context.message({
          type: "error",
          body: response.error,
        });
      } else {
        context.message({
          type: "success",
          body: "Your account has been deleted",
        });
        context.logout();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-header">
            <div></div>

            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ margin: "auto", display: "block" }}
              src="/img/deleteAccount.png"
              alt="delete account"
            />
          </div>
          <div className="deleteModalText">
            <h3>Are you sure you want to delete your account?</h3>
            <p>
              Deleting your account will permanently remove <br />
              all your data and cannot be undone
            </p>
          </div>
          <div className="btns-container-modal">
            <Button
              className="cancel-btn"
              onClick={() => setOpen(false)}
              color="primary"
            >
              No
            </Button>
            <Button
              className="danger-btn"
              onClick={handleClickDeleteAccount}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteAccountModal;
