import React, { useEffect, useState, useContext, useRef } from "react";
import ContentPageHeader from "../../ContentPageHeader/ContentPageHeader";
import {
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdminCard from "../../../../Shared/Components/Card/AdminCard";
import GlobalContext from "../../../../Context/Context";
import { useParams } from "react-router-dom";
import { ConfirmationModal } from "./ConfirmationModal";
import { Rules } from "./Rules";
import IndexingRules from "./IndexingRules";
import Priority from "./Priority";
import "../Search.css";

const ManageSearchResults = () => {
  const [url, setUrl] = useState("");
  const [clearType, setClearType] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [indexSize, setIndexSize] = useState(0);
  const context = useContext(GlobalContext);
  const params = useParams();
  const domain = context.getDomains()[params?.index];
  const inputEl = useRef();

  const getIndexSize = async () => {
    const requestJson = {
      action: 44,
      host: domain?.domain,
      credentials: context.loadCredentials(),
    };
    try {
      const response = await context.myFetch(requestJson);
      if (response && !response.error) {
        setIndexSize(response.totalDocs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDomainChanges = async (type) => {
    setClearType(type);
    setOpenConfirm(true);
  };

  useEffect(() => {
    getIndexSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <section className="content-header">
        <ContentPageHeader
          pageName={`Manage search results - index size ${context.commaSeparator(
            indexSize
          )} pages`}
          subMenu="Search"
        />
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "750px",
          boxShadow: "unset",
        }}
      >
        <section className="manage-searchResults">
          <div className="remove-reindex-div">
            <p>Remove or reindex by specific URL</p>
            <div className="inputs">
              <TextField
                sx={{
                  width: "100%",
                  marginRight: "20px",
                  "& .MuiOutlinedInput-root": {
                    height: "44px",
                    fontFamily: "Poppins",
                    border: "2px solid var(--Grey, #EBEFF3)",
                    borderRadius: "100px",
                    "& fieldset": {
                      borderColor: "white",
                      border: "none",
                    },
                  },
                }}
                inputProps={{
                  sx: {
                    "&::placeholder": {
                      color: "#9a9b9d",
                    },
                  },
                }}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="http[s]://..."
                inputRef={inputEl}
              />
            </div>
          </div>
          {Boolean(url?.length) && (
            <Priority
              url={url}
              domain={domain}
              Context={context}
              setUrl={setUrl}
              inputEl={inputEl}
            />
          )}

          <Accordion
            defaultExpanded={true}
            sx={{
              marginBottom: "10px",
              width: "100%",
              border: "none",
              boxShadow: "unset",
              borderRadius: "20px !important",
              background: "var(--Light-Grey, #F4F6F8)",
              color: "var(--Mid-Grey, #616060)",
              fontFamily: "Poppins",
              fontSize: "14px",
              "&::before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Manage all current search index (a fresh one will be built over
              time)
            </AccordionSummary>
            <AccordionDetails sx={{ display: "flex" }}>
              <Button
                className="clear-index-btn"
                style={{ margin: "20px 0" }}
                onClick={() => handleDomainChanges("clear")}
              >
                Clear domain index
              </Button>
              <Button
                className="re-index-btn"
                style={{ marginLeft: "10px" }}
                onClick={() => handleDomainChanges("reindex")}
              >
                Reindex domain
              </Button>
            </AccordionDetails>
          </Accordion>
          <Rules domain={domain} Context={context} />
          <IndexingRules domain={domain} />
          <ConfirmationModal
            openConfirm={openConfirm}
            setOpenConfirm={setOpenConfirm}
            domain={domain?.domain}
            Context={context}
            clearType={clearType}
          />
        </section>
      </AdminCard>
    </div>
  );
};

export default ManageSearchResults;
