import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../../../Context/Context";
import { useParams } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const SearchQueries = ({ dateStart, daysBack, domain, isClickedReload }) => {
  const actionId = 28;
  const [totalCount, setTotalCount] = useState(0);

  const context = useContext(GlobalContext);
  // const domain = context.getDomains()[params?.index]?.domain;
  const [isLoading, setIsLoading] = useState(true);
  const [series, setSeries] = useState([]);
  let startTime = Date.now();

  const getChartData = async (metaData) => {
    setIsLoading(true);
    startTime = Date.now();
    try {
      const result = await context.DBmanager.getGraphData(
        context.loadCredentials(),
        dateStart,
        daysBack,
        actionId,
        domain,
        metaData
      );
      setSeries([
        {
          name: "Search count",
          color: "#5DAAA1",
          data: result.map((data) => ({
            x: new Date(data._date),
            y: data.count,
          })),
        },
      ]);
      const totalSQMetaData = {
        graphType: "timeLine",
      };
      getTotalCount(totalSQMetaData);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const getTotalCount = async (metaData) => {
    try {
      const result = await context.DBmanager.getGraphData(
        context.loadCredentials(),
        dateStart,
        daysBack,
        3,
        domain,
        metaData
      );
      if (result.length > 0) {
        const totalSearchSum = result.reduce(
          (sum, item) => sum + (item.totalSearch || 0),
          0
        );
        setTotalCount(totalSearchSum);
      } else {
        setTotalCount(0);
      }
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, 1000 - elapsedTime);
      if (remainingTime > 0) {
        setTimeout(() => setIsLoading(false), remainingTime);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    const searchQueriesMetaData = { graphType: "timeLine" };
    if (context.state.DBmanagerReady) getChartData(searchQueriesMetaData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateStart,
    daysBack,
    context.state.domainIndex,
    context.state.DBmanagerReady,
    isClickedReload,
  ]);

  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    title: {
      text: "Search Queries",
      align: "left",
      style: {
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#616060",
        fontWeight: "500",
      },
    },
    subtitle: {
      text: `Total Search Count: ${totalCount}`,
      align: "left",
      style: {
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#616060",
        fontWeight: "500",
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Poppins",
          color: "#616060",
          fontWeight: "500",
        },
      },
    },
    yaxis: {
      opposite: false,
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Poppins",
          color: "#616060",
          fontWeight: "500",
        },
      },
    },
    legend: {
      horizontalAlign: "left",
    },
    grid: {
      show: true,
      borderColor: "#f1f1f1",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  return (
    <div>
      <div
        id="search-queries-chart"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 350,
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: "#5daaa1" }} />
          </Box>
        ) : (
          <div style={{ width: "100%" }}>
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={350}
            />
          </div>
        )}
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default SearchQueries;
