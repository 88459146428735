import React, { useState } from "react";
import { ResizableBox } from "react-resizable";
import {
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HeyDayFormLabel from "../../../../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./SearchBoxInputWizard.css";

const unitOptions = ["px", "%", "vw", "vh"];

const SearchBoxInputWizard = ({
  styles,
  setStyles,
  placeholder,
  setPlaceholder,
  handleSaveSearchBoxStyle,
}) => {
  const [widthUnit, setWidthUnit] = useState("px");
  const [heightUnit, setHeightUnit] = useState("px");

  const updateStyle = (property, value, unit) => {
    if (unit) {
      setStyles({
        ...styles,
        [property]: `${value}${unit}`,
      });
    } else {
      setStyles({
        ...styles,
        [property]: value,
      });
    }
  };

  const onResize = (event, { size }) => {
    setStyles({
      ...styles,
      width: size.width,
      height: size.height,
    });
  };

  const parseDimension = (dimension) => {
    return parseInt(dimension, 10) || 0;
  };

  return (
    <div>
      <div className="wizard-input-container">
        <HeyDayFormLabel>Preview Search box placeholder</HeyDayFormLabel>
        <ResizableBox
          style={{ marginTop: "10px" }}
          width={parseDimension(styles.width)}
          height={parseDimension(styles.height)}
          onResize={onResize}
          minConstraints={[120, 20]}
          maxConstraints={[2000, 200]}
        >
          <div
            style={{
              border: `${styles.borderWidth}px solid ${styles.borderColor}`,
              borderRadius: `${styles.borderRadius}px`,
              width: "100%",
              height: "100%",
              position: "relative",
            }}
          >
            <input
              type="text"
              style={{
                width: "99%",
                height: "99%",
                margin: "auto",
                display: "block",
                color: styles.color,
                fontSize: styles.fontSize,
                fontFamily: styles.fontFamily,
                border: "none",
                outline: "none",
                padding: "5px",
                direction: styles.direction,
              }}
              placeholder={placeholder}
            />
          </div>
        </ResizableBox>
      </div>
      <div
        style={{ marginTop: "20px" }}
        className="wizard-header-underline"
      ></div>
      <div className="wizard-input-container">
        <HeyDayFormLabel sx={{ display: "block" }}>
          Language Direction
        </HeyDayFormLabel>
        <ToggleButtonGroup
          aria-label="direction"
          value={styles.direction}
          exclusive
          onChange={(e, value) => updateStyle("direction", value)}
          className="lang-toggle-button-group"
        >
          <ToggleButton
            value="ltr"
            disableRipple
            sx={{
              color: "#9A9B9D",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              textTransform: "unset",
              borderRadius: "100px",
              "&.Mui-selected": {
                color: "#373737",
                backgroundColor: "#FFFFFF",
                border: "1px solid #FFBA41",
              },
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              Left-to-right <ArrowForwardIcon fontSize="small" />
            </div>
          </ToggleButton>
          <ToggleButton
            disableRipple
            value="rtl"
            sx={{
              color: "#9A9B9D",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              textTransform: "unset",
              borderRadius: "100px",
              "&.Mui-selected": {
                color: "#373737",
                backgroundColor: "#FFFFFF",
                border: "1px solid #FFBA41",
              },
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowBackIcon fontSize="small" /> Right-to-Left
            </div>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>Text color:</HeyDayFormLabel>
        <div className="input-color-container">
          <input
            className="input-color"
            type="color"
            id="color"
            value={styles.color}
            onChange={(e) => updateStyle("color", e.target.value)}
          />
          <span>{styles.color}</span>
        </div>
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>Border Width:</HeyDayFormLabel>
        <div className="custom-number-input">
          <TextField
            type="number"
            sx={{
              width: "180px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            id="borderWidth"
            InputProps={{
              inputProps: {
                max: 50,
                min: 0,
              },
            }}
            value={styles.borderWidth}
            onChange={(e) =>
              updateStyle("borderWidth", parseInt(e.target.value))
            }
          />
        </div>
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>Border color:</HeyDayFormLabel>
        <div className="input-color-container">
          <input
            className="input-color"
            type="color"
            id="borderColor"
            value={styles.borderColor}
            onChange={(e) => updateStyle("borderColor", e.target.value)}
          />
          <span>{styles.borderColor}</span>
        </div>
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>
          Border radius:
        </HeyDayFormLabel>
        <TextField
          sx={{
            width: "180px",
            "& .MuiOutlinedInput-root": {
              height: "44px",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "100px",
              "& fieldset": {
                borderColor: "white",
                border: "none",
              },
            },
          }}
          type="number"
          InputProps={{
            inputProps: {
              max: 50,
              min: 0,
            },
          }}
          id="borderRadius"
          value={styles.borderRadius}
          onChange={(e) =>
            updateStyle("borderRadius", parseInt(e.target.value))
          }
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>Font Size:</HeyDayFormLabel>
          <TextField
            sx={{
              width: "180px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            type="number"
            InputProps={{
              inputProps: {
                max: 50,
                min: 0,
              },
            }}
            id="fontSize"
            value={styles.fontSize}
            onChange={(e) => updateStyle("fontSize", parseInt(e.target.value))}
          />
        </div>

        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>
            Font Family:
          </HeyDayFormLabel>
          <TextField
            sx={{
              width: "180px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            type="text"
            id="fontFamily"
            value={styles.fontFamily}
            onChange={(e) => updateStyle("fontFamily", e.target.value)}
          />
        </div>
        <div className="inputWizard-container">
          <HeyDayFormLabel sx={{ width: "125px" }}>
            Search box placeholder:
          </HeyDayFormLabel>

          <TextField
            sx={{
              width: "180px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            type="text"
            id="placeholder"
            value={placeholder}
            onChange={(e) => setPlaceholder(e.target.value)}
          />
        </div>
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>Width:</HeyDayFormLabel>
        <div className="custom-number-input">
          <TextField
            type="number"
            sx={{
              width: "120px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            id="sbWidth"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            value={parseInt(styles.width)}
            onChange={(e) =>
              updateStyle("width", parseInt(e.target.value), widthUnit)
            }
          />
          <Select
            value={widthUnit}
            onChange={(e) => {
              setWidthUnit(e.target.value);
              updateStyle("width", parseInt(styles.width), e.target.value);
            }}
            sx={{
              marginLeft: "10px",
              height: "44px",

              backgroundColor: "white",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "100px",
              ".MuiOutlinedInput-notchedOutline": {
                border: 0,

                fontFamily: "Poppins",
                color: "#616060",
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "& .MuiInputLabel-shrink": {
                fontFamily: "Poppins",
                color: "#616060",
              },
            }}
          >
            {unitOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="inputWizard-container">
        <HeyDayFormLabel sx={{ width: "125px" }}>Height:</HeyDayFormLabel>
        <div className="custom-number-input">
          <TextField
            type="number"
            sx={{
              width: "120px",
              "& .MuiOutlinedInput-root": {
                height: "44px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "100px",
                "& fieldset": {
                  borderColor: "white",
                  border: "none",
                },
              },
            }}
            id="sbHeight"
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            value={parseInt(styles.height)}
            onChange={(e) =>
              updateStyle("height", parseInt(e.target.value), heightUnit)
            }
          />
          <Select
            value={heightUnit}
            onChange={(e) => {
              setHeightUnit(e.target.value);
              updateStyle("height", parseInt(styles.height), e.target.value);
            }}
            sx={{
              marginLeft: "10px",
              height: "44px",

              backgroundColor: "white",
              border: "2px solid var(--Grey, #EBEFF3)",
              borderRadius: "100px",
              ".MuiOutlinedInput-notchedOutline": {
                border: 0,

                fontFamily: "Poppins",
                color: "#616060",
              },
              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "& .MuiInputLabel-shrink": {
                fontFamily: "Poppins",
                color: "#616060",
              },
            }}
          >
            {unitOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <div className="btn-next-step-container">
        <Button className="next-step-modal" onClick={handleSaveSearchBoxStyle}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default SearchBoxInputWizard;
