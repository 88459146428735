var tmplPlaceHoldersHebrew = {
  resWithout: "תוצאות ללא",
  approx: "בערך",
  resFound: "תוצאות נמצאו",
  noRes: "לא נמצאו תוצאות עבור",
  didYouMean: "האם התכוונתם ל",
  prevPage: "הקודם",
  nextPage: "הבא",
  AC_item_left: "AC_item_left",
  AC_item_right: "AC_item_right",
  searchBoxText: "חיפוש",
  missing: "חסר",
  must_include: "חייב להכיל",
  for: "עבור",
  brands: "מותגים",
  clear: "ניקוי",
  priceRange: "מחירים",
  categories: "קטגוריות",
  sortBy: "מיון לפי",
  relevance: "רלוונטיות",
  priceLTH: "מחיר: מנמוך לגבוה",
  priceHTL: "מחיר: מגבוה לנמוך",
  filter: "סנן",
  to: "החל מ",
  from: "ל",
  ellipsisF: "...",
  ellipsisL: "...",
  dateN: "תאריך: חדש לישן",
  dateL: "תאריך: ישן לחדש",
  forMoreRes: "לתוצאות נוספות",
  minPrice: "מחיר מינימום",
  maxPrice: "מחיר מקסימום",
  showFilters: 'הצג סינונים',
  hideFilters: 'הסתר סינונים',
};

export default tmplPlaceHoldersHebrew;
