import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../../../Context/Context";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { Button, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import InviteMembersModal from "../../../Admin/ManageAccount/InviteMembers/InviteMembersModal";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import HeyDayCard from "../../../../Shared/Components/Card/SelfServeCard";
import ContactUs from "../../ContactUs/ContactUs";
import HeydayCardLoading from "../../../Admin/Loading/HeydayCardLoading";
import ConnectYourDataForm from "./ConnectYourDataForm";
import ConnectYourDataHeader from "./ConnectYourDataHeader";

const ConnectYourData = () => {
  const context = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContactUsPage, setIsContactUsPage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const pDomain = sessionStorage.getItem("pDomain") || "";
  const pFeedUrl = sessionStorage.getItem("pFeedUrl") || "";
  const navigate = useNavigate();
  
  const handleClickInvite = () => {
    setIsModalOpen(true);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", minHeight: "100vh" }}>
      <div className="logout-container">
        <IconButton onClick={() => context.logout()}>
          <LogoutIcon />
        </IconButton>
        <span>Log Out</span>
      </div>
      <HeyDayCard contentStyle={{ position: "relative", minHeight: "590px" }} sx={{ width: "100%", maxWidth: "880px", position: "relative", marginTop: "100px", boxShadow: { xs: "unset", md: "0px 5px 7px rgba(113, 113, 113, 0.25)" } }}>
        {loading && <HeydayCardLoading loading={loading} />}
        <ProgressBar stepName={"connectYourData"} progressPercentage={"30"} />
        <ConnectYourDataHeader />
        {isContactUsPage ? (
          <ContactUs setIsContactUsPage={setIsContactUsPage} message={"onboarding - Connect your data - Provide feed"} location={"Connect your data"} backTo={"/selfserve/2"} />
        ) : (
          <ConnectYourDataForm setLoading={setLoading} errorMessage={errorMessage} setErrorMessage={setErrorMessage} setIsContactUsPage={setIsContactUsPage} pDomain={pDomain} pFeedUrl={pFeedUrl} navigate={navigate} />
        )}
      </HeyDayCard>
      <InviteMembersModal open={isModalOpen} setOpen={setIsModalOpen} />
      <div className="invite-btn-container">
        <Button onClick={handleClickInvite} className="invite-btn" startIcon={<PersonAddAltOutlinedIcon sx={{ color: "#5daaa1", backgroundColor: "#ffffff", borderRadius: "25px", padding: "5px" }} />}>
          Invite members
        </Button>
      </div>
    </div>
  );
};

export default ConnectYourData;
