import { FormLabel } from '@mui/material';
import { styled } from '@mui/system';

const HeyDayFormLabel = styled(FormLabel)(({ theme }) => ({
  color: 'var(--Mid-Grey, #616060)',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  letterSpacing: '0.7px',
}));
export default HeyDayFormLabel;
