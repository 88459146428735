var tmplPlaceHoldersSpanish = {
  resWithout: "resultados sin",
  approx: "Aproximadamente",
  resFound: "resultados encontrados",
  noRes: "No se encontraron resultados para",
  didYouMean: "¿Quisiste decir",
  prevPage: "Anterior",
  nextPage: "Siguiente",
  AC_item_left: "AC_item_left",
  AC_item_right: "AC_item_right",
  searchBoxText: "buscar",
  missing: "Falta",
  must_include: "Debe incluir",
  for: "para",
  brands: "Marcas",
  clear: "limpiar",
  priceRange: "Rango de precios",
  categories: "Categorías",
  sortBy: "Ordenar por",
  relevance: "Relevancia",
  priceLTH: "Precio: de menor a mayor",
  priceHTL: "Precio: de mayor a menor",
  filter: "Filtrar",
  to: "A",
  from: "De",
  ellipsisF: "...",
  ellipsisL: "...",
  dateN: "Fecha: más reciente primero",
  dateL: "Fecha: más antigua primero",
  forMoreRes: "Para más resultados",
  minPrice: "Precio mínimo",
  maxPrice: "Precio máximo",
  showFilters: 'Mostrar filtros',
  hideFilters: 'Ocultar filtros'
};

export default tmplPlaceHoldersSpanish;
