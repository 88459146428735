import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import HeyDayTextField from "./HeyDayTextField";
import { styled } from "@mui/system";

const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: "100%",
  fontFamily: "Poppins",
}));

function HeyDayAutocomplete({
  onChange,
  requiredField,
  style,
  options,
  label,
  getOptionLabelKeys,
  defaultOption,
  searchKeys,
  error,
  ...props
}) {
  const filterOptions = (options, { inputValue }) => {
    if (!inputValue) {
      return options;
    }
    const inputValueLower = inputValue.toLowerCase();
    return options.filter((option) => {
      return searchKeys.some((key) =>
        option[key].toLowerCase().includes(inputValueLower)
      );
    });
  };

  const getOptionLabel = (option) => {
    if (!getOptionLabelKeys || !Array.isArray(getOptionLabelKeys)) {
      return "";
    }
    return getOptionLabelKeys
      .map((key) => option[key])
      .filter((label) => label)
      .join("  ");
  };

  const renderOption = (props, option) => (
    <li {...props} style={{ fontFamily: "Poppins, sans-serif" }}>
      {getOptionLabelKeys.map((key, index) =>
        key === "flag" ? (
          <span
            key={index}
            role="img"
            aria-label={option.name}
            style={{
              fontFamily: "Noto Color Emoji, Poppins, sans-serif",
              marginRight: "10px",
            }}
          >
            {option[key]}
          </span>
        ) : (
          <span key={index} style={{ marginRight: "10px" }}>
            {option[key]}
          </span>
        )
      )}
    </li>
  );

  const renderInput = (params) => {
    const selectedOption = options.find(
      (option) => getOptionLabel(option) === params.inputProps.value
    );
    const isFlagSelected = selectedOption && selectedOption.flag;

    return (
      <HeyDayTextField
        labelValue={label}
        required={requiredField}
        error={error}
        {...params}
        inputProps={{
          ...params.inputProps,
          style: {
            ...params.inputProps.style,
            fontFamily: isFlagSelected
              ? "Noto Color Emoji, Poppins, sans-serif"
              : "Poppins, sans-serif",
          },
        }}
      />
    );
  };

  if (options) {
    return (
      <CustomAutocomplete
        onChange={onChange}
        style={style}
        options={options}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={renderInput}
        {...props}
      />
    );
  }
  return null;
}

export default HeyDayAutocomplete;
