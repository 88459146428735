import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ConfirmModal = ({ openConfirm, setOpenConfirm }) => {
  const navigate = useNavigate();
  const goBack = async () => {
    setOpenConfirm(false);
  };

  return (
    <>
      {openConfirm && (
        <Dialog open={openConfirm} classes={{ paper: "mui-paper" }}>
          <div className="confirm-modal-dialog-img">
            <img alt="alert" src="/img/warning.png" />
          </div>

          <DialogTitle sx={{ fontFamily: "Poppins", textAlign: "center" }}>
            Activate your account
          </DialogTitle>
          <DialogContent sx={{ fontFamily: "Poppins", textAlign: "center" }}>
            <p>
              Your trial period is ending soon. To continue using our service,
              please complete your payment before your trial expires. Access to
              our features will be limited until payment is made. Act now to
              avoid any interruption in your experience.
            </p>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              sx={{ marginRight: "20px !important" }}
              className="skip-btn"
              onClick={goBack}
              color="primary"
            >
              Later
            </Button>
            <Button
              className="complete-payment-btn"
              sx={{ marginRight: "20px !important" }}
              onClick={() => navigate("/payment")}
              color="primary"
            >
              Complete your payment
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
