import React from "react";
import "./NewsTemplates.css";

const NewsTemplates = ({ setStep, setSelectedTemplate, handleCloseModal }) => {
  const handleChoseTemplate = (templateName) => {
    setSelectedTemplate(templateName);
    handleCloseModal();
    // setStep(3);
  };
  return (
    <div className="templates-container">
      <div
        className="template-card"
        onClick={() => handleChoseTemplate("search")}
      >
        <img alt="search" src="/img/searchTemplates/search.png" />
        <h6>News/Media</h6>
      </div>
    </div>
  );
};

export default NewsTemplates;
