import React, { useContext, useState, useEffect } from "react";
import ContentPageHeader from "../../ContentPageHeader/ContentPageHeader";
import GlobalContext from "../../../../Context/Context";
import AdminCard from "../../../../Shared/Components/Card/AdminCard";
import { useParams } from "react-router-dom";
import SynonymRows from "./SynonymRows";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import toast, { Toaster } from "react-hot-toast";
import "./ManageSynonyms.css";

const ManageSynonyms = () => {
  const context = useContext(GlobalContext);
  const params = useParams();
  const domain = context.getDomains()[params?.index];
  const credentials = context.loadCredentials();
  const [synonymsArray, setSynonymsArray] = useState([
    { mappedWord: "", synonyms: "", isExclusive: 0 },
  ]);

  const getSynonyms = async () => {
    const requestJson = {
      action: 35,
      host: domain && domain.domain,
      credentials,
    };
    try {
      const responseJson = await context.myFetch(requestJson);

      if (responseJson.synonymsConf && responseJson.synonymsConf.length > 0) {
        let synonyms = responseJson.synonymsConf.map((el) => {
          el.synonyms = el.synonyms.join(", ");
          return el;
        });
        synonyms && setSynonymsArray(synonyms);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addRow = () => {
    let newArr = [...synonymsArray];
    newArr.push({ mappedWord: "", synonyms: "", isExclusive: 0 });
    setSynonymsArray(newArr);
  };

  const deleteAllSynonyms = async () => {
    const requestJson = {
      action: 1034,
      host: domain && domain.domain,
      synonymsConf: [],
      credentials: credentials,
    };
    const responseJson = await context.myFetch(requestJson);
    if (!responseJson.error) {
      toast.success("Synonyms have been deleted.");

      setSynonymsArray([{ mappedWord: "", synonyms: "", isExclusive: 0 }]);
      getSynonyms();
    } else {
      context.message({
        type: "error",
        body: responseJson.error,
      });
    }
  };

  const saveSynonyms = async () => {
    const stateSynonyms = [...synonymsArray];

    const filtered = stateSynonyms.filter(
      (el) => el.synonyms.length && el.mappedWord.length
    );
    const synonymsConf = filtered.map((el) => {
      if (typeof el.synonyms === "string") {
        el.synonyms = el.synonyms
          .split(",")
          .map((synonym) => synonym.trim())
          .filter((synonym) => synonym.length);
      } else if (Array.isArray(el.synonyms)) {
        el.synonyms = el.synonyms
          .map((synonym) => synonym.trim())
          .filter((synonym) => synonym.length);
      }
      return el;
    });

    const requestJson = {
      action: 1034,
      host: domain && domain.domain,
      synonymsConf,
      credentials: credentials,
    };
    const responseJson = await context.myFetch(requestJson);
    if (!responseJson.error) {
      context.message({
        type: "success",
        body: "Synonyms have been saved",
      });
      getSynonyms();
    } else {
      context.message({
        type: "error",
        body: responseJson.error,
      });
    }
  };

  useEffect(() => {
    getSynonyms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <section className="content-header">
        <ContentPageHeader pageName="Manage synonyms" subMenu="Search" />
        <div className="page-header-container">
          <h1 className="page-header-title">Synonyms</h1>
          <Button
            sx={{
              backgroundColor: "#ffffff",
              color: "var(--Mid-Grey, #616060)",
              borderRadius: "25px",
              border: "2px solid",
              textTransform: "unset",
              borderColor: "var(--Yellow, #FFBA41)",
              fontFamily: "Poppins",
              padding: "5px 15px",
              height: "40px",
              "&:hover": {
                backgroundColor: "#ffffff",
                border: "2px solid",
                borderColor: "var(--Green, #5daaa1)",
                color: "var(--Green, #5daaa1)",
              },
            }}
            onClick={addRow}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add synonym
          </Button>
        </div>
      </section>
      <AdminCard
        contentStyle={{
          position: "relative",
          minHeight: "270px",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "750px",
          boxShadow: "unset",
        }}
      >
        <div className="admin-card-main-description">
          Exclude synonyms from search results
        </div>
        <SynonymRows
          synonymsArray={synonymsArray}
          setSynonymsArray={setSynonymsArray}
          handleSave={saveSynonyms}
          handleDelete={deleteAllSynonyms}
        />
        <Toaster />
      </AdminCard>
    </div>
  );
};

export default ManageSynonyms;
