import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontFamily: "Poppins",
    fontSize: "14px",
  },
});

const InfoTooltip = ({ text, iconStyle }) => {
  return (
    <CustomTooltip title={text}>
      <InfoOutlinedIcon sx={iconStyle} />
    </CustomTooltip>
  );
};

export default InfoTooltip;
