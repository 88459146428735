import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GlobalContext from "../../../../Context/Context";
import { Box, CircularProgress } from "@mui/material";
import GEOchart from "./GEOchart";

const SessionByCountry = ({
  dateStart,
  daysBack,
  config,
  domain,
  isClickedReload,
}) => {
  const context = useContext(GlobalContext);
  // const domain = context.getDomains()[params?.index]?.domain;
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  let startTime = Date.now();

  const actionId = 3;
  const metaData = {
    graphType: "summery",
    sum: [["Impressions", ["impressions"]]],
    groupBy: ["GEO"],
  };

  const parseData = (serverData, geoMapping) => {
    const parsedData = [["Country", "Popularity"]];
    serverData.forEach((item) => {
      const country = geoMapping[item.GEO] || item.GEO;
      parsedData.push([country, item.impressions]);
    });
    return parsedData;
  };

  const getChartData = async () => {
    setIsLoading(true);
    startTime = Date.now();
    setChartData([]);
    try {
      const result = await context.DBmanager.getGraphData(
        context.loadCredentials(),
        dateStart,
        daysBack,
        actionId,
        domain,
        metaData
      );
      const ParsedData = parseData(result, config?.paramsNames?.GEO);
      setChartData(ParsedData);
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, 1000 - elapsedTime);
      if (remainingTime > 0) {
        setTimeout(() => setIsLoading(false), remainingTime);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (context.state.DBmanagerReady) getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateStart,
    daysBack,
    config,
    context.state.domainIndex,
    context.state.DBmanagerReady,
    isClickedReload,
  ]);

  return (
    <div
      id="impressions-chart"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "270px",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#5daaa1" }} />
        </Box>
      ) : (
        <div style={{ width: "100%", position: "relative" }}>
          <GEOchart data={chartData} />
        </div>
      )}
    </div>
  );
};

export default SessionByCountry;
