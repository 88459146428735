import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../../../Context/Context";
import ReactApexChart from "react-apexcharts";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const SiteAnalytics = ({ dateStart, daysBack, domain, isClickedReload }) => {
  const actionId = 3;
  const metaData = {
    graphType: "timeLine",
  };

  const [impressionsData, setImpressionsData] = useState([]);
  const context = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);

  const getChartData = async () => {
    setIsLoading(true);
    let startTime = Date.now();
    try {
      const result = await context.DBmanager.getGraphData(
        context.loadCredentials(),
        dateStart,
        daysBack,
        actionId,
        domain,
        metaData
      );
      setImpressionsData(result);

      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, 1000 - elapsedTime);
      if (remainingTime > 0) {
        setTimeout(() => setIsLoading(false), remainingTime);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (context.state.DBmanagerReady) getChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateStart,
    daysBack,
    context.state.domainIndex,
    context.state.DBmanagerReady,
    isClickedReload,
  ]);

  const series = [
    {
      name: "Impressions count",
      color: "#5DAAA1",
      data: impressionsData.map((data) => ({
        x: new Date(data._date),
        y: data.impressions,
      })),
    },
  ];

  const options = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    title: {
      text: "Site Analytics",
      align: "left",
      style: {
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#616060",
        fontWeight: "500",
      },
    },
    subtitle: {
      text: "",
      align: "left",
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Poppins",
          color: "#616060",
          fontWeight: "500",
        },
      },
    },
    yaxis: {
      opposite: false,
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Poppins",
          color: "#616060",
          fontWeight: "500",
        },
      },
    },
    legend: {
      horizontalAlign: "left",
    },
    grid: {
      show: true,
      borderColor: "#f1f1f1",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  return (
    <div>
      <div
        id="impressions-chart"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 350,
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: "#5daaa1" }} />
          </Box>
        ) : (
          <div style={{ width: "100%" }}>
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={350}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default SiteAnalytics;
