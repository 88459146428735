import React, { useContext, useState } from "react";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import GlobalContext from "../../../Context/Context";
import {
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@mui/material";
import HeyDayTextField from "../../../Shared/Components/TextField/HeyDayTextField";

const Payment = () => {
  const context = useContext(GlobalContext);
  const [clientName, setClientName] = useState("");
  const [coin, setCoin] = useState("1");
  const [iframeUrl, setIframeUrl] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [errorName, setErrorName] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

  window.handleFetchApproval = (data) => {
    console.log("Received data from iframe:", data);
    if (data.paramString) {
      confirmPayment(data.paramString);
    }
  };

  const getIframeUrl = async () => {
    if (clientName.length < 2) {
      setErrorName(true);
      return;
    }
    setButtonEnabled(false);
    const requestJson = {
      action: 19,
      Coin: coin,
      ClientName: encodeURIComponent(clientName),
      credentials: context.loadCredentials(),
    };
    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        if (response.redirUrl) {
          console.log(response.redirUrl);
          setIframeUrl(response.redirUrl);
        }
      } else {
        context.message({
          type: "error",
          body: response.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });

      console.error("Error:", error);
    }
    setButtonEnabled(false);
  };

  const handleSaveAffStatus = async () => {
    const requestJson = {
      action: 1002,
      setStatus: "1",
      credentials: context.loadCredentials(),
    };

    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        sessionStorage.setItem("affStatus", 1);
      }
      console.log(response);
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
      console.error("Error:", error);
    }
  };

  const confirmPayment = async (paramString) => {
    const requestJson = {
      action: 1020,
      credentials: context.loadCredentials(),
      redirParam: paramString,
      ClientName: encodeURIComponent(clientName),
    };
    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        if (response.actionStatus) {
          setPaymentComplete(true);
          await handleSaveAffStatus();
        }
      } else {
        context.message({
          type: "error",
          body: response.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });

      console.error("Error:", error);
    }
  };

  const handleInputChange = (e) => {
    setIframeUrl("");
    setErrorName(false);
    setClientName(e.target.value);
    setButtonEnabled(true);
  };

  const handleCoinChange = (e) => {
    setIframeUrl("");
    setCoin(e.target.value);
    setButtonEnabled(true);
  };

  return (
    <div>
      <AdminCard
        contentStyle={{
          position: "relative",
          minHeight: "270px",
        }}
        sx={{
          width: "100%",
          position: "relative",
          maxWidth: "1150px",
          boxShadow: "unset",
        }}
      >
        <div className="payment-fields">
          <p>Please provide your payment details.</p>
          <p>*Enjoy a 14-day trial period where you won't be charged</p>
          {!paymentComplete && (
            <div className="inputs-wrapper-a">
              <HeyDayTextField
                className="hdy-text-field"
                style={{ marginTop: "20px" }}
                required
                labelValue="Name on card"
                value={clientName}
                onChange={handleInputChange}
                error={errorName}
              />
              <FormControl sx={{ marginTop: "20px" }}>
                <InputLabel
                  sx={{
                    color: "#929292",
                    fontFamily: "'Heebo', sans-serif",
                    left: "15px",
                    top: "-5px",

                    "&.Mui-focused": {
                      color: "#929292 !important",
                      top: "-5px",
                      left: "15px",
                    },
                  }}
                >
                  Select your currency
                </InputLabel>
                <Select
                  value={coin}
                  onChange={handleCoinChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem
                    sx={{ fontFamily: "'Heebo', sans-serif" }}
                    value={"1"}
                  >
                    <Typography variant="inherit">ILS</Typography>
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "'Heebo', sans-serif" }}
                    value={"2"}
                  >
                    <Typography variant="inherit">USD</Typography>
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "'Heebo', sans-serif" }}
                    value={"3"}
                  >
                    <Typography variant="inherit">EURO</Typography>
                  </MenuItem>
                  <MenuItem
                    sx={{ fontFamily: "'Heebo', sans-serif" }}
                    value={"4"}
                  >
                    <Typography variant="inherit">POUND</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                sx={{
                  marginTop: "20px",
                  width: "100%",
                  backgroundColor: "#5c64ff",
                  boxShadow: "unset",
                  fontFamily: "Poppins",
                  textTransform: "unset",
                  "&:hover": {
                    backgroundColor: "#7278f5",
                    boxShadow: "inherit",
                  },
                }}
                variant="contained"
                disabled={!buttonEnabled}
                onClick={() => {
                  getIframeUrl();
                  setButtonEnabled(false);
                }}
              >
                Load Payment Info
              </Button>
            </div>
          )}
          {!buttonEnabled ? (
            <div key={iframeUrl}>
              <iframe
                id="hdy-payment-iframe"
                title="hdy-payment-iframe"
                src={iframeUrl}
                style={{
                  width: "100%",
                  height: "540px",
                  marginBottom: 20,
                  border: "none",
                }}
              />
            </div>
          ) : (
            <div>
              <img
                className="payment-img-a"
                src="/img/payment.jpg"
                alt="payment"
              />
            </div>
          )}
          <p>
            *Billing occurs monthly and will be processed at the end of each
            month.
          </p>
        </div>
      </AdminCard>
    </div>
  );
};

export default Payment;
