export function generatePathMap(input, prefix = "") {
  const output = {};
  for (const [key, value] of Object.entries(input)) {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          Object.assign(output, generatePathMap(value[i], `${newKey}.${i}`));
        }
      } else {
        Object.assign(output, generatePathMap(value, newKey));
      }
    } else {
      output[value] = newKey;
    }
  }
  return output;
}

export function createObjects(arr, pathMap) {
  return arr.map((item) => {
    const newObj = {};
    for (const key in pathMap) {
      const path = pathMap[key].split(".");
      let value = item;
      for (const p of path) {
        if (value === null || value === undefined || value[p] === undefined) {
          value = undefined;
          break;
        }
        value = value[p];
      }
      newObj[key] = value;
    }
    return newObj;
  });
}

export async function updateIntegrationState(step, context) {
  let sessionDomains = sessionStorage.getItem("domains") || "";
  sessionDomains = sessionDomains.split(",");
  if (sessionDomains.length > 1) {
    return;
  } else {
    const requestJson = {
      action: 1016,
      setIntegrationState: step,
      host: sessionStorage.getItem("currentDomain"),
      credentials: context.loadCredentials(),
    };

    try {
      const response = await context.myFetch(requestJson);
      if (!response.error) {
        sessionStorage.setItem("integrationState", step);
      }
      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  }
}

export async function getIntegrationState(context) {
  const requestJson = {
    action: 1016,
    host: sessionStorage.getItem("currentDomain"),
    credentials: context.loadCredentials(),
  };

  try {
    const response = await context.myFetch(requestJson);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
}
