import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Tooltip,
  Popover,
  Button,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HeyDayFormLabel from "../../../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import "./DisplayElements.css";

const DisplayElements = ({
  item,
  index,
  toggleEdit,
  register,
  getValues,
  setValue,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    getValues(item.textAreaId)
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    setValue(item.textAreaId, e.target.value);
  };

  return (
    <div className="config-elements" key={index}>
      {item.formItemType === "input" && (
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          <HeyDayFormLabel sx={{ display: "flex" }}>
            {item.textAreaLabel}
            {item.textAreaId === "logo" && (
              <img
                style={{
                  marginLeft: "20px",
                  width: "44px",
                  objectFit: "contain",
                }}
                alt="logo"
                src={getValues(item.textAreaId)}
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            )}
          </HeyDayFormLabel>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            <TextField
              sx={{
                width: "100%",
                fontFamily: "Poppins",
                fontSize: "14px",
                marginRight: "20px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  "& fieldset": { borderColor: "white", border: "none" },
                },
              }}
              inputProps={{ sx: { "&::placeholder": { color: "#9a9b9d" } } }}
              {...register(item.textAreaId)}
              defaultValue={getValues(item.textAreaId)}
              onChange={(e) => setValue(item.textAreaId, e.target.value)}
              id={item.textAreaId}
              placeholder={item.textAreaPlaceholder}
              disabled={item.textAreaIdEdit}
            />
            <IconButton
              onClick={() => toggleEdit(item.textAreaId)}
              disabled={!item.textAreaIdEdit}
            >
              <EditIcon />
            </IconButton>
          </div>
        </Box>
      )}
      {item.formItemType === "select" &&
        item.textAreaId !== "defaultCurrency" && (
          <Box
            sx={{
              width: "100%",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <HeyDayFormLabel sx={{ display: "flex" }}>
              {item.textAreaLabel}
            </HeyDayFormLabel>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "20px",
              }}
            >
              <TextField
                select
                sx={{
                  maxWidth: "481px",
                  width: "160px",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  marginRight: "20px",
                  "& .MuiOutlinedInput-root": {
                    height: "44px",
                    border: "2px solid var(--Grey, #EBEFF3)",
                    borderRadius: "100px",
                    "& fieldset": { borderColor: "white", border: "none" },
                  },
                }}
                id={item.textAreaId}
                {...register(item.textAreaId)}
                defaultValue={getValues(item.textAreaId)}
                onChange={(e) => setValue(item.textAreaId, e.target.value)}
                disabled={item.textAreaIdEdit}
              >
                <MenuItem value="he-HE">he-HE</MenuItem>
                <MenuItem value="en-US">en-US</MenuItem>
              </TextField>
              <IconButton
                onClick={() => toggleEdit(item.textAreaId)}
                disabled={!item.textAreaIdEdit}
              >
                <EditIcon />
              </IconButton>
            </div>
          </Box>
        )}
      {item.formItemType === "select" &&
        item.textAreaId === "defaultCurrency" && (
          <Box
            sx={{
              width: "100%",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <HeyDayFormLabel sx={{ display: "flex" }}>
              {item.textAreaLabel}
            </HeyDayFormLabel>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "20px",
              }}
            >
              <TextField
                select
                sx={{
                  maxWidth: "481px",
                  width: "160px",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  marginRight: "20px",
                  "& .MuiOutlinedInput-root": {
                    height: "44px",
                    border: "2px solid var(--Grey, #EBEFF3)",
                    borderRadius: "100px",
                    "& fieldset": { borderColor: "white", border: "none" },
                  },
                }}
                id={item.textAreaId}
                {...register(item.textAreaId)}
                defaultValue={getValues(item.textAreaId)}
                onChange={(e) => setValue(item.textAreaId, e.target.value)}
                disabled={item.textAreaIdEdit}
              >
                <MenuItem value="" disabled>
                  Select Currency
                </MenuItem>
                <MenuItem value="$">USD - $</MenuItem>
                <MenuItem value="€">EUR - €</MenuItem>
                <MenuItem value="£">GBP - £</MenuItem>
                <MenuItem value="₪">ILS - ₪</MenuItem>
              </TextField>
              <IconButton
                onClick={() => toggleEdit(item.textAreaId)}
                disabled={!item.textAreaIdEdit}
              >
                <EditIcon />
              </IconButton>
            </div>
          </Box>
        )}
      {item.formItemType === "textArea" && (
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          <HeyDayFormLabel sx={{ display: "flex", alignItems: "center" }}>
            {item.textAreaLabel}
            {item.isTmpl && (
              <Tooltip title="Copy Placeholder">
                <IconButton
                  onClick={handlePopoverClick}
                  size="small"
                  sx={{ ml: 1 }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </HeyDayFormLabel>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClick}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: { maxWidth: "450px", maxHeight: "400px" },
            }}
          >
            <Typography sx={{ p: 1 }}>{item.textAreaPlaceholder}</Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
              <Button
                sx={{
                  color: "var(--Green, #5daaa1)",
                  fontFamily: "Poppins",
                  textTransform: "none",
                }}
                startIcon={<ContentCopyIcon />}
                onClick={() => copyToClipboard(item.textAreaPlaceholder)}
                size="small"
              >
                Copy
              </Button>
            </Box>
          </Popover>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <textarea
              style={{
                flex: "1",
                marginTop: "15px",
                marginRight: "10px",
                fontFamily: "Poppins",
                fontSize: "14px",
                border: "2px solid var(--Grey, #EBEFF3)",
                borderRadius: "10px",
                minHeight: "200px",
                maxWidth: "100%",
                outlineColor: "#5daaa1",
              }}
              id={item.textAreaId}
              placeholder={item.textAreaPlaceholder}
              {...register(item.textAreaId)}
              defaultValue={getValues(item.textAreaId)}
              onChange={(e) => setValue(item.textAreaId, e.target.value)}
              disabled={item.textAreaIdEdit}
            />
            <IconButton
              style={{ marginTop: "15px" }}
              onClick={() => toggleEdit(item.textAreaId)}
              disabled={!item.textAreaIdEdit}
            >
              <EditIcon />
            </IconButton>
          </div>
        </Box>
      )}
      {item.formItemType === "radio" && (
        <Box sx={{ width: "100%", marginTop: "20px" }}>
          <HeyDayFormLabel sx={{ display: "flex" }}>
            {item.textAreaLabel}
          </HeyDayFormLabel>
          <RadioGroup
            row
            id={item.textAreaId}
            value={selectedValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                "& .Mui-checked": { color: "#5DAAA1 !important" },
              }}
              value="ltr"
              control={<Radio />}
              label="LTR"
            />
            <FormControlLabel
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                "& .Mui-checked": { color: "#5DAAA1 !important" },
              }}
              value="rtl"
              control={<Radio />}
              label="RTL"
            />
          </RadioGroup>
        </Box>
      )}
    </div>
  );
};

export default DisplayElements;
