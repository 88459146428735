import React, { useState } from "react";
import { Modal, Button, IconButton, Box } from "@mui/material";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import ArrowBackIosNewTwoToneIcon from "@mui/icons-material/ArrowBackIosNewTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import "./SelectTemplateModal.css";
import EcommerceTemplates from "./Wizard/ecommerce/templates/EcommerceTemplates";
import NewsTemplates from "./Wizard/news/templates/NewsTemplates";
import Wizard from "./Wizard/Wizard";
import { useTheme } from "@mui/material/styles";

export const SelectTemplateModal = ({
  domain,
  configs,
  selectedTemplate,
  setSelectedTemplate,
  setStylesheetValue,
  getStyleValue,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [step, setStep] = useState(1);
  const stepsHeader = {
    1: "Select Your Website Type",
    2: "Select your search results template",
    3: "Customize your search box",
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setStep(1);
    setOpen(true);
  };

  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxWidth: "900px",
    minHeight: "300px",
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  };

  return (
    <div>
      <div
        style={{ textAlign: "left", margin: "10px 0 10px 0", fontSize: "14px" }}
      >
        Your selected template:
      </div>
      <img
        style={{ width: "200px" }}
        alt={selectedTemplate}
        src={`/img/searchTemplates/${selectedTemplate}.png`}
        onError={(e) => {
          e.target.style.display = "none";
        }}
      />
      <div>Or</div>
      <Button
        sx={{
          color: " var(--Green, #5DAAA1)",
          fontFamily: "Poppins",
          fontSize: "14px",
          textDecorationLine: "underline",
          textTransform: "unset",
          padding: 0,
        }}
        onClick={handleOpen}
      >
        Select Different Template
      </Button>
      <Modal
        open={open}
        onClose={null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            {step > 1 ? (
              <IconButton onClick={() => setStep(step - 1)}>
                <ArrowBackIosNewTwoToneIcon />
              </IconButton>
            ) : (
              <div></div>
            )}
            <div className="select-tmp-title">{stepsHeader[step]}</div>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {step === 1 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button
                  sx={{
                    height: "151px",
                    width: "45%",
                    color: selectedType === "ecommerce" ? "#5DAAA1" : "#616060",
                    textTransform: "unset",
                    fontFamily: "Poppins",
                    backgroundColor:
                      selectedType === "ecommerce" ? "#EDF6F5" : undefined,
                    borderRadius: "10px",
                    border: "1px solid #EBEFF3",
                    borderColor:
                      selectedType === "ecommerce" ? "#5DAAA1" : "#EBEFF3",
                  }}
                  onClick={() => setSelectedType("ecommerce")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <StorefrontOutlinedIcon />
                    </div>
                    <div style={{ width: "100%" }}>eCommerce</div>
                  </div>
                </Button>
                <Button
                  sx={{
                    height: "151px",
                    width: "45%",
                    color: selectedType === "news" ? "#5DAAA1" : "#616060",
                    textTransform: "unset",
                    fontFamily: "Poppins",
                    backgroundColor:
                      selectedType === "news" ? "#EDF6F5" : undefined,
                    borderRadius: "10px",
                    border: "1px solid #EBEFF3",
                    borderColor:
                      selectedType === "news" ? "#5DAAA1" : "#EBEFF3",
                  }}
                  onClick={() => setSelectedType("news")}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <NewspaperOutlinedIcon />
                    </div>
                    <div style={{ width: "100%" }}>Content/News</div>
                  </div>
                </Button>
              </Box>
            </>
          )}
          {selectedType !== "" && step === 1 && (
            <div className="btn-next-step-container">
              <Button className="next-step-modal" onClick={() => setStep(2)}>
                Next
              </Button>
            </div>
          )}
          {step === 2 && selectedType === "ecommerce" && (
            <EcommerceTemplates
              setStep={setStep}
              setSelectedTemplate={setSelectedTemplate}
              domain={domain}
              handleCloseModal={handleClose}
            />
          )}
          {step === 2 && selectedType === "news" && (
            <NewsTemplates
              setStep={setStep}
              setSelectedTemplate={setSelectedTemplate}
              domain={domain}
              handleCloseModal={handleClose}
            />
          )}
          {step === 3 && (
            <Wizard
              domain={domain}
              configs={configs}
              setStylesheetValue={setStylesheetValue}
              getStyleValue={getStyleValue}
              handleCloseModal={handleClose}
              selectedType={selectedType}
              selectedTemplate={selectedTemplate}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};
