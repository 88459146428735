import React, { useState, useContext, useEffect } from "react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import HeyDayFormLabel from "../../../../../../../../Shared/Components/FormLabel/HeyDayFormLabel";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import GlobalContext from "../../../../../../../../Context/Context";
import DisplayElements from "./DisplayElements";
import {
  Grid,
  TextField,
  IconButton,
  Button,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const SearchBoxLocation = ({ domain, configs = [], searchBox = {} }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const context = useContext(GlobalContext);

  const [ga4Configuration, setGa4Configuration] = useState(() => {
    const foundItem = configs.find(
      (item) => item?.GA4 && Object.keys(item?.GA4)?.length > 0
    );
    return foundItem
      ? foundItem.GA4
      : {
          gtagKey: "",
          trackingId: "",
        };
  });

  const [elements, setElements] = useState(
    searchBox.elements ? searchBox.elements : []
  );

  const [renderHidden, setRenderHidden] = useState(() => {
    const foundItem = configs.find(
      (item) => item?.renderHidden && Object.keys(item?.renderHidden).length > 0
    );
    return foundItem
      ? [foundItem.renderHidden]
      : [{ type: "triggered", selector: "" }];
  });

  const addElement = () => {
    setElements((elements) => [
      ...elements,
      { type: "triggered", selector: "" },
    ]);
  };
  const removeAllElements = () => {
    setElements([]);
  };

  const handleChangeGa4 = (e) => {
    const { name, value } = e.target;
    setGa4Configuration((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
  };

  const handleChangeRenderHidden = (value, index) => {
    const updatedArray = [...renderHidden];
    updatedArray[index].type = value;
    setRenderHidden(updatedArray);
  };

  const setRenderHiddenSelector = async (e, index) => {
    let clone = [...renderHidden];
    clone[index].selector = e.target.value;
    setRenderHidden(clone);
  };

  const handleChangeElement = (value, index) => {
    const updatedArray = [...elements];
    updatedArray[index].type = value;
    setElements(updatedArray);
  };

  const setSelector = async (e, index) => {
    let clone = [...elements];
    clone[index].selector = e.target.value;
    setElements(clone);
  };

  const handleSaveLocationConfig = async () => {
    const updatedConfig = [...configs];
    const ga4Index = updatedConfig.findIndex((item) => item.GA4);
    if (ga4Index !== -1) {
      updatedConfig[ga4Index].GA4 = ga4Configuration;
    } else {
      updatedConfig.push({ GA4: ga4Configuration });
    }

    const renderHiddenIndex = updatedConfig.findIndex(
      (item) => item.renderHidden
    );
    if (renderHiddenIndex !== -1) {
      updatedConfig[renderHiddenIndex].renderHidden = renderHidden[0];
    } else {
      updatedConfig.push({ renderHidden: renderHidden[0] });
    }

    const searchBoxIndex = updatedConfig.findIndex((item) => item.searchBox);
    if (searchBoxIndex !== -1) {
      updatedConfig[searchBoxIndex].searchBox["elements"] = elements;
    } else {
      updatedConfig.push({ searchBox: { elements: elements } });
    }

    try {
      const requestJson = {
        action: 1014,
        host: domain?.domain,
        configs: updatedConfig,
        credentials: context.loadCredentials(),
      };

      const responseJson = await fetchRequest(requestJson);
      if (responseJson.domains) {
        sessionStorage.setItem(
          "allDomains",
          JSON.stringify(responseJson.domains)
        );
        context.message({
          type: "success",
          body: "Indexing settings were successfully updated",
        });
      } else if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error.message,
      });
    }
  };

  const fetchRequest = async (requestJson) => {
    const responseJson = await context.myFetch(requestJson);
    return responseJson;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <div>Manage searchbox location</div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <HeyDayFormLabel
          sx={{
            marginRight: "20px",
            marginBottom: isMobileView ? "10px" : undefined,
          }}
        >
          Customized location
        </HeyDayFormLabel>
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          <Button
            sx={{
              backgroundColor: "#ffffff",
              color: "var(--Green, #5daaa1)",
              borderRadius: "25px",
              border: "2px dashed",
              textTransform: "unset",
              borderColor: "var(--Green, #5daaa1)",
              fontFamily: "Poppins",
              padding: "5px 15px",
              height: "40px",
              "&:hover": {
                backgroundColor: "#5daaa1",
                border: "2px solid",
                borderColor: "var(--Green, #5daaa1)",
                color: "var(--Green, #ffffff)",
              },
            }}
            onClick={addElement}
          >
            Add Elements
            <AddCircleOutlineRoundedIcon sx={{ marginLeft: "10px" }} />
          </Button>
          <Button
            disableRipple={true}
            sx={{
              color: "#F64A4A",
              "&:hover": {
                backgroundColor: "unset",
              },
            }}
            onClick={removeAllElements}
          >
            <DeleteOutlineRoundedIcon />
          </Button>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <HeyDayFormLabel>Activating elements</HeyDayFormLabel>
        <DisplayElements
          elements={elements}
          setElements={setElements}
          handleChangeElement={handleChangeElement}
          setSelector={setSelector}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <HeyDayFormLabel>Render hidden</HeyDayFormLabel>
        <DisplayElements
          elements={renderHidden}
          setElements={setRenderHidden}
          handleChangeElement={handleChangeRenderHidden}
          setSelector={setRenderHiddenSelector}
        />
      </div>
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <HeyDayFormLabel>GA4 configuration</HeyDayFormLabel>
        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ flexWrap: "wrap", marginTop: "10px" }}
        >
          <Grid item>
            <TextField
              value={ga4Configuration?.gtagKey}
              name="gtagKey"
              onChange={handleChangeGa4}
              placeholder="gtag key"
              sx={{
                maxWidth: "381px",
                width: "160px",
                marginRight: isMobileView ? "0" : "20px",
                marginBottom: isMobileView ? "10px" : "0",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              value={ga4Configuration?.trackingId}
              onChange={handleChangeGa4}
              name="trackingId"
              placeholder="tracking Id"
              sx={{
                maxWidth: "481px",
                marginRight: isMobileView ? "0" : "20px",
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  border: "2px solid var(--Grey, #EBEFF3)",
                  borderRadius: "100px",
                  "& fieldset": {
                    borderColor: "white",
                    border: "none",
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ color: "#F64A4A" }}
                      edge="end"
                      onClick={() => {
                        setGa4Configuration({
                          gtagKey: "",
                          trackingId: "",
                        });
                      }}
                    >
                      <CancelOutlinedIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Button
        sx={{
          backgroundColor: "#ffffff",
          color: "var(--Green, #5daaa1)",
          borderRadius: "25px",
          marginTop: "40px",
          border: "2px solid",
          textTransform: "unset",

          borderColor: "var(--Green, #5daaa1)",
          fontFamily: "Poppins",
          padding: "5px 15px",
          height: "40px",
          width: "109px",
          "&:hover": {
            backgroundColor: "#5daaa1",
            border: "2px solid",
            borderColor: "var(--Green, #5daaa1)",
            color: "var(--Green, #ffffff)",
          },
        }}
        onClick={handleSaveLocationConfig}
      >
        Save
      </Button>
    </div>
  );
};

export default SearchBoxLocation;
