import React from "react";
import {
  Grid,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const DisplayElements = ({
  elements,
  setElements,
  handleChangeElement,
  setSelector,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {elements.length < 1 ? <div>No active elements</div> : ""}
      {elements.length > 0 &&
        elements.map((element, index) => (
          <Grid
            key={index}
            container
            justifyContent={isMobile ? "flex-start" : "space-between"}
            alignItems="center"
            spacing={2}
            style={{ flexWrap: "wrap", marginTop: "10px" }}
          >
            <Grid item xs={12} sm={6} sx={{ textAlign: "left" }}>
              <TextField
                select
                sx={{
                  maxWidth: "481px",
                  width: "160px",
                  marginRight: isMobile ? "0" : "20px",
                  marginBottom: isMobile ? "10px" : "0", // Added margin bottom for mobile
                  "& .MuiOutlinedInput-root": {
                    height: "44px",
                    border: "2px solid var(--Grey, #EBEFF3)",
                    borderRadius: "100px",
                    "& fieldset": {
                      borderColor: "white",
                      border: "none",
                    },
                  },
                }}
                value={element.type || ""}
                onChange={(e) => handleChangeElement(e.target.value, index)}
              >
                <MenuItem value="triggered">triggered</MenuItem>
                <MenuItem value="holder">holder</MenuItem>
                <MenuItem value="AC_holder">AC_holder</MenuItem>
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
              sm={isMobile ? 12 : 5}
              className="searchElementsInput"
              sx={{ textAlign: "left" }}
            >
              <TextField
                value={element.selector}
                onChange={(e) => setSelector(e, index)}
                placeholder="Selector"
                sx={{
                  maxWidth: "481px",
                  width: "160px",
                  marginRight: "20px",
                  "& .MuiOutlinedInput-root": {
                    height: "44px",
                    border: "2px solid var(--Grey, #EBEFF3)",
                    borderRadius: "100px",
                    "& fieldset": {
                      borderColor: "white",
                      border: "none",
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ color: "#F64A4A" }}
                        edge="end"
                        onClick={() => {
                          setElements((elements) =>
                            elements.filter((el, _i) => _i !== index)
                          );
                        }}
                      >
                        <CancelOutlinedIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        ))}
    </div>
  );
};

export default DisplayElements;
