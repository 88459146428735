import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../../../Context/Context";
import ContentPageHeader from "../ContentPageHeader/ContentPageHeader";
import DomainField from "./DomainField";
import DomainFieldAction from "./DomainFieldAction";
import AdminCard from "../../../Shared/Components/Card/AdminCard";
import { Integration, IntegrationAction } from "./Integration";
// import { TermsAndConditions } from "../TermsAndConditions/TermsAndConditions";
import { StartOnboardingModal } from "./StartOnboardingModal";
import { Typography, Button } from "@mui/material";
import "./AddDomain.css";

const AddDomain = () => {
  const [current, setCurrent] = useState(0);
  const [newIndex, setIndex] = useState(null);
  const [domainName, setDomainName] = useState("");
  const context = useContext(GlobalContext);
  const allDomains = context.getDomains();
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    if (
      (allDomains &&
        allDomains.length === 0 &&
        !sessionStorage.getItem("onboardingModal")) ||
      (!allDomains && !sessionStorage.getItem("onboardingModal"))
    ) {
      sessionStorage.setItem("onboardingModal", 1);
      setOpenConfirm(true);
    }
  }, []);

  return (
    <>
      {current === 2 ? (
        <>terms</>
      ) : (
        // <TermsAndConditions newIndex={newIndex} />
        <>
          <>
            <section className="content-header">
              <ContentPageHeader pageName="Add Domain" />
              <Button
                className="btn-start-on-boarding"
                onClick={() => (window.location.href = "/selfserve/2")}
              >
                Start On Boarding
              </Button>
            </section>
          </>
          <StartOnboardingModal
            openConfirm={openConfirm}
            setOpenConfirm={setOpenConfirm}
          />
          <AdminCard
            contentStyle={{
              position: "relative",
              minHeight: "270px",
            }}
            sx={{
              width: "100%",
              position: "relative",
              maxWidth: "1150px",
              boxShadow: "unset",
            }}
          >
            <section>
              <div id="onboard" className="prettify hideItAfter">
                {current === 0 && (
                  <div className="signupPopup">
                    <img src="/img/hand_popup.png" alt="heyday" />
                  </div>
                )}
                <div className="steps-header">
                  <div id="step2" className={current === 1 ? "activeStep" : ""}>
                    <Typography variant="body1">Integrate to HeyDay</Typography>
                  </div>
                </div>
                <div className={`steps-content step-${current}`}>
                  {current === 0 ? (
                    <DomainField setDomainName={setDomainName} />
                  ) : (
                    <Integration domainName={domainName} />
                  )}
                  <div className="steps-action margin-lg-top">
                    {current === 0 && (
                      <DomainFieldAction
                        current={current}
                        setCurrent={setCurrent}
                        domainName={domainName}
                        setDomainName={setDomainName}
                        setIndex={setIndex}
                        context={context}
                      />
                    )}
                    {current === 1 && (
                      <IntegrationAction
                        setCurrent={setCurrent}
                        current={current}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </AdminCard>
        </>
      )}
    </>
  );
};

export default AddDomain;
