import React from "react";
import "./EcommerceTemplates.css";

const EcommerceTemplates = ({
  setStep,
  setSelectedTemplate,
  handleCloseModal,
}) => {
  const handleChoseTemplate = (templateName) => {
    setSelectedTemplate(templateName);
    // setStep(3);
    handleCloseModal();
  };
  return (
    <div className="templates-container">
      <div
        className="template-card"
        onClick={() => handleChoseTemplate("eCenterMenu")}
      >
        <img alt="ecommerceMiddle" src="/img/searchTemplates/eCenterMenu.png" />
        <h6>eCommerce</h6>
        <div>Center Filters Menu</div>
      </div>
      <div
        className="template-card"
        onClick={() => handleChoseTemplate("eSideMenu")}
      >
        <img alt="ecommerceMiddle" src="/img/searchTemplates/eSideMenu.png" />
        <h6>eCommerce</h6>
        <div>Side Filters Menu</div>
      </div>
      <div
        className="template-card"
        onClick={() => handleChoseTemplate("eSlideMenu")}
      >
        <img alt="ecommerceMiddle" src="/img/searchTemplates/eSlideMenu.png" />
        <h6>eCommerce</h6>
        <div>Slide in Menu</div>
      </div>
    </div>
  );
};

export default EcommerceTemplates;
