import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import IOSSwitch from "../../../Shared/Components/Switch/IOSSwitch";

const FiltersAnalytics = ({
  options,
  metaData,
  setMetaData,
  getChartData,
  context,
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [groupBy, setGroupBy] = useState(metaData.groupBy || []);

  useEffect(() => {
    if (metaData.groupBy) {
      setGroupBy(metaData.groupBy);
    }
  }, [metaData.groupBy]);

  const handleApplyFilters = () => {
    if (context.state.DBmanagerReady) {
      getChartData(3);
    }
  };

  const handleOptionSelect = (category, selectedValues) => {
    const whereParams = selectedValues.length > 0 ? selectedValues : null;
    let updatedState = { ...metaData };
    if (!updatedState.where) {
      updatedState.where = {};
    }
    if (whereParams === null) {
      delete updatedState.where[category];
    } else {
      updatedState.where[category] = {
        isExclude: false,
        params: whereParams,
      };
    }
    if (Object.keys(updatedState.where).length === 0) {
      delete updatedState.where;
    }
    setMetaData(updatedState);
  };

  const handleExcludeChange = (category, isChecked) => {
    setMetaData((prevState) => ({
      ...prevState,
      where: {
        ...prevState.where,
        [category]: {
          ...prevState.where?.[category],
          isExclude: !isChecked,
        },
      },
    }));
  };

  const handleGroupBySelect = (event) => {
    const { value } = event.target;
    setGroupBy(value);
    setMetaData((prevState) => {
      const updatedMetaData = { ...prevState };
      if (value.length > 0) {
        updatedMetaData.groupBy = value;
      } else {
        delete updatedMetaData.groupBy;
      }
      return updatedMetaData;
    });
  };

  const toggleCategory = (category) => {
    setSelectedCategories((prevSelectedCategories) => {
      const updatedSelectedCategories = prevSelectedCategories.includes(
        category
      )
        ? prevSelectedCategories.filter((cat) => cat !== category)
        : [...prevSelectedCategories, category];

      setMetaData((prevMetaData) => {
        const updatedMetaData = { ...prevMetaData };

        if (!updatedMetaData.where) {
          updatedMetaData.where = {};
        }

        if (updatedSelectedCategories.length === 0) {
          delete updatedMetaData.where[category];
          if (Object.keys(updatedMetaData.where).length === 0) {
            delete updatedMetaData.where;
          }
        } else {
          updatedMetaData.where[category] = {};
        }

        if (updatedMetaData.where) {
          Object.keys(updatedMetaData.where).forEach((key) => {
            if (Object.keys(updatedMetaData.where[key]).length === 0) {
              delete updatedMetaData.where[key];
            }
          });
        }

        return updatedMetaData;
      });

      return updatedSelectedCategories;
    });
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        flexWrap="wrap"
        style={{ marginBottom: "20px", marginLeft: "0px" }}
      >
        {Object.entries(options).map(([category, values]) => (
          <Button
            key={category}
            onClick={() => toggleCategory(category)}
            variant={
              selectedCategories.includes(category) ? "contained" : "outlined"
            }
            sx={{
              borderRadius: "25px",
              fontFamily: "Poppins",
              color: selectedCategories.includes(category) ? "#fff" : "#5DAAA1",
              backgroundColor: selectedCategories.includes(category)
                ? "#5DAAA1"
                : "transparent",
              border: selectedCategories.includes(category)
                ? "1px solid transparent"
                : "1px solid #5DAAA1",
              "&:hover": {
                backgroundColor: selectedCategories.includes(category)
                  ? "#fff"
                  : "#5DAAA1",
                color: selectedCategories.includes(category)
                  ? "#5DAAA1"
                  : "#fff",
                border: selectedCategories.includes(category)
                  ? "1px solid #5DAAA1"
                  : "none",
              },
            }}
            style={{ margin: "20px 5px 0 5px" }}
          >
            {category}
          </Button>
        ))}
      </Grid>
      <Grid container spacing={2}>
        {Object.entries(options).map(([category, values]) => (
          <Grid item xs={12} key={category}>
            {selectedCategories.includes(category) && (
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <Autocomplete
                    multiple
                    options={Object.entries(values)}
                    getOptionLabel={([value, label]) => label}
                    isOptionEqualToValue={(option, value) =>
                      option[0].toLowerCase() === value[0].toLowerCase()
                    }
                    onChange={(event, selectedValues) =>
                      handleOptionSelect(category, selectedValues)
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "50px",
                            background: "white",
                            fontFamily: "Poppins",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputLabel-outlined": {
                            paddingLeft: "20px",
                            fontFamily: "Poppins",
                          },
                          "& .MuiInputLabel-shrink": {
                            fontFamily: "Poppins",
                            color: "#616060",
                          },
                        }}
                        {...params}
                        label={category}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        onChange={(event) =>
                          handleExcludeChange(category, event.target.checked)
                        }
                        defaultChecked
                      />
                    }
                    label="Include"
                    labelPlacement="top"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Group By</InputLabel>
            <Select
              multiple
              value={groupBy}
              onChange={handleGroupBySelect}
              sx={{
                borderRadius: "25px",
                background: "white",
                fontFamily: "Poppins",
                maxWidth: "400px",
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputLabel-shrink": {
                  fontFamily: "Poppins",
                  color: "#616060",
                },
              }}
              input={<OutlinedInput label="Group By" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {Object.keys(options).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid sx={{ textAlign: "end" }} item xs={12}>
          <Button
            sx={{
              backgroundColor: "#ffffff",
              color: "var(--Green, #5daaa1)",
              borderRadius: "25px",
              marginLeft: "25px",
              border: "2px solid",
              textTransform: "unset",

              borderColor: "var(--Green, #5daaa1)",
              fontFamily: "Poppins",
              padding: "5px 15px",
              height: "40px",

              "&:hover": {
                backgroundColor: "#5daaa1",
                border: "2px solid",
                borderColor: "var(--Green, #5daaa1)",
                color: "var(--Green, #ffffff)",
              },
            }}
            onClick={handleApplyFilters}
          >
            Apply Filters
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default FiltersAnalytics;
