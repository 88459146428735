import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: 55,
  border: "2px solid #5daaa1",
  backgroundColor: "var(--White, #FFF)",
  fontSize: "16px",
  fontFamily: "Poppins-Medium",
  textTransform: "none",
  fontWeight: "400",
  color: "#373737",
  "&:hover": {
    backgroundColor: "#5daaa1",
    color: "#ffffff",
  },
  "& .MuiButtonBase-root": {
    "&:hover": {
      "& .MuiTouchRipple-root": {
        backgroundColor: "#5daaa1",
      },
    },
  },
  "& .MuiTouchRipple-root": {
    backgroundColor: "transparent",
  },
}));

function ButtonSave({
  text,
  style,
  disableRipple,
  disable,
  onClick,
  ...props
}) {
  return (
    <CustomButton
      disableRipple={disableRipple}
      disabled={disable}
      style={style}
      onClick={onClick}
      {...props}
    >
      {text}
    </CustomButton>
  );
}

export default ButtonSave;
