export const defaultStyles = {
  bar: {
    top: "0px",
    position: "sticky",
    zIndex: "99",
    backgroundColor: "#007aff",
    width: "100%",
    fontFamily: "Roboto, HelveticaNeue, Arial, sans-serif",
    color: "#ffffff",
    padding: "10px",
    textAlign: "center",
    display: "block",
  },
  arrow: {
    position: "absolute",
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderRight: "20px solid transparent",
    borderTop: `10px solid #007aff`,
    bottom: "-9px",
    left: "50%",
    transform: "translateX(-50%)",
  },
};

export const productCardDefaultStyle = {
  Rlink: {
    direction: "ltr",
    fontFamily: "Google Sans, GoogleSans-Local, Roboto, Arial, Sans-Serif",
    margin: "10px",
    width: "218px",
    borderRadius: "8px",
    borderColor: "#dadce0",
    borderWidth: "1px",
    borderStyle: "solid",
    textAlign: "left",
    backgroundColor: "white",
  },
  RimgC: {
    margin: "10px auto auto auto",
    height: "160px",
    maxWidth: "100%",
    width: "100%",
    border: "none",
  },
  Rimg: {
    borderRadius: 0,
    padding: "5px",
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  resultsBox: {
    width: "90%",
    margin: "auto",
  },
  Rbrand: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    lineClamp: 2,
    minHeight: "25px",
    textAlign: "left",
    marginRight: "4px",
    marginLeft: "4px",
    WebkitBoxOrient: "vertical",
    fontFamily: "Google Sans, GoogleSans-Local, Roboto, Arial, Sans-Serif",
    fontSize: "14px",
    color: "#038dff",
  },
  Rtitle: {
    minHeight: "38px",
    marginRight: "4px",
    marginLeft: "4px",
    fontSize: "16px",
    fontFamily: "Google Sans, GoogleSans-Local, Roboto, Arial, Sans-Serif",
    textAlign: "left",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    color: "#000",
    textDecoration: "unset",
  },
  Rprice: {
    direction: "ltr",
    overflow: "hidden",
    minHeight: "25px",
    display: "inline-block",
    margin: "5px",
    letterSpacing: "0.2px",
    cursor: "pointer",
    color: "#202124",
    marginRight: "4px",
    fontSize: "16px",
    fontFamily:
      "Google Sans Bold, Google Sans, GoogleSans-Local-Bold, Roboto-Bold, HelveticaNeue-Bold, HelveticaNeue, Sans-Serif-Bold, Arial, Sans-Serif",
    lineHeight: "24px",
    textAlign: "left",
  },
  Rprice_before: {
    textDecoration: "line-through",
    color: "red",
    direction: "ltr",
    overflow: "hidden",
    minHeight: "25px",
    display: "inline-block",
    margin: "5px 4px 5px 5px",
    letterSpacing: "0.2px",
    cursor: "pointer",
    fontSize: "16px",
    fontFamily:
      '"Google Sans Bold", "Google Sans", GoogleSans-Local-Bold, Roboto-Bold, HelveticaNeue-Bold, HelveticaNeue, Sans-Serif-Bold, Arial, sans-serif',
    lineHeight: "24px",
    textAlign: "left",
  },
};
