import React, { useContext } from "react";
import GlobalContext from "../../../../Context/Context";
import ProgressBar from "../../ProgressBar/ProgressBar";
import HeyDayCard from "../../../../Shared/Components/Card/SelfServeCard";
import { IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

const IntegrationComplete = () => {
  const context = useContext(GlobalContext);
  return (
    <div
      className="integration-complete-container"
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div className="logout-container">
        <IconButton onClick={() => context.logout()}>
          <LogoutIcon />
        </IconButton>
        <span>Log Out</span>
      </div>
      <HeyDayCard
        contentStyle={{ position: "relative", minHeight: "530px" }}
        sx={{
          width: "100%",
          maxWidth: "880px",
          position: "relative",
          boxShadow: "unset",
          marginTop: "100px",
        }}
      >
        <ProgressBar stepName={"done"} progressPercentage={"100"} />
        <h1>We are all set!</h1>
        <img
          style={{ maxWidth: "320px", width: "70%" }}
          alt="complete"
          src="/img/complete.png"
        />
        <p>Now we can start working on your site</p>
        <a href="/dashboard">Continue to Dashboard</a>
      </HeyDayCard>
    </div>
  );
};

export default IntegrationComplete;
