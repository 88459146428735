import React, { useState, useContext } from "react";
import GlobalContext from "../../../Context/Context";
import { useParams, useLocation } from "react-router-dom";
import { Box, Avatar } from "@mui/material";
import HeyDayCard from "../../../Shared/Components/Card/SelfServeCard";
import HeyDayTextField from "../../../Shared/Components/TextField/HeyDayTextField";
import HeyDayButton from "../../../Shared/Components/Button/HeyDayButton";
import { login } from "../../../Auth/utils/auth";

function myFetch(requestJson, callback, errorCallback) {
  return fetch("https://admin.heyday.io/panWbPush/OP", {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestJson),
  })
    .then(function (response) {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then(function (responseJson) {
      if (responseJson.status) {
        if (!responseJson.actionStatus) {
          var err = "error ";
          if (responseJson.error) err += responseJson.error;

          if (errorCallback) errorCallback(responseJson);
          else alert(err);
        } else {
          if (callback) callback(responseJson);
        }
      }
      return responseJson;
    })
    .catch(function (error) {
      console.error("Fetch error:", error);
      throw error;
    });
}

const Invitation = () => {
  const context = useContext(GlobalContext);
  const location = useLocation();
  const params = useParams();
  const token = params?.token || false;
  const userType = params?.type || false;
  const queryParams = new URLSearchParams(location.search);
  const click_id = queryParams.get("id");
  const click_src = queryParams.get("click_src");
  const gclid = queryParams.get("gclid");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isLoggedIn = !!sessionStorage.getItem("token");
  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateContactName = (name) => {
    return name.length >= 3 && name.length <= 32;
  };

  const validateForm = () => {
    const newErrors = {};
    if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
    }
    if (!validateContactName(contactName)) {
      newErrors.contactName = "Contact name must be between 3 to 32 characters";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const invitation_signup = async () => {
    function handleErr(responseJson) {
      if (!responseJson.error) responseJson.error = "error";
    }

    var requestJson = {
      action: 1001,
      token: token,
    };
    if (window.jwt) {
      requestJson.jwt = window.jwt;
    } else {
      requestJson.uName = email;
      requestJson.password = password;
      requestJson.contactName = contactName;
    }

    if (userType) {
      if (userType === "1") {
        requestJson.affType = 1;
      } else if (userType === "2") {
        requestJson.affType = 2;
      }
    } else {
      requestJson.affType = 0;
    }
    try {
      const responseJson = await myFetch(
        requestJson,
        function (responseJson) {
          if (window.location.search.match("code")) {
            var queryStr = window.location.search.substr(1);
            queryStr = "heyDayAffId=" + responseJson.affId + "&" + queryStr;
            document.location.href = "newDomain.html?" + queryStr;
            return;
          }
        },
        handleErr
      );

      if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      } else {
        context.message({
          type: "success",
          body: "Sign up successfully!",
        });

        const email = email || "";
        const password = password || "";
        // login(context, email, password, userType) // default navigate to onboarding
        login(context, email, password, "0")
          .then(() => {
            console.log("Logged in successfully!");
          })
          .catch((error) => {
            console.error("Failed to log in:", error);
          });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
      console.error(error);
    }
  };

  const handleCredentialResponse = (response) => {
    console.log(response);
    window.jwt = response.credential;
    var base64Url = response.credential.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    try {
      let j = JSON.parse(jsonPayload);
      console.log(j);
      if (j.email && j.name) {
        invitation_signup();
      } else {
        alert("data error");
      }
    } catch (e) {
      alert("network error");
    }
  };

  const handleClickSubmit = () => {
    if (validateForm()) {
      invitation_signup();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: isLoggedIn ? "" : "100vh",
      }}
    >
      <Box className="login-main-container">
        <img
          style={{
            margin: "20px 0",
            height: "62px",
            width: "175px",
            cursor: "pointer",
          }}
          alt="Heyday-logo"
          src="/img/HeyDay_Logo.png"
          onClick={() => (window.location.href = "/login")}
        />
        {isLoggedIn ? (
          <div>You must logout for continue with your Invitation</div>
        ) : (
          <HeyDayCard
            sx={{
              width: "100%",
              "@media (max-width: 780px)": {
                boxShadow: "unset",
              },
            }}
          >
            <div style={{ width: "100%" }}>
              <div className="hdy-card-title">Create An Account</div>
              <div className="card-line"></div>
              <div className="hdy-paragraph-text">
                Please enter your details
              </div>
              <HeyDayTextField
                style={{
                  marginTop: "20px",
                  width: "100%",
                }}
                onChange={(e) => setContactName(e.target.value)}
                labelValue="Contact Name"
                required
                type="text"
                error={!!errors.contactName}
                helperText={errors.contactName}
              />
              <HeyDayTextField
                style={{
                  marginTop: "20px",
                  width: "100%",
                }}
                labelValue="Email address"
                required
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                error={!!errors.email}
                helperText={errors.email}
              />
              <HeyDayTextField
                style={{
                  marginTop: "20px",
                  width: "100%",
                }}
                required
                onChange={(e) => setPassword(e.target.value)}
                labelValue="Password (8 characters minimum)"
                type="password"
              />
              <HeyDayButton
                style={{ marginTop: 30, width: "100%" }}
                text="Sign Up"
                onClick={handleClickSubmit}
                disable={email === "" || contactName === "" || password === ""}
              />
            </div>
            <div
              className="hdy-paragraph-text"
              style={{ fontSize: "16px", marginTop: "10px" }}
            >
              Or
            </div>
            <Box
              sx={{
                boxShadow: "0px 5px 7px 3px rgba(113, 113, 113, 0.25)",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                margin: "auto",
                padding: "10px",
                position: "relative",
                cursor: "pointer",
                marginTop: "20px",
              }}
              onClick={() => context.initGoogle(handleCredentialResponse)}
            >
              <Avatar
                sx={{
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                src="/img/googleIcon.png"
              />
            </Box>
          </HeyDayCard>
        )}
      </Box>
    </div>
  );
};

export default Invitation;
