import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { styled } from "@mui/system";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& input:-internal-autofill-selected": {
    WebkitBoxShadow: "0 0 0 1000px #f5f6f8 inset",
  },
  "& label": {
    color: "#929292",
    fontFamily: "Helvetica Neue",
    left: "15px",
    top: "-5px",
  },
  "& label.Mui-focused": {
    top: "-5px",
    left: "15px",
    color: "#929292",
  },
  "& .MuiOutlinedInput-input": {
    paddingTop: 0,
    background: "var(--Light-Grey, #F4F6F8)",
    paddingBottom: 0,
  },
  "& .MuiOutlinedInput-root": {
    height: "44px",
    padding: "0 14px !important",
    borderRadius: "55px",
    background: "var(--Light-Grey, #F4F6F8)",
    "& fieldset": {
      borderColor: "white",
      border: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: "white",
      border: "none",
    },
    "&.MuiOutlinedInput-root.Mui-disabled": {
      opacity: 0.5,
    },
  },
}));

function HeyDayTextField({
  onChange,
  style,
  inputPropsStyle,
  labelValue,
  type,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <CustomTextField
      InputProps={{
        style: {
          ...inputPropsStyle,
        },
        endAdornment:
          type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                sx={{ color: "#5DAAA1" }}
                onClick={togglePasswordVisibility}
                edge="end"
                tabIndex={-1}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ) : undefined,
      }}
      type={showPassword ? "text" : type}
      style={style}
      FormHelperTextProps={{ style: { marginLeft: "30px" } }}
      label={labelValue}
      onChange={onChange}
      {...props}
    />
  );
}

export default HeyDayTextField;
