var tmplPlaceHoldersGerman = {
  resWithout: "Ergebnisse ohne",
  approx: "Ungefähr",
  resFound: "Ergebnisse gefunden",
  noRes: "Keine Ergebnisse gefunden für",
  didYouMean: "Meinten Sie",
  prevPage: "Vorherige",
  nextPage: "Nächste",
  AC_item_left: "AC_item_left",
  AC_item_right: "AC_item_right",
  searchBoxText: "Suche",
  missing: "Fehlt",
  must_include: "Muss enthalten",
  for: "für",
  brands: "Marken",
  clear: "Löschen",
  priceRange: "Preisspanne",
  categories: "Kategorien",
  sortBy: "Sortieren nach",
  relevance: "Relevanz",
  priceLTH: "Preis: niedrig bis hoch",
  priceHTL: "Preis: hoch bis niedrig",
  filter: "Filtern",
  to: "Bis",
  from: "Von",
  ellipsisF: "...",
  ellipsisL: "...",
  dateN: "Datum: neueste zuerst",
  dateL: "Datum: älteste zuerst",
  forMoreRes: "Für weitere Ergebnisse",
  minPrice: "Mindestpreis",
  maxPrice: "Höchstpreis",
  showFilters: 'Filter anzeigen',
  hideFilters: 'Filter ausblenden'
};

export default tmplPlaceHoldersGerman;
