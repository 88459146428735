import React, { useContext } from "react";
import { Box, Avatar } from "@mui/material";
import HeyDayTextField from "../../Shared/Components/TextField/HeyDayTextField";
import GlobalContext from "../../Context/Context";
import HeyDayCard from "../../Shared/Components/Card/SelfServeCard";
import HeyDayButton from "../../Shared/Components/Button/HeyDayButton";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { SIGNUP_VALIDATION_SCHEMA } from "../../consts/validators.consts";
import { login } from "../utils/auth";

function myFetch(requestJson, callback, errorCallback) {
  return fetch("https://admin.heyday.io/panWbPush/OP", {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestJson),
  })
    .then(function (response) {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Network response was not ok.");
      }
    })
    .then(function (responseJson) {
      if (responseJson.status) {
        if (!responseJson.actionStatus) {
          var err = "error ";
          if (responseJson.error) err += responseJson.error;

          if (errorCallback) errorCallback(responseJson);
          else alert(err);
        } else {
          if (callback) callback(responseJson);
        }
      }
      return responseJson;
    })
    .catch(function (error) {
      console.error("Fetch error:", error);
      throw error;
    });
}

const SignUp = () => {
  const context = useContext(GlobalContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get("type");
  const click_id = queryParams.get("id");
  const click_src = queryParams.get("click_src");
  const gclid = queryParams.get("gclid");

  const handleCredentialResponse = (response) => {
    console.log(response);
    window.jwt = response.credential;
    var base64Url = response.credential.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    try {
      let j = JSON.parse(jsonPayload);
      console.log(j);
      if (j.email && j.name) {
        signup();
      } else {
        alert("data error");
      }
    } catch (e) {
      alert("network error");
    }
  };

  const initGoogle = () => {
    window.google.accounts.id.initialize({
      client_id:
        "132932844029-8hnok8p73dir4ubg0jguo92gl3bjsvj3.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.prompt((notification) => {
      // if one-tap is not displayed show the google sign-in button (in private mode for example)
      if (notification.isNotDisplayed()) {
        console.log("not displayed");
        window.google.accounts.id.renderButton(
          document.getElementById("g_id_onload"),
          {
            type: "standard",
            shape: "rectangular",
            theme: "outline",
            text: "continue_with",
            size: "large",
            logo_alignment: "center",
            width: 300,
          }
        );
      }
    });
  };

  const signup = async (values) => {
    function handleErr(responseJson) {
      if (!responseJson.error) responseJson.error = "error";
    }

    var requestJson = {
      action: 1000,
    };
    if (window.jwt) {
      requestJson.jwt = window.jwt;
    } else {
      requestJson.uName = values.email;
      requestJson.password = values.password;
      requestJson.contactName = values.contactName;
    }

    if (click_id) {
      requestJson.click_id = click_id;
      requestJson.click_src = click_src || "";
    } else if (gclid) {
      requestJson.click_id = gclid;
      requestJson.click_src = "google";
    }

    // @string - optional params
    ["utm_term", "utm_campaign", "utm_source", "utm_medium"].forEach((p) => {
      if (queryParams.get(p)) {
        requestJson[p] = queryParams.get(p);
      }
    });

    if (userType) {
      if (userType === "1") {
        requestJson.affType = 1;
      } else if (userType === "2") {
        requestJson.affType = 2;
      }
    } else {
      requestJson.affType = 0;
    }
    //To add:
    // requestJson.successiveAction after creating the account

    // requestJson.successiveAction = {
    //   action: 17,
    //   domainName: '',
    //   domainIcon: '',
    //   credentials: {
    //     uName: values.email,
    //     password: values.password,
    //   },
    //   configs: '[{"swPath":"/apps/p/heyDaySw.min.js"}]',
    // };

    try {
      const responseJson = await myFetch(
        requestJson,
        function (responseJson) {
          if (window.location.search.match("code")) {
            var queryStr = window.location.search.substr(1);
            queryStr = "heyDayAffId=" + responseJson.affId + "&" + queryStr;
            document.location.href = "newDomain.html?" + queryStr;
            return;
          }
        },
        handleErr
      );
      if (responseJson.affId) {
        context.message({
          type: "success",
          body: "Sign up successfully!",
        });

        const email = values?.email || "";
        const password = values?.password || "";
        // login(context, email, password, userType) // default navigate to onboarding
        login(context, email, password, "0")
          .then(() => {
            console.log("Logged in successfully!");
          })
          .catch((error) => {
            console.error("Failed to log in:", error);
          });
      }
      if (responseJson.error) {
        context.message({
          type: "error",
          body: responseJson.error,
        });
      }
    } catch (error) {
      context.message({
        type: "error",
        body: error,
      });
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      contactName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: SIGNUP_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      signup(values);
    },
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "100vh",
      }}
    >
      <Box className="login-main-container">
        <img
          style={{
            margin: "20px 0",
            height: "62px",
            width: "175px",
            cursor: "pointer",
          }}
          alt="Heyday-logo"
          src="/img/HeyDay_Logo.png"
          onClick={() => (window.location.href = "/login")}
        />
        <HeyDayCard
          sx={{
            width: "100%",
            "@media (max-width: 780px)": {
              boxShadow: "unset",
            },
          }}
        >
          <div className="hdy-card-title">Create An Account</div>
          <div className="card-line"></div>
          <div className="hdy-paragraph-text">Please enter your details</div>
          <form onSubmit={formik.handleSubmit}>
            <HeyDayTextField
              style={{
                marginTop: "20px",
                width: "100%",
              }}
              {...formik.getFieldProps("contactName")}
              error={
                formik.touched.contactName && Boolean(formik.errors.contactName)
              }
              helperText={
                formik.touched.contactName && formik.errors.contactName
              }
              labelValue="Contact Name"
              required
              InputLabelProps={{ shrink: true }}
            />
            <HeyDayTextField
              style={{
                marginTop: "20px",
                width: "100%",
              }}
              labelValue="Email address"
              required
              {...formik.getFieldProps("email")}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              type="email"
              InputLabelProps={{ shrink: true }}
            />
            <HeyDayTextField
              style={{
                marginTop: "20px",
                width: "100%",
              }}
              required
              {...formik.getFieldProps("password")}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              labelValue="Password (8 characters minimum)"
              type="password"
              InputLabelProps={{ shrink: true }}
            />
            <HeyDayTextField
              style={{
                marginTop: "20px",
                width: "100%",
              }}
              required
              type="password"
              {...formik.getFieldProps("confirmPassword")}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              labelValue="Confirm Password"
              InputLabelProps={{ shrink: true }}
            />
            <HeyDayButton
              style={{ marginTop: 30, width: "100%" }}
              text="Sign Up"
              type="submit"
              disabled={!formik.isValid || !formik.dirty}
            />
          </form>
          <div
            className="hdy-paragraph-text"
            style={{ fontSize: "16px", marginTop: "10px" }}
          >
            Or
          </div>
          <Box
            sx={{
              width: "50%",
              marginTop: "10px",
              display: "flex",
              marginLeft: "25%",
              height: "40px",
            }}
          >
            <Box
              sx={{
                boxShadow: "0px 5px 7px 3px rgba(113, 113, 113, 0.25)",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                margin: "auto",
                padding: "10px",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={initGoogle}
            >
              <Avatar
                sx={{
                  width: "24px",
                  height: "24px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                src="/img/googleIcon.png"
              />
            </Box>
          </Box>
          <div className="hdy-paragraph-text" style={{ marginTop: "30px" }}>
            Already have an account?{" "}
            <span>
              <Link className="hdy-green-href" to={"/login"}>
                Log in
              </Link>
            </span>
          </div>
        </HeyDayCard>
      </Box>
    </div>
  );
};

export default SignUp;
