import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Payment.css";

const ThankYouPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const notifyParent = (paramString) => {
    if (window.parent && window.parent.handleFetchApproval) {
      window.parent.handleFetchApproval({
        paramString,
      });
    }
  };
  useEffect(() => {
    let notify = false;
    if (window.parent !== window) {
      try {
        const parentSessionStorage = window.parent.sessionStorage;
        setIsLoggedIn(!!parentSessionStorage.getItem("token"));
        notify = !!parentSessionStorage.getItem("token");
      } catch (error) {
        console.error("Error accessing parent session storage:", error);
      }
    } else {
      console.log("This window is not in an iframe.");
      setIsLoggedIn(!!sessionStorage.getItem("token"));
      notify = !!sessionStorage.getItem("token");
    }
    const app = document.querySelector(".App");
    if (app) app.style.background = "transparent";
    let paramString = window.location.href.substring(
      window.location.href.indexOf("Id")
    );
    if (notify) notifyParent(paramString);
  }, []);

  return (
    <div className="payment-success-container">
      <img alt="payment-success" src="/img/payment-success.png" />
      <h1>Welcome to HeyDay!</h1>
      <div>
        <p>Congratulations! your are ready to go!</p>
        <p>processed successfully.</p>
      </div>
      {isLoggedIn ? (
        <p>Click on the "Next" button to complete your integration</p>
      ) : (
        <div className="hdy-paragraph-text" style={{ marginTop: "30px" }}>
          Switch to{" "}
          <span>
            <Link className="hdy-green-href" to={"/login"}>
              Login Page
            </Link>
          </span>
        </div>
      )}
    </div>
  );
};

export default ThankYouPage;
