import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Link, useLocation } from 'react-router-dom';

export default function NestedList({ nestedList, domainIndex }) {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component='nav'
      aria-labelledby='nested-list-subheader'
    >
      <React.Fragment key={nestedList.title}>
        <ListItemButton
          onClick={handleClick}
          disableRipple
          sx={{ borderRadius: '100px' }}
          style={{
            color:
              location.pathname ===
              '/' + nestedList.title.toLowerCase() + '/' + domainIndex
                ? '#FFBA41'
                : 'black',
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: '30px',
              color:
                location.pathname ===
                '/' + nestedList.title.toLowerCase() + '/' + domainIndex
                  ? '#FFBA41'
                  : 'black',
            }}
          >
            {nestedList.icon ? nestedList.icon : <StarBorder />}
          </ListItemIcon>
          <ListItemText primary={nestedList.title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {nestedList.links.map((link) => (
              <ListItemButton
                disableRipple
                to={link.url + '/' + domainIndex}
                component={Link}
                key={link.title}
                sx={{ ml: 4 }}
                style={{
                  width: '240px',
                  borderRadius: '100px',
                  textDecoration: 'none',
                  color:
                    location.pathname === link.url + '/' + domainIndex
                      ? '#ffffff'
                      : 'black',
                  backgroundColor:
                    location.pathname === link.url + '/' + domainIndex
                      ? '#FFBA41'
                      : 'transparent',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '30px',
                    color:
                      location.pathname === link.url + '/' + domainIndex
                        ? '#ffffff'
                        : 'black',
                  }}
                >
                  {link.icon ? link.icon : null}
                </ListItemIcon>
                <ListItemText primary={link.title} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    </List>
  );
}
