var defaultTmpl = {
  htmlBox: `<div class="hd-s-main">
              <%if(obj.logo){%>
                <div class="header">
                    <a hre="<%=obj.home%>"><img class="logo" src="<%=obj.logo%>" /></a>
                </div>
              <%}%>
             <div class="search_bar-fixed">
               <button class="closeSearch">
                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path class="closeSvgPath" d="M2.117 12l7.527 6.235-.644.765-9-7.521 9-7.479.645.764-7.529 6.236h21.884v1h-21.883z" stroke="#3b78e6"/></svg>
               </button>
               <button class="searchBtn">
               <svg width="17" height="22" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M28 14.5714C28 22.0714 21.9512 28.1429 14.5 28.1429C7.04878 28.1429 1 22.0714 1 14.5714C1 7.0715 7.04878 1 14.5 1C21.9512 1 28 7.0715 28 14.5714Z" stroke="#373737" stroke-width="2"/>
               <line x1="1" y1="-1" x2="6.82248" y2="-1" transform="matrix(0.494302 0.86929 -0.867189 0.497978 19.3333 27.2)" stroke="#373737" stroke-width="2" stroke-linecap="round"/>
               </svg>       
               </button>
                   <input type="text" class="HeyDay_q" placeholder="[!searchBoxText]" />
               <div class="autoComplete_container">
                 <div class="autoComplete"></div>
                 <div class="instantSearch"></div>
               </div>
               <button class="clearIcon">
                 <svg width="12px" height="12px">
                     <line x1="0%" y1="0%" x2="100%" y2="100%" style="stroke:#828282;stroke-width:2" />
                     <line x1="0%" y1="100%" x2="100%" y2="0%" style="stroke:#828282;stroke-width:2" />
                 </svg>
               </button>
           </div>
            <div class="searchResults-container">
              <div class="side-filters-menu"></div>
              <div class="searchResults"></div>
            </div>
             <div class="paging"></div>
           </div>`,

  results_data: `<div id="hdyRD" class="hdy-side-container-filter-menu">
    <button id="close-menu-btn" aria-label="Close Menu">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      <div class="hdy-side-elements">
        <div class="hdy-element" id="hdyPriceRangeElement">
          <div id="hdyPriceRangeMobile" class="hdy-dropdown">
            <button id="hdyFilterBarBtn" class="hdy-dropbtn">
              <div class="hdy-section-title">[!priceRange] 
              <svg class="hdy-caret-icon" xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" viewBox="0 0 256 256">
              <defs></defs>
              <g style="stroke: black; stroke-width: 4; stroke-linecap: round; stroke-linejoin: round; fill: none; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                <path d="M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z" style="stroke: black; stroke-width: 4; fill: black;" stroke-linecap="round" />
              </g>
            </svg>
              </div>
            </button>
            <div id="hdyPriceRangeContent" class="hdy-dropdown-content">
              <div class="hdy-clear-results">
                <button id="clearPriceRangeBtn" class="hdy-clear-btn">[!clear]</button>
              </div>
              <div class="hdy-priceRange-container">
                <%= obj.priceRange %>
              </div>
            </div>
          </div>
          <div class="hdy-line"></div>
        </div>
        <% if (obj.brands) { %>
        <div class="hdy-element">
          <div id="hdyAttributes" class="hdy-dropdown">
            <div id="hdyBrand" class="hdy-it-attribute">
              <button id="hdyFilterBarBtn" class="hdy-dropbtn">
                <div class="hdy-section-title">[!brands] 
                  <svg class="hdy-caret-icon" xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" viewBox="0 0 256 256">
                  <defs></defs>
                  <g style="stroke: black; stroke-width: 4; stroke-linecap: round; stroke-linejoin: round; fill: none; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                    <path d="M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z" style="stroke: black; stroke-width: 4; fill: black;" stroke-linecap="round" />
                  </g>
                </svg>
                </div>
              </button>
              <div class="hdy-dropdown-content">
                <div class="hdy-checkbox-div">
                  <div class="hdy-clear-results">
                    <button id="hdyClearBrandsBtn" class="hdy-clear-btn">[!clear]</button>
                  </div>
                  <div class="hdy-checkboxes-div"><%= obj.brands %></div>
                </div>
              </div>
            </div>
          </div>
          <div class="hdy-line"></div>
        </div>
        <% } %>
        <% if (obj.categories !== "</div>") { %>
        <div class="hdy-element">
          <div id="categories" class="hdy-dropdown">
            <button id="hdyFilterBarBtn" class="hdy-dropbtn">
              <div class="hdy-section-title">[!categories] 
                <svg class="hdy-caret-icon" xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" viewBox="0 0 256 256">
                <defs></defs>
                <g style="stroke: black; stroke-width: 4; stroke-linecap: round; stroke-linejoin: round; fill: none; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                  <path d="M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z" style="stroke: black; stroke-width: 4; fill: black;" stroke-linecap="round" />
                </g>
              </svg>
              </div>
            </button>
            <div class="hdy-dropdown-content">
              <div class="hdy-checkbox-div">
                <div class="hdy-clear-results">
                  <button class="clearCategoriesBtn">[!clear]</button>
                </div>
                <div class="hdy-checkboxes-div"><%= obj.categories %></div>
              </div>
            </div>
          </div>
          <div class="hdy-line"></div>
        </div>
        <% } %>
        <% Object.keys(obj).forEach(function(key) { %>
        <% if (key !== 'categories' && key !== 'brands' && key !== 'brand' && key !== 'sortBySelected' && key !== 'priceRange') { %>
        <div class="hdy-element">
          <div id="attributes" class="hdy-dropdown">
            <div id="<%= key %>" class="it_attribute">
              <button id="hdyFilterBarBtn" class="hdy-dropbtn">
                <div class="hdy-section-title"><% if (key === "color") { %>צבע<% } else { %><%= key %><% } %>
                  <svg class="hdy-caret-icon" xmlns="http://www.w3.org/2000/svg" version="1.1" width="14" height="14" viewBox="0 0 256 256">
                  <defs></defs>
                  <g style="stroke: black; stroke-width: 4; stroke-linecap: round; stroke-linejoin: round; fill: none; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                    <path d="M 90 24.25 c 0 -0.896 -0.342 -1.792 -1.025 -2.475 c -1.366 -1.367 -3.583 -1.367 -4.949 0 L 45 60.8 L 5.975 21.775 c -1.367 -1.367 -3.583 -1.367 -4.95 0 c -1.366 1.367 -1.366 3.583 0 4.95 l 41.5 41.5 c 1.366 1.367 3.583 1.367 4.949 0 l 41.5 -41.5 C 89.658 26.042 90 25.146 90 24.25 z" style="stroke: black; stroke-width: 4; fill: black;" stroke-linecap="round" />
                  </g>
                </svg>
                </div>
              </button>
              <div class="hdy-dropdown-content">
                <div class="hdy-checkbox-div">
                  <div class="hdy-clear-results">
                    <button id="hdyClearAttBtn" class="clearAttBtn">[!clear]</button>
                  </div>
                  <div class="hdy-checkboxes-div"><%= obj[key] %></div>
                </div>
              </div>
            </div>
          </div>
          <div class="hdy-line"></div>
        </div>
        <% } %>
        <% }); %>
      </div>
    </div>
    
           `,
  autocomplete_item: `<li class="ACel">
              <div class="clockIcon"><svg class="[!AC_item_left]" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="8.16016" cy="8" r="7" stroke="#C5C5C5" stroke-opacity="0.9" stroke-width="2"/>
              <path d="M8.16016 4V9L11.6602 11" stroke="#C5C5C5" stroke-opacity="0.9" stroke-width="2"/>
              </svg>
              <span class="removeHistory">x</span>
              </div>
              <div class="searchIcon"><svg class="[!AC_item_right]" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83207 0.984141C4.96202 1.0875 4.35371 1.25898 3.64191 1.60149C2.96611 1.92667 2.52673 2.23768 1.95867 2.79296C1.05462 3.67665 0.496543 4.69042 0.212303 5.96539C0.120315 6.37782 0.108825 6.5044 0.100368 7.19554C0.0922333 7.85858 0.102108 8.02453 0.171291 8.38602C0.651277 10.8948 2.47623 12.8131 4.95331 13.4124C5.34054 13.5061 5.48426 13.5214 6.16444 13.5417C6.82007 13.5612 7.00245 13.5541 7.37615 13.4945C8.08951 13.3809 8.89938 13.1003 9.49288 12.7613L9.76976 12.6031L11.8686 14.733C14.2542 17.1537 14.1426 17.0617 14.6978 17.067C15.1018 17.0709 15.3807 16.9551 15.6572 16.6688C15.935 16.381 16.0093 16.1988 16.0129 15.7963C16.0182 15.2104 16.1329 15.3563 13.7454 12.9113L11.6465 10.7619L11.7806 10.5431C12.1444 9.94978 12.4465 9.15268 12.5862 8.4177C12.6589 8.03483 12.6675 7.8887 12.6592 7.17643C12.6491 6.31096 12.6021 5.99703 12.3785 5.30231C11.8785 3.74885 10.6761 2.35189 9.22607 1.63997C8.36796 1.21869 7.57022 1.02093 6.6028 0.989628C6.24964 0.978222 5.90282 0.975761 5.83207 0.984141ZM7.23499 2.97938C8.7562 3.26349 10.0699 4.42702 10.5527 5.91786C11.2134 7.95761 10.2827 10.2015 8.37822 11.161C7.6446 11.5306 6.99951 11.6701 6.20099 11.6318C5.60565 11.6033 5.24261 11.5278 4.75058 11.3303C3.85166 10.9695 3.04026 10.257 2.55 9.39794C1.96944 8.38063 1.84396 6.96783 2.23547 5.85705C2.46813 5.19703 2.83436 4.61915 3.32014 4.14545C4.36404 3.1276 5.77639 2.70694 7.23499 2.97938Z" fill="#C5C5C5" fill-opacity="0.9"/>
              </svg>
              </div>
              <%=obj.item%>
              </li>`,
  instant_search_item: `<a class="Rlink" onclick="navigateToUrl('<%=obj.clickUrl%>')";"><div class="IRmain <%=obj.phz%>">
              <div class="IRimgC"><img src="<%=obj.pd.img%>" class="IRimg" /></div>
              <div class="instant-text">
              <%if(obj.pd.title){%>
                <div class="Rtitle"><%=obj.pd.title%></div>
              <%}%>
              <%if(obj.pd.product && obj.pd.product.sale_price){%>
              <div class="Rprice_before"><%= obj.pd.product.currency %> <%=obj.pd.product.price%></div>
              <div class="Rprice"><%= obj.pd.product.currency %> <%=obj.pd.product.sale_price%></div>
              <%}%>
              <%else {%>
                <div class="Rprice"><%= obj.pd.product.currency %> <%=obj.pd.product.price%></div>
              <%}%> <%if(obj.pd.brand){%>
              <div class="Rbrand"><%=obj.pd.brand%></div>
              <%}%></div>
              </div></a>`,
  search_item: `<div class="Rlink">
      <div class="Rmain <%=obj.phz%>">
          <div class="slideshow-container">
              <% if (Array.isArray(obj.pd.additional_images) && obj.pd.additional_images.length > 0) { %>
                  <% obj.pd.additional_images = [obj.pd.img, ...obj.pd.additional_images]; %>
                  <% obj.pd.additional_images.forEach(function(image, index) { %>
                      <div class="RimgC fade slide">
                          <a href="<%=obj.clickUrl%>" target="_parent">
                              <img src="<%= image %>" <%if(obj.defaultImg){%> onerror="this.onerror=null;this.src='<%=obj.defaultImg%>';"<%}%> class="Rimg" />
                          </a>
                      </div>
                  <% }); %>
                  <a class="prev"></a>
                  <a class="next"></a>
              <% } else { %>
                  <div class="RimgC">
                      <a href="<%=obj.clickUrl%>" target="_parent">
                          <img src="<%= obj.pd.img %>" <%if(obj.defaultImg){%> onerror="this.onerror=null;this.src='<%=obj.defaultImg%>';"<%}%> class="Rimg" />
                      </a>
                  </div>
              <% } %>
          </div>
          <div class="dots-container">
              <% if (Array.isArray(obj.pd.additional_images) && obj.pd.additional_images.length > 0) { %>
                  <% obj.pd.additional_images.forEach(function(_, index) { %>
                      <span class="dot <%= index === 0 ? 'imgActive' : '' %>"></span>
                  <% }); %>
              <% } %>
          </div>
          <div class="results-box">
              <% if(obj.pd.brand){ %>
                  <div class="Rbrand"><%= obj.pd.brand %></div>
              <% } else { %>
                  <div class="Rbrand"></div>
              <% } %>
              <a href="<%=obj.clickUrl%>" target="_parent" class="Rtitle"><%= obj.pd.title %></a>
              <% if (obj.pd.hdyAttr) { %>
                <div class="attributes-container">
                    <% for (var attrKey in obj.pd.hdyAttr) { %>
                        <div class="attribute-group">
                            <div class="hdy-attr-dropdown">
                                <div class="hdy-attr-selection">
                                    <span class="hdy-selected-value"><%= decodeURIComponent(attrKey) %>:</span>
                                    <div class="hdy-dropdown-arrow">
                                    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon"><path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
                                    </div>
                                </div>
                                <div class="hdy-dropdown-list">
                                    <% for (var valueKey in obj.pd.hdyAttr[attrKey]) { %>
                                        <div class="hdy-dropdown-item" data-value-url="<%= obj.pd.hdyAttr[attrKey][valueKey].url %>" data-value-img="<%= obj.pd.hdyAttr[attrKey][valueKey].img %>">
                                            <%= valueKey %>
                                        </div>
                                    <% } %>
                                </div>
                            </div>
                        </div>
                    <% } %>
                </div>
            <% } %>
              <% if(obj.pd.product && obj.pd.product.sale_price){ %>
                  <div class="Rprice_before"><%= obj.pd.product.currency %><%= obj.pd.product.price %></div>
                  <div class="Rprice"><%= obj.pd.product.currency %><%= obj.pd.product.sale_price %></div>
              <% } else { %>
                  <% if(obj.pd.product && obj.pd.product.price && obj.pd.product.currency){ %>
                      <div class="Rprice"><%= obj.pd.product.currency %><%= obj.pd.product.price %></div>
                  <% } %>
              <% } %>
              <div class="add-to-cart-container">
                  <div class="add-qty">
                      <div class="quantity-control" data-quantity="">
                          <button class="quantity-btn" onclick="decreaseQuantity(this)">
                              <svg viewBox="0 0 409.6 409.6">
                                  <g>
                                      <g>
                                          <path d="M392.533,187.733H17.067C7.641,187.733,0,195.374,0,204.8s7.641,17.067,17.067,17.067h375.467 c9.426,0,17.067-7.641,17.067-17.067S401.959,187.733,392.533,187.733z" />
                                      </g>
                                  </g>
                              </svg>
                          </button>
                          <input type="number" class="quantity-input" data-quantity-target="" value="1" step="1" min="1" max="" name="quantity">
                          <button class="quantity-btn" onclick="increaseQuantity(this)">
                              <svg viewBox="0 0 426.66667 426.66667">
                                  <path d="m405.332031 192h-170.664062v-170.667969c0-11.773437-9.558594-21.332031-21.335938-21.332031-11.773437 0-21.332031 9.558594-21.332031 21.332031v170.667969h-170.667969c-11.773437 0-21.332031 9.558594-21.332031 21.332031 0 11.777344 9.558594 21.335938 21.332031 21.335938h170.667969v170.664062c0 11.777344 9.558594 21.335938 21.332031 21.335938 11.777344 0 21.335938-9.558594 21.335938-21.335938v-170.664062h170.664062c11.777344 0 21.335938-9.558594 21.335938-21.335938 0-11.773437-9.558594-21.332031-21.335938-21.332031zm0 0" />
                              </svg>
                          </button>
                      </div>
                  </div>
                  <div class="add-to-cart-btn" data-product-id="<%= obj.pd.id %>" data-product-url="<%=obj.clickUrl%>" onclick="handleAddToCart(this)">הוספה לסל</div>
              </div>
          </div>
      </div>
  </div>`,
  selected_filters: `[!selectedFilters]`,
  ellipsis_format1: `[!ellipsisF]`,
  ellipsis_format2: `[!ellipsisL]`,
  results_without: `<p id="res_p">[!resWithout]: <b><%=obj.nr%></b><br />[!approx] <%=obj.res%> [!resFound]</p>`,
  approximately_found: `<p class="res_p">[!approx] <%=obj.res%> [!resFound] [!for] "<%=obj.q%>"</p>`,
  filters: `<div class="filters-container"><div class="select-dropdown">
                        <div class="select-dropdown-header" id="selectDropdownMenuButton">
                          <span class="selected">
                            <% if (obj.sortBySelected === "relevance") { %>
                                [!relevance]
                            <% } else if (obj.sortBySelected === "price_asc") { %>
                              [!priceLTH]
                            <% } else if (obj.sortBySelected === "price_desc") { %>
                              [!priceHTL]
                            <% } else if (obj.sortBySelected === "date_asc") { %>
                              [!dateN]
                            <% }else if (obj.sortBySelected === "date_desc") { %>
                              [!dateL]
                            <% }else { %>
                                [!sortBy]
                            <% } %>
                        </span>
                            <i class="arrow"></i>
                            <div class="select-dropdown-menu" id="selectDropdownMenu">
                                <div class="select-dropdown-item" data-value="relevance">[!relevance]</div>
                                <div class="select-dropdown-item" data-value="price_asc">[!priceLTH]</div>
                                <div class="select-dropdown-item" data-value="price_desc">[!priceHTL]</div>
                                <div class="select-dropdown-item" data-value="date_asc">[!dateN]</div>
                                <div class="select-dropdown-item" data-value="date_desc">[!dateL]</div>
                            </div>
                        </div>
                    </div></div>`,
  no_results: `<p class="noRes">[!noRes] <b><%=obj.q%>`,
  spelling_suggestions: `<p class="hd-s-syn">[!didYouMean]
              <span class="c-p" onclick="search({q:\'<%=obj.q%>\', dym:true})">\"<%=obj.v%>\"</span>?
              </p><br/>`,
  spelling_correction: `<div>
                <p class="hd-s-syn fn-12">Showing results for <b><i><%=obj.c%></i></b></p>
                <p class="hd-s-syn">Display results for <span class="c-p" onclick="search({f:true})">\"<%=obj.q%>\"</span> instead</p>
                </div>`,
  paging_item: `<<%if(obj.active){%>span<%}else{%>a<%}%> href="<%=obj.href%>" data-p="<%=obj.href%>" title="<%=obj.title%>" class="<%=obj.active?'active':'do-search'%>"><%=obj.title%></<%if(obj.active){%>span<%}else{%>a<%}%>>`,
  autocomplete_holder: "ul",
  price_range_slider: `<div class="hdy-price-range-slider">
      <div class="d-flex">
        <div class="wrapper">
          <div class="price-input">
            <div class="field-hdy">
              <% if (obj.rangeFilter) { %>
                <input type="number" id="rangeFrom" class="input-min" value="<%= obj.rangeFilter[1]/10 %>">
              <% } else if (obj.catData.ranges.price) { %>
                <input type="number" id="rangeFrom" class="input-min" value="<%= obj.catData.ranges.price[0]/10 %>">
              <% } %>
            </div>
            <span class="span-to">[!to]</span>
            <div class="field-hdy">
              <% if (obj.rangeFilter) { %>
                <input type="number" id="rangeTo" class="input-max" value="<%= obj.rangeFilter[2]/10 %>">
              <% } else if (obj.catData.ranges.price) { %>
                <input type="number" id="rangeTo" class="input-max" value="<%= obj.catData.ranges.price[1]/10 %>">
              <% } %>
            </div>
          </div>
          <div id="hdy-slider" class="slider">
            <div class="progress"></div>
          </div>
          <div class="range-input">
            <% if (obj.rangeFilter) { %>
              <input type="range" class="range-min" min="<%= obj.rangeFilter[1]/10 %>" max="<%= obj.rangeFilter[2]/10 %>" value="<%= obj.rangeFilter[1]/10 %>" step="1">
              <input type="range" class="range-max" min="<%= obj.rangeFilter[1]/10 %>" max="<%= obj.rangeFilter[2]/10 %>" value="<%= obj.rangeFilter[2]/10 %>" step="1">
            <% } else if (obj.catData.ranges.price) { %>
              <input type="range" class="range-min" min="<%= obj.catData.ranges.price[0]/10 %>" max="<%= obj.catData.ranges.price[1]/10 %>" value="<%= obj.catData.ranges.price[0]/10 %>" step="1">
              <input type="range" class="range-max" min="0" max="<%= obj.catData.ranges.price[1]/10 %>" value="<%= obj.catData.ranges.price[1]/10 %>" step="1">
            <% } %>
          </div>
        </div>
        <button id="filterRangeBtn">[!filter]</button>
      </div>
    </div>`,
  price_range: `<div class="filter-price-range-container">
     <div class="form-input"><input placeholder="[!from]" min="1" type="number" id ="rangeFrom" class="" /><label for="[!from]">[!from]</label></div><div class="form-input"><input type="number" min="1" placeholder="[!to]" id ="rangeTo" class=""/><label for="[!to]">[!to]</label></div><button id="filterRangeBtn">[!filter]</button></div>`,
};

export default defaultTmpl;
