import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "../Auth/Login/Login";
import SignUp from "../Auth/SignUp/SignUp";
import Invitation from "../Components/Admin/Invitation/Invitation";
import ThankYouPage from "../Components/SelfServe/Steps/Payment/ThankYouPage";
import AutoLogin from "../Auth/Login/AutoLogin";
import Welcome from "../Components/Admin/Welcome/Welcome";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/autoLogin" element={<AutoLogin />} />
      <Route path="/invitation/:token" element={<Invitation />} />
      <Route path="/selfserve/thank-you-page/*" element={<ThankYouPage />} />
      <Route path="/welcome/:token/:uName/:password" element={<Welcome />} />
      <Route path="/*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default AuthRoutes;
